import AbstractService from '@/api/services/AbstractService';
import Consultation from '@/api/objects/Consultation';

class PatientExpedientConsultationService extends AbstractService {
  public async all(expedientId: number): Promise<Consultation[]> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedientId}/consultations',
      { expedientId }));
  }
}

export default new PatientExpedientConsultationService();
