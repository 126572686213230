import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { GeneralModalType, GeneralState } from '@/store/modules/general/general.types';

type Context = ActionContext<GeneralState, State>;

const actions: ActionTree<GeneralState, State> = {
  modal({ commit }: Context, payload: Partial<GeneralModalType>) {
    commit('modal', payload);
  },
  disableModalLeftButton({ commit }: Context) {
    commit('disableModalLeftButton');
  },
  enableModalLeftButton({ commit }: Context) {
    commit('enableModalLeftButton');
  },
  disableModalRightButton({ commit }: Context) {
    commit('disableModalRightButton');
  },
  enableModalRightButton({ commit }: Context) {
    commit('enableModalRightButton');
  },
  hideModal({ commit }: Context) {
    commit('hideModal');
  },
};

export default actions;
