
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ThePatientConsultationScrollItem',
  components: {},
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/consultation', ['consultations', 'currentConsultation']),
    ...mapState('patient/expedient/treatment', ['currentTreatment']),
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
    canShowConsultation(): boolean {
      return this.can('patient.expedient.consultation.show');
    },
  },
  methods: {
    type(consultation) {
      const type = this.formsSettings.consultation_type_options.find((o) => o.key === consultation.type);

      return type.label;
    },
    async setConsultation(consultation) {
      await this.$store.dispatch('patient/expedient/consultation/getCurrentConsultation', {
        expedientId: this.currentExpedient.id,
        consultationId: consultation.id,
      });

      await this.$router.push({
        name: 'patient.expedient.show',
        params: {
          expedient: this.currentExpedient.id,
          tabName: 'consultas',
          consultation: consultation.id,
        },
        query: { tratamiento: this.currentTreatment?.id },
      });
    },
  },

});
