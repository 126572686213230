import AbstractService from '@/api/services/AbstractService';
import Professional from '@/api/objects/Professional';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';

class AdminProfessionalService extends AbstractService {
  public async get(purchaseAuthorizationId: number): Promise<PurchaseAuthorization> {
    return this.request('get', this.buildPath('admin/purchase-authorization/{purchaseAuthorizationId}',
      { purchaseAuthorizationId }));
  }

  public async all(filters: any, paginate = false): Promise<Professional[] | any> {
    const path = paginate ? this.buildPath('admin/purchase-authorizations-paginated')
      : this.buildPath('admin/purchase-authorizations');

    return this.request(
      'get',
      path,
      filters,
      {},
      undefined,
      paginate,
    );
  }

  public async download(filters?: any): Promise<any> {
    return this.request('get', this.buildPath('admin/purchase-authorizations-export'), filters);
  }
}

export default new AdminProfessionalService();
