import BaseObject from '@/api/objects/BaseObject';
import User from '@/api/objects/User';
import Clinic from '@/api/objects/Clinic';

export interface ProfessionalContract {
  canFreelyAuthorize: boolean,
  bornDate: string;
  college: string;
  collegeNumber: string,
  genre: string,
  name: string,
  identityDocType: string;
  nif: string,
  phoneNumber: string,
  speciality: string,
  subspeciality: string,
  surname: string,
  user: User | null,
  active: boolean,
  personalLine1: string,
  personalLine2: string,
  personalCP: string,
  personalCity: string,
  personalProvince: string,
  personalCountry: string,
  professionalLine1: string,
  professionalLine2: string,
  professionalCP: string,
  professionalCity: string,
  professionalProvince: string,
  professionalCountry: string,
}

export default class Professional extends BaseObject implements ProfessionalContract {
  get active(): boolean {
    return this.attributes.active;
  }

  get canFreelyAuthorize(): boolean {
    return this.attributes.can_freely_authorize;
  }

  get identityDocType(): string {
    return this.attributes.identity_doc_type;
  }

  get bornDate(): string {
    return this.attributes.born_date;
  }

  get college(): string {
    return this.attributes.college;
  }

  get collegeNumber(): string {
    return this.attributes.college_number;
  }

  get genre(): string {
    return this.attributes.genre;
  }

  get name(): string {
    return this.attributes.name;
  }

  get nif(): string {
    return this.attributes.nif;
  }

  get personalLine1(): string {
    return this.attributes.personal_line1;
  }

  get personalLine2(): string {
    return this.attributes.personal_line2;
  }

  get personalCP(): string {
    return this.attributes.personal_cp;
  }

  get personalCity(): string {
    return this.attributes.personal_city;
  }

  get personalProvince(): string {
    return this.attributes.personal_province;
  }

  get personalCountry(): string {
    return this.attributes.personal_country;
  }

  get phoneNumber(): string {
    return this.attributes.phone_number;
  }

  get professionalLine1(): string {
    return this.attributes.professional_line1;
  }

  get professionalLine2(): string {
    return this.attributes.professional_line2;
  }

  get professionalCP(): string {
    return this.attributes.professional_cp;
  }

  get professionalCity(): string {
    return this.attributes.professional_city;
  }

  get professionalProvince(): string {
    return this.attributes.professional_province;
  }

  get professionalCountry(): string {
    return this.attributes.professional_country;
  }

  get speciality(): string {
    return this.attributes.speciality;
  }

  get subspeciality(): string {
    return this.attributes.subspeciality;
  }

  get surname(): string {
    return this.attributes.surname;
  }

  get user(): User | null {
    return this.getRelation('user');
  }

  get clinics(): Clinic[] | null {
    return this.getRelation('clinics');
  }
}
