import getRouteHome from '@/helpers/redirectToHome';

export default async function patient({
  next,
  store,
}: { next: any, store: any }): Promise<void> {
  const { authUser } = store.state.user;

  if (!authUser) {
    next({ name: 'login' });
  }

  if (authUser.role.name === 'Paciente') {
    next();
  } else {
    const routeName = getRouteHome(authUser.role.name);
    next({ name: routeName });
  }
}
