import BaseObject from '@/api/objects/BaseObject';
import Patient from '@/api/objects/Patient';
import User from '@/api/objects/User';
import Professional from '@/api/objects/Professional';
import Clinic from '@/api/objects/Clinic';

export interface CalendarEventContract {
  type: string;
  title: string;
  status: string;
  description: string;
  startDatetime: string;
  endDatetime: string;
  createdAt: string;
  updatedAt: string;
  clinicId: number | null;
  patientId: number | null;
  professionalId: number | null;
  ownerId: number | null;
  authorId: number | null;
  clinic: Clinic | null;
  patient: Patient | null;
  professional: Professional | null;
  owner: User | null;
  author: User | null;
}

export default class CalendarEvent extends BaseObject implements CalendarEventContract {
  get type(): string {
    return this.attributes.type;
  }

  get title(): string {
    return this.attributes.title;
  }

  get status(): string {
    return this.attributes.status;
  }

  get description(): string {
    return this.attributes.description;
  }

  get startDatetime(): string {
    return this.attributes.start_datetime;
  }

  get endDatetime(): string {
    return this.attributes.end_datetime;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get clinicId(): number {
    return this.attributes.clinic_id;
  }

  get patientId(): number {
    return this.attributes.patient_id;
  }

  get professionalId(): number {
    return this.attributes.professional_id;
  }

  get ownerId(): number {
    return this.attributes.owner_id;
  }

  get authorId(): number {
    return this.attributes.author_id;
  }

  get clinic(): Clinic | null {
    return this.getRelation('clinic');
  }

  get patient(): Patient | null {
    return this.getRelation('patient');
  }

  get professional(): Professional | null {
    return this.getRelation('professional');
  }

  get owner(): User | null {
    return this.getRelation('owner');
  }

  get author(): User | null {
    return this.getRelation('author');
  }
}
