
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Media from '@/api/objects/Media';
import api from '@/api/index';

export default defineComponent({
  // TODO: This could be refactored to be used for any role
  name: 'TheProfessionalDocumentShowModal',
  components: {
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  props: {
    media: {
      type: Media,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('user', ['authUser']),
    isPdf() {
      return this.media.name.includes('.pdf');
    },
    isWord() {
      return (this.media.name.includes('.doc') || this.media.name.includes('.docx'));
    },
    isText() {
      return this.media.name.includes('.txt');
    },
    isFile() {
      return !this.isPdf && !this.isWord && !this.isText;
    },
    isImage() {
      return this.media.mimeType.includes('image/');
    },
  },
  methods: {
    getCollectionName(name) {
      switch (name) {
        case 'x-ray': {
          return this.$t('Radiografías');
        }
        case 'analytical': {
          return this.$t('Analíticas');
        }
        case 'resonance': {
          return this.$t('Resonancias');
        }
        case 'images': {
          return this.$t('Imágenes');
        }
        case 'others': {
          return this.$t('Otros documentos');
        }
        default: {
          return this.$t('Otros documentos');
        }
      }
    },
    async download() {
      try {
        const url = await api.professional.expedients.media.download(this.currentExpedient.id, this.media.id);

        window.location.href = url;
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido al intentar descargar el archivo'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
