import { MutationTree } from 'vuex';
import InterConsultation from '@/api/objects/InterConsultation';
import { InterConsultationState } from '@/store/modules/interconsultation/interconsultation.types';

interface InterConsultationMutations {
  setCurrentInterConsultation(state: InterConsultationState, payload: InterConsultation | null): void;

  setInterConsultations(state: InterConsultationState, payload: InterConsultation[]): void;
}

const mutations: MutationTree<InterConsultationState> & InterConsultationMutations = {
  setCurrentInterConsultation(state: InterConsultationState, interConsultation: InterConsultation | null) {
    state.currentInterConsultation = interConsultation;
  },
  setInterConsultations(state: InterConsultationState, interConsultations: InterConsultation[]) {
    state.interConsultations = interConsultations;
  },
};

export default mutations;
