import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5563091c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "disabled", "rows", "value", "placeholder", "readonly"]
const _hoisted_2 = {
  key: 1,
  class: "invalid-feedback mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["form-label", {'text-disabled': _ctx.disabled}])
        }, _toDisplayString(_ctx.$t(_ctx.label)), 3))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      class: _normalizeClass(["form-control", _ctx.textareaClass]),
      id: _ctx.idKey,
      disabled: _ctx.disabled,
      rows: _ctx.rows,
      value: _ctx.value,
      placeholder: _ctx.$t(_ctx.placeholder),
      readonly: _ctx.readonly,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitValue && _ctx.emitValue(...args)))
    }, null, 42, _hoisted_1),
    (!!_ctx.yupErrorsVariable)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.yupErrorsVariable), 1))
      : _createCommentVNode("", true)
  ]))
}