import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "top",
  ref: "headerTop"
}
const _hoisted_2 = { class: "container-fluid header-container px-0" }
const _hoisted_3 = { class: "navbar navbar-expand-lg uq-header position-relative text-base fw-semi ls-base fixed-top d-flex align-items-center justify-content-between py-18 px-12 px-sm-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_impersonate_stop = _resolveComponent("the-impersonate-stop")!
  const _component_the_menu_professional = _resolveComponent("the-menu-professional")!
  const _component_the_menu_administrator = _resolveComponent("the-menu-administrator")!
  const _component_the_menu_patient = _resolveComponent("the-menu-patient")!
  const _component_the_menu_auth = _resolveComponent("the-menu-auth")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(_component_the_impersonate_stop),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("nav", _hoisted_3, [
        (_ctx.authUser)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.authUser.role.name === 'Medico' || _ctx.authUser.role.name === 'Nutricionista')
                ? (_openBlock(), _createBlock(_component_the_menu_professional, { key: 0 }))
                : (_ctx.authUser.role.name === 'Administrador')
                  ? (_openBlock(), _createBlock(_component_the_menu_administrator, { key: 1 }))
                  : (_ctx.authUser.role.name === 'Paciente')
                    ? (_openBlock(), _createBlock(_component_the_menu_patient, { key: 2 }))
                    : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createBlock(_component_the_menu_auth, { key: 1 }))
      ])
    ])
  ], 512))
}