
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api/index';

export default defineComponent({
  name: 'TheLogout',
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  methods: {
    async logout() {
      try {
        await api.auth.logout();

        await this.$store.dispatch('user/logout');

        await this.$store.dispatch('user/setGuest', true);

        await this.$router.push({ name: 'login' });
      } catch (e) {
        console.error(e);
      }
    },
  },
});
