
import { defineComponent, PropType } from 'vue';
import useCapabilities from '@/resources/capabilities';
import Consultation from '@/api/objects/Consultation';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'VPatientConsultations',
  props: {
    consultations: {
      type: Array as PropType<Consultation[]>,
      required: false,
    },
    showConsultation: {
      type: Boolean,
      required: false,
      default: false,
    },
    showProfessional: {
      type: Boolean,
      required: false,
      default: false,
    },
    goShowConsultation: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('patient/expedient/treatment', ['currentTreatment']),
    ...mapState('app', ['formsSettings']),
  },
  methods: {
    type(consultation) {
      const type = this.formsSettings.consultation_type_options.find((o) => o.key === consultation.type);

      return type.label;
    },
    async setConsultation(consultation) {
      await this.$store.dispatch('patient/expedient/consultation/getCurrentConsultation', {
        expedientId: consultation.expedientId,
        consultationId: consultation.id,
      });

      await this.$store.dispatch('patient/expedient/setExpedientSection', {
        expedientSection: 'consultas',
      });
    },
  },
});
