import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import {
  ConsultationState,
} from '@/store/modules/patient/expedient/modules/consultation/consultation.types';
import api from '@/api';

type Context = ActionContext<ConsultationState, State>;

const actions: ActionTree<ConsultationState, State> = {
  async refreshExpedientConsultations({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    const expedientConsultations = await api.patient.expedient.consultation.all(expedientId);
    commit('setConsultations', expedientConsultations);
  },
  async getCurrentConsultation({
    state,
    commit,
  }: Context, {
    expedientId,
    consultationId,
  }: { expedientId: number, consultationId: number }) {
    if (!expedientId || !consultationId) {
      commit('setCurrentConsultation', null);
    } else if (!state.currentConsultation || state.currentConsultation.id !== consultationId
      || state.currentConsultation.id === consultationId) {
      commit('setStatus', 'loading');
      try {
        const consultation = state.consultations.find((o) => o.id === consultationId);

        commit('setCurrentConsultation', consultation);
        commit('setStatus', 'loaded');
      } catch (e) {
        commit('setCurrentConsultation', null);
        commit('setStatus', 'loaded');
      }
    }
  },
};

export default actions;
