
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import api from '@/api/index';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheLogin',
  components: {
    VFormGroupInputText,
    VButton,
  },
  data() {
    return {
      apiErrors: '',
      status: 'loaded',
    };
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      email: yup.string()
        .email()
        .required()
        .label(t('Correo electrónico')),
      password: yup.string()
        .required()
        .min(8)
        .label(t('Contraseña')),
    });

    const form = useFormValidation(rules);

    const { value: email } = useField('email');
    const { value: password } = useField('password');

    return {
      ...form,
      email,
      password,
      ...useCapabilities(),
    };
  },
  methods: {
    async login() {
      try {
        this.toggleAccepted();
        await api.auth.login(this.values);

        this.status = 'loading';

        await this.$store.dispatch('user/setAuthUser');

        this.toggleAccepted();
        this.status = 'loaded';

        await this.$store.dispatch('user/redirectToHome', { method: 'push' });
      } catch (e) {
        this.toggleAccepted();
        this.status = 'loaded';
        this.apiErrors = e.response.data.errors;
      }
    },
  },
});
