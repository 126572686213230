import getRouteHome from '@/helpers/redirectToHome';

export default async function professional({
  next,
  store,
}: { next: any, store: any }): Promise<void> {
  const { authUser } = store.state.user;
  const { isGuest } = store.state.user;

  if (authUser && !isGuest && (authUser.role?.name === 'Medico' || authUser.role?.name === 'Nutricionista')) {
    next();
  } else if (!authUser && isGuest) {
    next({ name: 'login' });
  } else {
    const routeName = getRouteHome(authUser.role.name);
    next({ name: routeName });
  }
}
