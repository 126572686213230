import { computed } from 'vue';
import { useStore } from 'vuex';

const useCapabilities = () => {
  const { state } = useStore();

  const user = computed(() => state.user.authUser);
  const permissions = computed(() => state.user.authUser?.role);

  const can = (permission: string) => permissions.value?.permissions?.includes(permission);

  return {
    user,
    can,
  };
};

export default useCapabilities;
