import { createLogger } from 'vuex';
import actions from '@/store/modules/protocol/protocol.actions';
import mutations from '@/store/modules/protocol/protocol.mutations';
import type { ProtocolState } from '@/store/modules/protocol/protocol.types';

export default {
  namespaced: true,
  state: (): ProtocolState => ({
    status: null,
    protocols: null,
    currentProtocol: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
