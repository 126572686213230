import { createLogger } from 'vuex';
import actions from '@/store/modules/treatment/treatment.actions';
import mutations from '@/store/modules/treatment/treatment.mutations';
import type { TreatmentState } from '@/store/modules/treatment/treatment.types';

export default {
  namespaced: true,
  state: (): TreatmentState => ({
    currentTreatment: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
