
import { defineComponent } from 'vue';
import api from '@/api';
import { mapState } from 'vuex';
import Media from '@/api/objects/Media';
import TheProfessionalResourcesGrid
  from '@/views/professional/resources/TheProfessionalResourcesGrid.vue';
import TheProfessionalResourcesList
  from '@/views/professional/resources/TheProfessionalResourcesList.vue';
// TODO: Check this
import Treeselect from 'vue3-treeselect';

export default defineComponent({
  name: 'TheProfessionalResources',
  components: {
    TheProfessionalResourcesList,
    TheProfessionalResourcesGrid,
    Treeselect,
  },
  data() {
    return {
      typeSelected: 'all',
      media: [] as Media[],
      status: 'loading',
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    categories() {
      const array: any[] = [];

      // eslint-disable-next-line no-unused-expressions
      this.formsSettings.uploaded_public_media_category_options?.forEach((value) => {
        if (!value.key.includes('.')) {
          array.push({
            id: value.key,
            label: value.label,
          });
        } else {
          // TODO: This should be dynamic, but I cannot spend more time on it, so I hardcode it
          const keys = value.key.split('.');

          if (keys.length === 2) {
            // Parent and children
            const find = array.find((category) => category.id === keys[0]);

            if (!find.children) {
              find.children = [];
            }

            find.children.push({
              id: value.key,
              label: value.label,
            });
          } else if (keys.length === 3) {
            // Parent and children and grandchildren
            const findParent = array.find((category) => category.id === keys[0]);

            if (!findParent.children) {
              findParent.children = [];
            }

            let findChildren = findParent.children.find((category) => category.id === `${keys[0]}.${keys[1]}`);

            if (!findChildren) {
              findParent.children.push({
                id: keys[1],
                label: '?',
              });

              findChildren = findParent.children.find((category) => category.id === `${keys[0]}.${keys[1]}`);
            }

            if (!findChildren.children) {
              findChildren.children = [];
            }

            findChildren.children.push({
              id: value.key,
              label: value.label,
            });
          }
        }
      });

      return array;
    },
  },
  watch: {
    typeSelected(newValue) {
      this.formsSettings.uploaded_public_media_category_options.forEach((type: any) => {
        if (type.key === newValue) {
          this.loadExpedientMedia();
        }
      });
    },
  },
  async mounted() {
    await this.loadExpedientMedia();
  },
  methods: {
    async loadExpedientMedia() {
      try {
        this.status = 'loading';

        const filters = {
          media_type: this.typeSelected,
        };

        this.media = await api.professional.expedients.media.allResources(filters);

        this.status = 'loaded';
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar los documentos'));
        this.status = 'loaded';
      }
    },
  },
});
