
import { defineComponent } from 'vue';
import TheProfessionalExpedientFormsCreateModal
  from '@/views/professional/expedients/medicalData/forms/TheProfessionalExpedientFormsCreateModal.vue';
import { mapState } from 'vuex';
import VMultipleButtonSelect from '@/components/vendor/basic/button/VMultipleButtonSelect.vue';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useCapabilities from '@/resources/capabilities';
import Form from '@/api/objects/Form';
import TheProfessionalNutritionalAnamnesis
  from '@/views/professional/anamnesis/TheProfessionalNutritionalAnamnesis.vue';
import TheMeasuresTableView
  from '@/views/professional/expedients/measures/parts/TheMeasuresTableView.vue';
import TheMeasuresChartView
  from '@/views/professional/expedients/measures/parts/TheMeasuresChartView.vue';

export default defineComponent({
  name: 'TheProfessionalExpedientMeasuresList',
  components: {
    TheProfessionalNutritionalAnamnesis,
    VButton,
    VMultipleButtonSelect,
    TheProfessionalExpedientFormsCreateModal,
    TheMeasuresTableView,
    TheMeasuresChartView,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: '',
      view: 'table',
      error: null,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'measures']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('app', ['formsSettings']),
    lastFormStructure(): Form {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === 'medidas');

      const lastFormStructureVersion = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      return lastFormStructureVersion.structure;
    },
    ascendentlyOrderedMeasures() {
      /*
       TODO: Please, make this work in just one method. I can't access a method from here nor
       use another computed from another one
       */
      const orderedMeasuresFormGroups = [] as any[];

      // Iterate through all measures forms
      this.measures.forEach((form: Form) => {
        const formGroups = form?.formGroups;
        if (formGroups) {
          formGroups.forEach((formGroup) => {
            orderedMeasuresFormGroups.push({
              formGroup,
              form,
            });
          });
        }
      });

      orderedMeasuresFormGroups.sort((fgFirst, fgSecond) => {
        const firstDate = fgFirst.formGroup.formValueByType('fecha').value;
        const secondDate = fgSecond.formGroup.formValueByType('fecha').value;

        if (firstDate < secondDate) {
          return 1;
        }

        if (firstDate > secondDate) {
          return -1;
        }

        return 0;
      });

      return orderedMeasuresFormGroups;
    },
    descendentlyOrderedMeasures() {
      /*
       TODO: Please, make this work in just one method. I can't access a method from here nor
       use another computed from another one
       */
      const orderedMeasuresFormGroups = [] as any[];

      // Iterate through all measures forms
      this.measures.forEach((form: Form) => {
        const formGroups = form?.formGroups;
        if (formGroups) {
          formGroups.forEach((formGroup) => {
            orderedMeasuresFormGroups.push({
              formGroup,
              form,
            });
          });
        }
      });

      orderedMeasuresFormGroups.sort((fgFirst, fgSecond) => {
        const firstDate = fgFirst.formGroup.formValueByType('fecha').value;
        const secondDate = fgSecond.formGroup.formValueByType('fecha').value;

        if (firstDate < secondDate) {
          return -1;
        }

        if (firstDate > secondDate) {
          return 1;
        }

        return 0;
      });

      return orderedMeasuresFormGroups;
    },
  },
  methods: {
    async loadExpedientMeasures() {
      await this.$store.dispatch('expedient/refreshExpedientMeasures', {
        expedientId: this.currentExpedient.id,
        treatmentId: this.currentTreatment?.id,
      });
    },
    async createExpedientMeasure(formData) {
      try {
        await api.professional.expedients.forms.create(this.currentExpedient.id, formData);

        this.$toast.success(this.$t('Los datos se han guardado correctamente.'));

        await this.loadExpedientMeasures();
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al guardar las medidas'));
      }
    },
    async answerNutritionalAnamnesis() {
      try {
        // eslint-disable-next-line max-len
        const nutritionalAnamnesis = await api.professional.expedients.anamnesis.startNutritional(this.currentExpedient.id);

        await this.$store.dispatch('expedient/setCurrentNutritionalAnamnesis', {
          nutritionalAnamnesis,
        });

        this.modalToShow = 'the-professional-nutritional-anamnesis';
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al empezar la anamnesis'));
      }
    },
  },
});
