import { createLogger } from 'vuex';
import actions from '@/store/modules/purchaseAuthorization/purchaseAuthorization.actions';
import mutations from '@/store/modules/purchaseAuthorization/purchaseAuthorization.mutations';
import type {
  PurchaseAuthorizationState,
} from '@/store/modules/purchaseAuthorization/purchaseAuthorization.types';

export default {
  namespaced: true,
  state: (): PurchaseAuthorizationState => ({
    currentPurchaseAuthorization: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
