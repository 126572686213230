
import { defineComponent } from 'vue';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'WaitingCard',
  components: {
    VButton,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: false,
      default: ('Esperando a que alguien se conecte a la videollamada...'), // Fixme como declarar el helper $t(
    },
  },
  computed: {
    ...mapState('expedient/room', ['currentRoom']),
    ...mapState('expedient', ['currentExpedient']),
  },
  methods: {
    async sendUrlToPatientViaMail() {
      if (this.currentRoom) {
        await this.$modal.confirm({
          title: this.$t('Enviar invitación por correo'),
          // eslint-disable-next-line max-len
          text: this.$t('Vamos a enviar un correo electrónico al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?'),
          confirmButtonText: this.$t('Enviar correo con enlace'),
          confirmButtonCallback: this.doSendUrlToPatientViaMail,
        });
      }
    },
    async doSendUrlToPatientViaMail() {
      try {
        await api.professional.expedients.room.sendRoomLinkToPatient(this.currentExpedient.id, 'mail');

        await this.$modal.info({
          title: this.$t('¡Enviado!'),
          // eslint-disable-next-line max-len
          text: this.$t('Hemos enviado el enlace al paciente por correo electrónico. Ahora podrá unirse a la videollamada.'),
        });

        return { error: true };
      } catch (e) {
        console.error({ e });
        await this.$modal.info({
          title: this.$t('Ha ocurrido un error'),
          text: this.$t('Algo ha salido mal, vuelve a probar más tarde...'),
        });

        return { error: true };
      }
    },
    async sendUrlToPatientViaSMS() {
      if (this.currentRoom) {
        await this.$modal.confirm({
          title: this.$t('Enviar invitación por SMS'),
          // eslint-disable-next-line max-len
          text: this.$t('Vamos a enviar un SMS al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?'),
          confirmButtonText: this.$t('Enviar SMS con enlace'),
          confirmButtonCallback: this.doSendUrlToPatientViaSMS,
        });
      }
    },
    async doSendUrlToPatientViaSMS() {
      try {
        await api.professional.expedients.room.sendRoomLinkToPatient(this.currentExpedient.id, 'sms');

        await this.$modal.info({
          title: this.$t('¡Enviado!'),
          text: this.$t('Hemos enviado el enlace al paciente por SMS. Ahora podrá unirse a la videollamada.'),
        });

        return { error: true };
      } catch (e) {
        console.error({ e });
        await this.$modal.info({
          title: this.$t('Ha ocurrido un error'),
          text: this.$t('Algo ha salido mal, vuelve a probar más tarde...'),
        });

        return { error: true };
      }
    },
  },
});
