
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import { mapState } from 'vuex';
import VLoader from '@/components/vendor/VLoader.vue';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminClinicEdit',
  components: {
    VFormGroupInputText,
    VLoader,
    VFormGroupSelect,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    vSelect,
  },
  props: {
    clinicId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('Nombre')),
      address: yup.string()
        .required()
        .label(t('Apellidos')),
      phoneNumberPrefix: yup.string()
        .required()
        .label(t('Prefijo telefónico')),
      phoneNumber: yup.string()
        .required()
        .label(t('Número de teléfono'))
        .matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: address } = useField('address');
    const { value: phoneNumberPrefix } = useField('phoneNumberPrefix');
    const { value: phoneNumber } = useField('phoneNumber');

    return {
      ...form,
      name,
      address,
      phoneNumberPrefix,
      phoneNumber,

      ...useCapabilities(),
    };
  },
  data() {
    return {
      isLoading: true,
    };
  },
  async mounted() {
    await this.loadClinic();
  },
  methods: {
    async loadClinic() {
      this.isLoading = true;

      const clinic = await api.admin.clinic.get(this.clinicId);

      this.name = clinic.name;
      this.address = clinic.address;
      const splitted = clinic.phoneNumber.split(' ');
      // eslint-disable-next-line prefer-destructuring
      this.phoneNumberPrefix = splitted[0];
      // eslint-disable-next-line prefer-destructuring
      this.phoneNumber = splitted[1];

      this.isLoading = false;
    },
    async updateClinic() {
      const data = {
        name: this.name,
        address: this.address,
        phone_number: `${this.values.phoneNumberPrefix} ${this.values.phoneNumber}`,
      };

      await api.admin.clinic.update(this.clinicId, data);

      this.$toast.success(this.$t('Clínica actualizada con éxito.'));

      this.$emit('clinic-updated');
    },
  },
});
