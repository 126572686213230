
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import VFormGroupTextarea from '@/components/vendor/basic/form/VFormGroupTextarea.vue';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import api from '@/api';
import Video from '@/api/objects/Video';
import vSelect from 'vue-select';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import useCapabilities from '@/resources/capabilities';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import TheAdminVideoUpdate from '@/views/administrator/videos/parts/TheAdminVideoUpdate.vue';
import TheAdminVideoDestroy from '@/views/administrator/videos/parts/TheAdminVideoDestroy.vue';

export default defineComponent({
  name: 'TheAdminVideoShow',
  components: {
    TheAdminVideoUpdate,
    TheAdminVideoDestroy,
    VButton,
    VFormGroupSelect,
    VFormGroupReadOnlyText,
    VFormGroupTextarea,
    vSelect,
  },
  props: {
    video: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      isLoading: true,
      videoLoaded: {} as Video,
      modalToShow: null as null | string,
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    visibility() {
      // eslint-disable-next-line max-len
      const visibility = this.formsSettings.video_visibility_options.find((ago) => ago.key === this.videoLoaded.visibility);

      return visibility.label;
    },
  },
  async created() {
    await this.loadVideo();
  },
  methods: {
    async loadVideo() {
      try {
        this.isLoading = true;

        this.videoLoaded = await api.admin.video.show(parseInt(this.video, 10));

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;

        this.$toast.error(this.$t('Ha ocurrido un error al cargar el vídeo.'));
      }
    },
    createUrl() {
      const aux = this.videoLoaded.url.substring(this.videoLoaded.url.indexOf('.') + 1);
      const urlId = aux.substring(aux.indexOf('/') + 1);

      return `https://player.vimeo.com/video/${urlId}`;
    },
  },
});
