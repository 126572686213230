
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import VChart from 'vue-echarts';
import { defineComponent } from 'vue';

use([
  CanvasRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

export default defineComponent({
  name: 'VLineChart',
  props: {
    values: {
      type: Array,
      required: false,
    },
    labels: {
      type: Array,
      required: false,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  components: {
    VChart,
  },
});
