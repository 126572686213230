import AbstractService from '@/api/services/AbstractService';
import Professional from '@/api/objects/Professional';

class AdminProfessionalService extends AbstractService {
  public store(data: any) {
    return this.request('post', this.buildPath('admin/professional'), [], data);
  }

  public all(filters: any, paginate = false): Promise<any> {
    const path = paginate ? this.buildPath('admin/professionals-paginated') : this.buildPath('admin/professionals');

    return this.request(
      'get',
      path,
      filters,
      {},
      undefined,
      paginate,
    );
  }

  public retrieve(professionalId: number): Promise<Professional> {
    return this.request('get', this.buildPath('admin/professional/{professionalId}', { professionalId }));
  }

  public update(professionalId: number, data: any): Promise<Professional> {
    return this.request('put', this.buildPath('admin/professional/{professionalId}', {
      professionalId,
    }), [], data);
  }

  public delete(professionalId: number): Promise<any> {
    return this.request('delete', this.buildPath('admin/professional/{professionalId}', {
      professionalId,
    }));
  }
}

export default new AdminProfessionalService();
