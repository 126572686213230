import PatientService from '@/api/services/Professional/PatientService';
import AuthService from '@/api/services/AuthService';
import ExpedientService from '@/api/services/Professional/Expedient/ExpedientService';
import ExpedientFormService from '@/api/services/Professional/Expedient/Forms/ExpedientFormService';
import ExpedientFormMeasuresService
  from '@/api/services/Professional/Expedient/Forms/ExpedientFormMeasuresService';
import ExpedientConsultationService
  from '@/api/services/Professional/Expedient/ExpedientConsultationService';
import ConsultationService from '@/api/services/Professional/ConsultationService';
import TreatmentService from '@/api/services/Professional/Expedient/Treatments/TreatmentService';
import TreatmentMeasureService
  from '@/api/services/Professional/Expedient/Treatments/TreatmentMeasureService';
import SettingsService from '@/api/services/Professional/SettingsService';
import ExpedientFormAnamnesisService
  from '@/api/services/Professional/Expedient/Forms/ExpedientFormAnamnesisService';
import ProductService from '@/api/services/Professional/ProductService';
import PurchaseAuthorizationService from '@/api/services/Professional/PurchaseAuthorizationService';
import ProtocolService from '@/api/services/Professional/ProtocolService';
import ProfessionalService from '@/api/services/Professional/ProfessionalService';
import MediaService from '@/api/services/Professional/Expedient/MediaService';
import CalendarEventService from '@/api/services/Professional/CalendarEventService';
import ExpedientRoomService from '@/api/services/Professional/Expedient/ExpedientRoomService';
import PatientExpedientService from '@/api/services/Patient/Expedient/PatientExpedientService';
import PatientExpedientFormMeasuresService
  from '@/api/services/Patient/Expedient/Forms/PatientExpedientFormMeasuresService';
import PatientTreatmentMeasureService
  from '@/api/services/Patient/Expedient/Treatments/PatientTreatmentMeasureService';
import PatientExpedientConsultationService
  from '@/api/services/Patient/Expedient/PatientExpedientConsultationService';
import PatientPurchaseAuthorizationService
  from '@/api/services/Patient/Expedient/PatientPurchaseAuthorizationService';
import PatientTreatmentService
  from '@/api/services/Patient/Expedient/Treatments/PatientTreatmentService';
import PatientExpedientFormService
  from '@/api/services/Patient/Expedient/Forms/PatientExpedientFormService';
import PatientConsultationService from '@/api/services/Patient/PatientConsultationService';
import PatientMediaService from '@/api/services/Patient/Expedient/PatientMediaService';
import PatientExpedientRoomService
  from '@/api/services/Patient/Expedient/PatientExpedientRoomService';
import PatientCalendarService from '@/api/services/Patient/PatientCalendarService';
import InterConsultationService from '@/api/services/Professional/InterConsultationService';
import AdminProfessionalService from '@/api/services/Admin/AdminProfessionalService';
import AdminClinicService from '@/api/services/Admin/AdminClinicService';
import ClinicService from '@/api/services/Professional/ClinicService';
import AdminCalendarEventService from '@/api/services/Admin/AdminCalendarEventService';
import AdminPatientService from '@/api/services/Admin/AdminPatientService';
import AdminImpersonateService from '@/api/services/Admin/AdminImpersonateService';
import AdminExpedientService from '@/api/services/Admin/AdminExpedientService';
import AdminMediaService from '@/api/services/Admin/AdminMediaService';
import AdminPurchaseAuthorizationService
  from '@/api/services/Admin/AdminPurchaseAuthorizationService';
import AdminVideoService from '@/api/services/Admin/AdminVideoService';
import AdminVideoCategoryService from '@/api/services/Admin/AdminVideoCategoryService';
import VideoService from '@/api/services/Professional/VideoService';
import VideoCategoryService from '@/api/services/Professional/VideoCategoryService';
import LocaleService from '@/api/services/Language/LocaleService';
import AdminResourceCategoryService from './services/Admin/AdminResourceCategoryService';

export default {
  professional: {
    patient: PatientService,
    expedient: ExpedientService,
    settings: SettingsService,
    expedients: {
      consultation: ExpedientConsultationService,
      treatments: TreatmentService,
      anamnesis: ExpedientFormAnamnesisService,
      forms: ExpedientFormService,
      media: MediaService,
      room: ExpedientRoomService,
    },
    expedientData: {
      measures: ExpedientFormMeasuresService,
      treatments: TreatmentService,
      treatment: {
        measure: TreatmentMeasureService,
      },
    },
    consultation: ConsultationService,
    clinic: ClinicService,
    product: ProductService,
    purchaseAuthorizations: PurchaseAuthorizationService,
    protocol: ProtocolService,
    calendarEvent: CalendarEventService,
    interConsultation: InterConsultationService,
    video: VideoService,
    videoCategory: VideoCategoryService,
  },
  patient: {
    expedients: PatientExpedientService,
    expedient: {
      consultation: PatientExpedientConsultationService,
      forms: PatientExpedientFormService,
      media: PatientMediaService,
      measures: PatientExpedientFormMeasuresService,
      purchaseAuthorizations: PatientPurchaseAuthorizationService,
      treatments: PatientTreatmentService,
      treatment: {
        measure: PatientTreatmentMeasureService,
      },
      room: PatientExpedientRoomService,
    },
    consultation: PatientConsultationService,
    calendar: PatientCalendarService,
  },
  admin: {
    professional: AdminProfessionalService,
    clinic: AdminClinicService,
    calendar: AdminCalendarEventService,
    patient: AdminPatientService,
    expedient: AdminExpedientService,
    impersonate: AdminImpersonateService,
    media: AdminMediaService,
    purchaseAuthorization: AdminPurchaseAuthorizationService,
    video: AdminVideoService,
    videoCategory: AdminVideoCategoryService,
    resourceCategory: AdminResourceCategoryService,
  },
  professionalData: ProfessionalService,
  language: {
    locale: LocaleService,
  },
  auth: AuthService,
};
