import AbstractService from '@/api/services/AbstractService';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';

class PurchaseAuthorizationService extends AbstractService {
  public async get(expedientId: number, purchaseAuthorizationId: number): Promise<PurchaseAuthorization> {
    // eslint-disable-next-line max-len
    return this.request('get', this.buildPath('expedient/{expedientId}/purchase-authorization/{purchaseAuthorizationId}',
      { expedientId, purchaseAuthorizationId }));
  }

  public async all(expedientId: number): Promise<PurchaseAuthorization[]> {
    return this.request('get', this.buildPath('expedient/{expedientId}/purchase-authorizations',
      { expedientId }));
  }

  public async create(expedientId: number, purchaseAuthorization: any) {
    return this.request('post', this.buildPath('expedient/{expedientId}/purchase-authorization',
      { expedientId }), [], purchaseAuthorization);
  }

  public async disable(expedientId: number, purchaseAuthorizationId: number) {
    return this.request('post',
      this.buildPath('expedient/{expedientId}/purchase-authorization/{purchaseAuthorizationId}/disable',
        {
          expedientId,
          purchaseAuthorizationId,
        }));
  }

  public async resendLink(expedientId: number, purchaseAuthorizationId: number) {
    return this.request('post',
      this.buildPath('expedient/{expedientId}/purchase-authorization/{purchaseAuthorizationId}/resend-link',
        {
          expedientId,
          purchaseAuthorizationId,
        }));
  }

  /**
   * Returns the cart redirection link of the provided token if valid.
   *
   * @param token
   */
  public async getCartRedirectionLink(token: string) {
    return this.request('get',
      this.buildPath('purchase-authorization/{token}/redirect',
        {
          token,
        }));
  }
}

export default new PurchaseAuthorizationService();
