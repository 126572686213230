import { MutationTree } from 'vuex';
import { TreatmentState } from '@/store/modules/treatment/treatment.types';

interface TreatmentMutations {
  setTreatment(state: TreatmentState, payload: any): void;
}

const mutations: MutationTree<TreatmentState> & TreatmentMutations = {
  setTreatment(state: TreatmentState, treatment: any) {
    state.currentTreatment = treatment;
  },
};

export default mutations;
