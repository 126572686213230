import { MutationTree } from 'vuex';

import {
  PurchaseAuthorizationState,
} from '@/store/modules/patient/expedient/modules/purchaseAuthorization/purchaseAuthorization.types';

import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';

interface PurchaseAuthorizationMutations {
  setCurrentPurchaseAuthorization(state: PurchaseAuthorizationState,
    purchaseAuthorization: PurchaseAuthorization): void;
}

const mutations: MutationTree<PurchaseAuthorizationState> & PurchaseAuthorizationMutations = {
  setCurrentPurchaseAuthorization(state: PurchaseAuthorizationState, purchaseAuthorization: PurchaseAuthorization) {
    state.currentPurchaseAuthorization = purchaseAuthorization;
  },
  setPurchaseAuthorizations(state: PurchaseAuthorizationState, purchaseAuthorizations: PurchaseAuthorization[]) {
    state.purchaseAuthorizations = purchaseAuthorizations;
  },
};

export default mutations;
