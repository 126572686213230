import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b089e66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "measures-container" }
const _hoisted_2 = { class: "measurement-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_measures_chart_view = _resolveComponent("the-measures-chart-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_the_measures_chart_view, {
        measures: _ctx.descendentlyOrderedMeasures,
        "expedient-id": _ctx.currentExpedient.id,
        onLoadExpedientMeasures: _ctx.loadExpedientMeasures,
        onShowFormCreateModal: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalToShow = 'the-professional-expedient-forms-create-modal'))
      }, null, 8, ["measures", "expedient-id", "onLoadExpedientMeasures"])
    ])
  ]))
}