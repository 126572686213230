import HttpParseResponse from '@/api/services/HttpParseResponse';

export default class BaseObject {
  public id: number;

  public attributes: any;

  public relations: any;

  public included: any[] = [];

  public constructor({ id, attributes }: any) {
    this.id = id;
    this.attributes = attributes;
  }

  public setRelations(relations): this {
    this.relations = relations;

    return this;
  }

  /**
   * Set Included
   *
   * @param included
   */
  public setIncluded(included: any[]): this {
    this.included = included;

    return this;
  }

  /**
   * Find object in included.
   *
   * @param object
   */
  public findObject(object: any) {
    object = this.included.find((o) => o.type === object.type && o.id === object.id);

    return HttpParseResponse.setIncluded(this.included).parseJson(object);
  }

  public getRelation(relation: any) {
    // When an optional relationship (mapped in back) does not exist, there are no relations
    if (!this.relations || !this.relations[relation]) {
      return null;
    }

    // Go through each object to get relation name
    relation = this.relations[relation].data;

    if (Array.isArray(relation)) {
      relation = relation.map((tmp) => this.findObject(tmp));
    } else if (relation) {
      relation = this.findObject(relation);
    }

    return relation;
  }
}
