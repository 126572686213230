
import { defineComponent } from 'vue';
// eslint-disable-next-line import/no-cycle
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Patient from '@/api/objects/Patient';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import { mapState } from 'vuex';
import api from '@/api';
import VLoader from '@/components/vendor/VLoader.vue';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import useCapabilities from '@/resources/capabilities';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminPatientUpdate',
  components: {
    VFormGroupInputDate,
    VFormGroupSelect,
    VFormGroupInputText,
    VLoader,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    vSelect,
  },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    // eslint-disable-next-line consistent-return
    /**
     * I use this method to store variables without
     * numbers, just text.
     *
     * @param number
     */
    const numberToSpelledString = function (number) {
      // TODO: Move to a helper
      switch (number) {
        case 1:
          return 'One';
        case 2:
          return 'Two';
        case 3:
          return 'Three';
        case 4:
          return 'Four';
        case 5:
          return 'Five';
        default:
          return 'One';
      }
    };

    /*
      Dynamically add phone numbers

      This is a trick I make to dynamically store up to
      5 different phone numbers with fixed variables that
      must exist in yup rules to be well managed.
     */
    const auxRules = {};
    for (let iterator = 1; iterator <= 5; iterator += 1) {
      // Dynamically build each fieldRule
      auxRules[`auxPhoneNumberPrefix${numberToSpelledString(iterator)}`] = yup.lazy((value, options) => {
        let rule = yup.string()
          .label(t('Prefijo telefónico'))
          .nullable();

        const field = options.parent[`auxPhoneNumber${numberToSpelledString(iterator)}`];
        if (!!field && field !== '') {
          rule = rule.required();
        }

        return rule;
      });
      auxRules[`auxPhoneNumber${numberToSpelledString(iterator)}`] = yup.lazy((value, options) => {
        let rule = yup.string()
          .label(t('Número de teléfono'));

        const field = options.parent[`auxPhoneNumberPrefix${numberToSpelledString(iterator)}`];
        if (!!field && field !== '') {
          rule = rule.required().matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras'));
        }

        return rule;
      });
    }

    const rules = yup.object({
      name: yup.string()
        .required()
        .max(60)
        .label(t('Nombre')),
      surname: yup.string()
        .required()
        .max(60)
        .label(t('Apellidos')),
      genre: yup.string()
        .required()
        .max(60)
        .label(t('Género')),
      bornDate: yup.string()
        .required()
        .max(60)
        .label(t('Fecha de nacimiento')),
      identityDocType: yup.string()
        .required()
        .label(t('Tipo de Documento')),
      nif: yup.lazy((value, options) => {
        // initialize default rules for all options
        const rule = yup.string()
          .required()
          .label(t('Número de documento'));
        // this property retrieves the information of the datasets initialized with yup
        const { identityDocType } = options.parent;
        const messageValidation = t('El {tipo_documento} registrado no es Válido', { tipo_documento: identityDocType });
        switch (identityDocType) {
          case 'dni':
            return rule.matches(/^[0-9]{8,8}[A-Za-z]$/g, messageValidation);
          case 'nie':
            return rule.matches(/^[XYZ|xyz]\d{7,8}[A-Za-z]$/, messageValidation);
          default:
            return rule; // here you can decide what is the default
        }
      }),
      phoneNumberPrefix: yup.string()
        .required()
        .label(t('Prefijo telefónico')),
      phoneNumber: yup.string()
        .required()
        .label(t('Número de teléfono'))
        .matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras')),
      email: yup.string()
        .email()
        .required()
        .label(t('Correo electrónico')),
      ...auxRules,
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: genre } = useField('genre');
    const { value: bornDate } = useField('bornDate');
    const { value: identityDocType } = useField('identityDocType');
    const { value: nif } = useField('nif');
    const { value: phoneNumberPrefix } = useField('phoneNumberPrefix');
    const { value: phoneNumber } = useField('phoneNumber');
    const { value: email } = useField('email');
    // Aux phone numbers
    const { value: auxPhoneNumberPrefixOne } = useField('auxPhoneNumberPrefixOne');
    const { value: auxPhoneNumberOne } = useField('auxPhoneNumberOne');
    const { value: auxPhoneNumberPrefixTwo } = useField('auxPhoneNumberPrefixTwo');
    const { value: auxPhoneNumberTwo } = useField('auxPhoneNumberTwo');
    const { value: auxPhoneNumberPrefixThree } = useField('auxPhoneNumberPrefixThree');
    const { value: auxPhoneNumberThree } = useField('auxPhoneNumberThree');
    const { value: auxPhoneNumberPrefixFour } = useField('auxPhoneNumberPrefixFour');
    const { value: auxPhoneNumberFour } = useField('auxPhoneNumberFour');
    const { value: auxPhoneNumberPrefixFive } = useField('auxPhoneNumberPrefixFive');
    const { value: auxPhoneNumberFive } = useField('auxPhoneNumberFive');

    return {
      ...form,
      ...useCapabilities(),
      name,
      surname,
      genre,
      bornDate,
      identityDocType,
      nif,
      phoneNumberPrefix,
      phoneNumber,
      email,
      auxPhoneNumberPrefixOne,
      auxPhoneNumberOne,
      auxPhoneNumberPrefixTwo,
      auxPhoneNumberTwo,
      auxPhoneNumberPrefixThree,
      auxPhoneNumberThree,
      auxPhoneNumberPrefixFour,
      auxPhoneNumberFour,
      auxPhoneNumberPrefixFive,
      auxPhoneNumberFive,

      numberToSpelledString,
    };
  },
  data() {
    return {
      patient: null as Patient | null,
      isLoading: true,
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  async beforeMount() {
    this.isLoading = true;

    // Load patient
    this.patient = await api.admin.patient.show(this.patientId);

    // Lets set data
    this.name = this.patient.user?.name;
    this.surname = this.patient.user?.surname;
    this.genre = this.patient.genre;
    this.bornDate = this.patient.bornDate;
    this.identityDocType = this.patient.identityDocType;
    this.nif = this.patient.nif;

    this.email = this.patient.user?.email;
    const phone = this.patient.user?.phoneNumber.split(' ');
    this.phoneNumberPrefix = phone ? phone[0] : '';
    this.phoneNumber = phone ? phone[1] : '';

    this.auxPhoneNumberPrefixOne = '';
    this.auxPhoneNumberOne = '';
    this.auxPhoneNumberPrefixTwo = '';
    this.auxPhoneNumberTwo = '';
    this.auxPhoneNumberPrefixThree = '';
    this.auxPhoneNumberThree = '';
    this.auxPhoneNumberPrefixFour = '';
    this.auxPhoneNumberFour = '';
    this.auxPhoneNumberPrefixFive = '';
    this.auxPhoneNumberFive = '';

    // Other aux phone numbers
    if (!!this.patient.user?.phoneNumbers) {
      let iterator = 1;
      this.patient.user.phoneNumbers.forEach((auxPhone) => {
        const aux = auxPhone.split(' ');
        // eslint-disable-next-line prefer-destructuring
        this[`auxPhoneNumberPrefix${this.numberToSpelledString(iterator)}`] = aux[0];
        // eslint-disable-next-line prefer-destructuring
        this[`auxPhoneNumber${this.numberToSpelledString(iterator)}`] = aux[1];

        iterator += 1;
      });
    }

    this.isLoading = false;
  },
  methods: {
    async updatePatient() {
      try {
        const data = {
          name: this.name,
          surname: this.surname,
          phone_number: `${this.values.phoneNumberPrefix} ${this.values.phoneNumber}`,
          genre: this.genre,
          identity_doc_type: this.identityDocType,
          nif: this.nif,
          born_date: this.bornDate,
          email: this.email,
          phone_numbers: [] as string[],
        };

        for (let iterator = 1; iterator <= 5; iterator += 1) {
          // Dynamically build each fieldRule
          if (this.values[`auxPhoneNumberPrefix${this.numberToSpelledString(iterator)}`]
            && this.values[`auxPhoneNumber${this.numberToSpelledString(iterator)}`]) {
            // eslint-disable-next-line no-useless-concat,max-len
            data.phone_numbers.push(`${this.values[`auxPhoneNumberPrefix${this.numberToSpelledString(iterator)}`]} ${this.values[`auxPhoneNumber${this.numberToSpelledString(iterator)}`]}`);
          }
        }

        await api.admin.patient.update(this.patientId, data);

        this.$toast.success(this.$t('Los datos se han guardado correctamente'));

        this.closeModal();

        this.$emit('patient-updated');
      } catch (e) {
        this.$toast.error(e.response?.data?.message);
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
