/**
 * Redirects to our website with the provided url.
 * If 'entities/1', it redirects to 'domain:8000/entities/1'.
 *
 * @param url
 * @param params
 */
import axios from 'axios';

/**
 * Gets a params array and pushes them into an url query params. Returns it as ?param1=value1&param2=value2.
 *
 * @param params
 * @returns {string}
 */
export const buildUrlQueryParams = (params: any = null): string => {
  let url = '';

  if (params !== null && params !== {}) {
    let oneParamAtLeast = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const i in params) {
      if (params[i] !== '' && params[i] !== null) {
        if (!oneParamAtLeast) {
          url += '?';
          oneParamAtLeast = true;
        }

        url += `${i}=${params[i]}&`;
      }
    }

    if (oneParamAtLeast) {
      url = url.slice(0, -1);
    }
  }

  return url;
};

export const goTo = (url: string, params: any = null): any => {
  if (url.startsWith('/')) {
    url = url.substring(1);
  }

  url += buildUrlQueryParams(params);

  window.location.href = `${window.location.origin}/${url}`;
};

/**
 * Fires a request to a url that must force navigator to download a file.
 *
 * @param url Url (maybe with params) that forces the download
 * @param params
 * @param filename
 */
export const downloadFromUrl = (url: string, params = null, filename = 'download.xlsx'): any => {
  if (url.startsWith('/')) {
    url = url.substring(1);
  }

  url += buildUrlQueryParams(params);

  if (!filename.endsWith('.xlsx')) {
    filename += '.xlsx';
  }

  axios({
    url,
    method: 'GET',
    responseType: 'blob', // important
  })
    .then((response) => {
      const urlAux = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlAux;
      link.setAttribute('download', filename); // or any other extension
      document.body.appendChild(link);
      link.click();
    });
};

export const getHummanSize = (size: any): string => {
  // eslint-disable-next-line radix
  size = parseInt(size);
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];

  if (size === 0) {
    return `0 ${units[1]}`;
  }

  let i = 0;

  // eslint-disable-next-line no-plusplus
  for (i; size > 1024; i++) {
    size /= 1024;
  }

  return `${size.toFixed(2)} ${units[i]}`;
};

export const camelToSnakeCase = (str: string): string => {
  if (/[A-Z]/.test(str)) {
    str = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).substr(1, 999);
  } else {
    str = str.toLowerCase();
  }

  return str;
};
