
import { defineComponent, PropType } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import api from '@/api';
import Professional from '@/api/objects/Professional';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import useCapabilities from '@/resources/capabilities';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  // TODO: Check if better with a store module and 'currentInterConsultation' instead of this prop
  name: 'TheProfessionalInterConsultationView',
  components: {
    VFormGroupInputText,
    VButton,
  },
  props: {
    professionals: {
      type: Array as PropType<Professional[]>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      comment: yup.string()
        .required()
        .max(8000)
        .nullable()
        .label(t('Mensaje')),
    });

    const form = useFormValidation(rules);

    const { value: comment } = useField('comment');

    return {
      ...useCapabilities(),
      ...form,
      comment,
    };
  },
  data() {
    return {
      removeCommentLoading: null,
    };
  },
  computed: {
    ...mapState('interConsultation', ['currentInterConsultation']),
    ...mapState('user', ['authUser']),
  },
  methods: {
    displayProfessionalUsername(comment) {
      if (this.authUser?.id === comment.author.id) {
        return `<span class="badge bg-tertiary text-white px-8 py-4 rounded-pill">${this.$t('Tú')}</span>`;
      }

      return `${comment.author.professional?.name} ${comment.author.professional?.surname}`;
    },
    emitUpdateInterConsultationEvent() {
      this.$emit('update-inter-consultation');
    },
    findProfessionalName(professionalId) {
      const professional = this.professionals.find((p: Professional) => p.id === professionalId);

      return `${professional?.name} ${professional?.surname}`;
    },
    async storeComment() {
      const data = {
        content: this.comment,
      };

      try {
        await api.professional.interConsultation.storeComment(this.currentInterConsultation.id, data);

        this.resetForm();

        this.$emit('emit-inter-consultation-updated');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al enviar el comentario'));
      }
    },
    async confirmDeleteComment(commentId) {
      await this.$modal.confirm({
        title: this.$t('Eliminar comentario'),
        text: this.$t('¿Estás seguro de que quieres eliminar este comentario?'),
        confirmButtonText: this.$t('Eliminar'),
        confirmButtonCallback: () => this.doDeleteComment(commentId),
      });
    },
    async doDeleteComment(commentId) {
      try {
        this.removeCommentLoading = commentId;
        await api.professional.interConsultation.deleteComment(this.currentInterConsultation.id, commentId);

        this.$emit('emit-inter-consultation-updated');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al borrar el comentario'));
      }
    },
    async finalizeInterConsultation() {
      await this.$modal.confirm({
        title: this.$t('Finalizar interconsulta'),
        // eslint-disable-next-line max-len
        text: this.$t('Si finalizas esta interconsulta podrás seguir visualizando los datos y comentarios pero no editarla ni comentar nuevamente. ¿Estás seguro?'),
        confirmButtonText: this.$t('Finalizar'),
        confirmButtonCallback: this.doFinalizeInterConsultation,
      });
    },
    async doFinalizeInterConsultation() {
      try {
        await api.professional.interConsultation.finalize(this.currentInterConsultation.id);

        this.$toast.success(this.$t('La interconsulta se ha finalizado con éxito'));

        this.$emit('emit-inter-consultation-updated');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al finalizar la interconsulta'));
      }
    },
    closeOffCanvas() {
      this.$emit('closed');
    },
  },
});
