
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VFormGroupReadOnlyText',
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    disclaimer: {
      type: String,
      required: false,
      default: null,
    },
    bgTransparent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
});
