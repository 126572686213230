import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53d6e838"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input_radio = _resolveComponent("v-input-radio")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValueOptions, (option, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: _normalizeClass(["form-check", {'form-check-inline': _ctx.formValueClass === 'inline'}]),
      key: index
    }, [
      _createVNode(_component_v_input_radio, {
        class: _normalizeClass([this.inputClass, {'is-invalid': !!_ctx.yupErrorsVariable}]),
        name: _ctx.formGroupName,
        id: _ctx.formGroupName + '_' + option.key,
        selected: _ctx.selected,
        value: _ctx.$t(option.key),
        disabled: _ctx.disabled,
        "onUpdate:value": _ctx.emitValue
      }, null, 8, ["class", "name", "id", "selected", "value", "disabled", "onUpdate:value"]),
      (!!option.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.formGroupName + '_' +option.key,
            class: _normalizeClass(["form-check-label", {'text-disabled': _ctx.disabled}])
          }, _toDisplayString(_ctx.$t(option.label)), 11, _hoisted_1))
        : _createCommentVNode("", true)
    ], 2))
  }), 128))
}