
import { defineComponent } from 'vue';
import TheMenuProfessional from '@/components/structural/TheMenuProfessional.vue';
import { mapState } from 'vuex';
import TheMenuAdministrator from '@/components/structural/TheMenuAdministrator.vue';
import TheMenuAuth from '@/components/structural/TheMenuAuth.vue';
import TheMenuPatient from '@/components/structural/TheMenuPatient.vue';
import TheImpersonateStop from '@/components/structural/TheImpersonateStop.vue';

export default defineComponent({
  name: 'TheHeader',
  components: {
    TheImpersonateStop,
    TheMenuPatient,
    TheMenuAuth,
    TheMenuAdministrator,
    TheMenuProfessional,
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
});
