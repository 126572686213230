import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container auth-container" }
const _hoisted_2 = { class: "container-form w-100 py-56 px-32 px-sm-56" }
const _hoisted_3 = { class: "h4 title text-primary text-center align-self-center align-content-center mb-16" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        (_ctx.status === null || _ctx.status === 'success')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('¡Gracias por aceptar las políticas de ProAge!')), 1))
          : (_ctx.status ==='politicas_aceptadas')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('¡Parece que tus políticas ya han sido aceptadas anteriormente!')), 1))
            : (_ctx.status ==='unknown_error')
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('¡Uups! Ha ocurrido un error.')), 1))
              : (_ctx.status ==='code_not_provided_or_invalid')
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('El token provisto no es válido.')), 1))
                : _createCommentVNode("", true)
      ])
    ])
  ]))
}