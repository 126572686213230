
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import TheProfessionalHomeExpedients
  from '@/views/professional/parts/TheProfessionalHomeExpedients.vue';
import api from '@/api';
import Consultation from '@/api/objects/Consultation';
import VLoader from '@/components/vendor/VLoader.vue';
import VProfessionalConsultations from '@/components/vendor/VProfessionalConsultations.vue';
import Expedient from '@/api/objects/Expedient';

export default defineComponent({
  name: 'TheProfessionalHome',
  components: {
    VProfessionalConsultations,
    VLoader,
    TheProfessionalHomeExpedients,
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('professional', ['expedients']),
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      status: 'loading',
      consultations: [] as Consultation[],
    };
  },
  async mounted() {
    await this.loadConsultations();

    this.status = 'loaded';
  },
  methods: {
    async loadConsultations() {
      try {
        this.consultations = await api.professional.consultation.all();

        this.consultations.forEach((consultation) => {
          consultation.expedientData = this.findProfessionalExpedient(consultation.expedientId);
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las consultas'));
      }
    },
    findProfessionalExpedient(expedientId) {
      return this.expedients.find((fv: Expedient) => fv.id === expedientId);
    },
  },
});
