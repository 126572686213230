
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ThePageNotFound',
  components: { VButton },
  computed: {
    ...mapState('user', ['authUser', 'isGuest']),
  },
  methods: {
    async redirect() {
      await this.$router.push({ name: 'login' });
    },
  },
});
