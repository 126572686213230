import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_form_group_nutritional_anamnesis = _resolveComponent("the-form-group-nutritional-anamnesis")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastFormStructure.form_groups, (formGroup, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(formGroup.class)
        }, [
          _createVNode(_component_the_form_group_nutritional_anamnesis, {
            "form-group-structure": formGroup,
            onFormGroupUpdated: _ctx.handleAutomations
          }, null, 8, ["form-group-structure", "onFormGroupUpdated"])
        ], 2))
      }), 128))
    ])
  ]))
}