
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import TheFormGroupAnamnesis from '@/views/professional/anamnesis/parts/TheFormGroupAnamnesis.vue';
import Form from '@/api/objects/Form';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import { getAnamnesisAlerts } from '@/helpers/anamnesisFormHelper';

export default defineComponent({
  name: 'TheFormAnamnesis',
  components: {
    VAlert,
    TheFormGroupAnamnesis,
  },
  props: {
    formVersion: {
      type: String,
      required: false,
    },
    formType: {
      type: String,
      required: false,
    },
    formStructure: {
      type: [Object, Form],
      required: false,
    },
  },
  data() {
    return {
      alerts: [] as string[],
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    ...mapState('expedient', ['currentAnamnesis']),
    lastFormStructure(): any {
      const formStructures = this.formsSettings.forms.find((obj) => obj.type === 'anamnesis'
        && obj.version === this.formVersion);

      return formStructures.structure;
    },
  },
  mounted() {
    this.checkAlerts();
  },
  methods: {
    checkAlerts() {
      this.alerts = getAnamnesisAlerts(this.currentAnamnesis);

      this.$emit('alerts-updated', this.alerts);
    },
  },
});
