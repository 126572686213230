import { MutationTree } from 'vuex';
import type {
  ConsultationState,
} from '@/store/modules/patient/expedient/modules/consultation/consultation.types';
import Consultation from '@/api/objects/Consultation';

interface ConsultationMutations {
  setCurrentConsultation(state: ConsultationState, payload: any): void;
}

const mutations: MutationTree<ConsultationState> & ConsultationMutations = {
  setStatus(state: ConsultationState, status: string) {
    state.status = status;
  },
  setCurrentConsultation(state: ConsultationState, consultation: Consultation) {
    state.currentConsultation = consultation;
  },
  setConsultations(state: ConsultationState, consultations: Consultation[]) {
    state.consultations = consultations;
  },

};

export default mutations;
