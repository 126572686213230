
import { defineComponent, watch } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState, useStore } from 'vuex';
import InterConsultation from '@/api/objects/InterConsultation';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import Professional from '@/api/objects/Professional';
import api from '@/api';
import TheProfessionalInterConsultationCreate
  from '@/views/professional/inter-consultations/parts/TheProfessionalInterConsultationCreate.vue';
import TheProfessionalInterConsultation
  from '@/views/professional/inter-consultations/parts/TheProfessionalInterConsultation.vue';

export default defineComponent({
  name: 'TheProfessionalInterConsultationsList',
  components: {
    TheProfessionalInterConsultation,
    TheProfessionalInterConsultationCreate,
    VTable,
    VButton,
    VTableBody,
    VTableHeader,
  },
  setup() {
    const {
      state,
      dispatch,
    } = useStore();

    watch(() => state.professional.currentProfessional, (value, oldValue) => {
      // this function loads the professional information if at any time it changes to null
      if (!!oldValue && !value) {
        dispatch('professional/getProfessional');
      }
    });

    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('interConsultation', ['currentInterConsultation', 'interConsultations']),
    ...mapState('user', ['authUser']),
  },
  data() {
    return {
      professionals: [] as Professional[],
      offCanvasToShow: null as null | string,
      modalToShow: null as null | string,
      isLoading: false,
      selectedInterConsultation: null as InterConsultation | null,
      mode: 'view',
    };
  },
  async beforeMount() {
    await this.loadInterConsultations();

    await this.loadProfessionalsList();
  },
  methods: {
    async loadInterConsultations() {
      await this.$store.dispatch('interConsultation/setInterConsultations');
      // TODO: If we want to handle pagination, it is better to handle interconsultations as local data
    },
    async loadProfessionalsList() {
      this.professionals = await api.professionalData.getProfessionalsList();
    },
    async refreshInterConsultation() {
      await this.loadInterConsultations();

      const interConsultation = this.interConsultations.find((i) => i.id === this.currentInterConsultation.id);

      if (!!interConsultation) {
        await this.selectInterConsultation(interConsultation);
      }
    },
    async selectInterConsultation(interConsultation: InterConsultation) {
      // We set that interConsultation as the current one in store
      await this.$store.dispatch('interConsultation/setCurrentInterConsultation', {
        interConsultation,
      });
    },
    async openInterconsultationView(interConsultation: InterConsultation) {
      await this.selectInterConsultation(interConsultation);

      this.offCanvasToShow = 'off-canvas-inter-consultation';
    },
    async handleInterConsultationCreatedEvent(interConsultationId: number) {
      // We refresh all inter-consultations
      await this.$store.dispatch('interConsultation/setInterConsultations');

      // TODO: Do something, but I don't know what yet
      const interConsultation = this.interConsultations.find((ic) => ic.id === interConsultationId);

      await this.$store.dispatch('interConsultation/setCurrentInterConsultation', {
        interConsultation,
      });
    },
  },
});
