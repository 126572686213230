
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheRGPDAcceptance',
  components: {
    VButton,
  },
  computed: {
    token() {
      if (!!this.$route.query.token) {
        return this.$route.query.token;
      }

      return null;
    },
  },
  data() {
    return {
      message: '',
    };
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      acceptRGPD: yup.boolean()
        .oneOf([true], t('Debes aceptar la política de protección de datos'))
        .default(false),
      acceptOtherConditions: yup.boolean()
        .oneOf([true], t('Debes aceptar las otras condiciones relacionadas con la protección de datos'))
        .default(false),
    });

    const form = useFormValidation(rules, {
      acceptRGPD: false,
      acceptOtherConditions: false,
    });

    const { value: acceptRGPD } = useField('acceptRGPD');
    const { value: acceptOtherConditions } = useField('acceptOtherConditions');

    return {
      ...form,
      acceptRGPD,
      acceptOtherConditions,
      ...useCapabilities(),
    };
  },
  async beforeMount() {
    const query = {
      status: 'unknown_error',
    };
    if (!this.token) {
      this.redirectResponseRGPD(query);
    } else if (this.token) {
      // We check if provided token is valid and also if those RGPD are accepted or not
      const params = {
        token: this.token,
      };

      try {
        const response = await api.professional.patient.checkRGPD(params);
        this.message = response.message;
      } catch (e) {
        if (e.response?.data?.code_error === 'code_not_provided_or_invalid') {
          query.status = 'code_not_provided_or_invalid';
        } else {
          query.status = 'politicas_aceptadas';
        }
        this.toggleAccepted();
        await this.redirectResponseRGPD(query);
      }
    }
  },
  methods: {
    async redirectResponseRGPD(query = {}) {
      await this.$router.replace({
        name: 'rgpd-acceptance.response',
        query,
      });
    },
    async agreeRGPD() {
      const { valid } = await this.validate();

      if (!valid) {
        this.$toast.error(this.$t('Debes aceptar las condiciones para continuar.'));
      } else {
        const params = {
          token: this.token,
        };
        const query = {
          status: 'unknown_error',
        };

        try {
          this.toggleAccepted();

          const response = await api.professional.patient.acceptRGPD(params);

          if (response.status === 'success') {
            query.status = response.status;
          }
        } catch (e) {
          this.toggleAccepted();
        }
        await this.redirectResponseRGPD(query);
      }
    },
  },
});
