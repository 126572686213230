import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fw-normal" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = {
  key: 0,
  class: "invalid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    onKeypress: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.meta.valid ? _ctx.changePhase() : null), ["prevent"]), ["enter"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('Cambiar de')), 1),
              _createTextVNode(_toDisplayString(_ctx.$t('fase')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('Selecciona una nueva fase para el tratamiento:')), 1),
            _createVNode(_component_v_select, {
              class: _normalizeClass(["mb-24", {'is-invalid': !!_ctx.errors.phaseIdAux}]),
              label: "label",
              options: _ctx.protocolPhasesOptions,
              reduce: returnValue => returnValue.key,
              "select-on-key-codes": [188, 13],
              modelValue: _ctx.phaseIdAux,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phaseIdAux) = $event)),
              selectable: option => ! option.disabled
            }, {
              "no-options": _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
              ]),
              _: 1
            }, 8, ["class", "options", "reduce", "modelValue", "selectable"]),
            (!!_ctx.errors.phaseIdAux)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.errors.phaseIdAux), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "link-secondary",
            class: "me-24",
            "aria-label": _ctx.$t('Cancelar'),
            "data-bs-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cancelar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"]),
          _createVNode(_component_v_button, {
            variant: "secondary",
            "aria-label": _ctx.$t('Cambiar de fase'),
            disabled: !_ctx.meta.valid,
            "is-loading": _ctx.accepted,
            onClick: _ctx.changePhase
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cambiar de fase')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "disabled", "is-loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}