
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import vSelect from 'vue-select';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import VFormGroupInputCheckbox from '@/components/vendor/basic/form/VFormGroupInputCheckbox.vue';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminProfessionalCreate',
  components: {
    VAlert,
    VFormGroupInputCheckbox,
    VButton,
    VFormGroupInputDate,
    VFormGroupSelect,
    VFormGroupInputText,
    vSelect,
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    roleOptions(): any {
      return [
        {
          key: 'Medico',
          label: this.$t('Médico'),
        },
        {
          key: 'Nutricionista',
          label: this.$t('Nutricionista'),
        },
      ];
    },
    specialityOptions(): any {
      let options = [
        {
          key: 'Nutricionista',
          label: this.$t('Nutricionista'),
        },
      ];

      if (this.role === 'Medico') {
        options = this.formsSettings.medic_speciality_options;
      }

      return options;
    },
  },
  data() {
    return {
      professional: {},
    };
  },
  watch: {
    role() {
      this.speciality = this.specialityOptions[0].key;
    },
  },
  setup() {
    const { t } = useI18n();

    /**
     * I use this method to store variables without
     * numbers, just text.
     *
     * @param number
     */
    // eslint-disable-next-line consistent-return
    const numberToSpelledString = function (number) {
      // TODO: Move to a helper
      switch (number) {
        case 1:
          return 'One';
        case 2:
          return 'Two';
        case 3:
          return 'Three';
        case 4:
          return 'Four';
        case 5:
          return 'Five';
        default:
          return 'One';
      }
    };

    // Dynamically add phone numbers
    const auxRules = {};
    for (let iterator = 1; iterator <= 5; iterator += 1) {
      // Dynamically build each fieldRule
      auxRules[`auxPhoneNumberPrefix${numberToSpelledString(iterator)}`] = yup.lazy((value, options) => {
        let rule = yup.string()
          .label(t('Prefijo telefónico'))
          .nullable();

        const field = options.parent[`auxPhoneNumber${numberToSpelledString(iterator)}`];
        if (!!field && field !== '') {
          rule = rule.required();
        }

        return rule;
      });
      auxRules[`auxPhoneNumber${numberToSpelledString(iterator)}`] = yup.lazy((value, options) => {
        let rule = yup.string()
          .label(t('Número de teléfono'));

        const field = options.parent[`auxPhoneNumberPrefix${numberToSpelledString(iterator)}`];
        if (!!field && field !== '') {
          rule = rule.required().matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras'));
        }

        return rule;
      });
    }

    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('Nombre')),
      surname: yup.string()
        .required()
        .label(t('Apellidos')),
      email: yup.string()
        .email()
        .required()
        .label(t('Correo electrónico')),
      phoneNumberPrefix: yup.string()
        .required()
        .label(t('Prefijo telefónico')),
      phoneNumber: yup.string()
        .required()
        .label(t('Número de teléfono'))
        .matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras')),
      genre: yup.string()
        .required()
        .label(t('Género')),
      role: yup.string()
        .required()
        .label(t('Tipo')),
      identityDocType: yup.string()
        .required()
        .label(t('Tipo de Documento')),
      nif: yup.lazy((value, options) => {
        // initialize default rules for all options
        const rule = yup.string()
          .required()
          .label(t('Número de documento'));
        // this property retrieves the information of the datasets initialized with yup
        const { identityDocType } = options.parent;
        const messageValidation = t('El {tipo_documento} registrado no es Válido', { tipo_documento: identityDocType });
        switch (identityDocType) {
          case 'dni':
            return rule.matches(/^[0-9]{8,8}[A-Za-z]$/g, messageValidation);
          case 'nie':
            return rule.matches(/^[XYZ|xyz]\d{7,8}[A-Za-z]$/, messageValidation);
          default:
            return rule; // here you can decide what is the default
        }
      }),
      speciality: yup.string()
        .required()
        .label(t('Especialidad')),
      subspeciality: yup.string()
        .required()
        .label(t('Subespecialidad')),
      college: yup.string()
        .required()
        .label(t('Colegio')),
      collegeNumber: yup.string()
        .required()
        .label(t('Nº colegiado')),
      bornDate: yup.date()
        .required()
        .max(new Date(), t('La fecha de nacimiento no puede ser futura'))
        .label(t('Fecha de nacimiento')),
      personalLine1: yup.string()
        .label(t('Dirección 1')),
      personalLine2: yup.string()
        .label(t('Dirección 2')),
      personalCP: yup.string()
        .label(t('Código postal')),
      personalCity: yup.string()
        .label(t('Ciudad')),
      personalProvince: yup.string()
        .label(t('Provincia')),
      personalCountry: yup.string()
        .label(t('País')),
      professionalLine1: yup.string()
        .label(t('Dirección 1')),
      professionalLine2: yup.string()
        .label(t('Dirección 2')),
      professionalCP: yup.string()
        .label(t('Código postal')),
      professionalCity: yup.string()
        .label(t('Ciudad')),
      professionalProvince: yup.string()
        .label(t('Provincia')),
      professionalCountry: yup.string()
        .label(t('País')),
      canFreelyAuthorize: yup.boolean()
        .label(t('Puede autorizar libremente')),
      active: yup.boolean()
        .label(t('Activo')),
      ...auxRules,
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: email } = useField('email');
    const { value: phoneNumberPrefix } = useField('phoneNumberPrefix');
    const { value: phoneNumber } = useField('phoneNumber');

    const { value: genre } = useField('genre');
    const { value: role } = useField('role');
    const { value: identityDocType } = useField('identityDocType');
    const { value: nif } = useField('nif');
    const { value: speciality } = useField('speciality');
    const { value: subspeciality } = useField('subspeciality');
    const { value: college } = useField('college');
    const { value: collegeNumber } = useField('collegeNumber');
    const { value: bornDate } = useField('bornDate');
    const { value: personalLine1 } = useField('personalLine1');
    const { value: personalLine2 } = useField('personalLine2');
    const { value: personalCP } = useField('personalCP');
    const { value: personalCity } = useField('personalCity');
    const { value: personalProvince } = useField('personalProvince');
    const { value: personalCountry } = useField('personalCountry');
    const { value: professionalLine1 } = useField('professionalLine1');
    const { value: professionalLine2 } = useField('professionalLine2');
    const { value: professionalCP } = useField('professionalCP');
    const { value: professionalCity } = useField('professionalCity');
    const { value: professionalProvince } = useField('professionalProvince');
    const { value: professionalCountry } = useField('personaprofessionalCountry');
    const { value: active } = useField('active');
    const { value: canFreelyAuthorize } = useField('canFreelyAuthorize');
    // Aux phone numbers
    const { value: auxPhoneNumberPrefixOne } = useField('auxPhoneNumberPrefixOne');
    const { value: auxPhoneNumberOne } = useField('auxPhoneNumberOne');
    const { value: auxPhoneNumberPrefixTwo } = useField('auxPhoneNumberPrefixTwo');
    const { value: auxPhoneNumberTwo } = useField('auxPhoneNumberTwo');
    const { value: auxPhoneNumberPrefixThree } = useField('auxPhoneNumberPrefixThree');
    const { value: auxPhoneNumberThree } = useField('auxPhoneNumberThree');
    const { value: auxPhoneNumberPrefixFour } = useField('auxPhoneNumberPrefixFour');
    const { value: auxPhoneNumberFour } = useField('auxPhoneNumberFour');
    const { value: auxPhoneNumberPrefixFive } = useField('auxPhoneNumberPrefixFive');
    const { value: auxPhoneNumberFive } = useField('auxPhoneNumberFive');

    return {
      ...form,
      name,
      surname,
      email,
      phoneNumberPrefix,
      phoneNumber,
      personalLine1,
      personalLine2,
      personalCP,
      personalCity,
      personalProvince,
      personalCountry,
      professionalLine1,
      professionalLine2,
      professionalCP,
      professionalCity,
      professionalProvince,
      professionalCountry,

      genre,
      role,
      identityDocType,
      nif,
      speciality,
      subspeciality,
      college,
      collegeNumber,
      bornDate,
      active: true,
      canFreelyAuthorize,

      auxPhoneNumberPrefixOne,
      auxPhoneNumberOne,
      auxPhoneNumberPrefixTwo,
      auxPhoneNumberTwo,
      auxPhoneNumberPrefixThree,
      auxPhoneNumberThree,
      auxPhoneNumberPrefixFour,
      auxPhoneNumberFour,
      auxPhoneNumberPrefixFive,
      auxPhoneNumberFive,

      numberToSpelledString,

      ...useCapabilities(),
    };
  },
  methods: {
    handleCheckboxClick(e, variable) {
      if (e === 'true') {
        this[variable] = true;
      } else if (e === 'false') {
        this[variable] = false;
      }
    },
    async createProfessional() {
      this.toggleAccepted();

      const aux = [] as string[];
      for (let iterator = 1; iterator <= 5; iterator += 1) {
        // Dynamically build each fieldRule
        if (this.values[`auxPhoneNumberPrefix${this.numberToSpelledString(iterator)}`]
          && this.values[`auxPhoneNumber${this.numberToSpelledString(iterator)}`]) {
          // eslint-disable-next-line no-useless-concat,max-len
          aux.push(`${this.values[`auxPhoneNumberPrefix${this.numberToSpelledString(iterator)}`]} ${this.values[`auxPhoneNumber${this.numberToSpelledString(iterator)}`]}`);
        }
      }

      this.professional = {
        name: this.values.name,
        surname: this.values.surname,
        email: this.values.email,
        phone_number: `${this.values.phoneNumberPrefix} ${this.values.phoneNumber}`,
        genre: this.values.genre,
        role: this.values.role,
        identity_doc_type: this.values.identityDocType,
        nif: this.values.nif,
        speciality: this.values.speciality,
        subspeciality: this.values.subspeciality,
        college: this.values.college,
        college_number: this.values.collegeNumber,
        born_date: this.values.bornDate,
        can_freely_authorize: this.values.canFreelyAuthorize ?? false,
        active: this.values.active ?? true,
        phone_numbers: aux,
        personal_line1: this.values.personalLine1,
        personal_line2: this.values.personalLine2,
        personal_cp: this.values.personalCP,
        personal_city: this.values.personalCity,
        personal_province: this.values.personalProvince,
        personal_country: this.values.personalCountry,
        professional_line1: this.values.professionalLine1,
        professional_line2: this.values.professionalLine2,
        professional_cp: this.values.professionalCP,
        professional_city: this.values.professionalCity,
        professional_province: this.values.professionalProvince,
        professional_country: this.values.professionalCountry,
      };

      try {
        /*
            TODO: Be more exhaustive when parsing the phone number as here is the only step in which
            We will take care that is a valid phone number so the RGPD SMS goes to him
         */
        await api.admin.professional.store(this.professional);
        await this.$modal.info({
          title: this.$t('Profesional creado'),
          // eslint-disable-next-line max-len
          text: this.$t('Se ha creado el profesional :name :surname con éxito, le hemos enviado un correo de bienvenida y otro para que establezca su contraseña. El profesional debe establecer su contraseña para poder acceder a la plataforma.', {
            name: this.values.name,
            surname: this.values.surname,
          }),
        });

        this.resetForm();

        this.toggleAccepted();

        await this.$router.push({ name: 'admin.professional.index' });
      } catch (e) {
        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);

        this.toggleAccepted();
      }
    },
  },
});
