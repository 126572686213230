
import { defineComponent } from 'vue';
import * as yup from 'yup';
import useCapabilities from '@/resources/capabilities';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import addRule from '@/helpers/formValidation';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroup from '@/components/vendor/basic/form/VFormGroup.vue';
import VInputDate from '@/components/vendor/basic/form/parts/VInputDate.vue';
import { mapState, useStore } from 'vuex';
import { DateTime } from 'luxon';
import {
  calculateBMR,
  calculateICA,
  calculateICC,
  calculateIMC,
} from '@/helpers/formCalculationHelper';

export default defineComponent({
  name: 'TheProfessionalExpedientFormsCreateModal',
  components: {
    VInputDate,
    VFormGroup,
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  props: {
    formName: {
      type: String,
      required: true,
    },
    treatmentId: {
      type: Number,
      required: false,
      default: null,
    },
    formStructure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      formJson: {},
      disabledButton: false,
    };
  },
  beforeMount() {
    const dateAux = DateTime.local()
      .toSQLDate();
    this.values.fecha = dateAux.toString();
  },
  setup(props) {
    const { state } = useStore();

    const formsStructure = state.app.formsSettings.forms;

    const formStructure = formsStructure.filter((obj) => obj.type === props.formName);

    let lastFormStructure = formStructure.reduce((objA, objB) => (objA.version > objB.version
      ? objA : objB));

    lastFormStructure = lastFormStructure.structure;

    const formGroupStructure = lastFormStructure.form_groups[0];

    const yupObject = {};

    if (formGroupStructure) {
      formGroupStructure.form_values.forEach((formValue) => {
        let add = yup;

        // eslint-disable-next-line no-unused-expressions
        formValue?.rules?.front.forEach((rule) => {
          const myRule = {
            message: '',
            number: -1,
            name: '',
          };

          // Rule could be:   max:80!Mensaje   |   max:80   |   label
          if (rule.includes('!')) {
            const x = rule.split('!');
            if (rule.includes(':')) {
              const y = x[0].split(':');
              // FIXME: Please, remove these f**cking comments because I cannot make it work without them
              // eslint-disable-next-line prefer-destructuring
              myRule.message = x[1];
              // eslint-disable-next-line prefer-destructuring
              myRule.number = y[1];
              // eslint-disable-next-line prefer-destructuring
              myRule.name = y[0];
            } else {
              // eslint-disable-next-line prefer-destructuring
              myRule.message = x[1];
              // eslint-disable-next-line prefer-destructuring
              myRule.name = x[0];
            }
          } else if (rule.includes(':')) {
            const y = rule.split(':');
            // eslint-disable-next-line prefer-destructuring
            myRule.number = y[1];
            // eslint-disable-next-line prefer-destructuring
            myRule.name = y[0];
          } else {
            myRule.name = rule;
          }

          add = addRule(add, myRule.name, formValue.label, myRule.message, myRule.number);
        });

        yupObject[formValue.key] = add;
      });
    }

    const rules = yup.object(yupObject);

    const form = useFormValidation(rules);

    const formJson = {};

    if (formGroupStructure) {
      formGroupStructure.form_values.forEach((formValue) => {
        formJson[formValue.key] = useField(formValue.key).value;
      });
    }

    return {
      formGroupStructure,
      ...form,
      ...useCapabilities(),
      ...formJson,
    };
  },
  computed: {
    ...mapState('expedient', ['currentAnamnesis', 'currentExpedient']),
  },
  methods: {
    async createFormGroup() {
      this.toggleAccepted();

      let form: any = {};

      const formValuesKeys = Object.keys(this.meta.initialValues);

      const values = {};

      formValuesKeys.forEach((formValueKey) => {
        values[formValueKey] = !!this.values[formValueKey] ? this.values[formValueKey] : '';
      });

      let formGroupName = '';

      if (this.formStructure) {
        this.formStructure.form_groups.forEach((formGroup) => {
          formGroupName = formGroup.key;
        });
      }

      form = {
        form_key: this.formName,
        form_group_name: formGroupName,
        form_values: values,
      };

      if (this.formName === 'medidas') {
        if (!!this.treatmentId) {
          form.treatment_id = this.treatmentId;
        }
      }

      this.toggleAccepted();

      this.$emit('create-form-requested', form);

      this.closeModal();
    },
    /**
     * Checks the input change of an input and executes the onChangeKey (where Key is the actual key
     * of the input field) function if it exists here in this vue component.
     *
     * @param event
     */
    changeInput(event) {
      this.formGroupStructure.form_values.forEach((formValue) => {
        if (formValue.key === event.target.name) {
          // We will only trigger just for the inputted element
          const functionName = this.snakeCaseToCamel(`on_change_${formValue.key}`);

          if (typeof this[functionName] === 'function') {
            this[functionName](event.target.name, event.target.value);
          }
        }
      });
    },
    onChangePeso(key, weight) {
      // We make automations for each form. TODO: This will need refactor for maintainability
      if (this.formName === 'medidas') {
        const height = Number(this.values.altura ?? 0);

        const { genre, age } = this.currentExpedient.patient;

        this.values.imc = calculateIMC(weight, height);
        this.values.bmr = calculateBMR(weight, height, genre, age);
      }
    },
    onChangeAltura(key, height) {
      // We make automations for each form. TODO: This will need refactor for maintainability
      if (this.formName === 'medidas') {
        const weight = Number(this.values.peso ?? 0);
        const waistPerimeter = Number(this.values.perimetro_abdominal ?? 0);

        const { genre, age } = this.currentExpedient.patient;

        this.values.imc = calculateIMC(weight, height);
        this.values.bmr = calculateBMR(weight, height, genre, age);
        this.values.ica = calculateICA(waistPerimeter, height);
      }
    },
    onChangePerimetroAbdominal(key, waistPerimeter) {
      if (this.formName === 'medidas') {
        const hipPerimeter = Number(this.values.perimetro_cadera ?? 0);
        const height = Number(this.values.altura ?? 0);

        this.values.icc = calculateICC(waistPerimeter, hipPerimeter);
        this.values.ica = calculateICA(waistPerimeter, height);
      }
    },
    onChangePerimetroCadera(key, hipPerimeter) {
      if (this.formName === 'medidas') {
        const waistPerimeter = Number(this.values.perimetro_abdominal ?? 0);

        this.values.icc = calculateICC(waistPerimeter, hipPerimeter);
      }
    },
    round(value) {
      // TODO: Move this to a helper
      return Math.round(value * 100) / 100;
    },
    snakeCaseToCamel(str) {
      return str.toLowerCase()
        .replace(/([-_][a-z])/g, (group) => group
          .toUpperCase()
          .replace('-', '')
          .replace('_', ''));
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
