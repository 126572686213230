
import { defineComponent, PropType } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VLoader from '@/components/vendor/VLoader.vue';
import Video from '@/api/objects/Video';
import VPicture from '@/components/vendor/VPicture.vue';

export default defineComponent({
  // TODO: This could be refactored to be used for any role
  name: 'TheAdminVideosGrid',
  components: {
    VPicture,
    VLoader,
  },
  props: {
    videos: {
      type: Array as PropType<Video[]>,
      required: false,
    },
    status: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      showAddVideo: false,
      modalToShow: null,
    };
  },
  methods: {
    mediaConfirm() {
      this.showAddVideo = false;
      this.$emit('refresh-media');
    },
  },
});
