import { App, Plugin } from 'vue';
import type { GeneralModalType } from '@/store/modules/general/general.types';
import AlertDivLayout from '@/components/vendor/basic/alert/AlertDivLayout.vue';
import i18n from '@/lang';
import store from '../store';

const $t = i18n.global.t;
class Modal {
  /**
   * Shows a modal with the 'info' format.
   *
   * @param title
   * @param text
   * @param acceptButtonText
   * @param acceptButtonCallback
   * @param backdropStatic
   * @returns {Promise<void>}
   */
  async info({
    title = $t('Información'),
    text = $t('Modal de información'),
    acceptButtonText = 'Ok',
    acceptButtonCallback = null,
    backdropStatic = true,
    rightButtonClasses = 'btn-primary',
  }: Partial<GeneralModalType & {
    title: string,
    text: string,
    acceptButtonText: string,
    acceptButtonCallback: any | null,
    backdropStatic: boolean,
    rightButtonClasses: string,
  }>) {
    await store.dispatch('general/modal', {
      title,
      text,
      textLayout: null,
      showCloseX: false,
      backdropStatic,
      leftButtonText: null,
      leftButtonClasses: null,
      leftButtonLoadingText: null,
      leftButtonCallback: null,
      rightButtonText: acceptButtonText,
      rightButtonClasses,
      rightButtonLoadingText: acceptButtonText,
      rightButtonCallback: acceptButtonCallback,
    });
  }

  /**
   * Shows a modal with the 'delete' format. If your callback,
   * when failing, returns a { error: 'error' }, the modal
   * toasts the error and is not closed.
   *
   * @param title
   * @param text
   * @param deleteButtonText
   * @param deleteButtonLoadingText
   * @param textLayout
   * @param deleteButtonCallback
   * @param backdropStatic
   * @returns {Promise<void>}
   */
  async delete({
    title = $t('Eliminar'),
    text = $t('Eliminar'),
    deleteButtonText = $t('Eliminar'),
    deleteButtonLoadingText = $t('Eliminando...'),
    textLayout = AlertDivLayout,
    deleteButtonCallback = null,
    backdropStatic = true,
  }: Partial<GeneralModalType & {
    title: string,
    text: string,
    textLayout: any
    deleteButtonText: string,
    deleteButtonLoadingText: string,
    deleteButtonCallback: null | any,
    backdropStatic: boolean,
  }>) {
    await store.dispatch('general/modal', {
      title,
      text,
      textLayout,
      showCloseX: false,
      backdropStatic,
      leftButtonText: deleteButtonText,
      leftButtonClasses: 'btn-outline-danger',
      leftButtonLoadingText: deleteButtonLoadingText,
      leftButtonCallback: deleteButtonCallback,
      rightButtonText: $t('Cancelar'),
      rightButtonClasses: 'btn-link-secondary',
      rightButtonLoadingText: $t('Cancelar'),
      rightButtonCallback: null,
    });
  }

  /**
   * Shows a modal with the 'confirm' format. If your callback,
   * when failing, returns a { error: 'error' }, the modal
   * toasts the error and is not closed.
   *
   * @param title
   * @param text
   * @param confirmButtonText
   * @param confirmButtonLoadingText
   * @param confirmButtonCallback
   * @param backdropStatic
   * @returns {Promise<void>}
   */
  async confirm({
    title = $t('Confirmar'),
    text = $t('Confirmar'),
    confirmButtonText = $t('Confirmar'),
    confirmButtonLoadingText = $t('Confirmando...'),
    confirmButtonCallback = null,
    backdropStatic = true,
  }: Partial<GeneralModalType & {
    confirmButtonText: string,
    confirmButtonLoadingText: string,
    confirmButtonCallback: any | null,
    backdropStatic: boolean
  }>) {
    await store.dispatch('general/modal', {
      title,
      text,
      textLayout: null,
      showCloseX: false,
      backdropStatic,
      leftButtonText: $t('Cancelar'),
      leftButtonClasses: 'btn-link-secondary',
      leftButtonLoadingText: $t('Cancelar'),
      leftButtonCallback: null,
      rightButtonText: confirmButtonText,
      rightButtonClasses: 'btn-secondary',
      rightButtonLoadingText: confirmButtonLoadingText,
      rightButtonCallback: confirmButtonCallback,
    });
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $modal: Modal;
  }
}

const createModal: Plugin = {
  install(app: App) {
    app.config.globalProperties.$modal = new Modal();
  },
};

export default createModal;
