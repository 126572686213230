
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import useFormValidation from '@/resources/form';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import api from '@/api';
import { DateTime } from 'luxon';
import VFormGroupInputCheckbox from '@/components/vendor/basic/form/VFormGroupInputCheckbox.vue';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminCalendarEventCreate',
  components: {
    VAlert,
    VFormGroupInputDate,
    VFormGroupInputCheckbox,
    VFormGroupSelect,
    VFormGroupInputText,
    VButton,
    VModal,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    vSelect,
  },
  props: {
    initialDate: {
      type: DateTime,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const rules = yup.object({
      title: yup.string()
        .required()
        .label(t('Asunto')),
      type: yup.string()
        .required()
        .label(t('Tipo')),
      date: yup.string()
        .required()
        .label(t('Fecha')),
      startTime: yup.string()
        .required()
        .label(t('Inicio')),
      endTime: yup.string()
        .required()
        .label(t('Fin')),
      description: yup.string()
        .nullable()
        .label(t('Descripción')),
      patientId: yup.number()
        .required()
        .label(t('Paciente')),
      ownerId: yup.number()
        .required()
        .label(t('Profesional')),
      clinicId: yup.lazy((value, options) => {
        // initialize default rules for all options
        const rule = yup.number()
          .nullable()
          .label(t('Clínica'));
        switch (options.parent.type) {
          case 'primera_consulta':
          case 'revision':
            return rule.required(t('Debes seleccionar una clínica donde pasar consulta'));
          default:
            return rule.nullable();
        }
      }),
    });

    const form = useFormValidation(rules, {
      date: !!props.initialDate ? props.initialDate : DateTime.local(),
    });

    const { value: title } = useField('title');
    const { value: type } = useField('type');
    const { value: date } = useField<DateTime>('date');
    const { value: startTime } = useField('startTime');
    const { value: endTime } = useField('endTime');
    const { value: description } = useField('description');
    const { value: patientId } = useField('patientId');
    const { value: ownerId } = useField('ownerId');
    const { value: clinicId } = useField('clinicId');

    return {
      ...useCapabilities(),
      ...form,
      title,
      type,
      date,
      startTime,
      endTime,
      description,
      patientId,
      ownerId,
      clinicId,
    };
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
    calendarEventTypeOptions() {
      return [
        {
          key: 'primera_consulta',
          label: this.$t('Primera consulta'),
        },
        {
          key: 'revision',
          label: this.$t('Revisión'),
        },
        {
          key: 'videoconsulta',
          label: this.$t('Videoconsulta'),
        },
      ];
    },
    consultationType() {
      const type = this.calendarEventTypeOptions.find((o) => o.key === this.type);

      return type?.label;
    },
    /**
     * Interval of minutes we set between each time space (for startTime and endTime)
     */
    minutesInterval() {
      return 15;
    },
    startTimeOptions() {
      const options = [] as any[];

      for (let hour = 0; hour < 24; hour += 1) {
        // TODO: Use Luxon dates as in other methods
        for (let minute = 0; minute < 60; minute += this.minutesInterval) {
          const strHour = hour < 10 ? `0${hour}` : `${hour}`;
          const strMinute = minute < 10 ? `0${minute}` : `${minute}`;

          const object = {
            key: `${strHour}:${strMinute}`,
            label: `${strHour}:${strMinute}`,
          };

          options.push(object);
        }
      }

      return options;
    },
    endTimeOptions() {
      // TODO: Preserve previously endTime chosen if possible (?) Maybe better for UX (?)
      const options = [] as any[];

      // 'HH:mm' to [HH, mm]
      const startTimeSplitted = (this.startTime as string).split(':');

      let onlyLastIntervalAvailable = false;

      // We start from the startTime
      const start = DateTime.fromISO(`${this.date}T${startTimeSplitted[0]}:${startTimeSplitted[1]}:00`);
      let iterator = DateTime.fromISO(`${this.date}T${startTimeSplitted[0]}:${startTimeSplitted[1]}:00`);
      if (start.hour === 23 && start.minute === (60 - this.minutesInterval)) {
        // If they selected 23:45, we need to push the 23:59 only
        iterator = start.plus({ minute: this.minutesInterval - 1 });
        onlyLastIntervalAvailable = true;
      } else {
        iterator = start.plus({ minute: this.minutesInterval });
      }

      while (iterator.day === start.day) {
        // We format it to 00:00 format, filling spaces with zeros
        const strHour = iterator.hour < 10 ? `0${iterator.hour}` : `${iterator.hour}`;
        const strMinute = iterator.minute < 10 ? `0${iterator.minute}` : `${iterator.minute}`;

        options.push({
          key: `${strHour}:${strMinute}`,
          label: `${strHour}:${strMinute}`,
        });

        iterator = iterator.plus({ minute: this.minutesInterval });
      }

      if (!onlyLastIntervalAvailable) {
        // Special option that is the last available on a day 23:59
        options.push({
          key: '23:59',
          label: '23:59',
        });
      }

      return options;
    },
    isConsultation() {
      return this.type === 'primera_consulta' || this.type === 'videoconsulta' || this.type === 'revision';
    },
    isVideoconsulta() {
      return this.type === 'videoconsulta';
    },
  },
  watch: {
    startTime() {
      let tmpDate: string | DateTime | null | unknown = null;

      if (this.date instanceof DateTime) {
        tmpDate = this.date.toISODate();
      } else {
        tmpDate = this.date;
      }

      let start = DateTime.fromISO(`${tmpDate}T${this.startTime}:00`);

      if (start.hour === 23 && start.minute === (60 - this.minutesInterval)) {
        start = start.plus({ minute: this.minutesInterval - 1 });
      } else {
        start = start.plus({ minute: this.minutesInterval });
      }

      this.endTime = `${this.fillWithZeroAtBegining(start.hour)}:${this.fillWithZeroAtBegining(start.minute)}`;
    },
    ownerId(newValue) {
      this.clinicId = null;
      this.loadAvailableClinicsForProfessional(newValue);
    },
  },
  data() {
    return {
      isAllDayActivated: false,
      availablePatients: [] as any[],
      availableProfessionals: [] as any[],
      availableClinics: [] as any[],
    };
  },
  beforeMount() {
    this.type = 'primera_consulta';
    this.initDateAndTimes();
    this.loadAvailablePatients();
    this.loadAvailableProfessionals();
  },
  methods: {
    async loadAvailablePatients() {
      const patients = await api.admin.patient.all(null, false);

      patients.forEach((patient) => {
        this.availablePatients.push({
          key: patient.id,
          // eslint-disable-next-line max-len
          label: `${patient?.user?.name} ${patient?.user?.surname} - ${patient.nif}`,
        });
      });
    },
    async loadAvailableProfessionals() {
      const professionals = await api.admin.professional.all(null, false);

      professionals.forEach((professional) => {
        this.availableProfessionals.push({
          key: professional?.user?.id,
          // eslint-disable-next-line max-len
          label: `${professional.name} ${professional.surname} - ${professional.speciality} (${professional.subspeciality})`,
        });
      });
    },
    async loadAvailableClinicsForProfessional(professionalId) {
      const clinics = await api.admin.clinic.getIndexOfProfessional(professionalId);

      this.availableClinics = [];

      clinics.forEach((clinic) => {
        this.availableClinics.push({
          key: clinic.id,
          label: `${clinic.name} (${clinic.address})`,
        });
      });
    },
    /**
     * Starts the 'create' date with the current date if no prop provided, or with the one passed
     * through the prop. It also uses the current hour to set start and end time.
     * - If 13:45, it sets 13:45 and 14:00 (if interval is 15).
     * - If 13:59, it sets 13:45 and 14:00 (if interval is 15).
     */
    initDateAndTimes() {
      // We put the most near (before the current date) time that is multiple of this.minutesInterval minutes
      // TODO: Clean this
      const startTime = this.date.minus({ minute: this.date.minute % this.minutesInterval });
      let minutesToAdd = this.minutesInterval;
      if (startTime.hour === 23 && startTime.minute === (60 - this.minutesInterval)) {
        minutesToAdd = this.minutesInterval - 1;
      }
      const endTime = startTime.plus({ minute: minutesToAdd });

      // Format to fit v-select options
      // eslint-disable-next-line max-len
      const startTimeString = `${this.fillWithZeroAtBegining(startTime.hour)}:${this.fillWithZeroAtBegining(startTime.minute)}`;
      // eslint-disable-next-line max-len
      const endTimeString = `${this.fillWithZeroAtBegining(endTime.hour)}:${this.fillWithZeroAtBegining(endTime.minute)}`;

      this.startTime = startTimeString;
      this.endTime = endTimeString;
    },
    async createCalendarEvent() {
      await this.$modal.confirm({
        title: this.$t('Crear {tipo_consulta}', { tipo_consulta: this.consultationType }),
        // eslint-disable-next-line max-len
        text: this.$t('Vas a crear una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.', { tipo_consulta: this.consultationType }),
        confirmButtonText: this.$t('Confirmar'),
        confirmButtonCallback: this.doCreateCalendarEvent,
      });
    },
    async doCreateCalendarEvent() {
      try {
        this.toggleAccepted();

        let start = DateTime.fromISO(`${this.date}T${this.startTime}:00`);
        let end = DateTime.fromISO(`${this.date}T${this.endTime}:00`);

        if (this.isAllDayActivated) {
          start = DateTime.fromISO(`${this.date}T00:00:00`);
          end = DateTime.fromISO(`${this.date}T23:59:00`);
        }

        const calendarEventData: any = {
          title: this.title,
          description: this.description ?? '',
          type: this.type,
          start_datetime: start.toUTC()
            .toFormat('yyyy-MM-dd HH:mm:ss'),
          end_datetime: end.toUTC()
            .toFormat('yyyy-MM-dd HH:mm:ss'),
          patient_id: this.patientId,
          owner_id: this.ownerId,
        };

        if (this.isConsultation && !this.isVideoconsulta) {
          calendarEventData.clinic_id = this.clinicId;
        }

        await api.admin.calendar.postStoreConsultationEventCalendar(calendarEventData);

        this.$toast.success(this.$t('Se ha creado el evento de calendario con éxito.'));

        this.$emit('calendar-event-created');

        this.closeModal();
      } catch (e) {
        this.toggleAccepted();

        this.$toast.error(this.$t('Ha ocurrido un error al crear el evento de calendario.'));
      }
    },
    handleCheckboxClick(e, variable) {
      if (e === 'true') {
        this[variable] = true;
      } else if (e === 'false') {
        this[variable] = false;
      }
    },
    fillWithZeroAtBegining(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
