import axios, {
  AxiosInstance, AxiosResponse, CancelToken, Method,
} from 'axios';
import HttpParseResponse, { ApiResponseContract } from '@/api/services/HttpParseResponse';
import BaseObject from '@/api/objects/BaseObject';

export default class HttpClient {
  apiClient: AxiosInstance;

  constructor() {
    const apiClient = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
    });

    apiClient.interceptors.response.use(
      (response) => response,
      (error) => Promise.reject(error),
    );

    this.apiClient = apiClient;
  }

  /**
   * Request
   *
   * @param method
   * @param path
   * @param params
   * @param data
   * @param cancelToken
   */
  public async request(
    method: Method,
    path: string,
    params: any[] | undefined,
    data: any[] | undefined,
    cancelToken: CancelToken | undefined | any = undefined,
  ): Promise<AxiosResponse> {
    if (!!cancelToken) {
      return this.apiClient.request({
        method,
        url: path,
        cancelToken,
        params,
        data,
      });
    }

    return this.apiClient.request({
      method,
      url: path,
      params,
      data,
    });
  }

  /**
   *
   * @param response
   * @param paginate
   */
  public parseResponse(response: AxiosResponse, paginate: boolean): ApiResponseContract | BaseObject | BaseObject[] {
    if (!response.data.data) {
      return response.data;
    }

    return HttpParseResponse.setResponse(response)
      .parser(paginate);
  }
}
