import BaseObject from '@/api/objects/BaseObject';
import FormValue from '@/api/objects/FormValue';

export interface FormGroupContract {
  formId: number;
  name: string;
  expedientId: number;
  createdAt: string;
  updatedAt: string;
}

export default class FormGroup extends BaseObject implements FormGroupContract {
  get formId(): number {
    return this.attributes.form_id;
  }

  get name(): string {
    return this.attributes.name;
  }

  get expedientId(): number {
    return this.attributes.expedient_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get formValues(): FormValue[] {
    return this.getRelation('form_values');
  }

  formValueByType(type: string): FormValue | undefined {
    return this.formValues.find((fv) => fv.type === type);
  }

  formValueById(id: number): FormValue | undefined {
    return this.formValues.find((fv) => fv.id === id);
  }

  formValuesFormatted(formStructure?: []): any {
    const formValuesJSON = {};

    if (formStructure) {
      formStructure.forEach((formValueStructure: Record<string, any>) => {
        const formValue = this.formValues.find((fv) => fv.type === formValueStructure.key);
        formValuesJSON[formValueStructure.key] = !!formValue ? formValue.value : '';
      });
    } else {
      this.formValues.forEach((formValue) => {
        formValuesJSON[formValue.type] = formValue.value;
      });
    }

    return formValuesJSON;
  }
}
