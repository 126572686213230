
import { defineComponent } from 'vue';
import VInputCheckbox from '@/components/vendor/basic/form/parts/VInputCheckbox.vue';

export default defineComponent({
  name: 'VFormGroupInputBtnCheckbox',
  components: {
    VInputCheckbox,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    forKey: {
      type: String,
      required: false,
      default: 'checkbox',
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    cSize() {
      let ret: string | null = null;

      if (!!this.size) {
        ret = `btn-${this.size}`;
      }

      return ret;
    },
    cVariant() {
      let ret: string | null = null;

      if (!!this.variant) {
        ret = `btn-${this.variant}`;
      }

      return ret;
    },
  },
  methods: {
    emitValue(value) {
      this.$emit('update:value', value);
    },
  },
});
