import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fw-normal" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_form_group_input_text = _resolveComponent("v-form-group-input-text")!
  const _component_v_form_group_textarea = _resolveComponent("v-form-group-textarea")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form_group_select = _resolveComponent("v-form-group-select")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    "class-name": "modal-fullscreen-lg-down",
    onKeypress: _cache[6] || (_cache[6] = _withKeys(_withModifiers(($event: any) => (_ctx.meta.valid ? _ctx.createConsultation() : null), ["prevent"]), ["enter"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('Nueva')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('interconsulta')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_v_form_group_input_text, {
                value: _ctx.title,
                label: _ctx.$t('Título'),
                "yup-errors-variable": _ctx.errors.title,
                class: "mb-24",
                autocomplete: "off",
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.title = $event))
              }, null, 8, ["value", "label", "yup-errors-variable"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_v_form_group_textarea, {
                label: _ctx.$t('Descripción'),
                idKey: "descripcion",
                class: "mb-24",
                value: _ctx.description,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.description = $event))
              }, null, 8, ["label", "value"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_v_form_group_select, {
                label: _ctx.$t('Paciente'),
                class: "mb-40",
                "yup-errors-variable": _ctx.errors.expedient
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    class: _normalizeClass({'is-invalid': !!_ctx.errors.expedient}),
                    options: _ctx.availableExpedientOptions,
                    reduce: returnValue => returnValue.key,
                    "yup-errors-variable": _ctx.errors.expedient,
                    placeholder: "",
                    "select-on-key-codes": [188, 13],
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.expedient = $event)),
                    modelValue: _ctx.expedient,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.expedient) = $event))
                  }, {
                    "no-options": _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                    ]),
                    _: 1
                  }, 8, ["class", "options", "reduce", "yup-errors-variable", "modelValue"])
                ]),
                _: 1
              }, 8, ["label", "yup-errors-variable"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_form_group_select, {
                label: _ctx.$t('Profesional*'),
                class: "mb-40",
                "yup-errors-variable": _ctx.errors.professional
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_select, {
                    class: _normalizeClass({'is-invalid': !!_ctx.errors.professional}),
                    options: _ctx.availableProfessionalOptions,
                    reduce: returnValue => returnValue.key,
                    "yup-errors-variable": _ctx.errors.professional,
                    placeholder: "",
                    "select-on-key-codes": [188, 13],
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.professional = $event)),
                    modelValue: _ctx.professional,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.professional) = $event))
                  }, {
                    "no-options": _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                    ]),
                    _: 1
                  }, 8, ["class", "options", "reduce", "yup-errors-variable", "modelValue"])
                ]),
                _: 1
              }, 8, ["label", "yup-errors-variable"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "link-secondary",
            class: "me-24",
            "aria-label": _ctx.$t('Cancelar'),
            "data-bs-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cancelar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"]),
          _createVNode(_component_v_button, {
            variant: "secondary",
            "aria-label": _ctx.$t('Añadir'),
            disabled: !_ctx.meta.valid || _ctx.accepted,
            "is-loading": _ctx.accepted,
            onClick: _ctx.createInterConsultation
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Añadir')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "disabled", "is-loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}