import getRouteHome from '@/helpers/redirectToHome';

export default async function admin({
  next,
  store,
}: { next: any, store: any }): Promise<void> {
  const { authUser } = store.state.user;

  if (authUser.role.name === 'Administrador') {
    next();
  } else {
    const routeName = getRouteHome(authUser.role.name);
    next({ name: routeName });
  }
}
