import BaseObject from '@/api/objects/BaseObject';
import TreatmentProtocolPhase from '@/api/objects/TreatmentProtocolPhase';

export interface TreatmentContract {
  expedientId: number;
  status: string;
  endReason: string;
  endDate: string;
  createdAt: string;
  updatedAt: string;
  treatmentProtocolPhases: TreatmentProtocolPhase[];
}

export default class Treatment extends BaseObject implements TreatmentContract {
  get expedientId(): number {
    return this.attributes.expedient_id;
  }

  get status(): string {
    return this.attributes.status;
  }

  get endDate(): string {
    return this.attributes.end_date;
  }

  get endReason(): string {
    return this.attributes.end_reason;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get treatmentProtocolPhases(): TreatmentProtocolPhase[] {
    return this.getRelation('treatment_protocol_phases');
  }
}
