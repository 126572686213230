
import { defineComponent, watch } from 'vue';
import TheHeader from '@/components/structural/TheHeader.vue';
import { useStore } from 'vuex';
import router from '@/router';
import TheGeneralComponents from '@/components/structural/modals/TheGeneralComponents.vue';
import TheLanguageSwitcher from '@/components/structural/TheLanguageSwitcher.vue';

export default defineComponent({
  name: 'App',
  components: {
    TheGeneralComponents,
    TheHeader,
    TheLanguageSwitcher,
  },
  setup() {
    const {
      state,
      dispatch,
    } = useStore();

    watch(() => state.user.isGuest, (value, oldValue) => {
      if (!!oldValue && !value) {
        dispatch('user/setAuthUser');
        dispatch('app/getFormsSettings');
        dispatch('protocol/getProtocols');
      }
    });

    watch(() => router.currentRoute.value, async (values, previousValues) => {
      if (previousValues.params.expedient !== values.params.expedient) {
        // Expedient has changed, so we have to refresh its information
        let path = 'expedient/getCurrentExpedient';
        if (state.user.authUser.role.name === 'Paciente') {
          path = 'patient/expedient/getCurrentExpedient';
        }
        // TODO: Add here the 'admin' when available

        await dispatch(path, {
          expedientId: values.params.expedient,
          treatmentId: values.query.tratamiento,
        });
      }

      if (!!values.params.tabName && previousValues.params.tabName !== values.params.tabName) {
        // Expedient tabName has changed, so we have to refresh the current section (tab)
        let path = 'expedient/setExpedientSection';
        if (state.user.authUser.role.name === 'Paciente') {
          path = 'patient/expedient/setExpedientSection';
        }
        // TODO: Add here the 'admin' when available

        await dispatch(path, {
          expedientSection: values.params.tabName,
        });
      }

      if (previousValues.query.tratamiento !== values.query.tratamiento) {
        if (state.user.authUser.role.name === 'Medico' || state.user.authUser.role.name === 'Nutricionista') {
          // Treatment has changed, so we have to refresh it
          await dispatch('treatment/getTreatment', {
            expedientId: values.params.expedient,
            treatmentId: values.query.tratamiento,
          });

          await dispatch('expedient/refreshExpedientMeasures', {
            expedientId: values.params.expedient,
            treatmentId: values.query.tratamiento,
          });
        } else if (state.user.authUser.role.name === 'Paciente') {
          // Treatment has changed, so we have to refresh it
          await dispatch('patient/expedient/treatment/getTreatment', {
            expedientId: values.params.expedient,
            treatmentId: values.query.tratamiento,
          });
        }
      }
    });
  },
});
