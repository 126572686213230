import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a68792e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-items-center mb-16" }
const _hoisted_2 = {
  class: "h4 title fw-normal text-primary",
  id: "offcanvas"
}
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = {
  key: 0,
  class: "badge bg-tertiary text-xs ms-16 me-4"
}
const _hoisted_5 = {
  key: 1,
  class: "badge bg-secondary text-xs mx-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_off_canvas_header = _resolveComponent("off-canvas-header")!
  const _component_the_professional_inter_consultation_view = _resolveComponent("the-professional-inter-consultation-view")!
  const _component_the_professional_inter_consultation_update = _resolveComponent("the-professional-inter-consultation-update")!
  const _component_off_canvas_body = _resolveComponent("off-canvas-body")!
  const _component_v_off_canvas = _resolveComponent("v-off-canvas")!

  return (_openBlock(), _createBlock(_component_v_off_canvas, {
    id: "offcanvasInterconsultationView",
    class: "offcanvas-end"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_off_canvas_header, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("h3", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.mode === 'view' ? _ctx.$t('Visualización de') : _ctx.$t('Edición de')) + " ", 1),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('interconsulta')), 1)
            ]),
            (_ctx.currentInterConsultation.creatorProfessional?.user?.id === _ctx.authUser.id)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('Creador')), 1))
              : _createCommentVNode("", true),
            (_ctx.currentInterConsultation.status === 'closed')
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('Finalizada')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_off_canvas_body, null, {
        default: _withCtx(() => [
          (_ctx.can('professional.inter-consultation.get-index-by-creator') && _ctx.mode === 'view')
            ? (_openBlock(), _createBlock(_component_the_professional_inter_consultation_view, {
                key: 0,
                professionals: _ctx.professionals,
                onEmitInterConsultationUpdated: _ctx.emitRefreshInterConsultation,
                onUpdateInterConsultation: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mode = 'update'))
              }, null, 8, ["professionals", "onEmitInterConsultationUpdated"]))
            : _createCommentVNode("", true),
          (_ctx.can('professional.inter-consultation.update') &&
      _ctx.currentInterConsultation.creatorProfessional?.user?.id === _ctx.authUser.id &&
      _ctx.mode === 'update')
            ? (_openBlock(), _createBlock(_component_the_professional_inter_consultation_update, {
                key: 1,
                "inter-consultation": _ctx.currentInterConsultation,
                professionals: _ctx.professionals,
                onShowInterConsultation: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mode = 'view')),
                onEmitInterConsultationUpdated: _cache[2] || (_cache[2] = ($event: any) => {_ctx.emitRefreshInterConsultation(); _ctx.mode = 'view'})
              }, null, 8, ["inter-consultation", "professionals"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}