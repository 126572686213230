import BaseObject from '@/api/objects/BaseObject';
import Professional from '@/api/objects/Professional';

export interface ClinicContract {
  name: string;
  address: string;
  phoneNumber: string;
  createdAt: string;
  updatedAt: string;
}

export default class Clinic extends BaseObject implements ClinicContract {
  get name(): string {
    return this.attributes.name;
  }

  get address(): string {
    return this.attributes.address;
  }

  get phoneNumber(): string {
    return this.attributes.phone_number;
  }

  get professionals(): null | Professional[] {
    return this.getRelation('professionals');
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }
}
