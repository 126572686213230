import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = {
  class: "media-library-thumb",
  dusk: "thumb"
}
const _hoisted_3 = {
  key: 1,
  class: "media-library-thumb-extension"
}
const _hoisted_4 = { class: "media-library-thumb-extension-truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.uploadInfo)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(`media-library-progress-wrap ${!_ctx.uploadInfo.hasFinishedUploading ?
       'media-library-progress-wrap-loading' : ''} ${!_ctx.uploadInfo.hasFinishedUploading
       && _ctx.uploadInfo.uploadProgress === 100 ? 'media-library-progress-wrap-processing' : ''}`)
        }, [
          _createElementVNode("progress", {
            max: "100",
            value: _ctx.uploadInfo.uploadProgress,
            class: "media-library-progress"
          }, null, 8, _hoisted_1)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (!!_ctx.imgProps.src && !_ctx.imageErrored)
        ? (_openBlock(), _createElementBlock("img", _mergeProps({ key: 0 }, _ctx.imgProps, {
            class: "media-library-thumb-img",
            onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.imageErrored = true))
          }), null, 16))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.imgProps.extension), 1)
          ]))
    ])
  ], 64))
}