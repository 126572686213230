import BaseObject from '@/api/objects/BaseObject';

export interface CategoryContract {
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class Category extends BaseObject implements CategoryContract {
  get name(): string {
    return this.attributes.name;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get deletedAt(): string {
    return this.attributes.deleted_at;
  }
}
