import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { UserState } from '@/store/modules/user/user.types';
import api from '@/api';
import getRouteHome from '@/helpers/redirectToHome';
import router from '@/router/index';

type Context = ActionContext<UserState, State>;

const actions: ActionTree<UserState, State> = {
  async setAuthUser({
    commit,
    dispatch,
  }: Context) {
    try {
      const data = await api.auth.authUser();

      commit('auth', data);
      commit('setGuest', false);
      commit('setImpersonatedBy', data?.impersonatedBy);

      if (data.role.name === 'Medico' || data.role.name === 'Nutricionista') {
        await dispatch('professional/refreshExpedients', null, { root: true });
      }
    } catch (e) {
      commit('auth', null);
      commit('setGuest', true);
      commit('setImpersonatedBy', null);
    }
  },
  async logout({ commit }: Context) {
    await commit('auth', null);
  },
  async setGuest({ commit }: Context, isGuest: boolean) {
    commit('setGuest', isGuest);
  },

  async impersonateStart({ state, commit, dispatch }: Context, { userId }: { userId: number }) {
    await api.admin.impersonate.start(userId);
    await dispatch('setAuthUser');
    await dispatch('redirectToHome', { method: 'replace' });
  },

  async impersonateStop({ commit, dispatch }: Context) {
    await api.admin.impersonate.stop();
    await dispatch('setAuthUser');
    await dispatch('redirectToHome', { method: 'replace' });
  },

  async redirectToHome({ state }: Context, { method }: { method: string }) {
    const name = getRouteHome(state?.authUser?.role?.name);
    await router[method]({ name });
  },
};

export default actions;
