
import { defineComponent } from 'vue';
import { Offcanvas } from 'bootstrap';

export default defineComponent({
  name: 'VOffCanvas',
  props: {
    id: {
      type: String,
      required: false,
      default: 'offcanvar',
    },
  },
  mounted() {
    this.setup();
    this.open();
  },
  data() {
    return {
      doEmitOnClose: true,
      offCanvas: null as Offcanvas | null,
    };
  },
  methods: {
    setup() {
      const $offcanvas: any = this.$refs.offcanvas;

      this.offCanvas = new Offcanvas($offcanvas);

      $offcanvas.addEventListener('hidden.bs.offcanvas', () => {
        if (this.doEmitOnClose) {
          this.$emit('closed');
        }
      });
    },
    open() {
      if (this.offCanvas) {
        this.offCanvas.show();
      }
    },
    hide() {
      if (this.offCanvas) {
        this.offCanvas.hide();
      }
    },
    hideWithoutEmitting() {
      this.doEmitOnClose = false;
      this.hide();
    },
  },
  beforeUnmount() {
    if (this.offCanvas) {
      this.offCanvas.hide();
    }
  },
  closeOffCanvasEmit() {
    this.$emit('closed');
  },
  closeOffCanvas() {
    this.hide();
  },
});
