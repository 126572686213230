
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api/index';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheResetPassword',
  components: {
    VButton,
    VFormGroupInputText,
  },
  data() {
    return {
      apiErrors: '',
      status: 'loaded',
    };
  },
  computed: {
    email() {
      return this.$route.query.email;
    },
    token() {
      return this.$route.params.token;
    },
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      password: yup.string()
        .required()
        .min(8)
        .label(t('Contraseña')),
      passwordConfirmation: yup.string()
        .required()
        .min(8)
        .oneOf([yup.ref('password'), null])
        .label(t('Confirmación de la contraseña')),
    });

    const form = useFormValidation(rules);

    const { value: password } = useField('password');
    const { value: passwordConfirmation } = useField('passwordConfirmation');

    return {
      ...form,
      password,
      passwordConfirmation,
      ...useCapabilities(),
    };
  },
  methods: {
    async resetPassword() {
      try {
        this.toggleAccepted();

        const data = {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          token: this.token,
        };

        // We replace whitespaces by '+' as they are encoded like that
        if (data.email && typeof data.email === 'string') {
          data.email = data.email.replace(' ', '+');
        }

        this.status = 'loading';

        await api.auth.resetPasswords(data);

        await this.$router.push({ name: 'reset-password.success' });

        this.toggleAccepted();
        this.status = 'loading';
      } catch (e) {
        this.toggleAccepted();

        this.status = 'loading';
        this.apiErrors = e.response.data.errors;
      }
    },
  },
});
