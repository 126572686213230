
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import vSelect from 'vue-select';
import api from '@/api';
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalTreatmentFinishConfirmModal',
  components: {
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    vSelect,
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      endReason: yup.string()
        .required(t('Debes elegir una de las razones dadas o escribir una propia'))
        .nullable()
        .max(60)
        .label(t('Razón')),
    });

    const form = useFormValidation(rules);

    const { value: endReason } = useField('endReason');

    return {
      ...form,
      endReason,
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('app', ['formsSettings']),
  },
  mounted() {
    this.endReason = this.formsSettings.finish_treatment_reason_options[0].key;
  },
  methods: {
    async finishTreatment() {
      try {
        this.toggleAccepted();

        const data = {
          end_reason: this.endReason,
        };

        await api.professional.expedients.treatments.finish(this.currentExpedient.id,
          this.currentTreatment.id, data);

        await this.$store.dispatch('treatment/setCurrentTreatment', {
          treatment: null,
        });

        await this.$store.dispatch('expedient/refreshExpedientTreatments', {
          expedientId: this.currentExpedient.id,
        });

        this.$toast.success(this.$t('Se ha finalizado el tratamiento correctamente'));

        this.$emit('treatment-finished');

        this.closeModal();

        await this.$router.push({
          name: 'professional.expedient.show',
          params: {
            expedient: this.currentExpedient.id,
            tabName: this.$route.params.tabName,
          },
        });

        this.toggleAccepted();
      } catch (e) {
        this.toggleAccepted();

        if (e.response.data.code_error === 'treatment_already_finalized') {
          this.$toast.error(this.$t('Ha ocurrido un error, el tratamiento ya está finalizado'));
        } else {
          this.$toast.error(this.$t('Ha ocurrido un error al finalizar el tratamiento'));
        }
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
