
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import TheProfessionalConsultationView
  from '@/views/professional/expedients/consultation/parts/TheProfessionalConsultationView.vue';
import TheProfessionalConsultationUpdate
  from '@/views/professional/expedients/consultation/parts/TheProfessionalConsultationUpdate.vue';
import TheProfessionalConsultationCreate
  from '@/views/professional/expedients/consultation/parts/TheProfessionalConsultationCreate.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'TheProfessionalConsultationShow',
  components: {
    VButton,
    TheProfessionalConsultationCreate,
    TheProfessionalConsultationUpdate,
    TheProfessionalConsultationView,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('consultation', ['currentConsultation']),
    mode() {
      if (this.$route.name === 'professional.expedient.consultation.update') {
        return 'edit';
      }
      return 'view';
    },
    canShowConsultation(): boolean {
      return this.can('professional.expedient.consultation.show')
        || this.can('patient.expedient.consultation.show');
    },
  },
  data() {
    return {
      consultationId: Number(this.$route.params.consultation),
      modalToShow: null,
    };
  },
  async mounted() {
    if (!this.currentConsultation && !!this.consultationId) {
      await this.loadConsultation();
    }
  },
  methods: {
    async loadConsultation() {
      await this.$store.dispatch('consultation/getCurrentConsultation', {
        expedientId: this.currentExpedient.id,
        consultationId: this.consultationId,
      });
    },
  },
});
