<template>
  <div class="controls d-flex align-items-center justify-content-between">
    <div class="devices d-flex justify-content-center align-items-center">
      <v-button
        type="button"
        variant="icon"
        class="text-primary text-xl p-8 me-4"
        :class="!participant.audio ? 'btn-danger' : 'btn-light'"
        :aria-label="participant.audio ? $t('Desactivar micrófono') : $t('Activar micrófono')"
        :title="participant.audio ? $t('Desactivar micrófono') : $t('Activar micrófono')"
        @click="handleAudioClick"
      >
        <template v-if="participant.audio">
          <!-- Microphone activated -->
          <span class="icon icon-mic-light" aria-hidden="true"></span>
        </template>

        <template v-else>
          <span class="icon icon-mic-light text-white" aria-hidden="true"></span>
        </template>
      </v-button>

      <v-button
        type="button"
        variant="icon"
        class="text-primary text-xl p-8 me-4"
        :class="!participant.video ? 'btn-danger' : 'btn-light'"
        :aria-label="participant.video ? $t('Desactivar cámara') : $t('Activar cámara')"
        :title="participant.video ? $t('Desactivar cámara') : $t('Activar cámara')"
        @click="handleVideoClick"
      >
        <template v-if="participant.video">
          <!-- Camera activated -->
          <span class="icon icon-camera-light" aria-hidden="true"></span>
        </template>

        <template v-else>
          <span class="icon icon-camera-light text-white" aria-hidden="true"></span>
        </template>
      </v-button>

      <template v-if="supportsScreenshare && false">
        <v-button
          type="button"
          variant="icon"
          class="btn-light text-primary text-xl p-8 me-4"
          :aria-label="participant.screen ? $t('Dejar de compartir pantalla') : $t('Compartir pantalla')"
          :title="participant.screen ? $t('Dejar de compartir pantalla') : $t('Compartir pantalla')"
          @click="handleScreenshareClick"
        >
          <!-- Share screen -->
          <template v-if="!participant.screen">
            <span class="icon icon-desktop-light" aria-hidden="true"></span>
          </template>

          <template v-else>
            <span class="icon icon-desktop-light text-danger" aria-hidden="true"></span>
          </template>
        </v-button>
      </template>
    </div>

    <!-- This had the 'leave' class -->
    <v-button
      type="button"
      variant="icon"
      class="btn-danger text-primary text-xl p-8"
      :aria-label="$t('Finalizar llamada')"
      :title="$t('Finalizar llamada')"
      @click="leaveCall"
    >
      <span class="icon icon-close text-white" aria-hidden="true"/>
    </v-button>
  </div>
</template>

<script>
import daily from '@daily-co/daily-js';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default {
  name: 'Controls',
  components: { VButton },
  props: [
    'participant',
    'handleVideoClick',
    'handleAudioClick',
    'handleScreenshareClick',
    'leaveCall',
    'disableScreenShare',
  ],
  data() {
    return {
      supportsScreenshare: false,
    };
  },
  mounted() {
    // Only show the screen share button if the browser supports it
    this.supportsScreenshare = daily.supportedBrowser().supportsScreenShare;
  },
};
</script>

<style scoped>
.controls {
  position: absolute;
  bottom: 12px;
  left: 8px;
  justify-content: space-between;
  display: flex;
  width: calc(100% - 16px);
}

.devices {
  border-radius: 12px;
  padding: 14px 10px;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
</style>
