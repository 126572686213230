import AbstractService from '@/api/services/AbstractService';

class LocaleService extends AbstractService {
  public async show() {
    return this.request('get', this.buildPath('locale'));
  }

  public async update(params: any): Promise<any> {
    return this.request('put', this.buildPath('locale'), [], params);
  }
}

export default new LocaleService();
