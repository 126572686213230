
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Clinic from '@/api/objects/Clinic';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import api from '@/api';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import Professional from '@/api/objects/Professional';
import { mapState } from 'vuex';
import VLoader from '@/components/vendor/VLoader.vue';

export default defineComponent({
  name: 'TheAdminClinicShow',
  components: {
    VLoader,
    VFormGroupSelect,
    ModalFooter,
    VFormGroupReadOnlyText,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    vSelect,
  },
  props: {
    clinicId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
  data() {
    return {
      clinic: null as Clinic | null,
      selectedProfesional: null as Professional | null,
      availableProfessionalOptions: [] as any[],
    };
  },
  mounted() {
    this.refreshClinicAndAvailableProfessionals();
  },
  methods: {
    async refreshClinicAndAvailableProfessionals() {
      await this.loadClinic();
      await this.loadAvailableProfessionalOptions();
    },
    async loadClinic() {
      this.clinic = await api.admin.clinic.get(this.clinicId);
    },
    async loadAvailableProfessionalOptions() {
      const professionals = await api.admin.professional.all(null);

      // We push existing ids to an array to make the comparison easier
      const professionalIdsArray = [] as any[];
      if (this.clinic && this.clinic.professionals) {
        this.clinic.professionals.forEach((professional) => {
          professionalIdsArray.push(professional.id);
        });
      }

      // We empty v-select
      this.availableProfessionalOptions = [];
      this.selectedProfesional = null;

      professionals.forEach((professional) => {
        // We need to remove those professionals that are related to the clinic
        if (!professionalIdsArray.includes(professional.id)) {
          this.availableProfessionalOptions.push({
            key: professional.id,
            // eslint-disable-next-line max-len
            label: `${professional.name} ${professional.surname} - ${professional.speciality} (${professional.subspeciality})`,
          });
        }
      });
    },
    async associateProfessionalWithClinic(professionalId, clinicId) {
      await this.$modal.confirm({
        title: this.$t('Asociar profesional y clínica'),
        // eslint-disable-next-line max-len
        text: this.$t('Vas a asociar el profesional con esa clínica. Esto significa que podrá utilizarla como clínica para sus consultas. ¿Estás seguro?'),
        confirmButtonText: this.$t('Asociar'),
        confirmButtonCallback: () => {
          this.doAssociateOrDisassociateProfessionalFromClinic(professionalId, clinicId);
        },
      });
    },
    async disassociateProfessionalFromClinic(professionalId, clinicId) {
      await this.$modal.confirm({
        title: this.$t('Desasociar profesional y clínica'),
        // eslint-disable-next-line max-len
        text: this.$t('Vas a desasociar el profesional con esa clínica. Esto significa que no podrá utilizarla como clínica para sus consultas. ¿Estás seguro?'),
        confirmButtonText: this.$t('Desasociar'),
        confirmButtonCallback: () => {
          this.doAssociateOrDisassociateProfessionalFromClinic(professionalId, clinicId);
        },
      });
    },
    async doAssociateOrDisassociateProfessionalFromClinic(professionalId, clinicId) {
      try {
        await api.admin.clinic.attachOrDetachProfessional(professionalId, clinicId);

        await this.refreshClinicAndAvailableProfessionals();

        this.$toast.success(this.$t('Profesional asociado/desasociado con éxito de la clínica.'));
      } catch (e) {
        // TODO: Catch exception?
        if (e.response?.data?.code_error === 'clinic_not_detached') {
          // eslint-disable-next-line max-len
          this.$toast.error(this.$t('El profesional tiene alguna de sus consultas asociadas a la clínica, no podemos desasociarlo.'));
        }
      }
    },
  },
});
