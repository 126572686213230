import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["role"]
const _hoisted_2 = { class: "ms-16 text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.cVariant, "alert d-flex"]),
    role: _ctx.role
  }, [
    _createElementVNode("span", {
      class: _normalizeClass([[_ctx.iconClass, _ctx.cIconVariant], "icon main-icon text-md"])
    }, null, 2),
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 10, _hoisted_1))
}