
import { defineComponent } from 'vue';
import VLoader from '@/components/vendor/VLoader.vue';
import { mapState } from 'vuex';
import Call from '@/views/professional/videocalls/Call.vue';
import Room from '@/api/objects/Room';
import useCapabilities from '../../../../resources/capabilities';
import VButton from '../../../../components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'ThePatientVideoCallOverlay',
  components: {
    Call,
    VLoader,
    VButton,
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/room', ['currentRoom']),
    ...mapState('user', ['authUser']),
  },
  watch: {
    currentExpedient(newValue) {
      if (newValue) {
        this.loadRoomInVuexStore(true);
      }
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      status: 'waiting',
      userName: '',
      roomUrl: '',
      room: null as null | Room,
      windowMaximized: false,
      windowMinimized: true,
      windowNormal: false,
      modalToShow: null as string | null,
    };
  },
  beforeMount() {
    this.loadRoomInVuexStore(true);
  },
  methods: {
    async startVideocall() {
      this.status = 'loading';

      if (!this.currentRoom) {
        await this.loadRoomInVuexStore(true);
      }

      this.status = 'loaded';

      if (this.currentRoom) {
        this.joinRoom(this.currentRoom);
      } else {
        this.$toast.error(this.$t('Hubo un error al intentar acceder a la videollamada.'));
      }
    },
    /**
     * Loads the current expedient room in the vuex store. If the room doesn't exist, it creates
     * a new one and returns it.
     *
     * @param force Forces to dispatch the action in order to get the room through an API request.
     */
    async loadRoomInVuexStore(force: boolean) {
      if (!this.currentRoom || force) {
        try {
          await this.$store.dispatch('patient/expedient/room/getRoomByExpedient', {
            expedientId: this.currentExpedient.id,
          });
        } catch (e) {
          // The professional didn't create a room yet
        }
      }
    },
    joinRoom(room) {
      let name = 'alguien';
      if (this.authUser.role.name === 'Paciente') {
        // Here is the patient user trying to connect with the professional so:
        name = `${this.currentExpedient.patient.user.name} ${this.currentExpedient.patient.user.surname}`;
      } else if (this.authUser.role.name === 'Medico' || this.authUser.role.name === 'Nutricionista') {
        name = `${this.currentExpedient.professional.name} ${this.currentExpedient.professional.surname}`;
      }

      this.userName = name;
      this.roomUrl = room.url;
      this.status = 'in-call';
    },
    leaveCall() {
      this.status = 'idle';
      this.windowNormal = false;
      this.windowMinimized = true;
    },
  },
});
