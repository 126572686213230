<template>
  <div class="wrapper">
    <div class="permissions-card">
      <h5 class="text-danger">{{ $t('Cámara o micrófono bloqueado') }}</h5>
      <button @click="tryAgain">{{ $t('Reintentar') }}</button>
      <a
        class="text-primary"
        href="https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer"
        rel="noreferrer noopener"
        target="_blank"
      >
        {{ $t('Conseguir ayuda') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsErrorMsg',
  props: ['reset'],
  methods: {
    tryAgain() {
      this.reset();
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.permissions-card {
  background-color: #69925F0D;
  border-radius: 16px;
  flex: 1 1 350px;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .5rem;
  aspect-ratio: 16/9;
}

button {
  color: #fff;
  background-color: #121a24;
  font-size: 12px;
  border: 1px solid #2b3f56;
  border-radius: 8px;
  padding: 8px 12px;
  cursor: pointer;
  margin: 16px;
}
a {
  color: #fff;
  text-decoration: underline;
  font-size: 12px;
}
</style>
