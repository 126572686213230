import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mb-24" }
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_dynamic_input_nutritional_anamnesis = _resolveComponent("the-dynamic-input-nutritional-anamnesis")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      for: _ctx.formGroupStructure.label,
      class: _normalizeClass(["form-label w-100", {'text-disabled': _ctx.disabled}])
    }, _toDisplayString(_ctx.$t(_ctx.formGroupStructure.label)), 11, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formGroupStructure.form_values, (formValue, index) => {
      return (_openBlock(), _createBlock(_component_the_dynamic_input_nutritional_anamnesis, {
        key: index,
        type: formValue.type,
        "form-group-key": _ctx.formGroupStructure.key,
        "form-value-key": formValue.key,
        "form-value-label": formValue.label,
        "form-value-options": formValue.options,
        value: _ctx.currentFormGroup?.formValueByType(formValue.key).value,
        "form-value-class": formValue.class,
        suffix: formValue.suffix,
        prefix: formValue.prefix,
        taggable: formValue.taggable,
        dependency: formValue.dependency,
        onUpdateFormGroupRequest: _ctx.updateFormGroup,
        onShowFormGroup: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = $event))
      }, null, 8, ["type", "form-group-key", "form-value-key", "form-value-label", "form-value-options", "value", "form-value-class", "suffix", "prefix", "taggable", "dependency", "onUpdateFormGroupRequest"]))
    }), 128))
  ], 512)), [
    [_vShow, _ctx.show]
  ])
}