import BaseObject from '@/api/objects/BaseObject';
import Patient from '@/api/objects/Patient';
import Professional from '@/api/objects/Professional';
import Address from '@/api/objects/Address';

export interface UserContract {
  name: string;
  surname: string;
  email: string;
  emailVerifiedAt: string;
  phoneNumber: string;
  role: any;
  impersonatedBy: any;
  patient: Patient | null;
  professional: Professional | null;
  phone: string;
  addresses: Address[] | null;
  phoneNumbers: string[];
}

export default class User extends BaseObject implements UserContract {
  get name(): string {
    return this.attributes.name;
  }

  get surname(): string {
    return this.attributes.surname;
  }

  get role(): any {
    return this.attributes.role;
  }

  get impersonatedBy(): any {
    return this.attributes.impersonated_by;
  }

  get email(): string {
    return this.attributes.email;
  }

  get emailVerifiedAt(): string {
    return this.attributes.email_verified_at;
  }

  get phoneNumber(): string {
    return this.attributes.phone_number;
  }

  get patient(): Patient | null {
    return this.getRelation('patient');
  }

  get professional(): Professional | null {
    return this.getRelation('professional');
  }

  get phone(): string {
    return this.attributes.phone_number;
  }

  get addresses(): Address[] | null {
    return this.getRelation('addresses');
  }

  get phoneNumbers(): string[] {
    return this.attributes.phone_numbers;
  }
}
