import BaseObject from '@/api/objects/BaseObject';
import ProtocolPhase from '@/api/objects/ProtocolPhase';

export interface TreatmentProtocolPhaseContract {
  status: string;
  initDate: string;
  endDate: string;
  treatmentId: number;
  protocolPhaseId: number;
  createdAt: string;
  updatedAt: string;
  protocolPhase: ProtocolPhase;
}

export default class TreatmentProtocolPhase extends BaseObject implements TreatmentProtocolPhaseContract {
  get status(): string {
    return this.attributes.status;
  }

  get initDate(): string {
    return this.attributes.init_date;
  }

  get endDate(): string {
    return this.attributes.end_date;
  }

  get treatmentId(): number {
    return this.attributes.treatment_id;
  }

  get protocolPhaseId(): number {
    return this.attributes.protocol_phase_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get protocolPhase(): ProtocolPhase {
    return this.getRelation('protocol_phase');
  }
}
