import { MutationTree } from 'vuex';
import { ConsultationState } from '@/store/modules/consultation/consultation.types';
import Consultation from '@/api/objects/Consultation';

interface ConsultationMutations {
  setCurrentConsultation(state: ConsultationState, payload: any): void;
}

const mutations: MutationTree<ConsultationState> & ConsultationMutations = {
  setCurrentConsultation(state: ConsultationState, consultation: Consultation) {
    state.currentConsultation = consultation;
  },
};

export default mutations;
