import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "video-empty-state py-8 px-12 text-center"
}
const _hoisted_2 = { class: "h-100 w-100 d-flex flex-column align-items-center justify-content-center text-center" }
const _hoisted_3 = { class: "h5 title text-primary" }
const _hoisted_4 = {
  key: 1,
  class: "row row-cols-2 row-cols-md-3 row-cols-lg-4 gy-20"
}
const _hoisted_5 = {
  key: 0,
  class: "file-container h-100"
}
const _hoisted_6 = { class: "file d-flex flex-column text-decoration-none h-100" }
const _hoisted_7 = ["alt", "src", "srcset"]
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "text text-sm mb-8 fw-bold" }
const _hoisted_10 = { class: "description text text-sm mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_picture = _resolveComponent("v-picture")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_loader = _resolveComponent("v-loader")!

  return (_ctx.status === 'loaded')
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (!_ctx.videos.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('No hay vídeos de esta categoría')), 1)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.videos, (video) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: video.id,
                  class: "mb-32"
                }, [
                  (_ctx.can('professional.video.show'))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_router_link, {
                            "aria-label": _ctx.$t('Ver vídeo'),
                            to: {
                  name: 'professional.video.show', params: {
                      video:  video.id,
                }},
                            class: "text-decoration-none"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass([{ 'd-flex justify-content-center align-items-center no-thumbnail': !video.thumbnailUrl }, "image-container mb-16"])
                              }, [
                                (!!video.thumbnailUrl)
                                  ? (_openBlock(), _createBlock(_component_v_picture, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("img", {
                                          alt: video.name,
                                          src: video.thumbnailUrl,
                                          srcset: `${video.thumbnailUrl} 2x, ${video.thumbnailUrl} 1x`
                                        }, null, 8, _hoisted_7)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(video.name), 1))
                              ], 2),
                              _createElementVNode("p", _hoisted_9, _toDisplayString(video.name), 1),
                              _createElementVNode("p", _hoisted_10, _toDisplayString(video.description), 1)
                            ]),
                            _: 2
                          }, 1032, ["aria-label", "to"])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
      ], 64))
    : (_openBlock(), _createBlock(_component_v_loader, { key: 1 }))
}