
import { defineComponent, PropType } from 'vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import CalendarEvent from '@/api/objects/CalendarEvent';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';

export default defineComponent({
  name: 'ThePatientCalendarEventShow',
  components: {
    VFormGroupReadOnlyText,
    VButton,
    VModal,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
  },
  props: {
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
    professionalName() {
      return `${this.calendarEvent?.owner?.professional?.name} ${this.calendarEvent?.owner?.professional?.surname}`;
    },
    consultationType() {
      return this.formsSettings.calendar_event_type_options.find((item) => item.key === this.calendarEvent.type);
    },
  },
  methods: {
    closeModal() {
      this.$emit('closed');
    },
  },
});
