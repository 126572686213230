import BaseObject from '@/api/objects/BaseObject';
// eslint-disable-next-line import/no-cycle
import Protocol from '@/api/objects/Protocol';

export interface ProtocolPhaseContract {
  name: string;
  protocolId: number;
  createdAt: string;
  updatedAt: string;
  viewUrl: string;
  downloadUrl: string;
  protocol: Protocol;
}

export default class ProtocolPhase extends BaseObject implements ProtocolPhaseContract {
  get name(): string {
    return this.attributes.name;
  }

  get viewUrl(): string {
    return JSON.parse(this.attributes?.meta_data)?.view_url;
  }

  get downloadUrl(): string {
    return JSON.parse(this.attributes?.meta_data)?.download_url;
  }

  get protocolId(): number {
    return this.attributes.protocol_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get protocol(): Protocol {
    return this.getRelation('protocol');
  }
}
