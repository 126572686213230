import { vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "datetime-local",
    class: _normalizeClass(["form-control", {'form-control-sm': _ctx.size === 'sm'}]),
    disabled: _ctx.disabled,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.date) = $event))
  }, null, 10, _hoisted_1)), [
    [_vModelText, _ctx.date]
  ])
}