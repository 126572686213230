
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import { mapState } from 'vuex';
import Media from '@/api/objects/Media';
import ThePatientDocumentGrid
  from '@/views/patient/expedients/documents/ThePatientDocumentGrid.vue';
import ThePatientDocumentList
  from '@/views/patient/expedients/documents/ThePatientDocumentList.vue';

export default defineComponent({
  name: 'ThePatientDocument',
  components: {
    ThePatientDocumentList,
    ThePatientDocumentGrid,
    VButton,
  },
  data() {
    return {
      typeSelected: 'all',
      media: [] as Media[],
      status: 'loading',
    };
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('app', ['formsSettings']),
  },
  watch: {
    typeSelected(newValue) {
      this.formsSettings.uploaded_media_category_options.forEach((type: any) => {
        if (type.key === newValue) {
          this.loadExpedientMedia();
        }
      });
    },
  },
  async mounted() {
    await this.loadExpedientMedia();
  },
  methods: {
    async loadExpedientMedia() {
      try {
        this.status = 'loading';

        const filters = {
          media_type: this.typeSelected,
        };

        this.media = await api.patient.expedient.media.all(this.currentExpedient.id, filters);

        this.status = 'loaded';
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar los documentos'));
        this.status = 'loaded';
      }
    },
  },
});
