import { MutationTree } from 'vuex';
import { FormState } from '@/store/modules/patient/expedient/modules/form/form.types';
import Form from '@/api/objects/Form';

interface FormMutations {
  setForms(state: FormState, forms: Form[]): void;

  setMeasures(state: FormState, measures: Form[]): void;
}

const mutations: MutationTree<FormState> & FormMutations = {

  setForms(state: FormState, forms: Form[]) {
    state.forms = forms;
  },
  setMeasures(state: FormState, measures: Form[]) {
    state.measures = measures;
  },
};

export default mutations;
