import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { ProfessionalState } from '@/store/modules/professional/professional.types';
import api from '@/api';
import Expedient from '@/api/objects/Expedient';

type Context = ActionContext<ProfessionalState, State>;

const actions: ActionTree<ProfessionalState, State> = {
  async getProfessional({
    dispatch,
    commit,
  }: Context) {
    try {
      const professional = await api.professionalData.profile();
      commit('setCurrentProfessional', professional);

      // Load and set expedients
      await dispatch('refreshExpedients');
    } catch (e) {
      commit('setCurrentProfessional', null);
    }
  },
  async refreshExpedients({
    state,
    commit,
  }: Context) {
    if (state.expedients === null) {
      const expedients: Expedient[] = await api.professional.expedient.all(null, false);

      commit('setExpedients', expedients);
    }
  },
};

export default actions;
