import AbstractService from '@/api/services/AbstractService';
import Video from '@/api/objects/Video';

class AdminVideoService extends AbstractService {
  public async all(filters: any): Promise<Video[]> {
    return this.request('get', this.buildPath('admin/videos'), filters);
  }

  public async show(videoId: number): Promise<Video> {
    return this.request('get', this.buildPath('admin/video/{videoId}', {
      videoId,
    }));
  }

  public async create(video: Record<string, unknown>): Promise<Video> {
    return this.request('post', this.buildPath('admin/video'), [], video);
  }

  public async update(videoId, data: any): Promise<Video> {
    return this.request('put', this.buildPath('admin/video/{videoId}', {
      videoId,
    }), [], data);
  }

  public async delete(videoId): Promise<any> {
    return this.request('delete', this.buildPath('admin/video/{videoId}', {
      videoId,
    }));
  }
}

export default new AdminVideoService();
