
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import ThePatientConsultationView
  from '@/views/patient/expedients/consultation/parts/ThePatientConsultationView.vue';

export default defineComponent({
  name: 'ThePatientConsultationShow',
  components: {
    ThePatientConsultationView,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/consultation', ['currentConsultation', 'status']),
    mode() {
      if (this.$route.name === 'professional.expedient.consultation.update') {
        return 'edit';
      }
      return 'view';
    },
    canShowConsultation(): boolean {
      return this.can('patient.expedient.consultation.show');
    },
  },
  data() {
    return {
      consultationId: Number(this.$route.params.consultation),
      modalToShow: null,
    };
  },
  async mounted() {
    if (!this.currentConsultation && !!this.consultationId) {
      await this.loadConsultation();
    }
  },
  methods: {
    async loadConsultation() {
      await this.$store.dispatch('patient/expedient/consultation/getCurrentConsultation', {
        expedientId: this.currentExpedient.id,
        consultationId: this.consultationId,
      });
    },
  },
});
