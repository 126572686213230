import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "md-dropdown dropdown" }
const _hoisted_2 = ["aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_button = _resolveComponent("v-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_button, {
      id: _ctx.id,
      "aria-expanded": _ctx.defaultOpen,
      class: _normalizeClass([[_ctx.buttonClasses, _ctx.classes], "dropdown-toggle"]),
      "data-active": _ctx.active,
      "data-bs-auto-close": _ctx.preventClose ? 'outside' : null,
      "data-bs-toggle": "dropdown",
      type: "button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "button"),
        (!_ctx.disableIcon)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["dropdown-arrow ms-auto icon", `icon-${_ctx.icon} text-${_ctx.iconSize}`])
            }, null, 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["id", "aria-expanded", "class", "data-active", "data-bs-auto-close"]),
    _createElementVNode("ul", {
      "aria-labelledby": _ctx.id,
      class: _normalizeClass([[_ctx.dropdownClasses, _ctx.collapse, { 'dropdown-dark': _ctx.darkVariant }], "dropdown-menu"]),
      style: _normalizeStyle(_ctx.styles)
    }, [
      _renderSlot(_ctx.$slots, "content")
    ], 14, _hoisted_2)
  ]))
}