
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ThePurchaseAuthorizationCreate
  from '@/views/professional/purchaseAuthorization/parts/ThePurchaseAuthorizationCreate.vue';
import { POSITION } from 'vue-toastification';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';
import ThePurchaseAuthorizationShow
  from '@/views/professional/purchaseAuthorization/parts/ThePurchaseAuthorizationShow.vue';

export default defineComponent({
  name: 'TheProfessionalConsultationView',
  components: {
    ThePurchaseAuthorizationShow,
    ThePurchaseAuthorizationCreate,
    VButton,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: null as null | string,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'purchaseAuthorizations']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('consultation', ['currentConsultation']),
    ...mapState('app', ['formsSettings']),
  },
  methods: {
    async showPurchaseAuthorizationCreateModal() {
      // If no current treatment we cannot create it
      // TODO: Move this to a helper as it is repeated in different components
      if (!this.currentTreatment) {
        await this.$modal.info({
          title: this.$t('¡Uups!'),
          // eslint-disable-next-line max-len
          text: this.$t('El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.'),
          rightButtonClasses: 'btn-secondary',
          acceptButtonText: this.$t('Cerrar'),
          leftButtonText: this.$t('Volver al panel'),
        });
      } else {
        this.modalToShow = 'the-purchase-authorization-create';
      }
    },
    consultationAuthorizations() {
      const purchases = [] as PurchaseAuthorization[];

      this.purchaseAuthorizations.forEach((purchaseAuthorization: PurchaseAuthorization) => {
        if (purchaseAuthorization.consultationId === this.currentConsultation.id) {
          purchases.push(purchaseAuthorization);
        }
      });

      return purchases;
    },
    emitCreateConsultation() {
      this.$emit('create-consultation-requested');
    },
    via() {
      const via = this.formsSettings.consultation_via_options.find((o) => o.key === this.currentConsultation.via);

      return via.label;
    },
    type() {
      const type = this.formsSettings.consultation_type_options.find((o) => o.key === this.currentConsultation.type);

      return type.label;
    },
    status(value) {
      switch (value) {
        case 'pending':
          return this.$t('Pendiente');
        case 'finalized':
          return this.$t('Finalizado');
        case 'deprecated':
          return this.$t('Caducado');
        case 'disabled':
          return this.$t('Deshabilitada');
        default:
          return value;
      }
    },
    async loadPurchaseAuthorizations() {
      try {
        await this.$store.dispatch('expedient/refreshPurchaseAuthorizations', {
          expedientId: this.currentExpedient.id,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las autorizaciones de compra'), {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    },
    async setCurrentPurchase(purchaseAuthorization) {
      await this.$store.dispatch('purchaseAuthorization/getCurrentPurchaseAuthorization', {
        purchaseAuthorization,
      });
    },
  },
});
