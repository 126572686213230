import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import api from '@/api';
import { RoomState } from '@/store/modules/expedient/modules/room/room.types';
import Room from '@/api/objects/Room';

type Context = ActionContext<RoomState, State>;

const actions: ActionTree<RoomState, State> = {
  async getRoomByExpedient({
    commit,
  }: Context, { expedientId }: { expedientId: number}) {
    if (expedientId) {
      const room = await api.professional.expedients.room.getRoom(expedientId);
      commit('setCurrentRoom', room);
    }
  },
  async createRoomByExpedient({
    commit,
  }: Context, { expedientId }: { expedientId: number }) {
    const data = {
      expedient_id: expedientId,
      start_datetime: null,
      end_datetime: null,
    };

    // We store it
    const room = await api.professional.expedients.room.store(expedientId, data);
    commit('setCurrentRoom', room);
  },
  async setCurrentRoom({
    commit,
  }: Context, {
    room,
  }: { room: Room | null }) {
    commit('setCurrentRoom', room);
  },
};

export default actions;
