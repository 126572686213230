import { MutationTree } from 'vuex';
import { ExpedientState } from '@/store/modules/patient/expedient/expedient.types';
import Expedient from '@/api/objects/Expedient';

interface ExpedientMutations {
  setStatus(state: ExpedientState, status: string): void;

  setExpedient(state: ExpedientState, payload: any): void;

  setExpedientSection(state: ExpedientState, expedientSection: string): void;

}

const mutations: MutationTree<ExpedientState> & ExpedientMutations = {
  setStatus(state: ExpedientState, status: string) {
    state.status = status;
  },
  setExpedient(state: ExpedientState, expedient: Expedient) {
    state.currentExpedient = expedient;
  },
  setExpedientSection(state: ExpedientState, expedientSection: string) {
    state.expedientSection = expedientSection;
  },
};

export default mutations;
