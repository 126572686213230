
import { defineComponent, PropType } from 'vue';
import VLineChart from '@/components/vendor/VLineChart.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import Form from '@/api/objects/Form';
import useCapabilities from '@/resources/capabilities';

export default defineComponent({
  name: 'TheMeasuresChartView',
  components: {
    VButton,
    VLineChart,
  },
  props: {
    measures: {
      type: Array as PropType<Form[]>,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    formValuesStructure(): any {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === 'medidas');

      const lastFormStructure: any = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      const formGroupStructure = lastFormStructure.structure.form_groups[0];

      return formGroupStructure.form_values;
    },
  },
  data() {
    return {
      options: {},
      measureName: 'peso',
      measureLabel: this.$t('Peso (kg)'),
    };
  },
  beforeMount() {
    this.setChartOptions();
  },
  watch: {
    measureName() {
      this.setChartOptions();
    },
    measures() {
      this.setChartOptions();
    },
  },
  methods: {
    setChartOptions() {
      const labels: any[] = [];
      const values: any[] = [];

      this.measures.forEach((measure: any) => {
        const { formGroup } = measure;

        const fieldDate = formGroup.formValues.find((o) => o.type === 'fecha');

        if (!!fieldDate) {
          labels.push(this.$str.formatDate(fieldDate.value));
        }

        const field = formGroup.formValues.find((o) => o.type === this.measureName);

        if (!!field) {
          values.push(field.value.replace(/,/g, '.'));
        } else {
          values.push('');
        }
      });

      this.options = {
        title: {
          text: this.measureLabel,
          textStyle: {
            color: '#69925F',
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['line'],
        },
        xAxis: {
          data: labels,
        },
        yAxis: {
          type: 'value',
        },
        color: '#004b60',
        series: [
          {
            name: this.measureLabel,
            type: 'line',
            data: values,
          },
        ],
        animationDuration: 1500,
      };
    },
    setTypeMeasure(key: string, label: string) {
      this.measureName = key;
      this.measureLabel = label;
    },
  },
});
