import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import TheForgotPassword from '@/views/auth/TheForgotPassword.vue';
import TheResetPassword from '@/views/auth/TheResetPassword.vue';
import TheResetPasswordSuccessful from '@/views/auth/TheResetPasswordSuccessful.vue';
import TheAdminHome from '@/views/administrator/TheAdminHome.vue';
import TheProfessionalHome from '@/views/professional/TheProfessionalHome.vue';
import TheProfessionalExpedientsList
  from '@/views/professional/expedients/TheProfessionalExpedientsList.vue';
import ThePatientHome from '@/views/patient/ThePatientHome.vue';
import store from '@/store/index';
import ThePageNotFound from '@/views/ThePageNotFound.vue';
import Home from '@/views/Home.vue';
import middlewarePipeline from '@/router/middlewarePipeline';
import auth from '@/middleware/auth';
import guest from '@/middleware/guest';
import professional from '@/middleware/professional';
import patient from '@/middleware/patient';
import admin from '@/middleware/admin';
import TheProfessionalExpedientFile
  from '@/views/professional/expedients/TheProfessionalExpedientFile.vue';
import TheProfessionalExpedientCreate
  from '@/views/professional/expedients/parts/TheProfessionalExpedientCreate.vue';
import TheProfessionalPatientDataProtection
  from '@/views/professional/expedients/parts/TheProfessionalPatientDataProtection.vue';
import TheRGPDAcceptance from '@/views/auth/TheRGPDAcceptance.vue';
import TheProfessionalAnamnesis from '@/views/professional/anamnesis/TheProfessionalAnamnesis.vue';
import TheProfessionalProfile from '@/views/professional/TheProfessionalProfile.vue';
import TheRGPDAcceptanceResponse from '@/views/auth/TheRGPDAcceptanceResponse.vue';
import TheVerifyUserResponse from '@/views/auth/TheVerifyUserResponse.vue';
import TheProfessionalDocument from '@/views/professional/documents/TheProfessionalDocument.vue';
import ThePatientExpedientFile from '@/views/patient/expedients/ThePatientExpedientFile.vue';
import TheProfessionalCalendar from '@/views/professional/calendar/TheProfessionalCalendar.vue';
import ThePatientCalendar from '@/views/patient/calendar/ThePatientCalendar.vue';
import TheProfessionalInterconsultationsList
  from '@/views/professional/inter-consultations/TheProfessionalInterConsultationsList.vue';
import TheShopOrderRedirection from '@/views/shop/TheShopOrderRedirection.vue';
import TheAdminProfessionalCreate
  from '@/views/administrator/professional/TheAdminProfessionalCreate.vue';
import TheAdminClinicCreate from '@/views/administrator/clinic/TheAdminClinicCreate.vue';
import TheAdminClinicList from '@/views/administrator/clinic/TheAdminClinicList.vue';
import TheAdminCalendar from '@/views/administrator/calendar/TheAdminCalendar.vue';
import TheAdminPatientsList from '@/views/administrator/patient/TheAdminPatientsList.vue';
import TheAdminPatientExpedientCreate
  from '@/views/administrator/patient/TheAdminPatientExpedientCreate.vue';
import TheAdminProfessionalsList
  from '@/views/administrator/professional/TheAdminProfessionalsList.vue';
import TheAdminResources from '@/views/administrator/resources/TheAdminResources.vue';
import TheProfessionalResources from '@/views/professional/resources/TheProfessionalResources.vue';
import TheAdminPurchaseAuthorizationList
  from '@/views/administrator/purchaseAuthorization/TheAdminPurchaseAuthorizationList.vue';
import TheAdminVideos from '@/views/administrator/videos/TheAdminVideos.vue';
import TheAdminVideoCreate from '@/views/administrator/videos/parts/TheAdminVideoCreate.vue';
import TheAdminVideoUpdate from '@/views/administrator/videos/parts/TheAdminVideoUpdate.vue';
import TheAdminVideoShow from '@/views/administrator/videos/parts/TheAdminVideoShow.vue';
import TheProfessionalVideos from '@/views/professional/videos/TheProfessionalVideos.vue';
import TheProfessionalVideoShow
  from '@/views/professional/videos/parts/TheProfessionalVideoShow.vue';
import TheAdminVideoCategoryShow from '@/views/administrator/videos/category/TheAdminVideoCategoryShow.vue';
import TheAdminVideoCategoryCreateView from '@/views/administrator/videos/category/TheAdminVideoCategoryCreateView.vue';
import TheAdminResourceCategoryShow from '@/views/administrator/resources/category/TheAdminResourceCategoryShow.vue';
import TheAdminResourceCategoryCreate
  from '@/views/administrator/resources/category/TheAdminResourceCategoryCreate.vue';
import TheLogin from '../views/auth/TheLogin.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { middleware: [auth] },
  },
  // #region Administrador,
  {
    path: '/administrador',
    name: 'admin.home',
    component: TheAdminHome,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/profesional',
    name: 'admin.professional.store',
    component: TheAdminProfessionalCreate,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/clinica',
    name: 'admin.clinic.store',
    component: TheAdminClinicCreate,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/clinicas',
    name: 'admin.clinic.index',
    component: TheAdminClinicList,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/profesionales',
    name: 'admin.professional.index',
    component: TheAdminProfessionalsList,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/pacientes',
    name: 'admin.patient.index',
    component: TheAdminPatientsList,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/crear-expediente',
    name: 'admin.expedient.store',
    component: TheAdminPatientExpedientCreate,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/recursos',
    name: 'admin.resources.index',
    component: TheAdminResources,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/videoteca',
    name: 'admin.video.index',
    component: TheAdminVideos,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/videoteca/crear',
    name: 'admin.video.store',
    component: TheAdminVideoCreate,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/videoteca/:video/editar',
    name: 'admin.video.update',
    component: TheAdminVideoUpdate,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/videoteca/:video/ver',
    name: 'admin.video.show',
    component: TheAdminVideoShow,
    props: true,
    meta: { middleware: [auth, admin] },
    children: [
      {
        path: '/administrador/videoteca/:video/editar',
        name: 'admin.video.update',
        component: TheAdminVideoUpdate,
        meta: { middleware: [auth, admin] },
      },
    ],
  },
  {
    path: '/administrador/calendario',
    name: 'admin.calendar',
    component: TheAdminCalendar,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/autorizaciones-de-compra',
    name: 'admin.purchase-authorization.index',
    component: TheAdminPurchaseAuthorizationList,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/videoteca/categorias/crear',
    name: 'admin.video-category.store',
    component: TheAdminVideoCategoryCreateView,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/recursos/categorias/crear',
    name: 'admin.resource-category.store',
    component: TheAdminResourceCategoryCreate,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/videoteca/categorias',
    name: 'admin.video-category.index',
    component: TheAdminVideoCategoryShow,
    meta: { middleware: [auth, admin] },
  },
  {
    path: '/administrador/recursos/categorias',
    name: 'admin.resource-category.index',
    component: TheAdminResourceCategoryShow,
    meta: { middleware: [auth, admin] },
  },
  // #endregion Administrador
  {
    path: '/profesional',
    name: 'professional.home',
    component: TheProfessionalHome,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/perfil',
    name: 'professional.self-profile',
    component: TheProfessionalProfile,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/calendario',
    name: 'professional.calendar',
    component: TheProfessionalCalendar,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/interconsultas',
    name: 'professional.inter-consultation.get-index-by-creator',
    component: TheProfessionalInterconsultationsList,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/recursos',
    name: 'professional.resources',
    component: TheProfessionalResources,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/pacientes',
    name: 'professional.expedient.index',
    component: TheProfessionalExpedientsList,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/paciente',
    name: 'professional.expedient.store',
    component: TheProfessionalExpedientCreate,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/paciente/:expedient/proteccion-datos',
    name: 'professional.patient.rgpd',
    props: true,
    component: TheProfessionalPatientDataProtection,
    meta: { middleware: [auth, professional] },
  },
  /**
   * TODO: Mirar si el nombre de la Tab en un expediente sea un parámetro y la ruta sea sólo una o
   * hacer una ruta para cada Tab y que el componente siempre sea el mismo
   */
  {
    path: '/profesional/paciente/:expedient/:tabName?/:consultation?',
    name: 'professional.expedient.show',
    props: true,
    component: TheProfessionalExpedientFile,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/paciente/:expedient/:tabName?/:consultation?/editar',
    name: 'professional.expedient.consultation.update',
    props: true,
    component: TheProfessionalExpedientFile,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/anamnesis',
    name: 'professional.anamnesis',
    component: TheProfessionalAnamnesis,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/videoteca',
    name: 'professional.video.index',
    component: TheProfessionalVideos,
    meta: { middleware: [auth, professional] },
  },
  {
    path: '/profesional/videoteca/:video/ver',
    name: 'professional.video.show',
    component: TheProfessionalVideoShow,
    props: true,
    meta: { middleware: [auth, professional] },
  },
  /**
   *  Patients
   */
  {
    path: '/paciente',
    name: 'patient.home',
    component: ThePatientHome,
    meta: { middleware: [auth, patient] },
  },
  {
    path: '/paciente/:expedient/:tabName?/:consultation?',
    name: 'patient.expedient.show',
    props: true,
    component: ThePatientExpedientFile,
    meta: { middleware: [auth, patient] },
  },
  {
    path: '/paciente/calendario',
    name: 'patient.calendar.show',
    component: ThePatientCalendar,
    meta: { middleware: [auth, patient] },
  },
  {
    path: '/login',
    alias: '/',
    name: 'login',
    component: TheLogin,
    meta: { middleware: [guest] },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: TheForgotPassword,
    meta: { middleware: [guest] },
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: TheResetPassword,
    meta: { middleware: [guest] },
  },
  {
    path: '/reset-password/success',
    name: 'reset-password.success',
    component: TheResetPasswordSuccessful,
    meta: { middleware: [guest] },
  },
  {
    path: '/aceptar-rgpd',
    name: 'rgpd-acceptance',
    component: TheRGPDAcceptance,
  },
  {
    path: '/aceptar-rgpd/respuesta',
    name: 'rgpd-acceptance.response',
    component: TheRGPDAcceptanceResponse,
  },
  /**
   * WC
   */
  {
    path: '/pedido/:authorizationCode/completar',
    name: 'shop-order.complete',
    props: true,
    component: TheShopOrderRedirection,
  },
  {
    path: '/verificacion/usuario',
    name: 'verification.verify',
    component: TheVerifyUserResponse,
  },
  {
    path: '/404',
    name: 'not-found',
    component: ThePageNotFound,
  },
  {
    path: '/:pathMatch(.*)*',
    component: ThePageNotFound,
  },
  {
    path: '/profesional/documento',
    name: 'professional.document.show',
    component: TheProfessionalDocument,
    meta: { middleware: [auth, professional] },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const { middleware }: any = to.meta;
  const context = {
    to,
    from,
    next,
    store,
  };

  if (!middleware) {
    next();
  }

  middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export default router;
