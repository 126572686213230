import BaseObject from '@/api/objects/BaseObject';

export interface FormVersionContract {
  type: string;
  version: number;
  structure: string;
}

export default class FormVersion extends BaseObject implements FormVersionContract {
  get type(): string {
    return this.attributes.type;
  }

  get version(): number {
    return Number(this.attributes.version);
  }

  get structure(): string {
    return this.attributes.structure;
  }
}
