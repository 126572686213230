
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupTextarea from '@/components/vendor/basic/form/VFormGroupTextarea.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import VFormGroupInputDatetime from '@/components/vendor/basic/form/VFormGroupInputDatetime.vue';
import vSelect from 'vue-select';
import ThePurchaseAuthorizationCreate
  from '@/views/professional/purchaseAuthorization/parts/ThePurchaseAuthorizationCreate.vue';
import { POSITION } from 'vue-toastification';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';
import ThePurchaseAuthorizationShow
  from '@/views/professional/purchaseAuthorization/parts/ThePurchaseAuthorizationShow.vue';
import VLoader from '@/components/vendor/VLoader.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalConsultationUpdate',
  components: {
    VLoader,
    ThePurchaseAuthorizationShow,
    ThePurchaseAuthorizationCreate,
    VFormGroupInputDatetime,
    VFormGroupSelect,
    VFormGroupTextarea,
    VButton,
    vSelect,
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      type: yup.string()
        .required()
        .nullable()
        .label(t('Tipo de consulta')),
      via: yup.string()
        .required()
        .nullable()
        .label(t('Medio de consulta')),
      initDate: yup.string()
        .required()
        .label(t('Fecha de inicio')),
      endDate: yup.string()
        .nullable()
        .label(t('Fecha de fin')),
      clinicId: yup.number()
        .nullable()
        .label(t('Consulta física')),
      treatmentId: yup.number()
        .nullable()
        .label(t('Tratamiento')),
      observations: yup.string()
        .nullable()
        .label(t('Observaciones')),
    });

    const form = useFormValidation(rules);

    const { value: type } = useField('type');
    const { value: via } = useField('via');
    const { value: initDate } = useField('initDate');
    const { value: endDate } = useField('endDate');
    const { value: clinicId } = useField('clinicId');
    const { value: treatmentId } = useField('treatmentId');
    const { value: observations } = useField('observations');

    return {
      ...useCapabilities(),
      ...form,
      type,
      via,
      initDate,
      endDate,
      clinicId,
      treatmentId,
      observations,
    };
  },
  data() {
    return {
      clinicOptions: [] as any[],
      modalToShow: null as null | string,
      isLoading: true,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'treatments', 'purchaseAuthorizations']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('consultation', ['currentConsultation']),
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
    treatmentOptions() {
      const treatmentsAux: any[] = [];

      this.treatments.forEach((treatment) => {
        treatment.treatmentProtocolPhases.forEach((protocolPhaseTreatment) => {
          if (protocolPhaseTreatment.status === 'active') {
            treatmentsAux.push({
              key: treatment.id,
              label: `[${this.$str.formatDate(treatment.createdAt)}]
              ${protocolPhaseTreatment.protocolPhase.protocol.name} (${protocolPhaseTreatment.protocolPhase.name})`,
            });
          }
        });
      });

      return treatmentsAux;
    },
  },
  beforeMount() {
    this.setConsultationValues();
    this.loadAvailableClinics();
  },
  methods: {
    async loadAvailableClinics() {
      const clinics = await api.professional.clinic.getIndexOfProfessional();

      clinics.forEach((clinic) => {
        this.clinicOptions.push({
          key: clinic.id,
          label: clinic.name,
        });
      });

      this.isLoading = false;
    },
    setConsultationValues() {
      this.type = this.currentConsultation.type;
      this.via = this.currentConsultation.via;
      this.clinicId = this.currentConsultation.clinicId;
      this.treatmentId = this.currentConsultation.treatmentId;
      this.observations = this.currentConsultation.observations;
      this.initDate = this.currentConsultation.initDate;
      this.endDate = this.currentConsultation.endDate;
    },
    async updateConsultation() {
      this.toggleAccepted();

      const consultationForm = {
        init_date: !!this.initDate ? this.initDate : null,
        end_date: !!this.endDate ? this.endDate : null,
        type: this.type,
        via: this.via,
        treatment_id: this.treatmentId,
        expedient_id: this.currentConsultation.id,
        clinic_id: this.clinicId,
        observations: !!this.observations ? this.observations : this.$t('- Sin observaciones -'),
      };

      try {
        const consultation = await api.professional.expedients.consultation.update(
          this.currentExpedient.id,
          this.currentConsultation.id, consultationForm,
        );

        this.$toast.success(this.$t('Los cambios se han guardado correctamente. '));

        await this.$store.dispatch('expedient/refreshExpedientConsultations', {
          expedientId: this.currentExpedient.id,
        });

        await this.$store.dispatch('consultation/getCurrentConsultation', {
          expedientId: this.currentExpedient.id,
          consultationId: consultation.id,
        });

        this.$emit('updated');

        await this.$router.push({
          name: 'professional.expedient.show',
          params: {
            tabName: 'consultas',
            consultation: this.currentConsultation.id,
          },
          query: { tratamiento: this.currentTreatment?.id },
        });

        this.toggleAccepted();
      } catch (e) {
        this.toggleAccepted();

        this.$toast.error(this.$t('Ha ocurrido un error al guardar los cambios'));
      }
    },
    status(value) {
      switch (value) {
        case 'pending':
          return this.$t('Pendiente');
        case 'finalized':
          return this.$t('Finalizado');
        case 'deprecated':
          return this.$t('Caducado');
        case 'disabled':
          return this.$t('Deshabilitada');
        default:
          return value;
      }
    },
    consultationAuthorizations() {
      const purchases = [] as PurchaseAuthorization[];

      this.purchaseAuthorizations.forEach((purchaseAuthorization: PurchaseAuthorization) => {
        if (purchaseAuthorization.consultationId === this.currentConsultation.id) {
          purchases.push(purchaseAuthorization);
        }
      });

      return purchases;
    },
    async showPurchaseAuthorizationCreateModal() {
      // If no current treatment we cannot create it
      // TODO: Move this to a helper as it is repeated in different components
      if (!this.currentTreatment) {
        await this.$modal.info({
          title: this.$t('¡Uups!'),
          // eslint-disable-next-line max-len
          text: this.$t('El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.'),
          rightButtonClasses: 'btn-secondary',
          acceptButtonText: this.$t('Cerrar'),
          leftButtonText: this.$t('Volver al panel'),
        });
      } else {
        this.modalToShow = 'the-purchase-authorization-create';
      }
    },
    async setCurrentPurchase(purchaseAuthorization) {
      await this.$store.dispatch('purchaseAuthorization/getCurrentPurchaseAuthorization', {
        purchaseAuthorization,
      });
    },
    async loadPurchaseAuthorizations() {
      try {
        await this.$store.dispatch('expedient/refreshPurchaseAuthorizations', {
          expedientId: this.currentExpedient.id,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las autorizaciones de compra'), {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    },
  },
});
