import BaseObject from '@/api/objects/BaseObject';
import User from '@/api/objects/User';
import Expedient from '@/api/objects/Expedient';

export interface RoomContract {
  name: string;
  privacy: string;
  status: string;
  startDatetime: string;
  endDatetime: string;
  url: string;
  properties: string;
  createdAt: string;
  updatedAt: string;
  expedient: Expedient | null;
  owner: User | null;
}

export default class Room extends BaseObject implements RoomContract {
  get name(): string {
    return this.attributes.name;
  }

  get privacy(): string {
    return this.attributes.privacy;
  }

  get status(): string {
    return this.attributes.status;
  }

  get startDatetime(): string {
    return this.attributes.start_datetime;
  }

  get endDatetime(): string {
    return this.attributes.end_datetime;
  }

  get url(): string {
    return this.attributes.url;
  }

  get properties(): string {
    return this.attributes.properties;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get expedientId(): number {
    return this.attributes.expedient_id;
  }

  get expedient(): Expedient | null {
    return this.getRelation('expedient');
  }

  get owner(): User | null {
    return this.getRelation('owner');
  }
}
