
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VMultipleButtonSelect',
  props: {
    divClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {};
  },
});
