
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
// eslint-disable-next-line import/no-cycle
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import vSelect from 'vue-select';
import { mapState } from 'vuex';
import api from '@/api';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminPatientExpedientCreate',
  components: {
    VAlert,
    VFormGroupInputDate,
    VFormGroupSelect,
    VFormGroupInputText,
    VButton,
    vSelect,
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  data() {
    return {
      availableProfessionals: [] as any[],
    };
  },
  beforeMount() {
    this.loadAvailableProfessionals();
  },
  mounted() {
    this.phoneNumberPrefix = '34';
    // CARE: Careful because this hardcoded value could change in API
    this.identityDocType = 'dni';
    this.genre = 'not_specific';
  },
  setup() {
    const { t } = useI18n();

    /**
     * I use this method to store variables without
     * numbers, just text.
     *
     * @param number
     */
    // eslint-disable-next-line consistent-return
    const numberToSpelledString = function (number) {
      // TODO: Move to a helper
      switch (number) {
        case 1:
          return 'One';
        case 2:
          return 'Two';
        case 3:
          return 'Three';
        case 4:
          return 'Four';
        case 5:
          return 'Five';
        default:
          return 'One';
      }
    };

    // Dynamically add phone numbers
    const auxRules = {};
    for (let iterator = 1; iterator <= 5; iterator += 1) {
      // Dynamically build each fieldRule
      auxRules[`auxPhoneNumberPrefix${numberToSpelledString(iterator)}`] = yup.lazy((value, options) => {
        let rule = yup.string()
          .label(t('Prefijo telefónico'))
          .nullable();

        const field = options.parent[`auxPhoneNumber${numberToSpelledString(iterator)}`];
        if (!!field && field !== '') {
          rule = rule.required();
        }

        return rule;
      });
      auxRules[`auxPhoneNumber${numberToSpelledString(iterator)}`] = yup.lazy((value, options) => {
        let rule = yup.string()
          .label(t('Número de teléfono'));

        const field = options.parent[`auxPhoneNumberPrefix${numberToSpelledString(iterator)}`];
        if (!!field && field !== '') {
          rule = rule.required().matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras'));
        }

        return rule;
      });
    }

    const rules = yup.object({
      professionalId: yup.number()
        .required()
        .label(t('Profesional')),
      name: yup.string()
        .required()
        .label(t('Nombre')),
      surname: yup.string()
        .required()
        .label(t('Apellidos')),
      identityDocType: yup.string()
        .required()
        .label(t('Tipo de Documento')),
      nif: yup.lazy((value, options) => {
        // initialize default rules for all options
        const rule = yup.string()
          .required()
          .label(t('Número de documento'));
        // this property retrieves the information of the datasets initialized with yup
        const { identityDocType } = options.parent;
        const messageValidation = t('El {tipo_documento} registrado no es Válido', { tipo_documento: identityDocType });
        switch (identityDocType) {
          case 'dni':
            return rule.matches(/^[0-9]{8,8}[A-Za-z]$/g, messageValidation);
          case 'nie':
            return rule.matches(/^[XYZ|xyz]\d{7,8}[A-Za-z]$/, messageValidation);
          default:
            return rule; // here you can decide what is the default
        }
      }),
      genre: yup.string()
        .required()
        .label(t('Género')),
      bornDate: yup.date()
        .required()
        .max(new Date(), t('La fecha de nacimiento no puede ser futura'))
        .label(t('Fecha de nacimiento')),
      phoneNumberPrefix: yup.string()
        .required()
        .label(t('Prefijo telefónico')),
      phoneNumber: yup.string()
        .required()
        .label(t('Número de teléfono'))
        .matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras')),
      email: yup.string()
        .email()
        .label(t('Correo electrónico')),
      ...auxRules,
    });

    const form = useFormValidation(rules);

    const { value: professionalId } = useField('professionalId');
    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: identityDocType } = useField('identityDocType');
    const { value: nif } = useField('nif');
    const { value: genre } = useField('genre');
    const { value: bornDate } = useField('bornDate');
    const { value: phoneNumberPrefix } = useField('phoneNumberPrefix');
    const { value: phoneNumber } = useField('phoneNumber');
    const { value: email } = useField('email');
    // Aux phone numbers
    const { value: auxPhoneNumberPrefixOne } = useField('auxPhoneNumberPrefixOne');
    const { value: auxPhoneNumberOne } = useField('auxPhoneNumberOne');
    const { value: auxPhoneNumberPrefixTwo } = useField('auxPhoneNumberPrefixTwo');
    const { value: auxPhoneNumberTwo } = useField('auxPhoneNumberTwo');
    const { value: auxPhoneNumberPrefixThree } = useField('auxPhoneNumberPrefixThree');
    const { value: auxPhoneNumberThree } = useField('auxPhoneNumberThree');
    const { value: auxPhoneNumberPrefixFour } = useField('auxPhoneNumberPrefixFour');
    const { value: auxPhoneNumberFour } = useField('auxPhoneNumberFour');
    const { value: auxPhoneNumberPrefixFive } = useField('auxPhoneNumberPrefixFive');
    const { value: auxPhoneNumberFive } = useField('auxPhoneNumberFive');

    return {
      ...form,
      professionalId,
      name,
      surname,
      nif,
      genre,
      bornDate,
      identityDocType,
      phoneNumberPrefix,
      phoneNumber,
      email,

      auxPhoneNumberPrefixOne,
      auxPhoneNumberOne,
      auxPhoneNumberPrefixTwo,
      auxPhoneNumberTwo,
      auxPhoneNumberPrefixThree,
      auxPhoneNumberThree,
      auxPhoneNumberPrefixFour,
      auxPhoneNumberFour,
      auxPhoneNumberPrefixFive,
      auxPhoneNumberFive,

      numberToSpelledString,

      ...useCapabilities(),
    };
  },
  methods: {
    async loadAvailableProfessionals() {
      const professionals = await api.admin.professional.all(null);

      this.availableProfessionals = [];

      professionals.forEach((professional) => {
        this.availableProfessionals.push({
          key: professional?.id,
          // eslint-disable-next-line max-len
          label: `${professional.name} ${professional.surname} - ${professional.speciality} (${professional.subspeciality})`,
        });
      });
    },
    async createExpedient() {
      await this.$modal.confirm({
        title: this.$t('Crear expediente'),
        // eslint-disable-next-line max-len
        text: this.$t('Vas a crear un nuevo paciente y adjudicárselo al profesional indicado. De esta forma, se creará ese paciente y el profesional podrá comenzar a tratarlo. Además, enviaremos un correo electrónico al paciente para que restablezca su contraseña y tenga acceso a la plataforma. ¿Estás seguro?'),
        confirmButtonText: this.$t('Crear'),
        confirmButtonCallback: this.doCreateExpedient,
      });
    },
    async doCreateExpedient() {
      this.toggleAccepted();

      const aux = [] as string[];
      for (let iterator = 1; iterator <= 5; iterator += 1) {
        // Dynamically build each fieldRule
        if (this.values[`auxPhoneNumberPrefix${this.numberToSpelledString(iterator)}`]
          && this.values[`auxPhoneNumber${this.numberToSpelledString(iterator)}`]) {
          // eslint-disable-next-line no-useless-concat,max-len
          aux.push(`${this.values[`auxPhoneNumberPrefix${this.numberToSpelledString(iterator)}`]} ${this.values[`auxPhoneNumber${this.numberToSpelledString(iterator)}`]}`);
        }
      }

      const expedient = {
        professional_id: this.values.professionalId,
        name: this.values.name,
        surname: this.values.surname,
        identity_doc_type: this.values.identityDocType,
        nif: this.values.nif.toUpperCase(),
        genre: this.values.genre,
        born_date: this.values.bornDate,
        // We set a whitespace to be able to distinguish the prefix
        phone_number: `${this.values.phoneNumberPrefix} ${this.values.phoneNumber}`,
        email: this.values.email,
        phone_numbers: aux,
      };

      if (!expedient.email) {
        // Empty strings or just blank spaces are avoid
        expedient.email = null;
      }

      try {
        /*
        TODO: Be more exhaustive when parsing the phone number as here is the only step in which
        We will take care that is a valid phone number so the RGPD SMS goes to him
         */
        await api.admin.expedient.create(expedient);

        this.$toast.success(this.$t('El paciente se ha creado correctamente'));

        await this.$router.replace({
          name: 'admin.patient.index',
        });

        this.toggleAccepted();
      } catch (e) {
        // eslint-disable-next-line max-len
        if (e.response.data?.errors?.phone_number && e.response.data?.errors?.phone_number[0] === 'The phone number has already been taken.') {
          this.$toast.error(this.$t('El número de teléfono ya existe en nuestra base de datos, prueba con otro'));
        } else if (e.response.data?.code_error === 'expedient_patient_already_exists') {
        // eslint-disable-next-line max-len
          this.$toast.error(this.$t('Ese profesional ya tiene registrado a un paciente con ese número de identificación.'));
        } else {
          this.$toast.error(this.$t('Ha ocurrido un error al añadir el nuevo paciente'));
        }

        this.toggleAccepted();
      }
    },
  },
});
