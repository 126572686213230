
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheVerifyUserResponse',
  computed: {
    hash() {
      if (!!this.$route.query.signature) {
        return this.$route.query.signature;
      }
      return null;
    },
    message() {
      if (!!this.$route.query.message) {
        return this.$route.query.message;
      }
      return null;
    },
    status() {
      const { status } = this?.$route?.query;
      let result = this.$t('¡Error!');
      if (status !== 'error') {
        result = this.$t('Listo');
      }
      return result;
    },
  },
});
