import { createI18n } from 'vue-i18n';
import es from './es';
import en from './en';
import pt from './pt';

// eslint-disable-next-line max-len
// const locale = await api.language.locale.show() ?? 'es'; // TODO no se como definir por defecto el idioma cargando desde el api.

const i18n = createI18n({
  locale: 'es',
  legacy: false,
  fallbackLocale: 'en',
  messages: {
    es,
    en,
    pt,
  },
});

export default i18n;
