import AbstractService from '@/api/services/AbstractService';
import Form from '@/api/objects/Form';
import { CancelToken } from 'axios';

class ExpedientFormService extends AbstractService {
  public async all(expedientId: number): Promise<Form[]> {
    return this.request('get', this.buildPath('expedient/{expedientId}/forms', { expedientId }));
  }

  public async create(expedientId: number, form: any): Promise<Form[]> {
    return this.request('post', this.buildPath('expedient/{expedientId}/form-group', { expedientId }), [], form);
  }

  public async update(
    expedientId: number,
    formGroupId: number,
    form: any,
    cancelToken: CancelToken | undefined,
  ): Promise<Form[]> {
    return this.request('put',
      this.buildPath('expedient/{expedientId}/form-group/{formGroupId}', {
        expedientId,
        formGroupId,
      }), [], form, cancelToken);
  }

  public async delete(expedientId: number, formGroupId: number) {
    return this.request('delete', this.buildPath('expedient/{expedientId}/form-group/{formGroupId}', {
      expedientId,
      formGroupId,
    }));
  }
}

export default new ExpedientFormService();
