
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import VPatientConsultations from '@/components/vendor/VPatientConsultations.vue';
import Consultation from '@/api/objects/Consultation';
import api from '@/api';
import ThePatientListProfessionals from '@/views/patient/parts/ThePatientListProfessionals.vue';

export default defineComponent({
  name: 'ThePatientHome',
  components: {
    ThePatientListProfessionals,
    VPatientConsultations,
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
  data() {
    return {
      status: 'loading',
      consultations: [] as Consultation[],
    };
  },
  async mounted() {
    await this.loadConsultations();

    this.status = 'loaded';
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  methods: {
    async loadConsultations() {
      try {
        this.consultations = await api.patient.consultation.all();
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las consultas'));
      }
    },
  },
});
