import router from '../router';

/**
 * Iterates over all the provided filters and returns a query object with all those that are not
 * empty ('' or null'). This is a query to be used with the vue router.
 *
 * @param filters
 * @returns {{}}
 */
export function filtersToQueryParameters(filters: Record<string, any>): Record<string, string> {
  const query = {};

  Object.keys(filters)
    .forEach((index) => {
      if (filters[index] !== '' && filters[index] !== null) {
        query[index] = filters[index];
      }
    });

  return query;
}

/**
 * Loads the provided route_name with the provided filters. If the same route as user is in,
 * filters are set in the url.
 *
 * @param routeName
 * @param filters
 * @returns {Promise<NavigationFailure | void | undefined>}
 */
export async function setQueryParameters(routeName: string, filters: Record<string, any>) {
  await router.push({
    name: routeName,
    query: filtersToQueryParameters(filters),
  });
}
