
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api/index';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheForgotPassword',
  components: {
    VButton,
    VFormGroupInputText,
  },
  data() {
    return {
      apiErrors: '',
      mailSent: false,
      status: 'loaded',
    };
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      email: yup.string()
        .email()
        .required()
        .label(t('Correo electrónico')),
    });

    const form = useFormValidation(rules);

    const { value: email } = useField('email');

    return {
      ...form,
      email,
      ...useCapabilities(),
    };
  },
  methods: {
    async forgotPassword() {
      try {
        this.toggleAccepted();

        const data = {
          email: this.email,
        };

        this.status = 'loading';

        await api.auth.forgotPasswords(data);
        await this.$store.dispatch('user/setAuthUser');

        this.toggleAccepted();

        this.mailSent = true;
        this.status = 'loaded';
      } catch (e) {
        this.status = 'loaded';
        this.apiErrors = e.response.data.errors;
      }
    },
  },
});
