import HttpClient from '@/api/HttpClient';
import { CancelToken, Method } from 'axios';

export default class AbstractService {
  public sdk: HttpClient;

  protected basePath: string;

  protected perPage: number;

  constructor() {
    this.sdk = new HttpClient();
    this.perPage = 15;
    this.basePath = 'api/';
  }

  protected getClient(): HttpClient {
    return this.sdk;
  }

  protected async request(
    method: Method,
    path: string,
    params?: any[],
    data?: any,
    cancelToken?: CancelToken | undefined,
    paginate = false,
  ): Promise<any | any[]> {
    const response = await this.getClient()
      .request(method, path, params, data, cancelToken);

    return this.getClient()
      .parseResponse(response, paginate);
  }

  protected buildPath(path: string, args?: any): string {
    return this.sprintf(this.basePath + path, args);
  }

  sprintf = (path: string, args: any): string => {
    if (typeof args === 'undefined') {
      return path;
    }

    return path.replace(/{(\w+)}/g,
      (match: string, number: string) => (typeof args[number] !== 'undefined' ? args[number] : match));
  };
}
