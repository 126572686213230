
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import useCapabilities from '@/resources/capabilities';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'TheAdminPurchaseAuthorizationShow',
  components: {
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  props: {
    purchaseAuthorization: {
      type: PurchaseAuthorization,
      required: true,
    },
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    status() {
      // eslint-disable-next-line max-len
      const type = this.formsSettings.purchase_authorization_statuses.find((o) => o.key === this.purchaseAuthorization?.status);

      return type.label;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closed');
    },
  },
});
