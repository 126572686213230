import AbstractService from '@/api/services/AbstractService';

class AdminClinicService extends AbstractService {
  public async all(filters: any, paginate = false) {
    const path = paginate ? this.buildPath('admin/clinics-paginated') : this.buildPath('admin/clinics');

    return this.request(
      'get',
      path,
      filters,
      {},
      undefined,
      paginate,
    );
  }

  public async update(clinicId, data: any) {
    return this.request('put', this.buildPath('admin/clinic/{clinic}', {
      clinic: clinicId,
    }), [], data);
  }

  public async store(data: any) {
    return this.request('post', this.buildPath('admin/clinic'), [], data);
  }

  public async get(clinicId: any) {
    return this.request('get', this.buildPath('admin/clinic/{clinic}', {
      clinic: clinicId,
    }));
  }

  public async getIndexOfProfessional(userId: any) {
    return this.request('get', this.buildPath('admin/clinics-of-professional/{user}', {
      user: userId,
    }));
  }

  public attachOrDetachProfessional(professionalId, clinicId) {
    return this.request('post', this.buildPath('admin/clinic/{clinic}/professional/{professional}/toggle', {
      clinic: clinicId,
      professional: professionalId,
    }));
  }
}

export default new AdminClinicService();
