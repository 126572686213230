import Patient from '@/api/objects/Patient';
import AbstractService from '@/api/services/AbstractService';

class PatientService extends AbstractService {
  public async allPaginated(filters: any): Promise<Patient[]> {
    return this.request('get', this.buildPath('patients'), filters);
  }

  public async retrieve(patient: number): Promise<Patient> {
    return this.request('get', this.buildPath('patient/{patient}', { patient }));
  }

  public async store(expedient: any) {
    return this.request('post', this.buildPath('expedient'), [], expedient);
  }

  public async sendRGPD(patient: number, type: string) {
    return this.request('get', this.buildPath('patient/{patient}/send/{type}', {
      patient,
      type,
    }));
  }

  public async acceptRGPD(params: any) {
    return this.request('get', this.buildPath('rgpd/callback'), params);
  }

  public async acceptManualRGPD(expedient: number) {
    return this.request('post', this.buildPath('expedient/{expedient}/accept-rgpd',
      {
        expedient,
      }));
  }

  public async updatePatientData(expedient: number, patientData: any) {
    return this.request('put', this.buildPath('expedient/{expedient}/patient',
      {
        expedient,
      }), [], patientData);
  }

  public async checkRGPD(params: any): Promise<any> {
    return this.request('get', this.buildPath('rgpd/check'), params);
  }
}

export default new PatientService();
