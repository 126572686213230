import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import {
  PurchaseAuthorizationState,
} from '@/store/modules/purchaseAuthorization/purchaseAuthorization.types';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';

type Context = ActionContext<PurchaseAuthorizationState, State>;

const actions: ActionTree<PurchaseAuthorizationState, State> = {
  async getCurrentPurchaseAuthorization({
    commit,
  }: Context, {
    purchaseAuthorization,
  }: { purchaseAuthorization: PurchaseAuthorization }) {
    if (!purchaseAuthorization) {
      commit('setCurrentPurchaseAuthorization', null);
    } else {
      commit('setCurrentPurchaseAuthorization', purchaseAuthorization);
    }
  },
};

export default actions;
