import { computed, ref } from 'vue';
import { useForm } from 'vee-validate';

let useFormValidation;

export default useFormValidation = (schema: any, initialValues?: any) => {
  const {
    meta,
    values,
    errors,
    resetForm,
    validate,
  } = useForm({
    validationSchema: schema,
    initialValues,
  });

  const accepted = ref(false);

  // TODO: Look for this functionality in useForm
  // FIXME: Fixme please
  const isEmpty = computed(() => {
    let aux = true;

    if (!!values) {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const key in values) {
        const value = values[key];

        if (!!value) {
          aux = false;

          break;
        }
      }
    }

    return aux;
  });

  const toggleAccepted = () => {
    accepted.value = !accepted.value;
  };

  return {
    meta,
    values,
    errors,
    accepted,
    resetForm,
    validate,
    toggleAccepted,
    isEmpty,
  };
};
