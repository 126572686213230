
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VLoader from '@/components/vendor/VLoader.vue';
import Media from '@/api/objects/Media';
import ThePatientDocumentShowModal
  from '@/views/patient/expedients/documents/parts/ThePatientDocumentShowModal.vue';
import ThePatientDocumentUpload
  from '@/views/patient/expedients/documents/parts/ThePatientDocumentUpload.vue';

export default defineComponent({
  // TODO: This could be refactored to be used for any role
  name: 'ThePatientDocumentGrid',
  components: {
    ThePatientDocumentUpload,
    ThePatientDocumentShowModal,
    VLoader,
    VButton,
  },
  props: {
    media: {
      type: Array,
      required: false,
    },
    status: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      showAddMedia: false,
      modalToShow: null,
      currentMedia: null as null | Media,
    };
  },
  methods: {
    mediaConfirm() {
      this.showAddMedia = false;
      this.$emit('refresh-media');
    },
  },
});
