<template>
  <div class="d-flex flex-column mb-24" v-if="!!currentExpedient">
    <v-uploader
      ref="uploader"
      name="media"
      :upload-domain="domain"
      :route-prefix="route"
      :sortable="false"
      :multiple="true"
      file-type-help-text="PNG, JPG, JPEG, PDF, DOC, DOCX, XLS, XLSX"
      :with-credentials="true"
      :validation-rules="{ accept: [
          'image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'text/plain',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.media',
          'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], }"
      :afterUpload="onChange"
      @removed="onChange"
    >
      <template #fields="{
                    getCustomPropertyInputProps,
                    getCustomPropertyInputListeners,
                    getCustomPropertyInputErrors,
                }">
        <div class="media-library-properties">
          <div class="media-library-field">
            <label class="media-library-label mb-8">{{ $t('Tipo de documento') }}</label>
            <v-form-group-select>
              <select class="form-select" :aria-label="this.$t('Tipo de documento')"
                      placeholder="Otros documentos"
                      v-bind="getCustomPropertyInputProps('type')"
                      v-on="getCustomPropertyInputListeners('type')"
                      dusk="media-library-extra-field"
              >
                <option value="" selected>{{ $t('Otros documentos') }}</option>
                <option value="x-ray">{{ $t('Radiografías') }}</option>
                <option value="analytical">{{ $t('Analíticas') }}</option>
                <option value="resonance">{{ $t('Resonancias') }}</option>
                <option value="images">{{ $t('Imágenes') }}</option>
              </select>
            </v-form-group-select>

            <p v-for="error in getCustomPropertyInputErrors('type')" :key="error"
               class="media-library-text-error">
              {{ error }}
            </p>
          </div>
        </div>
      </template>
    </v-uploader>

    <v-button
      variant="secondary"
      class="btn-icon align-self-end mt-16"
      :aria-label="$t('Guardar documentos')"
      :disabled="media.length <= 0"
      @click="onSubmit"
    >
      <span class="icon icon-save pe-8"></span>
      <span>{{ $t('Guardar') }}</span>
    </v-button>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import VUploader from '@/components/upload/VUploader.vue';
import api from '@/api';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  // TODO: This could be refactored to be used for any role
  name: 'TheProfessionalDocumentUpload',
  components: {
    VButton,
    VFormGroupSelect,
    VUploader,
  },
  props: {
    consultationId: {
      type: Number,
      required: false,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      media: [],
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    domain() {
      return process.env.VUE_APP_API_URL;
    },
    route() {
      return `api/expedient/${this.currentExpedient?.id}/file`;
    },
    mediaUuids() {
      return this.media.map((media) => ({
        uuid: media.attributes.uuid,
        type: !!media.attributes.custom_properties.type ? media.attributes.custom_properties.type : 'others',
      }));
    },
  },
  methods: {
    onChange() {
      this.media = this.$refs.uploader.mediaLibrary.state.media;
    },
    async onSubmit() {
      try {
        await api.professional.expedients.media.confirm(this.currentExpedient.id, {
          consultation_id: !!this.consultationId ? this.consultationId : null,
          media: this.mediaUuids,
        });

        this.$toast.success(this.$t('Los documentos se han guardado correctamente.'));

        this.$emit('media-confirm');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al guardar los documentos'));
      }
    },
  },
});
</script>
