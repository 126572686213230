
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VFormGroup',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    formGroupClass: {
      type: String,
      required: false,
      default: null,
    },
    forKey: {
      type: String,
      required: false,
      default: '',
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitValue(value) {
      this.$emit('update:value', value);
    },
  },
});
