import BaseObject from '@/api/objects/BaseObject';
// eslint-disable-next-line import/no-cycle
import Product from '@/api/objects/Product';

export interface ProductPurchaseAuthorizationContract {
  days: string;
  doses: number;
  productId: number | null;
  purchaseAuthorizationId: number;
  quantity: string;
  createdAt: string;
  deletedAt: string;
  product: Product;
}

export default class ProductPurchaseAuthorization extends BaseObject implements ProductPurchaseAuthorizationContract {
  get days(): string {
    return this.attributes.days;
  }

  get doses(): number {
    return this.attributes.doses;
  }

  get productId(): number | null {
    return this.attributes.product_id;
  }

  get purchaseAuthorizationId(): number {
    return this.attributes.purchase_authorization_id;
  }

  get quantity(): string {
    return this.attributes.quantity;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get deletedAt(): any {
    return this.attributes.deleted_at;
  }

  get product(): Product {
    return this.getRelation('product');
  }
}
