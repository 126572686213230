
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'TheHome',
  computed: {
    ...mapState('user', ['authUser']),
  },
  methods: {},
});
