import { createLogger } from 'vuex';
import actions from '@/store/modules/app/app.actions';
import mutations from '@/store/modules/app/app.mutations';
import type { AppState } from '@/store/modules/app/app.types';

export default {
  namespaced: true,
  state: (): AppState => ({
    formsSettings: {},
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
