import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "fw-normal" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "col-5" }
const _hoisted_7 = { class: "col-3" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col-3" }
const _hoisted_10 = { class: "col-5" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col" }
const _hoisted_15 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_form_group_read_only_text = _resolveComponent("v-form-group-read-only-text")!
  const _component_the_patient_address_fieldset = _resolveComponent("the-patient-address-fieldset")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    size: "xl",
    "class-name": "modal-fullscreen-lg-down",
    onKeypress: _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.meta.valid ? _ctx.onSubmit() : null), ["prevent"]), ["enter"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('Tus')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('datos personales')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("fieldset", _hoisted_3, [
              _createElementVNode("legend", null, _toDisplayString(_ctx.$t('Información personal')), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('Nombre'),
                    text: _ctx.patient.user.name
                  }, null, 8, ["label", "text"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('Apellidos'),
                    text: _ctx.patient.user.surname
                  }, null, 8, ["label", "text"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('Género'),
                    text: _ctx.genre.label
                  }, null, 8, ["label", "text"])
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('Tipo de documento'),
                    text: _ctx.identityDocType.label
                  }, null, 8, ["label", "text"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('DNI'),
                    text: _ctx.patient.nif
                  }, null, 8, ["label", "text"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('Fecha de nacimiento'),
                    text: _ctx.$str.formatDate(_ctx.patient.bornDate)
                  }, null, 8, ["label", "text"])
                ])
              ])
            ]),
            _createElementVNode("fieldset", _hoisted_12, [
              _createElementVNode("legend", null, _toDisplayString(_ctx.$t('Contacto')), 1),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('Correo electrónico'),
                    text: _ctx.patient.user.email
                  }, null, 8, ["label", "text"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_v_form_group_read_only_text, {
                    class: "mb-24",
                    label: _ctx.$t('Teléfono'),
                    text: _ctx.patient.user.phone
                  }, null, 8, ["label", "text"])
                ])
              ])
            ]),
            _createVNode(_component_the_patient_address_fieldset, {
              type: "billing",
              "address-object": _ctx.billingAddress
            }, null, 8, ["address-object"]),
            _createVNode(_component_the_patient_address_fieldset, {
              type: "shipping",
              "address-object": _ctx.shippingAddress
            }, null, 8, ["address-object"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "link-secondary",
            class: "me-24",
            "aria-label": _ctx.$t('Cerrar'),
            "data-bs-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cerrar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}