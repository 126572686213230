
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VInput from '@/components/vendor/basic/form/parts/VInput.vue';
import FormGroup from '@/api/objects/FormGroup';
import * as yup from 'yup';
import addRule from '@/helpers/formValidation';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';

export default defineComponent({
  name: 'VFormColumn',
  components: { VInput },
  props: {
    formGroupStructure: {
      type: Object,
      required: true,
    },
    formGroupData: {
      type: FormGroup,
      required: false,
    },
    formType: {
      type: String,
      required: true,
    },
    formId: {
      type: Number,
      required: true,
    },
    forceSave: {
      type: Boolean,
      required: false,
      default: true,
    },
    actionButtons: {
      type: Object,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    suffixClass: {
      type: String,
      required: false,
    },
    prefixClass: {
      type: String,
      required: false,
    },
    adjustInputForValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const yupObject = {};

    if (props.formGroupStructure.form_values) {
      props.formGroupStructure.form_values.forEach((formValue) => {
        let add = yup;

        // eslint-disable-next-line no-unused-expressions
        formValue?.rules?.front.forEach((rule) => {
          const myRule = {
            message: '',
            number: -1,
            name: '',
          };

          // Rule could be:   max:80!Mensaje   |   max:80   |   label
          if (rule.includes('!')) {
            const x = rule.split('!');
            if (rule.includes(':')) {
              const y = x[0].split(':');
              // FIXME: Please, remove these f**cking comments because I cannot make it work without them
              // eslint-disable-next-line prefer-destructuring
              myRule.message = x[1];
              // eslint-disable-next-line prefer-destructuring
              myRule.number = y[1];
              // eslint-disable-next-line prefer-destructuring
              myRule.name = y[0];
            } else {
              // eslint-disable-next-line prefer-destructuring
              myRule.message = x[1];
              // eslint-disable-next-line prefer-destructuring
              myRule.name = x[0];
            }
          } else if (rule.includes(':')) {
            const y = rule.split(':');
            // eslint-disable-next-line prefer-destructuring
            myRule.number = y[1];
            // eslint-disable-next-line prefer-destructuring
            myRule.name = y[0];
          } else {
            myRule.name = rule;
          }

          add = addRule(add, myRule.name, formValue.label, myRule.message, myRule.number);
        });

        yupObject[formValue.key] = add;
      });
    }

    const rules = yup.object(yupObject);

    const initialValues = {};

    if (props.formGroupStructure) {
      props.formGroupStructure.form_values.forEach((formValueStructure) => {
        if (props.formGroupData?.formValueByType(formValueStructure.key)) {
          initialValues[formValueStructure.key] = props.formGroupData.formValueByType(formValueStructure.key)?.value;
        }
      });
    }

    const form = useFormValidation(rules, initialValues);

    const formJson = {};

    if (props.formGroupStructure) {
      props.formGroupStructure.form_values.forEach((formValue) => {
        formJson[formValue.key] = useField(formValue.key).value;
      });
    }

    return {
      ...form,
      ...useCapabilities(),
      ...formJson,
    };
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    async setLocalValue(value, formGroupId, formValueId) {
      if (this.formType === 'medidas') {
        await this.$store.dispatch('expedient/setMeasureFormValue', {
          formId: this.formId,
          formGroupId,
          formValueId,
          formValueValue: value,
        });
      } else {
        await this.$store.dispatch('expedient/setFormValue', {
          formId: this.formId,
          formGroupId,
          formValueId,
          formValueValue: value,
        });
      }

      this.$emit('update-form-group-request', formGroupId);
    },
    emitOldValue(formValueId, value) {
      this.$emit('old-value:value', {
        formValueId,
        value,
        formId: this.formId,
      });
    },
  },
});
