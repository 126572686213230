import BaseObject from '@/api/objects/BaseObject';
import User from '@/api/objects/User';
import Expedient from '@/api/objects/Expedient';

export interface PatientContract {
  identityDocType: string;
  nif: string;
  genre: string;
  bornDate: string;
  age: number;
}

export default class Patient extends BaseObject implements PatientContract {
  get identityDocType(): string {
    return this.attributes.identity_doc_type;
  }

  get nif(): string {
    return this.attributes.nif;
  }

  get genre(): string {
    return this.attributes.genre;
  }

  get bornDate(): string {
    return this.attributes.born_date;
  }

  get age(): number {
    return this.attributes.age;
  }

  get extraData(): any {
    return this.attributes.extra_data;
  }

  get user(): User | null {
    return this.getRelation('user');
  }

  get expedients(): Expedient[] | null {
    return this.getRelation('expedients');
  }
}
