
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VLoader from '@/components/vendor/VLoader.vue';
import Media from '@/api/objects/Media';
import TheProfessionalResourcesShowModal
  from '@/views/professional/resources/parts/TheProfessionalResourcesShowModal.vue';

export default defineComponent({
  // TODO: This could be refactored to be used for any role
  name: 'TheProfessionalResourcesGrid',
  components: {
    TheProfessionalResourcesShowModal,
    VLoader,
  },
  props: {
    media: {
      type: Array,
      required: false,
    },
    status: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      showAddMedia: false,
      modalToShow: null,
      currentMedia: null as null | Media,
    };
  },
  methods: {
    mediaConfirm() {
      this.showAddMedia = false;
      this.$emit('refresh-media');
    },
  },
});
