import AbstractService from '@/api/services/AbstractService';
import Category from '@/api/objects/Category';

class AdminVideoCategoryService extends AbstractService {
  public async all(): Promise<Category[]> {
    return this.request('get', this.buildPath('admin/video-categories'));
  }

  public async list(filters: any, paginate = false): Promise<any> {
    const path = paginate ? this.buildPath('admin/video-categories-paginated')
      : this.buildPath('admin/video-categories');

    return this.request(
      'get',
      path,
      filters,
      {},
      undefined,
      paginate,
    );
  }

  public async create(category: Record<string, unknown>): Promise<Category> {
    return this.request('post', this.buildPath('admin/video-category'), [], category);
  }

  public delete(categoryId: number): Promise<any> {
    return this.request('delete', this.buildPath('admin/video-category/{categoryId}', {
      categoryId,
    }));
  }
}

export default new AdminVideoCategoryService();
