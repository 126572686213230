
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import ThePatientPurchaseAuthorizationShow
  from '@/views/patient/expedients/purchaseAuthorization/parts/ThePatientPurchaseAuthorizationShow.vue';
import api from '@/api';

export default defineComponent({
  name: 'ThePatientGeneralAuthorizations',
  components: { ThePatientPurchaseAuthorizationShow },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/purchaseAuthorization', ['purchaseAuthorizations']),
    ...mapState('treatment', ['currentTreatment']),
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: '',
    };
  },
  methods: {
    async showPurchaseAuthorizationShowModal(purchaseAuthorization) {
      // Force to load the purchase authorization due to a problem with pivot models
      // eslint-disable-next-line max-len
      purchaseAuthorization = await api.patient.expedient.purchaseAuthorizations.get(this.currentExpedient.id, purchaseAuthorization.id);

      await this.setCurrentPurchase(purchaseAuthorization);
      this.modalToShow = 'the-purchase-authorization-show';
    },
    async changeExpedientSection(expedientSection) {
      await this.$store.dispatch('patient/expedient/setExpedientSection', {
        expedientSection,
      });
    },
    async setCurrentPurchase(purchaseAuthorization) {
      await this.$store.dispatch('patient/expedient/purchaseAuthorization/getCurrentPurchaseAuthorization', {
        purchaseAuthorization,
      });
    },
  },
});
