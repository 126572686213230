
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminClinicCreateModal',
  components: {
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    VFormGroupSelect,
    VFormGroupInputText,
    vSelect,
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  data() {
    return {
      clinic: {},
    };
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('Nombre')),
      address: yup.string()
        .required()
        .label(t('Apellidos')),
      phoneNumberPrefix: yup.string()
        .required()
        .label(t('Prefijo telefónico')),
      phoneNumber: yup.string()
        .required()
        .label(t('Número de teléfono'))
        .matches(/^\d{6,14}$/, 'Debe tener entre 6 y 14 cifras'),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: address } = useField('address');
    const { value: phoneNumberPrefix } = useField('phoneNumberPrefix');
    const { value: phoneNumber } = useField('phoneNumber');

    return {
      ...form,
      name,
      address,
      phoneNumberPrefix,
      phoneNumber,

      ...useCapabilities(),
    };
  },
  methods: {
    async createClinic() {
      this.toggleAccepted();

      this.clinic = {
        name: this.values.name,
        address: this.values.address,
        phone_number: `${this.values.phoneNumberPrefix} ${this.values.phoneNumber}`,
      };

      try {
        /*
        TODO: Be more exhaustive when parsing the phone number as here is the only step in which
        We will take care that is a valid phone number so the RGPD SMS goes to him
         */
        await api.admin.clinic.store(this.clinic);
        // eslint-disable-next-line max-len
        this.$toast.success(this.$t('Clínica {nombre_clinica} creada con éxito.', { nombre_clinica: this.values.name }));

        this.resetForm();

        this.closeModal();
        this.refreshClinics();

        this.toggleAccepted();
      } catch (e) {
        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);

        this.toggleAccepted();
      }
    },
    closeModal() {
      this.$emit('closed');
    },
    refreshClinics() {
      this.$emit('refresh-clinics');
    },
  },
});
