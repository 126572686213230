
import { defineComponent } from 'vue';
import VInputCheckbox from '@/components/vendor/basic/form/parts/VInputCheckbox.vue';

export default defineComponent({
  name: 'VFormGroupInputCheckbox',
  components: {
    VInputCheckbox,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    forKey: {
      type: String,
      required: false,
      default: 'checkbox',
    },
    labelClass: {
      type: String,
      required: false,
      default: null,
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitValue(value) {
      this.$emit('update:value', value);
    },
  },
});
