import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "main-container" }
const _hoisted_3 = { class: "container main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_the_language_switcher = _resolveComponent("the-language-switcher")!
  const _component_the_general_components = _resolveComponent("the-general-components")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_the_header),
      _createElementVNode("main", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_view)
        ])
      ])
    ]),
    _createVNode(_component_the_language_switcher),
    _createVNode(_component_the_general_components)
  ], 64))
}