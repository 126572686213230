
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import api from '@/api';
import Treeselect from 'vue3-treeselect';
import ResourceCategory from '@/api/objects/ResourceCategory';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminResourceCategoryCreate',
  components: {
    VFormGroupInputText,
    VButton,
    Treeselect,
    VFormGroupSelect,
  },
  data() {
    return {
      typeSelected: null as null | string,
      categories: [] as ResourceCategory[],
    };
  },
  created() {
    this.loadCategories();
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  emits: ['closed', 'reloadCategories'],
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      key: yup.string()
        .required()
        .label(t('Nombre')),
    });

    const form = useFormValidation(rules);

    const { value: key } = useField('key');

    return {
      ...form,
      key,
    };
  },
  methods: {
    formatCategoryName(input: string) {
      const words = input.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1));
      const formatted = words.join(' ');

      return formatted;
    },

    async loadCategories() {
      try {
        const array: any[] = [];
        const resp = await api.admin.resourceCategory.all();

        resp.forEach((value) => {
          if (!value.key.includes('.')) {
            array.push({
              id: value.key,
              label: value.label,
            });
          }
        });

        this.categories = array;
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las categorías'));
      }
    },
    async createCategory() {
      try {
        this.toggleAccepted();
        let keyString = this.key as string;
        if (this.typeSelected != null) {
          this.typeSelected = this.formatCategoryName(this.typeSelected);
          keyString = `${this.typeSelected}.${this.key}`;
        }
        const category: ResourceCategory = await api.admin.resourceCategory.create(
          {
            key: keyString.toLowerCase().replace(' ', '_'),
            label: this.key,
            label_select: this.typeSelected != null ? `${this.typeSelected} > ${this.key}` : this.key,
          },
        );

        this.$toast.success(this.$t('Categoría creada correctamente'));

        this.resetForm();

        this.toggleAccepted();
        this.closeModal();
        await this.$router.push({ name: 'admin.resource-category.index' });
      } catch (e) {
        this.toggleAccepted();

        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
