
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VProtocolName from '@/components/vendor/VProtocolName.vue';
import TheProfessionalTreatmentFinishConfirmModal
  from '@/views/professional/expedients/treatments/parts/TheProfessionalTreatmentFinishConfirmModal.vue';

export default defineComponent({
  name: 'TheProfessionalExpedientFileTreatmentsList',
  components: {
    TheProfessionalTreatmentFinishConfirmModal,
    VProtocolName,
    VButton,
    VTableBody,
    VTableHeader,
    VTable,
  },
  data() {
    return {
      modalToShow: null,
      treatmentSelected: null,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'treatments']),
    ...mapState('treatment', ['currentTreatment']),
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  methods: {
    async setCurrentTreatment(treatmentId) {
      await this.$store.dispatch('treatment/getTreatment', {
        expedientId: this.currentExpedient.id,
        treatmentId,
      });

      await this.$router.push({
        name: 'professional.expedient.show',
        params: {
          expedient: this.currentExpedient.id,
          tabName: this.$route.params.tabName,
          treatment: treatmentId,
        },
        query: { tratamiento: treatmentId },
      });
    },
  },
});
