
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import api from '@/api';
import Category from '@/api/objects/Category';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminVideoCategoryCreateView',
  components: {
    VFormGroupInputText,
    VButton,
  },
  emits: ['closed', 'reloadCategories'],
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('Nombre')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');

    return {
      ...form,
      name,
    };
  },
  methods: {
    async createCategory() {
      try {
        this.toggleAccepted();

        const category: Category = await api.admin.videoCategory.create({ name: this.name });

        this.$toast.success(this.$t('Categoría creada correctamente'));

        this.resetForm();

        this.toggleAccepted();

        this.$emit('reloadCategories', category.id);
        this.closeModal();
        await this.$router.push({ name: 'admin.video-category.index' });
      } catch (e) {
        this.toggleAccepted();

        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
