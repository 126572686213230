
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import VPatientConsultations from '@/components/vendor/VPatientConsultations.vue';
import ThePatientGeneralAuthorizations
  from '@/views/patient/expedients/general/parts/ThePatientGeneralAuthorizations.vue';
import ThePatientExpedientMeasuresList
  from '@/views/patient/expedients/general/measures/ThePatientExpedientMeasuresList.vue';

export default defineComponent({
  name: 'ThePatientExpedientFileGeneral',
  components: {
    ThePatientExpedientMeasuresList,
    ThePatientGeneralAuthorizations,
    VPatientConsultations,
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/consultation', ['consultations']),
    ...mapState('patient/expedient/treatment', ['currentTreatment']),
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
});
