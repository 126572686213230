
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import withTimeout from '@/resources/timeOut';
// eslint-disable-next-line import/no-cycle
import { filtersToQueryParameters, setQueryParameters } from '@/helpers/Url';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VInput from '@/components/vendor/basic/form/parts/VInput.vue';
import Patient from '@/api/objects/Patient';
import TheAdminPatientShow from '@/views/administrator/patient/TheAdminPatientShow.vue';
import { mapState } from 'vuex';
import TheAdminPatientUpdate from '@/views/administrator/patient/TheAdminPatientUpdate.vue';
import VPagination from '@/components/vendor/VPagination.vue';

export default defineComponent({
  name: 'TheAdminPatientsList',
  components: {
    VPagination,
    TheAdminPatientUpdate,
    TheAdminPatientShow,
    VInput,
    VButton,
    VTableBody,
    VTableHeader,
    VTable,
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  data() {
    return {
      patients: [] as Patient[],
      pagination: null as any,
      filters: {
        search: [] as any,
      },
      do_search: true,
      timers: {
        search: '',
      },
      selectedPatient: null as Patient | null,
      modalToShow: null as string | null,
    };
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  async mounted() {
    await this.setQueryParametersAsFilters();
    await this.doSearch();
  },
  methods: {
    getIdentificationDocumentType(idDocType) {
      return this.formsSettings.accepted_identification_document_types.find((item) => item.key === idDocType).label;
    },
    search() {
      this.timers.search = withTimeout(async () => {
        await this.doSearch();
      }, this.timers.search, 250);
    },
    /**
     * Sets the local filters as url query parameters.
     */
    setFiltersAsQueryParameters() {
      setQueryParameters('admin.patient.index', this.filters);
    },
    /**
     * Detects any query parameter in the url and loads it in the filters (if available)
     *
     * @returns {Promise<void>}
     */
    async setQueryParametersAsFilters() {
      const queryParameters = this.$route.query;

      if (queryParameters.search) {
        this.filters.search = queryParameters.search;
      } else {
        this.filters.search = '';
      }
    },
    async loadPatients(page?: number) {
      try {
        const filters = { ...this.filters } as any;

        // We manage page filter if provided
        if (!!page) {
          filters.page = page;
        }

        const query = filtersToQueryParameters(filters);

        const resp = await api.admin.patient.all(query, true);

        if (!!resp.meta) {
          this.patients = resp.data;
          this.pagination = {
            meta: resp.meta,
            pagination: resp.links,
          };
        } else {
          this.patients = resp;
        }
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar los pacientes'));
      }
    },
    async doSearch() {
      this.do_search = false;
      await this.setFiltersAsQueryParameters();
      await this.loadPatients();
    },
    async handlePageChangedEvent(page) {
      // We handle page only on the method call to avoid malfunctioning with page filter
      await this.loadPatients(page);
    },
    emptyFilters() {
      this.filters = {
        search: '',
      };

      this.doSearch();
    },
  },
});
