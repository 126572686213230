import AbstractService from '@/api/services/AbstractService';
import Room from '@/api/objects/Room';

class PatientExpedientRoomService extends AbstractService {
  public async getLastRoom(expedient: number): Promise<Room> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedient}/room', { expedient }));
  }
}

export default new PatientExpedientRoomService();
