import AbstractService from '@/api/services/AbstractService';
import Form from '@/api/objects/Form';

class PatientExpedientFormMeasuresService extends AbstractService {
  public async all(expedient: number): Promise<Form[]> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedient}/measures/forms', { expedient }));
  }
}

export default new PatientExpedientFormMeasuresService();
