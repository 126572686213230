import { createLogger } from 'vuex';
import actions from '@/store/modules/patient/expedient/modules/consultation/consultation.actions';
import mutations
  from '@/store/modules/patient/expedient/modules/consultation/consultation.mutations';
import type {
  ConsultationState,
} from '@/store/modules/patient/expedient/modules/consultation/consultation.types';

export default {
  namespaced: true,
  state: (): ConsultationState => ({
    status: null,
    currentConsultation: null,
    consultations: [],
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
