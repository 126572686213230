import AbstractService from '@/api/services/AbstractService';
import Media from '@/api/objects/Media';

class PatientMediaService extends AbstractService {
  public async all(expedientId: number, filters?: any): Promise<Media[]> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedientId}/files', { expedientId }),
      filters);
  }

  public async confirm(expedientId: number, mediaUuid: string[]): Promise<Media[]> {
    return this.request('post', this.buildPath('patient-zone/expedient/{expedientId}/file/confirm',
      { expedientId }), [], mediaUuid);
  }

  public async create(expedientId: number, media: Record<string, unknown>): Promise<Media[]> {
    return this.request('post', this.buildPath('patient-zone/expedient/{expedientId}/file/uploads',
      { expedientId }), [], media);
  }

  public async download(expedientId: number, fileId: number): Promise<string> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedientId}/file/{fileId}/download',
      { expedientId, fileId }));
  }
}

export default new PatientMediaService();
