import { createLogger } from 'vuex';
import actions from '@/store/modules/patient/expedient/modules/room/room.actions';
import mutations from '@/store/modules/patient/expedient/modules/room/room.mutations';
import type { RoomState } from '@/store/modules/patient/expedient/modules/room/room.types';

export default {
  namespaced: true,
  state: (): RoomState => ({
    currentRoom: null,
    appState: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
