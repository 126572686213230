
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import useFormValidation from '@/resources/form';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { DateTime } from 'luxon';
import api from '@/api';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupInputDatetime from '@/components/vendor/basic/form/VFormGroupInputDatetime.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalConsultationCreate',
  components: {
    VFormGroupSelect,
    VFormGroupInputDatetime,
    VButton,
    VModal,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    vSelect,
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      type: yup.string()
        .required()
        .nullable()
        .label(t('Tipo de consulta')),
      via: yup.string()
        .required()
        .nullable()
        .label(t('Medio de consulta')),
      initDate: yup.string()
        .required()
        .label(t('Fecha de inicio')),
      clinicId: yup.number()
        .nullable()
        .label(t('Consulta física')),
      treatmentId: yup.number()
        .nullable()
        .label(t('Tratamiento')),
    });

    const form = useFormValidation(rules);

    const { value: type } = useField('type');
    const { value: via } = useField('via');
    const { value: initDate } = useField('initDate');
    const { value: clinicId } = useField('clinicId');
    const { value: treatmentId } = useField('treatmentId');

    return {
      ...useCapabilities(),
      ...form,
      type,
      via,
      initDate,
      clinicId,
      treatmentId,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'treatments']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
    treatmentOptions() {
      const treatmentsAux: any[] = [];

      this.treatments.forEach((treatment) => {
        treatment.treatmentProtocolPhases.forEach((protocolPhaseTreatment) => {
          if (protocolPhaseTreatment.status === 'active') {
            treatmentsAux.push({
              key: treatment.id,
              label: `[${this.$str.formatDate(treatment.createdAt)}]
              ${protocolPhaseTreatment.protocolPhase.protocol.name} (${protocolPhaseTreatment.protocolPhase.name})`,
            });
          }
        });
      });

      return treatmentsAux;
    },
  },
  data() {
    return {
      clinicOptions: [] as any[],
      date: '',
    };
  },
  beforeMount() {
    const dateAux = DateTime.local()
      .toUTC();

    this.initDate = dateAux.toString();
    this.loadAvailableClinics();
  },
  async mounted() {
    // CARE: Carefull because this hardcoded value could change in API
    this.type = 'revision';
    this.via = 'face_to_face';
    this.treatmentId = null;
  },
  methods: {
    async loadAvailableClinics() {
      const clinics = await api.professional.clinic.getIndexOfProfessional();

      clinics.forEach((clinic) => {
        this.clinicOptions.push({
          key: clinic.id,
          label: clinic.name,
        });
      });
    },
    async createConsultation() {
      this.toggleAccepted();

      const consultationForm = {
        init_date: this.initDate,
        type: this.type,
        via: this.via,
        treatment_id: this.treatmentId,
        expedient_id: this.currentExpedient.id,
        clinic_id: this.clinicId ?? null,
        observations: this.$t('- Sin observaciones -'),
      };

      try {
        const consultation = await api.professional.expedients.consultation.store(
          this.currentExpedient.id, consultationForm,
        );

        this.$toast.success(this.$t('La consulta se ha añadido correctamente'));

        await this.$store.dispatch('expedient/refreshExpedientConsultations', {
          expedientId: this.currentExpedient.id,
        });

        await this.$store.dispatch('consultation/getCurrentConsultation', {
          expedientId: this.currentExpedient.id,
          consultationId: consultation.id,
        });

        await this.$router.push({
          name: 'professional.expedient.consultation.update',
          params: {
            tabName: 'consultas',
            consultation: consultation.id,
          },
          query: { tratamiento: this.currentTreatment?.id },
        });

        this.toggleAccepted();

        this.$emit('created');

        this.closeModal();
      } catch (e) {
        this.toggleAccepted();

        this.$toast.error(this.$t('Ha ocurrido un error al crear la consulta'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
