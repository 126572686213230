import AbstractService from '@/api/services/AbstractService';
import Media from '@/api/objects/Media';

class AdminMediaService extends AbstractService {
  public async all(filters?: any): Promise<Media[]> {
    return this.request('get', this.buildPath('admin/resources', {}),
      filters);
  }

  public async download(fileId: number): Promise<string> {
    return this.request('get', this.buildPath('admin/resource/{fileId}/download',
      { fileId }));
  }

  public async create(media: Record<string, unknown>): Promise<Media[]> {
    return this.request('post', this.buildPath('admin/resource/uploads/public',
      {}), [], media);
  }

  public async confirm(mediaUuid: string[]): Promise<Media[]> {
    return this.request('post', this.buildPath('admin/resource/confirm',
      {}), [], mediaUuid);
  }

  public async destroy(fileId: number): Promise<string> {
    return this.request('delete', this.buildPath('admin/resource/{fileId}/destroy',
      { fileId }));
  }
}

export default new AdminMediaService();
