import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13f97884"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input_checkbox = _resolveComponent("v-input-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_input_checkbox, {
      class: _normalizeClass(["btn-check", [this.inputClass, {'is-invalid': !!_ctx.yupErrorsVariable}]]),
      id: _ctx.forKey,
      value: _ctx.value,
      disabled: _ctx.disabled,
      "onUpdate:value": _ctx.emitValue
    }, null, 8, ["class", "id", "value", "disabled", "onUpdate:value"]),
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["btn", [this.labelClass, _ctx.cSize, _ctx.cVariant, {'text-disabled': _ctx.disabled}]]),
          for: _ctx.forKey
        }, _toDisplayString(_ctx.$t(_ctx.label)), 11, _hoisted_1))
      : _createCommentVNode("", true)
  ], 64))
}