import Expedient from '@/api/objects/Expedient';
import AbstractService from '@/api/services/AbstractService';

class PatientExpedientService extends AbstractService {
  public async index(): Promise<Expedient[]> {
    return this.request('get', this.buildPath('patient-zone/expedients'));
  }

  public async retrieve(expedient: number): Promise<Expedient> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedient}/', { expedient }));
  }
}

export default new PatientExpedientService();
