
import { defineComponent } from 'vue';
import withTimeout from '@/resources/timeOut';
import { mapState } from 'vuex';
import api from '@/api';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import TheDynamicInputNutritionalAnamnesis
  from '@/views/professional/anamnesis/parts/TheDynamicInputNutritionalAnamnesis.vue';
import Consultation from '@/api/objects/Consultation';
import Form from '@/api/objects/Form';
import FormGroup from '@/api/objects/FormGroup';

export default defineComponent({
  name: 'TheFormGroupNutritionalAnamnesis',
  components: {
    TheDynamicInputNutritionalAnamnesis,
  },
  props: {
    formGroupStructure: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'currentNutritionalAnamnesis', 'consultations', 'measures']),
    currentFormGroup() {
      if (this.currentNutritionalAnamnesis) {
        return this.currentNutritionalAnamnesis.formGroupByName(this.formGroupStructure.key);
      }

      return null;
    },
    lastConsultation(): Consultation | null {
      if (!!this.consultations.length) {
        // eslint-disable-next-line max-len
        return this.consultations.reduce((consultationA, consultationB) => (consultationA.initDate < consultationB.initDate ? consultationA : consultationB));
      }

      return null;
    },
    lastMeasure(): FormGroup | null {
      const measuresFormGroups = [] as any[];

      // Iterate through all measures forms
      this.measures.forEach((form: Form) => {
        const formGroups = form?.formGroups;
        if (formGroups) {
          formGroups.forEach((formGroup: FormGroup) => {
            measuresFormGroups.push({
              formGroup,
            });
          });
        }
      });

      if (!!this.measures.length) {
        // eslint-disable-next-line max-len
        return (measuresFormGroups.reduce((consultationA, consultationB) => (consultationA.createdAt > consultationB.createdAt ? consultationA : consultationB))).formGroup;
      }

      return null;
    },
  },
  data() {
    return {
      cancelToken: undefined as CancelTokenSource | undefined,
      timers: {
        updateFormGroup: '',
      },
      show: true,
    };
  },
  beforeMount() {
    // We automatically set the first consultation date to make the professional easier
    if (this.currentFormGroup?.name === 'fecha_primera_consulta') {
      const fecha = this.currentFormGroup?.formValueByType('fecha');

      // eslint-disable-next-line no-unused-expressions
      if (!fecha.value) {
        if (!!this.lastConsultation) {
          fecha.value = this.lastConsultation.initDate;
        }
      }
    }

    // We automatically set a date to make the professional easier going to the actual date
    if (this.currentFormGroup?.name === 'fecha_de_nacimiento') {
      const fecha = this.currentFormGroup?.formValueByType('fecha');
      // eslint-disable-next-line no-unused-expressions
      if (!fecha.value) {
        fecha.value = this.currentExpedient.patient.bornDate;
      }
    }

    this.setCurrentFormGroupValuesFromMeasures();
  },
  methods: {
    updateFormGroup() {
      /**
       * A unique cancelToken is generated and passed to a request of updating a form-group, which
       * has a timer and a timeout of about 250.
       */
      this.timers.updateFormGroup = withTimeout(async () => {
        if (this.cancelToken !== undefined) {
          this.cancelToken.cancel();
        }

        this.cancelToken = axios.CancelToken.source();

        await this.doUpdateFormGroup();
      }, this.timers.updateFormGroup, 250);
    },
    async doUpdateFormGroup() {
      const formGroupJSON = {
        _method: 'PUT',
        form_values: this.currentFormGroup.formValuesFormatted(),
      };

      const cancelToken: CancelToken | undefined | any = this.cancelToken?.token;

      try {
        await api.professional.expedients.forms.update(
          this.currentExpedient.id,
          this.currentFormGroup.id,
          formGroupJSON,
          cancelToken,
        );

        this.$emit('form-group-updated', this.currentFormGroup);
      } catch (e) {
        if (e.response) {
          this.$toast.error(this.$t('Ha ocurrido un error al guardar los cambios'));
        }

        if (!axios.isCancel(e)) {
          this.cancelToken = undefined;

          throw e;
        }
      }
    },
    setCurrentFormGroupValuesFromMeasures() {
      this.changeCurrentFormGroupValue('estatura', 'altura');
      this.changeCurrentFormGroupValue('peso', 'peso');
      this.changeCurrentFormGroupValue('perimetro_abdominal', 'perimetro_abdominal');
      this.changeCurrentFormGroupValue('perimetro_cadera', 'perimetro_cadera');
      this.changeCurrentFormGroupValue('icc', 'icc');
    },
    changeCurrentFormGroupValue(anamnesisFormGroupName, measuresFormGroupName) {
      if (this.lastMeasure) {
        if (this.currentFormGroup?.name === anamnesisFormGroupName) {
          const anamnesisFormValue = this.currentFormGroup?.formValueByType(anamnesisFormGroupName);

          // eslint-disable-next-line no-unused-expressions
          if (!anamnesisFormValue.value) {
            const measureFormValue = this.lastMeasure.formValueByType(measuresFormGroupName);

            if (!!measureFormValue) {
              anamnesisFormValue.value = measureFormValue.value;
            }
          }
        }
      }
    },
  },
});
