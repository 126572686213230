
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VInputSwitch',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: 'input',
    },
    checked: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  mounted() {
    this.inputValue = this.value;
  },
  methods: {
    updateValue(e) {
      this.$emit('update:value', e.target.value);
    },
  },
});
