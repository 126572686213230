import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { ExpedientState } from '@/store/modules/expedient/expedient.types';
import api from '@/api';
import Form from '@/api/objects/Form';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';

type Context = ActionContext<ExpedientState, State>;

const actions: ActionTree<ExpedientState, State> = {
  async getCurrentExpedient({
    state,
    dispatch,
    commit,
  }: Context, {
    expedientId,
    treatmentId,
    showLoader = true,
  }: { expedientId: number, treatmentId?: number, showLoader?: boolean }) {
    if (!expedientId) {
      await dispatch('cleanCurrentExpedient');
    } else if (!state.currentExpedient || state.currentExpedient.id !== expedientId
      || state.currentExpedient.id === expedientId) {
      if (showLoader) {
        commit('setStatus', 'loading');
      }

      try {
        const expedient = await api.professional.expedient.retrieve(expedientId);
        commit('setExpedient', expedient);

        // Load and set measures
        await dispatch('refreshExpedientMeasures', {
          expedientId,
          treatmentId,
        });

        // Load and set consultations
        await dispatch('refreshExpedientConsultations', {
          expedientId,
        });

        // Load and set forms
        await dispatch('refreshExpedientForms', {
          expedientId,
        });

        // Load and set treatments
        // FIXME: Que la API retorne directamente el Form, no un array
        await dispatch('refreshExpedientTreatments', {
          expedientId,
        });

        // Load and set purchase authorizations
        await dispatch('refreshPurchaseAuthorizations', {
          expedientId,
        });

        // Set current consultation
        commit('consultation/setCurrentConsultation', null, { root: true });

        if (showLoader) {
          commit('setStatus', 'loaded');
        }
      } catch (e) {
        commit('setStatus', 'loaded');
      }
    }
  },

  async cleanCurrentExpedient({
    commit,
  }: Context) {
    commit('setExpedient', null);
    commit('setForms', []);
    commit('setMeasures', null);
    commit('setTreatments', []);
    commit('setConsultations', []);
    commit('consultation/setCurrentConsultation', null, { root: true });
  },
  async refreshExpedientTreatments({
    state,
    commit,
    dispatch,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    const expedientTreatments = await api.professional.expedients.treatments.all(expedientId);

    await commit('setTreatments', expedientTreatments);

    const treatment = state.treatments.find((t) => t.status === 'active');

    await dispatch('treatment/setCurrentTreatment', { treatment }, { root: true });
  },
  async refreshExpedientForms({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    const expedientForms = await api.professional.expedients.forms.all(expedientId);

    commit('setForms', expedientForms);
  },
  async refreshExpedientMeasures({
    commit,
  }: Context, {
    expedientId,
    treatmentId,
  }: { expedientId: number, treatmentId?: number }) {
    if (!treatmentId) {
      const expedientMeasures = await api.professional.expedientData.measures.all(expedientId);

      commit('setMeasures', expedientMeasures);
    } else {
      const treatmentMeasures = await api.professional.expedientData.treatment.measure.all(
        expedientId,
        treatmentId,
      );

      commit('setMeasures', treatmentMeasures);
    }
  },
  async refreshExpedientConsultations({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    const expedientConsultations = await api.professional.expedients.consultation.all(expedientId);
    commit('setConsultations', expedientConsultations);
  },
  async setCurrentAnamnesis({
    commit,
  }: Context, {
    anamnesis,
  }: { anamnesis: Form }) {
    commit('setAnamnesis', anamnesis);
  },
  async setCurrentNutritionalAnamnesis({
    commit,
  }: Context, {
    nutritionalAnamnesis,
  }: { nutritionalAnamnesis: Form }) {
    commit('setNutritionalAnamnesis', nutritionalAnamnesis);
  },
  async setAnamnesisFormValue({
    commit,
  }: Context, {
    formGroupKey,
    formValueKey,
    formValueValue,
  }: { formGroupKey: string, formValueKey: string, formValueValue: string | number }) {
    commit('setAnamnesisFormValue', {
      formGroupKey,
      formValueKey,
      formValueValue,
    });
  },
  async setNutritionalAnamnesisFormValue({
    commit,
  }: Context, {
    formGroupKey,
    formValueKey,
    formValueValue,
  }: { formGroupKey: string, formValueKey: string, formValueValue: string | number }) {
    commit('setNutritionalAnamnesisFormValue', {
      formGroupKey,
      formValueKey,
      formValueValue,
    });
  },
  async setFormValue({
    commit,
  }: Context, {
    formId,
    formGroupId,
    formValueId,
    formValueValue,
  }: { formId: number, formGroupId: number, formValueId: number, formValueValue: string | number }) {
    commit('setFormValue', {
      formId,
      formGroupId,
      formValueId,
      formValueValue,
    });
  },
  async setMeasureFormValue({
    commit,
  }: Context, {
    formId,
    formGroupId,
    formValueId,
    formValueValue,
  }: { formId: number, formGroupId: number, formValueId: number, formValueValue: string | number }) {
    commit('setMeasureFormValue', {
      formId,
      formGroupId,
      formValueId,
      formValueValue,
    });
  },
  async setExpedientSection({
    commit,
  }: Context, {
    expedientSection,
  }: { expedientSection: string }) {
    commit('setExpedientSection', expedientSection);
  },
  async refreshPurchaseAuthorizations({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    // eslint-disable-next-line max-len
    const purchasesAuthorizations: PurchaseAuthorization[] = await api.professional.purchaseAuthorizations.all(expedientId);
    commit('setPurchaseAuthorizations', purchasesAuthorizations);
  },

  async refreshExpedientMedia({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    const expedientMedia = await api.professional.expedients.consultation.all(expedientId);
    commit('setMedia', expedientMedia);
  },
};

export default actions;
