import BaseObject from '@/api/objects/BaseObject';

export interface MediaContract {
  collectionName: string;
  conversionsDisk: string;
  disk: string;
  fileName: string;
  mimeType: string;
  modelId: string;
  name: string;
  originalUrl: string;
  previewUrl: string;
  type: string;
  humanReadableSize: string;
  storageUrl: string;
  consultationId: number;
  createdAt: string;
  updatedAt: string;
}

export default class Media extends BaseObject implements MediaContract {
  get collectionName(): string {
    return this.attributes.collection_name;
  }

  get conversionsDisk(): string {
    return this.attributes.conversions_disk;
  }

  get disk(): string {
    return this.attributes.disk;
  }

  get fileName(): string {
    return this.attributes.file_name;
  }

  get mimeType(): string {
    return this.attributes.mime_type;
  }

  get modelId(): string {
    return this.attributes.model_id;
  }

  get name(): string {
    return this.attributes.name;
  }

  get customProperties(): string {
    return this.attributes.custom_properties;
  }

  get originalUrl(): string {
    return this.attributes.original_url;
  }

  get previewUrl(): string {
    return this.attributes.preview_url;
  }

  get type(): string {
    return this.attributes.type;
  }

  get humanReadableSize(): string {
    return this.attributes.human_readable_size;
  }

  get storageUrl(): string {
    return this.attributes.storage_url;
  }

  get consultationId(): number {
    return this.attributes.consultation_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }
}
