import { MutationTree } from 'vuex';
import { ProfessionalState } from '@/store/modules/professional/professional.types';
import Professional from '@/api/objects/Professional';
import Expedient from '@/api/objects/Expedient';

interface ProfessionalMutations {
  setCurrentProfessional(state: ProfessionalState, payload: any): void;

  setExpedients(state: ProfessionalState, payload: any): void;
}

const mutations: MutationTree<ProfessionalState> & ProfessionalMutations = {
  setCurrentProfessional(state: ProfessionalState, professional: Professional) {
    state.currentProfessional = professional;
  },
  setExpedients(state: ProfessionalState, expedients: Expedient[]) {
    state.expedients = expedients;
  },
};

export default mutations;
