
import { defineComponent, PropType } from 'vue';
import InterConsultation from '@/api/objects/InterConsultation';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import useCapabilities from '@/resources/capabilities';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Professional from '@/api/objects/Professional';
import api from '@/api';
import VFormGroupTextarea from '@/components/vendor/basic/form/VFormGroupTextarea.vue';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  // TODO: Check if better with a store module and 'currentInterConsultation' instead of this prop
  name: 'TheProfessionalInterConsultationUpdate',
  components: {
    VFormGroupReadOnlyText,
    VFormGroupTextarea,
    VButton,
    VFormGroupInputText,
  },
  props: {
    interConsultation: {
      type: InterConsultation,
      required: true,
    },
    professionals: {
      type: Array as PropType<Professional[]>,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      title: yup.string()
        .required()
        .nullable()
        .max(60)
        .label(t('Título')),
      description: yup.string()
        .required()
        .nullable()
        .label(t('Descripción')),
    });

    const form = useFormValidation(rules);

    const { value: title } = useField('title');
    const { value: description } = useField('description');

    return {
      ...useCapabilities(),
      ...form,
      title,
      description,
    };
  },
  data() {
    return {
      removeCommentLoading: null,
    };
  },
  beforeMount() {
    this.setInterConsultationValues();
  },
  methods: {
    displayProfessionalUsername(comment) {
      if (this.authUser?.id === comment.author.id) {
        return `<span class="badge bg-tertiary text-white px-8 py-4 rounded-pill">${this.$t('Tú')}</span>`;
      }

      return `${comment.author.professional?.name} ${comment.author.professional?.surname}`;
    },
    setInterConsultationValues() {
      this.title = this.interConsultation.title;
      this.description = this.interConsultation.description;
    },
    findProfessionalName(professionalId) {
      const professional = this.professionals.find((p: Professional) => p.id === professionalId);

      return `${professional?.name} ${professional?.surname}`;
    },
    async updateInterConsultation() {
      try {
        const data = {
          title: this.title,
          description: this.description,
        };

        await api.professional.interConsultation.update(this.interConsultation.id, data);

        this.$toast.success(this.$t('Los cambios se han guardado correctamente.'));

        this.$emit('emit-inter-consultation-updated');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al actualizar la interconsulta'));
      }
    },
    emitShowInterConsultationEvent() {
      this.$emit('show-inter-consultation');
    },
    emitUpdateInterConsultationEvent() {
      this.$emit('update-inter-consultation');
    },
    async finalizeInterConsultation() {
      await this.$modal.confirm({
        title: this.$t('Finalizar interconsulta'),
        // eslint-disable-next-line max-len
        text: this.$t('Si finalizas esta interconsulta podrás seguir visualizando los datos y comentarios pero no editarla ni comentar nuevamente. ¿Estás seguro?'),
        confirmButtonText: this.$t('Finalizar'),
        confirmButtonCallback: this.doFinalizeInterConsultation,
      });
    },
    async doFinalizeInterConsultation() {
      try {
        await api.professional.interConsultation.finalize(this.interConsultation.id);

        this.$toast.success(this.$t('La interconsulta se ha finalizado con éxito'));

        this.$emit('emit-inter-consultation-updated');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al finalizar la interconsulta'));
      }
    },
  },
});
