import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 3,
  class: "invalid-feedback mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input_mask = _resolveComponent("v-input-mask")!
  const _component_v_input = _resolveComponent("v-input")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.forKey,
          class: "form-label"
        }, _toDisplayString(_ctx.$t(_ctx.label)), 9, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.mask)
      ? (_openBlock(), _createBlock(_component_v_input_mask, {
          key: 1,
          type: _ctx.type,
          id: _ctx.forKey,
          name: _ctx.forKey,
          class: _normalizeClass([this.inputClass, {'is-invalid': !!_ctx.yupErrorsVariable}]),
          disabled: _ctx.disabled,
          placeholder: !!_ctx.placeholder ? _ctx.$t(_ctx.placeholder) : '',
          mask: _ctx.mask,
          value: _ctx.value,
          "onUpdate:value": _ctx.emitValue
        }, null, 8, ["type", "id", "name", "class", "disabled", "placeholder", "mask", "value", "onUpdate:value"]))
      : (_openBlock(), _createBlock(_component_v_input, {
          key: 2,
          id: _ctx.forKey,
          name: _ctx.forKey,
          type: _ctx.type,
          class: _normalizeClass([this.inputClass, {'is-invalid': !!_ctx.yupErrorsVariable}]),
          disabled: _ctx.disabled,
          placeholder: !!_ctx.placeholder ? _ctx.$t(_ctx.placeholder) : '',
          value: _ctx.value,
          "onUpdate:value": _ctx.emitValue
        }, null, 8, ["id", "name", "type", "class", "disabled", "placeholder", "value", "onUpdate:value"])),
    (!!_ctx.yupErrorsVariable)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.yupErrorsVariable), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}