import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id", "disabled", "rows", "value", "placeholder"]
const _hoisted_2 = { key: 10 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input = _resolveComponent("v-input")!
  const _component_v_form_group_input_date = _resolveComponent("v-form-group-input-date")!
  const _component_v_form_group_input_datetime = _resolveComponent("v-form-group-input-datetime")!
  const _component_v_form_group_input_checkbox = _resolveComponent("v-form-group-input-checkbox")!
  const _component_v_form_group_input_switch = _resolveComponent("v-form-group-input-switch")!
  const _component_v_form_group_input_btn_checkbox = _resolveComponent("v-form-group-input-btn-checkbox")!
  const _component_v_form_group_input_radio = _resolveComponent("v-form-group-input-radio")!
  const _component_v_select = _resolveComponent("v-select")!

  return ((_ctx.type === 'text' || _ctx.type === 'number') && _ctx.show)
    ? (_openBlock(), _createBlock(_component_v_input, {
        key: 0,
        id: _ctx.formValueKey,
        name: _ctx.formValueKey,
        type: _ctx.type,
        disabled: _ctx.disabled,
        value: _ctx.value,
        suffix: _ctx.suffix,
        prefix: _ctx.prefix,
        placeholder: _ctx.formValueLabel,
        "onUpdate:value": _ctx.emitValue
      }, null, 8, ["id", "name", "type", "disabled", "value", "suffix", "prefix", "placeholder", "onUpdate:value"]))
    : ((_ctx.type === 'textarea') && _ctx.show)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 1,
          class: "form-control",
          id: _ctx.formValueKey,
          disabled: _ctx.disabled,
          rows: _ctx.formValueClass,
          value: _ctx.value,
          placeholder: _ctx.formValueLabel,
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitValue($event.target.value)))
        }, null, 40, _hoisted_1))
      : ((_ctx.type === 'date') && _ctx.show)
        ? (_openBlock(), _createBlock(_component_v_form_group_input_date, {
            key: 2,
            value: _ctx.value,
            "onUpdate:value": _ctx.emitValue
          }, null, 8, ["value", "onUpdate:value"]))
        : ((_ctx.type === 'dateTime') && _ctx.show  && false)
          ? (_openBlock(), _createBlock(_component_v_form_group_input_datetime, {
              key: 3,
              value: _ctx.value,
              "onUpdate:value": _ctx.emitValue
            }, null, 8, ["value", "onUpdate:value"]))
          : ((_ctx.type === 'checkbox') && _ctx.show)
            ? (_openBlock(), _createBlock(_component_v_form_group_input_checkbox, {
                key: 4,
                "for-key": _ctx.formValueKey,
                label: _ctx.formValueLabel,
                value: _ctx.value,
                class: _normalizeClass({'form-check-inline': _ctx.formValueClass === 'inline'}),
                "onUpdate:value": _ctx.emitValue
              }, null, 8, ["for-key", "label", "value", "class", "onUpdate:value"]))
            : ((_ctx.type === 'switch') && _ctx.show)
              ? (_openBlock(), _createBlock(_component_v_form_group_input_switch, {
                  key: 5,
                  "for-key": _ctx.formValueKey,
                  label: _ctx.formValueLabel,
                  value: _ctx.value,
                  class: _normalizeClass({'form-check-inline': _ctx.formValueClass === 'inline'}),
                  "onUpdate:value": _ctx.emitValue
                }, null, 8, ["for-key", "label", "value", "class", "onUpdate:value"]))
              : ((_ctx.type === 'buttoncheckbox') && _ctx.show)
                ? (_openBlock(), _createBlock(_component_v_form_group_input_btn_checkbox, {
                    key: 6,
                    "for-key": _ctx.formValueKey,
                    label: _ctx.formValueLabel,
                    variant: "category",
                    value: _ctx.value,
                    class: _normalizeClass({'form-check-inline': _ctx.formValueClass === 'inline'}),
                    "onUpdate:value": _ctx.emitValue
                  }, null, 8, ["for-key", "label", "value", "class", "onUpdate:value"]))
                : ((_ctx.type === 'radiobutton') && _ctx.show)
                  ? (_openBlock(), _createBlock(_component_v_form_group_input_radio, {
                      key: 7,
                      "form-group-name": _ctx.formGroupKey,
                      "form-value-key": _ctx.formValueKey,
                      "form-value-label": _ctx.formValueLabel,
                      "form-value-options": _ctx.formValueOptions,
                      "form-value-class": _ctx.formValueClass,
                      selected: _ctx.value,
                      "onUpdate:value": _ctx.emitValue
                    }, null, 8, ["form-group-name", "form-value-key", "form-value-label", "form-value-options", "form-value-class", "selected", "onUpdate:value"]))
                  : ((_ctx.type === 'select') && _ctx.show)
                    ? (_openBlock(), _createBlock(_component_v_select, {
                        key: 8,
                        class: "mb-24",
                        label: "label",
                        "push-tags": "",
                        taggable: _ctx.taggable,
                        options: _ctx.formValueOptions,
                        "create-option": option => ({ key: option, label: _ctx.$t(option) }),
                        reduce: returnValue => returnValue.key,
                        modelValue: _ctx.selectFormValueSelected,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectFormValueSelected) = $event))
                      }, {
                        "no-options": _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                        ]),
                        option: _withCtx((concept) => [
                          _createTextVNode(_toDisplayString(_ctx.$t(concept.label)), 1)
                        ]),
                        "selected-option": _withCtx((concept) => [
                          _createTextVNode(_toDisplayString(_ctx.$t(concept.label)), 1)
                        ]),
                        _: 1
                      }, 8, ["taggable", "options", "create-option", "reduce", "modelValue"]))
                    : ((_ctx.type === 'multiselect' && !!_ctx.loaded) && _ctx.show)
                      ? (_openBlock(), _createBlock(_component_v_select, {
                          key: 9,
                          class: "mb-24",
                          label: "label",
                          multiple: "",
                          "push-tags": "",
                          taggable: _ctx.taggable,
                          closeOnSelect: false,
                          options: _ctx.tempFormValueOptions,
                          "create-option": option => ({ key: option, label: option }),
                          reduce: returnValue => returnValue.key,
                          "select-on-key-codes": [188, 13],
                          modelValue: _ctx.multiselectFormValueSelected,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.multiselectFormValueSelected) = $event))
                        }, {
                          "no-options": _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                          ]),
                          option: _withCtx((concept) => [
                            _createTextVNode(_toDisplayString(_ctx.$t(concept.label)), 1)
                          ]),
                          "selected-option": _withCtx((concept) => [
                            _createTextVNode(_toDisplayString(_ctx.$t(concept.label)), 1)
                          ]),
                          _: 1
                        }, 8, ["taggable", "options", "create-option", "reduce", "modelValue"]))
                      : (_ctx.show)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('Ese tipo de input no se puede mostrar')), 1))
                        : _createCommentVNode("", true)
}