
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import Professional from '@/api/objects/Professional';
import { mapState } from 'vuex';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import api from '@/api';
import Clinic from '@/api/objects/Clinic';
import VLoader from '@/components/vendor/VLoader.vue';
import vSelect from 'vue-select';
import TheAdminClinicCreateModal from '@/views/administrator/clinic/TheAdminClinicCreateModal.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';

export default defineComponent({
  name: 'TheAdminProfessionalShow',
  components: {
    VFormGroupSelect,
    TheAdminClinicCreateModal,
    VLoader,
    VFormGroupReadOnlyText,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    vSelect,
  },
  props: {
    professional: {
      type: Professional,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
    phoneNumberPrefix() {
      return (this.professional.phoneNumber.split(' '))[0];
    },
    phoneNumber() {
      return (this.professional.phoneNumber.split(' '))[1];
    },
    canFreelyAuthorize() {
      return this.professional.canFreelyAuthorize ? this.$t('Sí') : this.$t('No');
    },
    isActive() {
      return this.professional.active ? this.$t('Sí') : this.$t('No');
    },
  },
  data() {
    return {
      status: 'loading',
      modalToShow: '' as string,
      professionalClinics: null as Clinic[] | null,
      currentProfessional: {} as Professional,
      availableClinicsOptions: [],
      selectedClinicId: null,
    };
  },
  mounted() {
    this.loadInitData();
  },
  methods: {
    async impersonate() {
      await this.$modal.confirm({
        title: this.$t('Iniciar sesión como'),
        // eslint-disable-next-line max-len
        text: this.$t('Vas a iniciar sesión como el profesional {nombre_profesional}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?', { nombre_profesional: `${this.currentProfessional.name} ${this.currentProfessional.surname}` }),
        confirmButtonText: this.$t('Confirmar'),
        confirmButtonCallback: this.doImpersonate,
      });
    },
    async doImpersonate() {
      try {
        await this.$store.dispatch('user/impersonateStart', { userId: this.currentProfessional?.user?.id });
      } catch (e) {
        // this.$toast.error(this.$t('Ha ocurrido un error al intentar personificar al usuario'));
        await this.$modal.info({
          title: this.$t('Error personificando'),
          text: this.$t('Ha ocurrido un error al intentar personificar al profesional'),
        });
      }
    },
    parseRoleName(value) {
      switch (value) {
        case 'Medico':
          return this.$t('Médico');
        case 'Nutricionista':
          return this.$t('Nutricionista');
        default:
          return this.$t('- Sin tipo -');
      }
    },
    identityDocType() {
      // eslint-disable-next-line max-len
      const docType = this.formsSettings.accepted_identification_document_types.find((idk) => idk.key === this.currentProfessional.identityDocType);

      return docType.label;
    },
    genre() {
      // TODO: Please, do this like a computed, it says error when using this.formsSettings (computed also)
      const genre = this.formsSettings.accepted_genre_options.find((ago) => ago.key === this.currentProfessional.genre);

      return genre.label;
    },
    async loadInitData() {
      this.currentProfessional = this.professional;

      await this.loadAvailableClinics();

      this.status = 'loaded';
    },
    async loadAvailableClinics() {
      try {
        const allClinics = await api.admin.clinic.all(null, false);

        // eslint-disable-next-line max-len
        const availableClinics = allClinics.filter((allClinic) => !this.currentProfessional.clinics?.some((professionalClinic) => allClinic.id === professionalClinic.id));

        this.availableClinicsOptions = availableClinics.map((clinic: Clinic) => ({
          key: clinic.id,
          label: clinic.name,
        }));
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar todas las clínicas de la plataforma.'));
      }
    },
    async associateProfessionalWithClinic() {
      await this.$modal.confirm({
        title: this.$t('Asociar profesional y clínica'),
        // eslint-disable-next-line max-len
        text: this.$t('Vas a asociar el profesional con esa clínica. Esto significa que podrá utilizarla como clínica para sus consultas. ¿Estás seguro?'),
        confirmButtonText: this.$t('Asociar'),
        confirmButtonCallback: this.doAssociateOrDisassociateProfessionalFromClinic,
      });
    },
    async disassociateProfessionalFromClinic() {
      await this.$modal.confirm({
        title: this.$t('Desasociar profesional y clínica'),
        // eslint-disable-next-line max-len
        text: this.$t('Vas a desasociar el profesional con esa clínica. Esto significa que no podrá utilizarla como clínica para sus consultas. ¿Estás seguro?'),
        confirmButtonText: this.$t('Desasociar'),
        confirmButtonCallback: this.doAssociateOrDisassociateProfessionalFromClinic,
      });
    },
    async doAssociateOrDisassociateProfessionalFromClinic() {
      try {
        await api.admin.clinic.attachOrDetachProfessional(this.currentProfessional.id, this.selectedClinicId);

        this.$emit('refresh-professional-list');

        await this.refreshCurrentProfessional();

        this.$toast.success(this.$t('Profesional asociado/desasociado con éxito de la clínica.'));
      } catch (e) {
        // TODO: Catch exception?
        if (e.response?.data?.code_error === 'clinic_not_detached') {
          // eslint-disable-next-line max-len
          this.$toast.error(this.$t('El profesional tiene alguna de sus consultas asociadas a la clínica, no podemos desasociarlo.'));
        }
      }
    },
    async refreshCurrentProfessional() {
      try {
        this.currentProfessional = await api.admin.professional.retrieve(this.professional.id);
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar los datos del profesional'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
