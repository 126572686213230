import { createLogger } from 'vuex';
import actions from '@/store/modules/professional/professional.actions';
import mutations from '@/store/modules/professional/professional.mutations';
import type { ProfessionalState } from '@/store/modules/professional/professional.types';

export default {
  namespaced: true,
  state: (): ProfessionalState => ({
    currentProfessional: null,
    expedients: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
