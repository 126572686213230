import { MutationTree } from 'vuex';
import {
  TreatmentState,
} from '@/store/modules/patient/expedient/modules/treatment/treatment.types';
import Treatment from '@/api/objects/Treatment';

interface TreatmentMutations {
  setTreatment(state: TreatmentState, payload: any): void;
}

const mutations: MutationTree<TreatmentState> & TreatmentMutations = {
  setTreatment(state: TreatmentState, treatment: any) {
    state.currentTreatment = treatment;
  },
  setTreatments(state: TreatmentState, treatments: Treatment[]) {
    state.treatments = treatments;
  },
};

export default mutations;
