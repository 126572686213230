
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'TheImpersonateStop',
  components: {
    VButton,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
  methods: {
    async impersonateStop() {
      await this.$store.dispatch('user/impersonateStop');
    },
  },
});
