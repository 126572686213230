export default {
  mixed: {
    default: 'Not valid',
    required: '{label} is required',
    oneOf: 'Passwords must match',
    notType: 'You must enter a valid data',
  },
  string: {
    min: 'You must enter a minimum of {min} characters',
    max: 'You must enter a maximum of {max} characters',
    email: 'You must enter a valid email address',
  },
  number: {
    min: 'Value must be greater than {min}',
    max: 'The value must be less than {max}',
  },
};
