
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VInput',
  props: {
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    id: {
      type: String,
      required: false,
      default: 'input',
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    suffix: {
      type: Object,
      required: false,
    },
    suffixClass: {
      type: String,
      required: false,
    },
    prefix: {
      type: Object,
      required: false,
    },
    prefixClass: {
      type: String,
      required: false,
    },
    forceSave: {
      type: Boolean,
      required: false,
      default: true,
    },
    actionButtons: {
      type: Object,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    yupErrorsClass: {
      type: String,
      required: false,
    },
    adjustInputForValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tmpVal: this.value,
      showPopper: false,
    };
  },
  computed: {
    isDirty() {
      return this.tmpVal !== this.value;
    },
    cIconVariant() {
      let ret: null | string = null;

      if (!!this.actionButtons?.icon) {
        ret = `icon-${this.actionButtons.icon}`;
      }

      return ret;
    },
    inputWidth() {
      return `${this.tmpVal.length + 2}ch`;
    },
  },
  watch: {
    tmpVal(newValue) {
      this.updateValue(newValue);
    },
    value() {
      this.tmpVal = this.value;
    },
  },
  methods: {
    updateValue(value) {
      if (!!this.forceSave) {
        this.emitUpdateValueEvent(value);
      }

      this.emitValueToSetYupErrors(value);
    },
    emitUpdateValueEvent(e) {
      if (typeof e === 'object') {
        this.$emit('update:value', this.tmpVal);
      } else {
        this.$emit('update:value', e);
      }

      this.emitOldValue();
    },
    emitOldValue() {
      this.$emit('old-value:value', this.value);
    },
    emitValueToSetYupErrors(e) {
      if (typeof e === 'object') {
        this.$emit('update-yup:value', this.tmpVal);
      } else {
        this.$emit('update-yup:value', e);
      }
    },
    resetTempValue() {
      this.tmpVal = this.value;
    },
  },
});
