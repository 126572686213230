import AbstractService from '@/api/services/AbstractService';
import Consultation from '@/api/objects/Consultation';

class ExpedientConsultationService extends AbstractService {
  public async all(expedientId: number): Promise<Consultation[]> {
    return this.request('get', this.buildPath('expedient/{expedientId}/consultations',
      { expedientId }));
  }

  public async store(expedientId: number, consultation: any): Promise<Consultation> {
    return this.request('post', this.buildPath('expedient/{expedientId}/consultation',
      { expedientId }), [], consultation);
  }

  public async update(expedientId: number, consultationId: number, consultation: any): Promise<Consultation> {
    return this.request('put', this.buildPath('expedient/{expedientId}/consultation/{consultationId}',
      {
        expedientId,
        consultationId,
      }), [], consultation);
  }
}

export default new ExpedientConsultationService();
