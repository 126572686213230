
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';
import qs from 'qs';
import TheAdminPurchaseAuthorizationShow
  from '@/views/administrator/purchaseAuthorization/parts/TheAdminPurchaseAuthorizationShow.vue';
import { mapState } from 'vuex';
import VPagination from '@/components/vendor/VPagination.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import withTimeout from '@/resources/timeOut';
import VLoader from '@/components/vendor/VLoader.vue';
import axios from 'axios';
import { DateTime } from 'luxon';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import querystring from 'query-string';

export default defineComponent({
  name: 'TheAdminPurchaseAuthorizationList',
  components: {
    VFormGroupInputDate,
    VLoader,
    VFormGroupSelect,
    VPagination,
    TheAdminPurchaseAuthorizationShow,
    VButton,
    VTableBody,
    VTableHeader,
    VTable,
    vSelect,
  },
  data() {
    return {
      loaded: false,
      loadedSearch: true,
      selectedPurchase: null as PurchaseAuthorization | null,
      purchaseAuthorizations: [] as PurchaseAuthorization[] | any[],
      purchaseAuthorizationsPagination: null as any,
      modalToShow: null as any,

      timer: '',
      filters: {
        professionals: [] as string[],
        patients: [] as string[],
        status: [] as string[],
        due_date: {
          start_at: null,
          end_at: null,
        },
        created_at: {
          start_at: null,
          end_at: null,
        },
      },
      filtersParsed: {},

      page: 1,

      availableProfessionalOptions: [] as any[],
      availablePatientOptions: [] as any[],
      selectedStatus: null as number | null,
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    domain() {
      return process.env.VUE_APP_API_URL;
    },
    availableStatusOptions(): Record<string, string>[] {
      return this.formsSettings.purchase_authorization_statuses;
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  async beforeMount() {
    await this.setQueryParametersAsFilters();

    await Promise.all([
      this.loadProfessionals(),
      this.loadPatients(),
      this.loadPurchaseAuthorizations(),
    ]);

    this.loaded = true;
  },
  methods: {
    parseDate(date) {
      if (!!date) {
        return this.$str.formatDateTime(date, 'yyyy-MM-dd');
      }

      return date;
    },
    async setQueryParametersAsFilters() {
      const { query }: any = this.$route;

      const {
        professionals,
        patients,
        status,
        page,
        // eslint-disable-next-line camelcase
        created_at,
        // eslint-disable-next-line camelcase
        due_date,
      }: any = qs.parse(query);

      if (!!page) {
        this.page = page;
      }

      if (!!professionals) {
        this.filters.professionals = professionals;
      }

      if (!!patients) {
        this.filters.patients = patients;
      }

      // eslint-disable-next-line camelcase
      if (!!created_at) {
        if (!!created_at.start_at) {
          this.filters.created_at.start_at = created_at.start_at;
        }

        if (!!created_at.end_at) {
          this.filters.created_at.end_at = created_at.end_at;
        }
      }

      // eslint-disable-next-line camelcase
      if (!!due_date) {
        if (!!due_date.start_at) {
          this.filters.due_date.start_at = due_date.start_at;
        }

        if (!!due_date.end_at) {
          this.filters.due_date.end_at = due_date.end_at;
        }
      }

      if (!!status) {
        this.filters.status = status;
      }
    },
    async setFiltersAsQueryParameters() {
      const filters = { ...this.filters } as any;

      if (!!this.page) {
        filters.page = this.page;
      }

      if (!filters.created_at.start_at) {
        delete filters.created_at.start_at;
      }

      if (!filters.created_at.end_at) {
        delete filters.created_at.end_at;
      }

      if (!filters.due_date.start_at) {
        delete filters.due_date.start_at;
      }

      if (!filters.due_date.end_at) {
        delete filters.due_date.end_at;
      }

      this.filtersParsed = filters;

      let query: any = qs.stringify(filters, {
        encode: false,
        strictNullHandling: true,
        skipNulls: true,
      });
      query = querystring.parse(query);

      await this.$router.push({
        name: 'admin.purchase-authorization.index',
        query,
      });
    },
    search() {
      this.loadedSearch = false;

      this.timer = withTimeout(async () => {
        this.page = 1;
        await this.doSearch();
      }, this.timer, 250);
    },
    async doSearch() {
      await this.setFiltersAsQueryParameters();
      await this.loadPurchaseAuthorizations();

      this.loadedSearch = true;
    },
    async showPurchaseAuthorizationShowModal(purchaseAuthorization) {
      // Force to load the purchase authorization due to a problem with pivot models
      purchaseAuthorization = await api.admin.purchaseAuthorization.get(purchaseAuthorization.id);

      this.selectedPurchase = purchaseAuthorization;
      this.modalToShow = 'the-admin-purchase-authorization-show';
    },
    async loadProfessionals() {
      const professionals = await api.admin.professional.all(null);

      professionals.forEach((professional) => {
        this.availableProfessionalOptions.push({
          key: professional.id.toString(),
          // eslint-disable-next-line max-len
          label: `${professional.name} ${professional.surname} - ${professional.speciality} (${professional.subspeciality})`,
        });
      });
    },
    async loadPatients() {
      const patients = await api.admin.patient.all(null);

      patients.forEach((patient) => {
        this.availablePatientOptions.push({
          key: patient.id.toString(),
          label: `${patient.user.name} ${patient.user.surname} (${patient.nif})`,
        });
      });
    },
    async loadPurchaseAuthorizations() {
      const resp = await api.admin.purchaseAuthorization.all(this.filtersParsed, true);

      if (!!resp.meta) {
        this.purchaseAuthorizations = resp.data;

        this.purchaseAuthorizationsPagination = {
          meta: resp.meta,
          pagination: resp.links,
        };
      } else {
        this.purchaseAuthorizations = resp;
      }
    },
    async selectPurchase(purchaseAuthorization) {
      this.selectedPurchase = purchaseAuthorization;
    },
    async handlePageChangedEvent(page) {
      this.page = page;

      await this.setFiltersAsQueryParameters();

      // We handle page only on the method call to avoid malfunctioning with page filter
      await this.loadPurchaseAuthorizations();
    },
    async downloadReport(config) {
      const response = await axios({
        url: config.url,
        params: this.filtersParsed,
        baseURL: this.domain,
        responseType: 'blob',
        withCredentials: true,
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });

      const date = DateTime.now()
        .toFormat('yyyy-MM-dd_hh-mm');

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${config.name}-${date}.xlsx`;
      link.click();
      window.URL.revokeObjectURL(url);
    },
    status(status: string) {
      const type = this.formsSettings.purchase_authorization_statuses.find(
        (o) => o.key === status,
      );

      return type.label;
    },
  },
});
