import BaseObject from '@/api/objects/BaseObject';
import User from '@/api/objects/User';

export interface CommentContract {
  commentableType: string;
  commentableId: number;
  type: string;
  status: string;
  content: string;
  isOwner: number;
  userId: number;
  commentId: number;
  author: User | null;
  // eslint-disable-next-line no-use-before-define
  parent: Comment | null;
}

export default class Comment extends BaseObject implements CommentContract {
  get commentableType(): string {
    return this.attributes.commentable_type;
  }

  get commentableId(): number {
    return this.attributes.commentable_id;
  }

  get type(): string {
    return this.attributes.type;
  }

  get status(): string {
    return this.attributes.status;
  }

  get content(): string {
    return this.attributes.content;
  }

  get isOwner(): number {
    return this.attributes.is_owner;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get userId(): number {
    return this.attributes.user_id;
  }

  get commentId(): number {
    return this.attributes.comment_id;
  }

  get author(): User | null {
    return this.getRelation('user');
  }

  get parent(): Comment | null {
    return this.getRelation('parent');
  }
}
