
import { defineComponent } from 'vue';
import Address from '@/api/objects/Address';
import { mapState } from 'vuex';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';

export default defineComponent({
  name: 'ThePatientAddressFieldset',
  components: {
    VFormGroupReadOnlyText,
  },
  props: {
    addressObject: {
      type: Address,
      required: false,
    },
    type: {
      type: String,
      required: true,
      default: 'billing',
    },
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    isBillingAddress() {
      return this.type === 'billing';
    },
  },
});
