import AbstractService from '@/api/services/AbstractService';
import Clinic from '@/api/objects/Clinic';

class ConsultationService extends AbstractService {
  public async getIndexOfProfessional(): Promise<Clinic[]> {
    return this.request('get', this.buildPath('clinics/professional'));
  }
}

export default new ConsultationService();
