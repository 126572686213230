import AbstractService from '@/api/services/AbstractService';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';

class PatientPurchaseAuthorizationService extends AbstractService {
  public async get(expedientId: number, purchaseAuthorizationId: number): Promise<PurchaseAuthorization> {
    // eslint-disable-next-line max-len
    return this.request('get', this.buildPath('expedient/{expedientId}/purchase-authorization/{purchaseAuthorizationId}',
      { expedientId, purchaseAuthorizationId }));
  }

  public async all(expedientId: number): Promise<PurchaseAuthorization[]> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedientId}/purchase-authorizations',
      { expedientId }));
  }
}

export default new PatientPurchaseAuthorizationService();
