
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import api from '@/api';

export default defineComponent({
  name: 'TheLanguageSwitcher',
  data() {
    return {
      locale: this.$i18n.locale,
    };
  },
  async mounted() {
    // Load language from localStorage or cookie
    this.locale = localStorage.getItem('lang') ?? 'es';
    this.$i18n.locale = localStorage.getItem('lang') ?? 'es';
  },
  watch: {
    async locale(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!!this.$root) {
          localStorage.setItem('lang', newValue);
          const response = await api.language.locale.update({ locale: newValue });

          if (response.status === 'error') {
            this.$toast.error(response?.message);
          }
          await this.$store.dispatch('app/getFormsSettings');

          this.$root.$i18n.locale = newValue;
        }
      }
    },
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
});
