import BaseObject from '@/api/objects/BaseObject';
import Patient from '@/api/objects/Patient';
import Professional from '@/api/objects/Professional';

export interface ExpedientContract {
  rgpdConfirmedAt: string;
  patientId: number;
  professionalId: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
}

export default class Expedient extends BaseObject implements ExpedientContract {
  get rgpdConfirmedAt(): string {
    return this.attributes.rgpd_confirmed_at;
  }

  get patientId(): number {
    return this.attributes.patient_id;
  }

  get professionalId(): number {
    return this.attributes.professional_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get deletedAt(): any {
    return this.attributes.deleted_at;
  }

  get patient(): Patient | null {
    return this.getRelation('patient');
  }

  get professional(): Professional | null {
    return this.getRelation('professional');
  }
}
