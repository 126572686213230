
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import api from '@/api';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ThePurchaseAuthorizationShow',
  components: {
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('purchaseAuthorization', ['currentPurchaseAuthorization']),
    ...mapState('app', ['formsSettings']),
  },
  methods: {
    async disablePurchaseAuthorization() {
      await this.$modal.confirm({
        title: this.$t('Deshabilitar autorización de compra'),
        // eslint-disable-next-line max-len
        text: this.$t('¿Estás seguro de que quieres deshabilitar esta autorización de compra? Si la deshabilitas,no podrá utilizarse para adquirir productos en la tienda.'),
        confirmButtonText: this.$t('Deshabilitar'),
        confirmButtonCallback: () => this.doDisablePurchaseAuthorization(),
      });
    },
    async doDisablePurchaseAuthorization() {
      try {
        const purchaseAuthorization = await api.professional.purchaseAuthorizations.disable(
          this.currentPurchaseAuthorization.expedientId,
          this.currentPurchaseAuthorization.id,
        );

        this.$toast.success(this.$t('La autorización de compra se ha deshabilitado correctamente'));

        await this.$store.dispatch('expedient/refreshPurchaseAuthorizations', {
          expedientId: this.currentPurchaseAuthorization.expedientId,
        });

        await this.$store.dispatch('purchaseAuthorization/getCurrentPurchaseAuthorization', {
          purchaseAuthorization,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al dishabilitar la autorización de compra'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
    status() {
      // eslint-disable-next-line max-len
      const type = this.formsSettings.purchase_authorization_statuses.find((o) => o.key === this.currentPurchaseAuthorization?.status);

      return type.label;
    },
  },
});
