import BaseObject from '@/api/objects/BaseObject';
// eslint-disable-next-line import/no-cycle
import User from '@/api/objects/User';

export interface AddressContract {
  addresableType: string;
  addressableId: number;
  type: string;
  status: string;
  addressOne: string;
  addressTwo: string;
  postCode: string;
  city: string;
  state: string;
  country: string;
  userId: number;
  user: User | null;
  createdAt: string;
  updatedAt: string;
}

export default class Address extends BaseObject implements AddressContract {
  get addresableType(): string {
    return this.attributes.addresable_type;
  }

  get addressableId(): number {
    return this.attributes.addressable_id;
  }

  get type(): string {
    return this.attributes.type;
  }

  get status(): string {
    return this.attributes.status;
  }

  get addressOne(): string {
    return this.attributes.address_one;
  }

  get addressTwo(): string {
    return this.attributes.address_two;
  }

  get postCode(): string {
    return this.attributes.post_code;
  }

  get city(): string {
    return this.attributes.city;
  }

  get state(): string {
    return this.attributes.state;
  }

  get country(): string {
    return this.attributes.country;
  }

  get userId(): number {
    return this.attributes.user_id;
  }

  get user(): User | null {
    return this.getRelation('user');
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }
}
