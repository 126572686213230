import { createLogger } from 'vuex';
import actions from '@/store/modules/user/user.actions';
import mutations from '@/store/modules/user/user.mutations';
import type { UserState } from '@/store/modules/user/user.types';

export default {
  namespaced: true,
  state: (): UserState => ({
    authUser: null,
    isGuest: true,
    impersonatedBy: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
