import { MutationTree } from 'vuex';
import { AppState } from '@/store/modules/app/app.types';

interface AppMutations {
  setFormsSettings(state: AppState, payload: any): void;
}

const mutations: MutationTree<AppState> & AppMutations = {
  setFormsSettings(state: AppState, formsSettings: any) {
    state.formsSettings = formsSettings;
  },
};

export default mutations;
