
import { defineComponent } from 'vue';
import VInputDate from '@/components/vendor/basic/form/parts/VInputDate.vue';

export default defineComponent({
  name: 'VFormGroupInputDate',
  components: {
    VInputDate,
  },
  emits: ['update:value'],
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
      default: null,
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    mask: {
      type: Object,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitValue(value) {
      this.$emit('update:value', value);
    },
  },
});
