import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_the_form_nutritional_anamnesis = _resolveComponent("the-form-nutritional-anamnesis")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    size: "xl",
    "class-name": "modal-fullscreen-lg-down"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Responder anamnesis nutricional')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createVNode(_component_the_form_nutritional_anamnesis, {
            "form-version": _ctx.currentNutritionalAnamnesis.version,
            "form-type": _ctx.currentNutritionalAnamnesis.type,
            "form-structure": _ctx.currentNutritionalAnamnesisStructure
          }, null, 8, ["form-version", "form-type", "form-structure"])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-sm btn-link-secondary me-24",
            "aria-label": _ctx.$t('Cancelar'),
            "data-bs-dismiss": "modal"
          }, _toDisplayString(_ctx.$t('Cancelar')), 9, _hoisted_1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}