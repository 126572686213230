import AbstractService from '@/api/services/AbstractService';
import Video from '@/api/objects/Video';

class VideoService extends AbstractService {
  public async all(filters: any): Promise<Video[]> {
    return this.request('get', this.buildPath('videos'), filters);
  }

  public async show(videoId: number): Promise<Video> {
    return this.request('get', this.buildPath('video/{videoId}', {
      videoId,
    }));
  }
}

export default new VideoService();
