import { createApp } from 'vue';
import { setLocale } from 'yup';
import Toast from 'vue-toastification';
import createToast from '@/resources/toast';
import createFormatter from '@/helpers/str';
import i18n from '@/lang';
import router from '@/router';
import store from '@/store';
import App from '@/App.vue';
import createModal from '@/resources/modal';
import FloatingVue from 'floating-vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
import './assets/scss/app.scss';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toast);
app.use(i18n);
app.use(createToast);
app.use(createModal);
app.use(FloatingVue);

app.use(createFormatter, {
  locale: 'es-ES',
});

const { t } = i18n.global;

setLocale({
  mixed: {
    default: () => t('mixed.default'),
    required: (params) => t('mixed.required', { label: t(params.label) }),
    oneOf: () => t('mixed.oneOf'),
  },
  string: {
    min: (params) => t('string.min', { min: params.min }),
    max: (params) => t('string.max', { max: params.max }),
    email: (params) => t('string.email'),
  },
  number: {
    min: (params) => t('number.min', { min: params.min }),
    max: (params) => t('number.max', { max: params.max }),
  },
});

app.mount('#app');

// Activate sentry for frontend
if (process.env.VUE_APP_SENTRY_ENABLED === 'true') {
  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [...process.env.VUE_APP_SENTRY_ORIGINS.split(','), /^\//],
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    tracesSampleRate: process.env.VUE_APP_SENTRY_TRACE_SAMPLE,
  });
}
