
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Media from '@/api/objects/Media';
import api from '@/api/index';

export default defineComponent({
  // TODO: This could be refactored to be used for any role
  name: 'TheAdminResourcesShowModal',
  components: {
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  props: {
    media: {
      type: Media,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    isPdf() {
      return this.media.name.includes('.pdf');
    },
    isWord() {
      return (this.media.name.includes('.doc') || this.media.name.includes('.docx'));
    },
    isText() {
      return this.media.name.includes('.txt');
    },
    isFile() {
      return !this.isPdf && !this.isWord && !this.isText;
    },
    isImage() {
      return this.media.mimeType.includes('image/');
    },
    collectionName() {
      // eslint-disable-next-line max-len
      return this.formsSettings.uploaded_public_media_category_options.find((o) => o.key === this.media.collectionName).label;
    },
  },
  methods: {
    async download() {
      try {
        const url = await api.admin.media.download(this.media.id);

        window.location.href = url;
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al intentar descargar el archivo'));
      }
    },
    async deleteMedia() {
      await this.$modal.confirm({
        title: this.$t('Eliminar recurso'),
        text: this.$t('Vas a eliminar el recurso. No podrás recuperarlo. ¿Estás seguro?'),
        confirmButtonText: this.$t('Eliminar'),
        confirmButtonCallback: this.doDeleteMedia,
      });
    },
    async doDeleteMedia() {
      try {
        await api.admin.media.destroy(this.media.id);

        // TODO: If everything ok, close modal an refresh
        this.$emit('refresh-media');

        this.$toast.success(this.$t('El recurso se ha eliminado correctamente.'));

        this.closeModal();
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al intentar eliminar el archivo.'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
