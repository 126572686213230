
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';

export default defineComponent({
  name: 'TheMenuAuth',
  computed: {
    ...mapState('user', ['authUser']),
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
});
