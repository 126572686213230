import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container auth-container" }
const _hoisted_2 = { class: "container-form w-100 py-56 px-32 px-sm-56" }
const _hoisted_3 = { class: "h4 title text-primary text-center align-self-center align-content-center mb-16" }
const _hoisted_4 = { class: "text-center align-self-center align-content-center mb-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_loader = _resolveComponent("v-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", null, _toDisplayString(_ctx.subtitle), 1),
        (_ctx.showLoader)
          ? (_openBlock(), _createBlock(_component_v_loader, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}