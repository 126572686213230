import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-group"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "row" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_form_group_read_only_text = _resolveComponent("v-form-group-read-only-text")!

  return true
    ? (_openBlock(), _createElementBlock("fieldset", _hoisted_1, [
        (_ctx.isBillingAddress)
          ? (_openBlock(), _createElementBlock("legend", _hoisted_2, _toDisplayString(_ctx.$t('Dirección de facturación')), 1))
          : (_openBlock(), _createElementBlock("legend", _hoisted_3, _toDisplayString(_ctx.$t('Dirección de envío')), 1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_v_form_group_read_only_text, {
              class: "mb-24",
              label: _ctx.$t('Dirección 1'),
              text: _ctx.addressObject?.addressOne ?? ''
            }, null, 8, ["label", "text"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_v_form_group_read_only_text, {
              class: "mb-24",
              label: _ctx.$t('Dirección 2'),
              text: _ctx.addressObject?.addressTwo ?? ''
            }, null, 8, ["label", "text"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_v_form_group_read_only_text, {
              class: "mb-24",
              label: _ctx.$t('Código postal'),
              text: _ctx.addressObject?.postCode ?? ''
            }, null, 8, ["label", "text"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_v_form_group_read_only_text, {
              label: _ctx.$t('Ciudad'),
              class: "mb-24",
              text: _ctx.addressObject?.city ?? ''
            }, null, 8, ["label", "text"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_v_form_group_read_only_text, {
              class: "mb-24",
              label: _ctx.$t('Provincia'),
              text: _ctx.addressObject?.state ?? ''
            }, null, 8, ["label", "text"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_v_form_group_read_only_text, {
              class: "mb-24",
              label: _ctx.$t('País'),
              text: _ctx.addressObject?.country ?? ''
            }, null, 8, ["label", "text"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}