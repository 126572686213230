import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64676fca"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "invalid-feedback mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.formGroupClass)
  }, [
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["form-label", _ctx.labelClass])
        }, _toDisplayString(_ctx.$t(_ctx.label)), 3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (!!_ctx.yupErrorsVariable)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.yupErrorsVariable), 1))
      : _createCommentVNode("", true)
  ], 2))
}