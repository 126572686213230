import Expedient from '@/api/objects/Expedient';
import AbstractService from '@/api/services/AbstractService';

class ExpedientService extends AbstractService {
  public async all(filters: any, paginate = false): Promise<Expedient[] | any> {
    const path = paginate ? this.buildPath('expedients-paginated') : this.buildPath('expedients');

    return this.request(
      'get',
      path,
      filters,
      {},
      undefined,
      paginate,
    );
  }

  public async getIndexByPatient(): Promise<Expedient[]> {
    return this.request('get', this.buildPath('expedients/patient'));
  }

  public async retrieve(expedient: number): Promise<Expedient> {
    return this.request('get', this.buildPath('expedient/{expedient}', { expedient }));
  }

  public async create(expedient: any) {
    return this.request('post', this.buildPath('expedient'), [], expedient);
  }

  /**
   * Updates the previous address if 'type' and 'user_id' are the same, or creates a new one if no
   * previous exist.
   *
   * @param expedient
   * @param address
   */
  public async storeOrUpdateAddress(expedient: number, address: any) {
    return this.request('put', this.buildPath('expedient/{expedient}/address', {
      expedient,
    }), [], address);
  }
}

export default new ExpedientService();
