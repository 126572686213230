import AbstractService from '@/api/services/AbstractService';
import Room from '@/api/objects/Room';

class ExpedientRoomService extends AbstractService {
  public async store(expedient: number, data: any): Promise<Room> {
    return this.request('post', this.buildPath('expedient/{expedient}/room', { expedient }), [], data);
  }

  public async getRoom(expedient: number): Promise<Room> {
    return this.request('get', this.buildPath('expedient/{expedient}/room', { expedient }));
  }

  public async sendRoomLinkToPatient(expedient: number, via: string) {
    return this.request('post', this.buildPath('expedient/{expedient}/room/send-link-to-patient', { expedient }), [], {
      via,
    });
  }
}

export default new ExpedientRoomService();
