
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import Form from '@/api/objects/Form';
import TheFormGroupNutritionalAnamnesis
  from '@/views/professional/anamnesis/parts/TheFormGroupNutritionalAnamnesis.vue';
import { calculateICC } from '@/helpers/formCalculationHelper';

export default defineComponent({
  name: 'TheFormNutritionalAnamnesis',
  components: {
    TheFormGroupNutritionalAnamnesis,
  },
  props: {
    formVersion: {
      type: String,
      required: false,
    },
    formType: {
      type: String,
      required: false,
    },
    formStructure: {
      type: [Object, Form],
      required: false,
    },
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    ...mapState('expedient', ['currentNutritionalAnamnesis']),
    lastFormStructure(): any {
      const formStructures = this.formsSettings.forms.find((obj) => obj.type === 'anamnesis_nutricional'
        && obj.version === this.formVersion);

      return formStructures.structure;
    },
  },
  methods: {
    handleAutomations(updatedFormGroup) {
      if (updatedFormGroup.name === 'perimetro_abdominal') {
        const { value } = updatedFormGroup.formValues[0];

        // Lets look for the 'perimetro_cadera'
        const hipPerimeterFormGroup = this.currentNutritionalAnamnesis.formGroupByName('perimetro_cadera');
        const hipPerimeter = hipPerimeterFormGroup.formValues[0].value;

        if (hipPerimeter) {
          // Lets calculate, because we can
          const icc = calculateICC(hipPerimeter, value);

          // Ok, set that ICC
          const iccFormGroup = this.currentNutritionalAnamnesis.formGroupByName('icc');
          const iccFormValue = iccFormGroup.formValues[0];
          iccFormValue.value = icc;
        }
      }

      if (updatedFormGroup.name === 'perimetro_cadera') {
        const { value } = updatedFormGroup.formValues[0];

        // Lets look for the 'perimetro_abdominal'
        const waistPerimeterFormGroup = this.currentNutritionalAnamnesis.formGroupByName('perimetro_abdominal');
        const waistPerimeter = waistPerimeterFormGroup.formValues[0].value;

        if (waistPerimeter) {
          // Lets calculate, because we can
          const icc = calculateICC(waistPerimeter, value);

          // Ok, set that ICC
          const iccFormGroup = this.currentNutritionalAnamnesis.formGroupByName('icc');
          const iccFormValue = iccFormGroup.formValues[0];
          iccFormValue.value = icc;
        }
      }
    },
  },
});
