
import { defineComponent } from 'vue';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import useCapabilities from '@/resources/capabilities';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import api from '@/api';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import { mapState } from 'vuex';
import getExpedientsOptions from '@/helpers/selectOptionsHelper';
import VFormGroupTextarea from '@/components/vendor/basic/form/VFormGroupTextarea.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  // TODO: Check if better with a store module and 'currentInterConsultation' instead of this prop
  name: 'TheProfessionalInterConsultationCreate',
  components: {
    VFormGroupTextarea,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    VFormGroupSelect,
    VFormGroupInputText,
    vSelect,
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
  data() {
    return {
      availableExpedientOptions: [] as any[],
      availableProfessionalOptions: [] as any[],
    };
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      title: yup.string()
        .required()
        .max(60)
        .label(t('Título')),
      description: yup.string()
        .required()
        .label(t('Descripción')),
      expedient: yup.number()
        .nullable()
        .label(t('Paciente')),
      professional: yup.number()
        .nullable()
        .required()
        .label(t('Profesional')),
    });

    const form = useFormValidation(rules);

    const { value: title } = useField('title');
    const { value: description } = useField('description');
    const { value: expedient } = useField('expedient');
    const { value: professional } = useField('professional');

    return {
      ...useCapabilities(),
      ...form,
      title,
      description,
      expedient,
      professional,
    };
  },
  beforeMount() {
    this.loadAvailableOptions();
  },
  methods: {
    async loadAvailableOptions() {
      this.availableExpedientOptions = await getExpedientsOptions();

      const professionals = await api.professionalData.getProfessionalsList();

      professionals.forEach((professional) => {
        if (professional.id !== this.authUser.professional.id) {
          // We need to remove the user logged in as an option
          this.availableProfessionalOptions.push({
            key: professional.id,
            // eslint-disable-next-line max-len
            label: `${professional.name} ${professional.surname} - ${professional.speciality} (${professional.subspeciality})`,
          });
        }
      });
    },
    async createInterConsultation() {
      this.toggleAccepted();

      const data = {
        title: this.title,
        description: this.description,
        patient_id: this.expedient,
        target_professional_id: this.professional,
      };

      try {
        const interConsultation = await api.professional.interConsultation.store(
          data,
        );

        this.$toast.success(this.$t('La interconsulta se ha creado con éxito'));

        this.toggleAccepted();

        this.$emit('inter-consultation-created', interConsultation.id);

        this.closeModal();
      } catch (e) {
        this.toggleAccepted();

        this.$toast.error(this.$t('Ha ocurrido un error al crear la interconsulta'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
