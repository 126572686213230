
import { defineComponent, watch } from 'vue';
import { mapState, useStore } from 'vuex';
import VInputDate from '@/components/vendor/basic/form/parts/VInputDate.vue';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';

export default defineComponent({
  name: 'TheProfessionalProfile',
  components: {
    VTable,
    VTableHeader,
    VFormGroupInputText,
    VInputDate,
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('professional', ['currentProfessional']),
    ...mapState('app', ['formsSettings']),
    identityDocType(): string {
      // eslint-disable-next-line max-len
      return this.formsSettings.accepted_identification_document_types.find((o) => o.key === this.currentProfessional?.identityDocType).label;
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  setup() {
    const {
      state,
      dispatch,
    } = useStore();

    watch(() => state.professional.currentProfessional, (value, oldValue) => {
      // this function loads the professional information if at any time it changes to null
      if (!!oldValue && !value) {
        dispatch('professional/getProfessional');
      }
    });
  },
  async beforeMount() {
    await this.loadProfessional();
    this.isLoading = false;
  },
  methods: {
    genre() {
      // TODO: Please, do this like a computed, it says error when using this.formsSettings (computed also)
      const genre = this.formsSettings.accepted_genre_options.find((ago) => ago.key === this.currentProfessional.genre);

      return genre.label;
    },
    async loadProfessional() {
      await this.$store.dispatch('professional/getProfessional');
    },
  },
});
