import { AxiosResponse } from 'axios';
import BaseObject from '@/api/objects/BaseObject';
import User from '@/api/objects/User';
import Patient from '@/api/objects/Patient';
import Expedient from '@/api/objects/Expedient';
import Form from '@/api/objects/Form';
import FormValue from '@/api/objects/FormValue';
import FormGroup from '@/api/objects/FormGroup';
import Treatment from '@/api/objects/Treatment';
import Consultation from '@/api/objects/Consultation';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';
import Product from '@/api/objects/Product';
import ProductPurchaseAuthorization from '@/api/objects/ProductPurchaseAuthorization';
import Protocol from '@/api/objects/Protocol';
import ProtocolPhase from '@/api/objects/ProtocolPhase';
import TreatmentProtocolPhase from '@/api/objects/TreatmentProtocolPhase';
import FormVersion from '@/api/objects/FormVersion';
import Professional from '@/api/objects/Professional';
import Media from '@/api/objects/Media';
import Clinic from '@/api/objects/Clinic';
import CalendarEvent from '@/api/objects/CalendarEvent';
import Room from '@/api/objects/Room';
import Address from '@/api/objects/Address';
import InterConsultation from '@/api/objects/InterConsultation';
import Comment from '@/api/objects/Comment';
import Category from '@/api/objects/Category';
import Video from '@/api/objects/Video';
import ResourceCategory from '../objects/ResourceCategory';

export interface ApiResponseContract {
  data: BaseObject | BaseObject[],
  meta: any,
  links: any,
  included: any[]
}

class HttpParseResponse {
  protected response: AxiosResponse | null = null;

  protected included: any[] = [];

  protected meta: any = {};

  protected links: any = {};

  protected data: any[] | any = null;

  /**
   *
   * @param data
   */
  public setResponse({ data }: AxiosResponse): this {
    const { included, meta, links } = data;

    this.data = data.data;

    if (!!included) {
      this.included = included;
    }

    if (!!meta) {
      this.meta = meta;
    }

    if (!!links) {
      this.links = links;
    }

    return this;
  }

  /**
   *
   * @param data
   */
  public setIncluded(included: any[]): this {
    this.included = included;

    return this;
  }

  public parser(paginate = false): any {
    const {
      data, meta, included, links,
    } = this;

    const parseData = this.parseJson(data);

    if (!!paginate) {
      return {
        data: parseData, meta, included, links,
      };
    }

    return parseData;
  }

  /**
   * Discriminates if the object type is array and calls to map
   *
   * @param data
   */
  public parseJson(data) {
    if (!Array.isArray(data)) {
      return this.mapObject(data);
    }

    return data.map((t) => this.mapObject(t));
  }

  /**
   *  Build relations object
   *
   * @param object
   * @param relationships
   * @protected
   */
  protected mapRelations(object: BaseObject, relationships: any) {
    object.setIncluded(this.included);
    object.setRelations(relationships);

    return this;
  }

  protected mapObject = (tmp: any) => {
    let object: BaseObject;

    switch (tmp.type) {
      case 'user':
        object = new User(tmp);
        break;
      case 'patient':
        object = new Patient(tmp);
        break;
      case 'expedient':
        object = new Expedient(tmp);
        break;
      case 'protocol':
        object = new Protocol(tmp);
        break;
      case 'protocol_phase':
        object = new ProtocolPhase(tmp);
        break;
      case 'treatment':
        object = new Treatment(tmp);
        break;
      case 'treatment_protocol_phase':
        object = new TreatmentProtocolPhase(tmp);
        break;
      case 'consultation':
        object = new Consultation(tmp);
        break;
      case 'form':
        object = new Form(tmp);
        break;
      case 'form_value':
        object = new FormValue(tmp);
        break;
      case 'form_group':
        object = new FormGroup(tmp);
        break;
      case 'purchase_authorization':
        object = new PurchaseAuthorization(tmp);
        break;
      case 'product':
        object = new Product(tmp);
        break;
      case 'product_purchase_authorization':
        object = new ProductPurchaseAuthorization(tmp);
        break;
      case 'form_version':
        object = new FormVersion(tmp);
        break;
      case 'professional':
        object = new Professional(tmp);
        break;
      case 'media':
        object = new Media(tmp);
        break;
      case 'clinic':
        object = new Clinic(tmp);
        break;
      case 'calendar_event':
        object = new CalendarEvent(tmp);
        break;
      case 'room':
        object = new Room(tmp);
        break;
      case 'address':
        object = new Address(tmp);
        break;
      case 'inter_consultation':
        object = new InterConsultation(tmp);
        break;
      case 'comment':
        object = new Comment(tmp);
        break;
      case 'category':
        object = new Category(tmp);
        break;
      case 'resource_category':
        object = new ResourceCategory(tmp);
        break;
      case 'video':
        object = new Video(tmp);
        break;
      default:
        throw Error(`Object not defined: ${JSON.stringify(tmp)}`);
    }

    if (!!tmp.relationships) {
      this.mapRelations(object, tmp.relationships);
    }

    return object;
  };
}

export default new HttpParseResponse();
