
import { defineComponent } from 'vue';
import * as yup from 'yup';
import useCapabilities from '@/resources/capabilities';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import Address from '@/api/objects/Address';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import { mapState } from 'vuex';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalPatientAddressFieldset',
  components: {
    VFormGroupSelect,
    vSelect,
    VButton,
    VFormGroupInputText,
  },
  props: {
    addressObject: {
      type: Address,
      required: false,
    },
    type: {
      type: String,
      required: true,
      default: 'billing',
    },
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('app', ['formsSettings']),
    isBillingAddress() {
      return this.type === 'billing';
    },
    countryStateOptions() {
      return this.formsSettings.address_country_state_options[this.country as string];
    },
  },
  setup(props) {
    const { t } = useI18n();
    const rules = yup.object({
      addressOne: yup.string()
        .required()
        .label(t('Dirección 1'))
        .max(60),
      addressTwo: yup.string()
        .nullable()
        .label(t('Dirección 2'))
        .max(60),
      postCode: yup.string()
        .required()
        .label(t('Código postal'))
        .max(60),
      city: yup.string()
        .required()
        .label(t('Población'))
        .max(60),
      state: yup.string()
        .required()
        .label(t('Provincia'))
        .max(60),
      country: yup.string()
        .required()
        .label(t('País'))
        .max(60),
    });

    const form = useFormValidation(rules, {
      addressOne: props.addressObject?.addressOne,
      addressTwo: props.addressObject?.addressTwo,
      postCode: props.addressObject?.postCode,
      city: props.addressObject?.city,
      state: props.addressObject?.state,
      country: props.addressObject?.country,
    });

    const { value: addressOne } = useField('addressOne');
    const { value: addressTwo } = useField('addressTwo');
    const { value: state } = useField('state');
    const { value: city } = useField('city');
    const { value: country } = useField('country');
    const { value: postCode } = useField('postCode');

    return {
      ...form,
      ...useCapabilities(),
      addressOne,
      addressTwo,
      state,
      city,
      country,
      postCode,
    };
  },
  watch: {
    country() {
      this.state = '';
    },
  },
  data() {
    return {
      isStoring: false,
    };
  },
  beforeMount() {
    this.setLocalValues();
  },
  methods: {
    setLocalValues() {
      if (!!this.addressObject) {
        // We receive props and we need to set as local values and change those ones here as a form
        this.addressOne = this.addressObject?.addressOne;
        this.addressTwo = this.addressObject?.addressTwo;
        this.postCode = this.addressObject?.postCode;
        this.city = this.addressObject?.city;
        this.state = this.addressObject?.state;
        this.country = this.addressObject?.country;

        this.isStoring = true;
      }
    },
    async doUpdateAddress() {
      this.toggleAccepted();

      const data = {
        status: 'active',
        type: this.isBillingAddress ? 'billing' : 'shipping',
        address_one: this.addressOne,
        address_two: this.addressTwo ?? '',
        post_code: this.postCode,
        city: this.city,
        state: this.state,
        country: this.country,
      };

      await api.professional.expedient.storeOrUpdateAddress(this.currentExpedient.id, data);

      this.resetForm({
        values: {
          addressOne: this.addressOne,
          addressTwo: this.addressTwo ?? '',
          postCode: this.postCode,
          city: this.city,
          state: this.state,
          country: this.country,
        },
      });

      let message: string = this.$t('La dirección de facturación se ha actualizado correctamente.');
      if (!this.isBillingAddress) {
        message = this.$t('La dirección de envío se ha actualizado correctamente.');
      }

      this.$toast.success(message);

      this.$emit('address-updated');

      this.toggleAccepted();
    },
  },
});
