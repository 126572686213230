import { ActionTree } from 'vuex';
import { State } from '@/store/types';
import { ProtocolState } from '@/store/modules/protocol/protocol.types';
import api from '@/api';
import Protocol from '@/api/objects/Protocol';

const actions: ActionTree<ProtocolState, State> = {
  async getProtocols({ commit }) {
    try {
      const protocols: Protocol[] = await api.professional.protocol.all();

      commit('setProtocols', protocols);

      commit('setStatus', 'loaded');
    } catch (e) {
      commit('setProtocols', null);
      commit('setStatus', 'loaded');
    }
  },
};

export default actions;
