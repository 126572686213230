import BaseObject from '@/api/objects/BaseObject';

export interface ResourceCategoryContract {
  key: string;
  label: string;
  labelSelect: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class ResourceCategory extends BaseObject implements ResourceCategoryContract {
  get key(): string {
    return this.attributes.key;
  }

  get label(): string {
    return this.attributes.label;
  }

  get labelSelect(): string {
    return this.attributes.label_select;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get deletedAt(): string {
    return this.attributes.deleted_at;
  }
}
