import { vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "checkbox",
    "true-value": "true",
    "false-value": "false",
    id: _ctx.id,
    value: _ctx.inputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    disabled: _ctx.disabled,
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
    ref: "input"
  }, null, 40, _hoisted_1)), [
    [_vModelCheckbox, _ctx.inputValue]
  ])
}