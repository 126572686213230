import { createLogger } from 'vuex';
import mutations from '@/store/modules/interconsultation/interconsultation.mutations';
import actions from '@/store/modules/interconsultation/interconsultation.actions';
import { InterConsultationState } from '@/store/modules/interconsultation/interconsultation.types';

export default {
  namespaced: true,
  state: (): InterConsultationState => ({
    interConsultations: [],
    currentInterConsultation: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
