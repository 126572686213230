
import { defineComponent } from 'vue';
import api from '@/api';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import Form from '@/api/objects/Form';
import VFormGroupInputCheckbox from '@/components/vendor/basic/form/VFormGroupInputCheckbox.vue';
import VFormRow from '@/views/professional/expedients/medicalData/forms/parts/VFormRow.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'TheProfessionalExpedientFormsList',
  components: {
    VButton,
    VFormRow,
    VFormGroupInputCheckbox,
    VTableBody,
    VTableHeader,
    VTable,
  },
  props: {
    formName: {
      type: String,
      required: true,
    },
    formData: {
      type: Form,
      required: true,
    },
    formStructure: {
      type: Object,
      required: false,
    },
    forceSave: {
      type: Boolean,
      required: false,
      default: true,
    },
    inputClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('app', ['formsSettings']),
    lastFormStructure(): any {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === this.formName);

      const lastFormStructureVersion = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      return lastFormStructureVersion.structure;
    },
    canCreateOrUpdateFormGroup(): boolean {
      return this.can('professional.expedient.form-group.store')
      || this.can('professional.expedient.form-group.update');
    },
  },
  data() {
    return {
      formGroupIdSelected: -1,
      modalToShow: null,
      fastAccessElementsLoaded: false,
      fastAccessElements: {
        alergias: [
          {
            label: this.$t('Animales'),
            value: 'Animales',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Ácaros del polvo'),
            value: 'Ácaros del polvo',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Moho'),
            value: 'Moho',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Insectos'),
            value: 'Insectos',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Polen de gramíneas'),
            value: 'Polen de gramíneas',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Polen de árbol'),
            value: 'Polen de árbol',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Polen de malezas'),
            value: 'Polen de malezas',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Polen'),
            value: 'Polen',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Cacahuetes'),
            value: 'Cacahuetes',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Soja'),
            value: 'Soja',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Frutos secos'),
            value: 'Frutos secos',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Trigo'),
            value: 'Trigo',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Pescado y marisco'),
            value: 'Pescado y marisco',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Huevos'),
            value: 'Huevos',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Frutas y verduras'),
            value: 'Frutas y verduras',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('Leche'),
            value: 'Leche',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
          {
            label: this.$t('No conocidas'),
            value: 'No conocidas',
            field: 'nombre',
            isChecked: false,
            formGroupId: null,
          },
        ],
      },
    };
  },
  mounted() {
    // We iterate over existing values to check if it is registered so we check the value
    this.refreshCheckedAndUncheckedFastAccessElements();
  },
  methods: {
    /**
     * Iterates over the form values and through the fast access elements to set them as checked or
     * unchecked.
     * */
    refreshCheckedAndUncheckedFastAccessElements() {
      if (!!this.fastAccessElements[this.formName]) {
        this.fastAccessElements[this.formName].forEach((faE) => {
          const foundFormGroupId = this.doesFastAccessElementExist(faE.field, faE.value);
          if (foundFormGroupId) {
            faE.isChecked = true;
            faE.formGroupId = foundFormGroupId;
          } else {
            faE.isChecked = false;
            faE.formGroupId = null;
          }
        });
      }

      this.fastAccessElementsLoaded = true;
    },

    /**
     * Handles a fastAccessElement click to add or remove it if possible.
     * */
    fastAccessElementSelected(isChecked, formName, value, field) {
      if (isChecked === 'true') {
        if (!this.formData) {
          const element = this.buildElementFromFastAccess(field, value);

          this.createFormGroup(this.$t('respuesta'), element);
        } else {
          // Previous information and they want to add a new one, but we need to check that it
          // eslint-disable-next-line no-lonely-if
          if (!this.doesFastAccessElementExist(field, value)) {
            const element = this.buildElementFromFastAccess(field, value);

            this.createFormGroup(this.$t('respuesta'), element);
          } else {
            this.showErrorModal(this.$t('Parece que ese registro ya ha sido introducido.'));
          }
        }
      } else if (this.formData) {
        const foundFormGroupId = this.doesFastAccessElementExist(field, value);
        if (foundFormGroupId) {
          this.doDeleteFormDate(foundFormGroupId);
        } else {
          this.showErrorModal(this.$t('Parece que ese registro no existe o ya se ha eliminado.'));
        }
      }
    },

    /**
     * Shows a simple error message as a modal.
     * */
    async showErrorModal(message) {
      await this.$modal.info({
        title: this.$t('¡Uups!'),
        text: message,
        rightButtonClasses: 'btn-secondary',
        acceptButtonText: this.$t('Cerrar'),
        leftButtonText: this.$t('Volver al panel'),
      });
    },

    /**
     * Returns false if it doesn't exist or the formGroup id if existing to allow deleting it.
     * */
    doesFastAccessElementExist(field, value) {
      let found: any = false;
      // eslint-disable-next-line no-unused-expressions
      this.formData?.formGroups?.forEach((formGroup) => {
        if (!!formGroup.formValues) {
          formGroup.formValues.forEach((formValue) => {
            if (formValue.type === field && formValue.value === value) {
              found = formGroup.id;
            }
          });
        }
      });

      return found;
    },

    /**
     * Builds a new formGroup row to be stored. This is used when fastAccessElements are used.
     *
     * @param field
     * @param value
     */
    buildElementFromFastAccess(field, value) {
      const element = {};

      // We iterate over the form structure and replace the key we want
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      this.lastFormStructure.form_groups.forEach((formGroup) => {
        formGroup.form_values.forEach((formValue) => {
          if (formValue.key === field) {
            element[formValue.key] = value;
          } else {
            element[formValue.key] = '';
          }
        });
      });

      return element;
    },

    /**
     * Forces storing a formGroup when. This is to be done when fast access element is clicked.
     *
     * @param formGroupName
     * @param values
     */
    async createFormGroup(formGroupName, values) {
      let formData: any = {};

      formData = {
        form_key: this.formName,
        form_group_name: formGroupName,
        form_values: values,
      };

      // TODO: Please, refactor this, as it exist just like this in another component
      try {
        await api.professional.expedients.forms.create(this.currentExpedient.id, formData);

        this.$toast.success(this.$t('Los datos se han guardado correctamente.'));

        await this.$store.dispatch('expedient/refreshExpedientForms', {
          expedientId: this.currentExpedient.id,
        });

        this.$emit('load-expedient-forms-requested');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al guardar los datos'));
      }
    },

    // eslint-disable-next-line consistent-return
    async doDeleteFormDate(formGroupId: number) {
      try {
        await api.professional.expedients.forms.delete(
          this.currentExpedient.id,
          formGroupId,
        );

        this.$toast.success(this.$t('Los datos se han eliminado correctamente'));

        this.emitLoadExpedientFormsRequestEvent();
      } catch (e) {
        return {
          error: this.$t('Ha ocurrido un error al eliminar los datos'),
        };
      }
    },
    async confirmDeleteFormGroup(formGroupId) {
      await this.$modal.delete({
        title: this.$t('¿Seguro que desea eliminar este dato?'),
        text: this.$t('Al borrar el dato no podrás volver a visualizarlo'),
        deleteButtonText: this.$t('Eliminar'),
        textLayout: null,
        deleteButtonCallback: () => this.doDeleteFormDate(formGroupId),
      });
    },
    emitLoadExpedientFormsRequestEvent() {
      this.$emit('load-expedient-forms-requested');
    },
  },
});
