import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35220614"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-end mb-16" }
const _hoisted_2 = { class: "fw-bold me-4" }
const _hoisted_3 = { class: "purchase-authorization-container mb-16" }
const _hoisted_4 = { class: "h6 mb-8 fw-bold" }
const _hoisted_5 = { class: "content mb-0" }
const _hoisted_6 = { class: "fw-bold" }
const _hoisted_7 = { class: "content mb-0" }
const _hoisted_8 = { class: "mb-0 fw-bold" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "mb-0 me-12" }
const _hoisted_11 = { class: "content mb-0" }
const _hoisted_12 = { class: "fw-bold" }
const _hoisted_13 = { class: "content mb-0" }
const _hoisted_14 = { class: "fw-bold" }
const _hoisted_15 = { class: "product-list" }
const _hoisted_16 = { class: "h6 mb-8 fw-bold" }
const _hoisted_17 = { class: "me-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    size: "lg",
    "class-name": "modal-fullscreen-lg-down"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Autorización de compra')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('Fecha creación:')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$str.formatDate(_ctx.currentPurchaseAuthorization.createdAt)), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('Datos de la autorización de compra:')), 1),
            _createElementVNode("p", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('Fecha de vencimiento:')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$str.formatDate(_ctx.currentPurchaseAuthorization.dueDate)), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('Estado:')), 1),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t(_ctx.status())), 1)
              ])
            ]),
            (_ctx.currentPurchaseAuthorization.protocolPhaseTreatment)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", _hoisted_11, [
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('Protocolo:')), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.currentPurchaseAuthorization.protocolPhaseTreatment.protocolPhase.protocol.name), 1)
                  ]),
                  _createElementVNode("p", _hoisted_13, [
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('Fase:')), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.currentPurchaseAuthorization.protocolPhaseTreatment.protocolPhase.name), 1)
                  ])
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('Productos')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentPurchaseAuthorization.products, (product) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "product-container-item d-flex justify-content-between align-items-center gap-24 py-8 px-16",
                key: product.id
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_17, _toDisplayString(product.name) + " (" + _toDisplayString(product?.type) + ") -", 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('{doses} dosis durante {days} días', {
                  doses: product.pivot.doses,
                  days: product.pivot.days,
              })), 1)
                ]),
                _createElementVNode("div", null, "x" + _toDisplayString(product.pivot.quantity), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "secondary",
            "aria-label": this.$t('Volver'),
            onClick: _ctx.closeModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Volver')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}