import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { InterConsultationState } from '@/store/modules/interconsultation/interconsultation.types';
import InterConsultation from '@/api/objects/InterConsultation';
import api from '@/api';

type Context = ActionContext<InterConsultationState, State>;

const actions: ActionTree<InterConsultationState, State> = {
  async setCurrentInterConsultation({
    commit,
  }: Context, {
    interConsultation,
  }: { interConsultation: InterConsultation }) {
    commit('setCurrentInterConsultation', interConsultation);
  },
  async clearCurrentInterConsultation({ commit }: Context) {
    commit('setCurrentInterConsultation', null);
  },
  async setInterConsultations({
    commit,
  }: Context) {
    try {
      const interConsultations = await api.professional.interConsultation.getIndexByCreatorOrTarget();

      commit('setInterConsultations', interConsultations);
    } catch (e) {
      commit('setInterConsultations', null);
    }
  },
};

export default actions;
