import { MutationTree } from 'vuex';
import {
  PurchaseAuthorizationState,
} from '@/store/modules/purchaseAuthorization/purchaseAuthorization.types';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';

interface PurchaseAuthorizationMutations {
  setCurrentPurchaseAuthorization(state: PurchaseAuthorizationState,
    purchaseAuthorization: PurchaseAuthorization): void;
}

const mutations: MutationTree<PurchaseAuthorizationState> & PurchaseAuthorizationMutations = {
  setCurrentPurchaseAuthorization(state: PurchaseAuthorizationState, purchaseAuthorization: PurchaseAuthorization) {
    state.currentPurchaseAuthorization = purchaseAuthorization;
  },
};

export default mutations;
