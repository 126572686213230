
import { defineComponent } from 'vue';
import VInputRadio from '@/components/vendor/basic/form/parts/VInputRadio.vue';

export default defineComponent({
  name: 'VFormGroupInputRadio',
  components: {
    VInputRadio,
  },
  props: {
    formGroupName: {
      type: String,
      required: true,
      default: 'formGroupName',
    },
    formValueKey: {
      type: String,
      required: false,
      default: 'formValueKey',
    },
    formValueLabel: {
      type: String,
      required: false,
      default: 'formValueLabel',
    },
    formValueOptions: {
      type: Array,
      required: true,
      default: null,
    },
    formValueClass: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    selected: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitValue(value) {
      this.$emit('update:value', value);
    },
  },
});
