import AbstractService from '@/api/services/AbstractService';
import Consultation from '@/api/objects/Consultation';

class ConsultationService extends AbstractService {
  public async all(): Promise<Consultation[]> {
    return this.request('get', this.buildPath('patient-zone/consultations'));
  }
}

export default new ConsultationService();
