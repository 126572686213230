
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import TheProfessionalExpedientFormsList
  from '@/views/professional/expedients/medicalData/forms/TheProfessionalExpedientFormsList.vue';
import TheProfessionalExpedientFormsCreateModal
  from '@/views/professional/expedients/medicalData/forms/TheProfessionalExpedientFormsCreateModal.vue';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import api from '@/api';
import Form from '@/api/objects/Form';
import TheProfessionalExpedientMeasuresList
  from '@/views/professional/expedients/measures/TheProfessionalExpedientMeasuresList.vue';

export default defineComponent({
  name: 'TheProfessionalExpedientFileMedicalData',
  components: {
    TheProfessionalExpedientMeasuresList,
    TheProfessionalExpedientFormsList,
    VButton,
    TheProfessionalExpedientFormsCreateModal,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: null,
      medicalData: '',
      expedientForm: null as Form | null,
      options: {
        phone: {
          phone: true,
          delimiter: '-',
        },
      },
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'forms']),
    ...mapState('app', ['formsSettings']),
    lastFormStructure(): Form {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === this.medicalData);

      const lastFormStructureVersion = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      return lastFormStructureVersion.structure;
    },
    /**
     * Forms we don't want to show as normal dynamic forms
     */
    specialForms() {
      return [
        'anamnesis_nutricional',
        'medidas',
        'anamnesis',
        'test_de_sensibilidad_central',
      ];
    },
  },
  beforeMount() {
    this.setMedicalData('alergias');
  },
  methods: {
    parseField(field) {
      return field.structure;
    },
    async loadExpedientForms() {
      await this.$store.dispatch('expedient/refreshExpedientForms', {
        expedientId: this.currentExpedient.id,
      });

      this.setMedicalData(this.medicalData);

      // FIXME: Force component to refresh its fastAccessElements, but this is not working properly
      const $xx: any = this.$refs['the-professional-expedient-forms-list'];
      $xx.refreshCheckedAndUncheckedFastAccessElements();
    },
    async createExpedientForm(formData) {
      try {
        await api.professional.expedients.forms.create(this.currentExpedient.id, formData);

        this.$toast.success(this.$t('Los datos se han guardado correctamente.'));

        await this.loadExpedientForms();
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al guardar los datos'));
      }
    },
    setMedicalData(form) {
      this.medicalData = form;

      this.expedientForm = this.forms.find((f) => f.type === form);
    },
  },
});
