import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import api from '@/api';
import { RoomState } from '@/store/modules/patient/expedient/modules/room/room.types';
import Room from '@/api/objects/Room';

type Context = ActionContext<RoomState, State>;

const actions: ActionTree<RoomState, State> = {
  async getRoomByExpedient({
    commit,
  }: Context, { expedientId }: { expedientId: number}) {
    if (expedientId) {
      const room = await api.patient.expedient.room.getLastRoom(expedientId);
      commit('setCurrentRoom', room);
    }
  },
  async setCurrentRoom({
    commit,
  }: Context, {
    room,
  }: { room: Room | null }) {
    commit('setCurrentRoom', room);
  },
};

export default actions;
