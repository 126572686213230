/**
 * Returns an expression according to the provided dependency. This expression can be evaluated
 * as a js expression as if it was an if statement, so it would return true or false when
 * evaluated.
 * This is used in dynamic forms 'dependency' attribute.
 *
 * @param dependency
 * @param form
 */
import Form from '@/api/objects/Form';

export const getExpression = (dependency: Record<string, any>, form: Form): string => {
  let expression = '';

  dependency.rules.forEach((rule) => {
    if (!!expression) {
      expression += ` ${dependency.condition} `;
    }

    let {
      field,
      value,
    } = rule;

    const stateField = field.split('.');
    // We obtain anamnesis.formGroup.formValue value to compare with dependency rule condition
    const formGroup = form.formGroupByName(stateField[1])
      ?.formValueByType(stateField[2]);

    field = formGroup?.value;

    switch (typeof field) {
      case 'string':
        field = `'${field}'`;
        break;
      default:
        break;
    }

    switch (typeof value) {
      case 'string':
        value = `'${value}'`;
        break;
      default:
        break;
    }

    const { operator } = rule;

    switch (operator) {
      // TODO: Append different cases as 'IN' or things like that
      case '===':
      case '==':
      case '!==':
      case '!=':
      case '<':
      case '<=':
      case '>':
      case '>=':
      default:
        expression += `${field} ${operator} ${value}`;
        break;
    }
  });

  return expression;
};

/**
 * Shows a component if provided $dependency is null or undefined, or if the provided
 * $dependency is 'true' when evaluated as a js expression.
 * This is used in dynamic forms 'dependency' attribute.
 *
 * @param dependency
 * @param form
 */
export const showComponent = (dependency: Record<string, any> | undefined, form: Form): boolean => {
  let doShow = true;

  if (dependency !== undefined) {
    // eslint-disable-next-line no-eval
    doShow = eval(getExpression(dependency, form));
  }

  return doShow;
};

/**
 * Iterates through the anamnesis questions and, according to this method alertGenerator conditions,
 * returns the corresponding activated alerts as a string array.
 *
 * @param currentAnamnesis
 */
export const getAnamnesisAlerts = (currentAnamnesis: Form) => {
  const alerts: string[] = [];

  const alertGenerator = [
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.precisa_una_mejora_de_la_composicion_corporal_y_de_su_metabolismo_energetico',
          value: 'true',
        },
        {
          key: 'presenta_alguna_contraindicacion_para_iniciar_el_protocolo_de_tratamiento_proage.respuesta',
          value: 'no',
        },
      ],
      message: 'El paciente necesita el PROTOCOLO CAMBIO COMPOSICIÓN CORPORAL.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.precisa_una_mejora_de_la_composicion_corporal_y_de_su_metabolismo_energetico',
          value: 'true',
        },
        {
          key: 'presenta_alguna_contraindicacion_para_iniciar_el_protocolo_de_tratamiento_proage.respuesta',
          value: 'si',
        },
      ],
      message: 'Derivar al médico.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'no',
        },
      ],
      message: 'Derivar al médico.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'no',
        },
      ],
      message: 'Derivar al médico.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_intolerancias_alimenticias.respuesta',
          value: 'no',
        },
      ],
      message: 'Derivar al médico.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_intolerancias_alimenticias.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_valorado_la_necesidad_de_realizar_una_endoscopia_digestiva_o_una_prueba_de_imagen_radiologica.respuesta',
          value: 'no',
        },
      ],
      message: 'Derivar al médico.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_intolerancias_alimenticias.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_valorado_la_necesidad_de_realizar_una_endoscopia_digestiva_o_una_prueba_de_imagen_radiologica.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_tenido_en_cuenta_que_la_edad_es_un_factor_de_riesgo_de_enfermedades_organicas_graves_especialmente_a_partir_de_los_50_años.respuesta',
          value: 'no',
        },
      ],
      message: 'Derivar al médico.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_intolerancias_alimenticias.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_valorado_la_necesidad_de_realizar_una_endoscopia_digestiva_o_una_prueba_de_imagen_radiologica.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_tenido_en_cuenta_que_la_edad_es_un_factor_de_riesgo_de_enfermedades_organicas_graves_especialmente_a_partir_de_los_50_años.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'el_paciente_tiene_un_IMC_mayor_de_25.respuesta',
          value: 'si',
        },
      ],
      // eslint-disable-next-line max-len
      message: 'El paciente precisa PROTOCOLO DIGESTIVO Y COMPOSICIÓN CORPORAL (digestivo con dieta fodmap con cetosis).',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_intolerancias_alimenticias.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_valorado_la_necesidad_de_realizar_una_endoscopia_digestiva_o_una_prueba_de_imagen_radiologica.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_tenido_en_cuenta_que_la_edad_es_un_factor_de_riesgo_de_enfermedades_organicas_graves_especialmente_a_partir_de_los_50_años.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'el_paciente_tiene_un_IMC_mayor_de_25.respuesta',
          value: 'no',
        },
      ],
      message: 'El paciente precisa PROTOCOLO DIGESTIVO (digestivo con dieta fodmap sin cetosis).',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.precisa_fortalecer_su_sistema_inmune_o_normalizar_la_permeabilidad_intestinal',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'false',
        },
        {
          // eslint-disable-next-line max-len
          key: 'tiene_sintomas_digestivos.respuesta',
          value: 'si',
        },
      ],
      // eslint-disable-next-line max-len
      message: 'Por favor, haga clic en "Precisa una mejora de la composición corporal y de su metabolismo energético" y continúe respondiendo la anamnesis para poder determinar correctamente el resultado.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.precisa_fortalecer_su_sistema_inmune_o_normalizar_la_permeabilidad_intestinal',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'false',
        },
        {
          // eslint-disable-next-line max-len
          key: 'tiene_sintomas_digestivos.respuesta',
          value: 'no',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_realizado_una_evaluacion_adecuada_de_la_microbiota_y_de_la_permeabilidad_intestinal_del_paciente_mediante_estudio_de_microbiota_determinacion_de_DAO_determinacion_de_zonulina_determinacion_de_proteina_de_union_a_lipopolisacarido_bacteriano.respuesta',
          value: 'si',
        },
      ],
      // eslint-disable-next-line max-len
      message: 'La anamnesis sugiere un PROTOCOLO LONGEVIDAD-INMUNIDAD.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.precisa_fortalecer_su_sistema_inmune_o_normalizar_la_permeabilidad_intestinal',
          value: 'true',
        },
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'false',
        },
        {
          // eslint-disable-next-line max-len
          key: 'tiene_sintomas_digestivos.respuesta',
          value: 'no',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_realizado_una_evaluacion_adecuada_de_la_microbiota_y_de_la_permeabilidad_intestinal_del_paciente_mediante_estudio_de_microbiota_determinacion_de_DAO_determinacion_de_zonulina_determinacion_de_proteina_de_union_a_lipopolisacarido_bacteriano.respuesta',
          value: 'no',
        },
      ],
      // eslint-disable-next-line max-len
      message: 'Se deriva a su médico.',
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_intolerancias_alimenticias.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_valorado_la_necesidad_de_realizar_una_endoscopia_digestiva_o_una_prueba_de_imagen_radiologica.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_tenido_en_cuenta_que_la_edad_es_un_factor_de_riesgo_de_enfermedades_organicas_graves_especialmente_a_partir_de_los_50_años.respuesta',
          value: 'si',
          required: true,
        },
        {
          key: 'sufre_usted_de_migraña.respuesta',
          value: 'si',
        },
        {
          key: 'esta_cansado_se_levanta_cansado_tiene_la_sensacion_de_no_poder_acabar_el_dia.respuesta',
          value: 'si',
        },
        {
          key: 'tiene_dolores_erraticos_que_van_y_vienen.respuesta',
          value: 'si',
        },
      ],
      // eslint-disable-next-line max-len
      message: 'Tiene al menos un síntoma crítico del Síndrome de Sensibilidad Central. Se debe añadir quercetina y ubiquinol en un primer tratamiento o después de tratar su cuadro digestivo primero.',
      needed_positive_activators_number: 7,
    },
    {
      activators: [
        {
          // eslint-disable-next-line max-len
          key: 'cual_es_el_motivo_de_consulta.presenta_sintomas_digestivos_o_sospecha_de_intolerancia_alimentaria',
          value: 'true',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_la_presencia_de_sintomas_de_alarma_como_perdida_de_peso_excesiva_dolor_abdominal_intenso_anemia_sangrado_digestivo_masa_abdominal_adenopatias_fiebre_o_sintomas_nocturnos.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_enfermedades_organicas_mediante_estudios_analiticos.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_descartado_de_forma_razonable_intolerancias_alimenticias.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_valorado_la_necesidad_de_realizar_una_endoscopia_digestiva_o_una_prueba_de_imagen_radiologica.respuesta',
          value: 'si',
          required: true,
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_ha_tenido_en_cuenta_que_la_edad_es_un_factor_de_riesgo_de_enfermedades_organicas_graves_especialmente_a_partir_de_los_50_años.respuesta',
          value: 'si',
          required: true,
        },
        {
          key: 'tiene_o_ha_tenido_acufenos.respuesta',
          value: 'si',
        },
        {
          key: 'sufre_de_contracturas_musculares_de_repeticion.respuesta',
          value: 'si',
        },
        {
          key: 'tiene_piel_atopica_dermografismo__o_piel_muy_seca.respuesta',
          value: 'si',
        },
        {
          key: 'es_bruxista.respuesta',
          value: 'si',
        },
        {
          key: 'tiene_olvidos_frecuentes_niebla_mental_o_dificultad_para_concentrarse_o_memorizar.respuesta',
          value: 'si',
        },
        {
          key: 'esta_triste_o_ansioso.respuesta',
          value: 'si',
        },
        {
          key: 'duerme_bien_siente_que_el_sueño_es_reparador.respuesta',
          value: 'si',
        },
        {
          key: 'ha_sufrido_sincopes_alguna_vez.respuesta',
          value: 'si',
        },
        {
          key: 'se_le_duermen_los_dedos_de_las_manos_o_de_los_pies.respuesta',
          value: 'si',
        },
        {
          key: 'es_de_manos_y_pies_frios.respuesta',
          value: 'si',
        },
        {
          key: 'es_friolero_a.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'ha_tenido_infecciones_de_orina_de_repeticion_o_candidiasis_de_repeticion_o_molestias_al_orinar.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'es_sensible_a_los_ruidos_luces_u_olores.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'tiene_piernas_inquietas.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'le_salen_hematomas_al_mas_minimo_roce.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'en_el_caso_de_las_mujeres_tiene_reglas_dolorosas_y_o_endometriosis.respuesta',
          value: 'si',
        },
        {
          // eslint-disable-next-line max-len
          key: 'se_recupera_bien_cuando_hace_ejercicio.respuesta',
          value: 'si',
        },
      ],
      // eslint-disable-next-line max-len
      message: 'Tiene al menos 3 síntomas no-críticos del Síndrome de Sensibilidad Central. Se debe añadir quercetina y ubiquinol en un primer tratamiento o después de tratar su cuadro digestivo primero.',
      needed_positive_activators_number: 9,
    },
  ];

  /*
       We now iterate through all activators. There are required and no required activators, and also
       a 'needed_positive_activators_number'. Every required activator of a condition must be activated
       in order to consider the condition as valid, but it also must fit the 'needed_positive_activators_number'.
       This means:
        - A condition with 8 activated, 3 needed, but 1 of required ones is not ok, it won't be valid.
        - A condition with 2 activated, 3 needed, and all required ones are ok, it won't be valid.
        - A condition with 4 activated, 3 needed, and all required ones are ok, it will be valid.
       */
  alertGenerator.forEach((condition, index) => {
    let requiredAreActivated = true;
    let successfullActivators = 0;
    let resp = null as any;

    condition.activators.forEach((activator) => {
      const keysAux = activator.key.split('.');
      const formGroup = currentAnamnesis?.formGroupByName(keysAux[0]);
      if (!!formGroup) {
        resp = formGroup.formValueByType(keysAux[1]);

        // There is an intrinsic AND operator
        if (!!resp.value && resp.value === activator.value) {
          if ('required' in resp && !resp.required) {
            // A required activator is not ok, so it will be never fired
            requiredAreActivated = false;
          }
          successfullActivators += 1;
        }
      }
    });

    // Needed positive activators to be successfull. By default, all those activators
    // eslint-disable-next-line max-len
    const neededPositiveActivatorsNumber = condition.needed_positive_activators_number ?? condition.activators.length;

    // eslint-disable-next-line max-len
    if (successfullActivators >= neededPositiveActivatorsNumber && !alerts.includes(condition.message) && requiredAreActivated) {
      alerts.push(condition.message);
    }
  });

  // TODO: Now process new alert system based on points
  const from0to4values = [
    'cansado_y_desanimado_por_las_mananas', 'musculos_tensos_y_doloridos', 'tengo_ataques_de_panico',
    'rechino_dientes_o_aprieto_mandibula', 'tengo_diarrea_o_estrenimiento',
    'necesito_ayuda_para_actividades_diarias', 'soy_sensible_a_la_luz_brillante',
    'me_canso_facilmente_cuando_fisicamente_activo', 'siento_dolor_en_todo_mi_cuerpo',
    'tengo_dolores_de_cabeza', 'tengo_molestia_en_vejiga_o_sensacion_de_quemazon_al_orinar',
    'no_duermo_bien', 'tengo_dificultad_para_concentrarme',
    'tengo_problema_en_la_piel_como_resequedad_picor_o_sarpullido',
    'el_estres_hace_que_mis_sintomas_fisicos_empeoren', 'me_siento_triste_o_deprimido',
    'me_siento_con_poca_energia', 'tengo_tension_muscular_en_mi_cuello_y_hombros',
    'tengo_dolor_en_mi_mandibula',
    'algunos_olores_como_perfumes_me_hacen_sentir_mareado_y_con_nauseas',
    'tengo_que_orinar_frecuentemente',
    'mis_piernas_se_sienten_incomodas_e_inquietas_cuando_intento_dormir_por_la_noche',
    'tengo_dificultad_para_recordar_cosas', 'sufri_algun_trauma_cuando_era_nino',
    'tengo_dolor_en_mi_zona_pelvica',
  ];

  let sum = 0;
  from0to4values.forEach((formGroupName) => {
    const formGroup = currentAnamnesis?.formGroupByName(formGroupName);
    if (!!formGroup) {
      const resp = formGroup.formValueByType('respuesta');
      if (!!resp && !!resp.value) {
        switch (resp.value) {
          case 'siempre':
            sum += 4;
            break;
          case 'continuamente':
            sum += 3;
            break;
          case 'algunas_veces':
            sum += 2;
            break;
          case 'pocas_veces':
            sum += 1;
            break;
          case 'nunca':
          default:
            sum += 0;
            break;
        }
      }
    }
  });

  if (sum > 40) {
    alerts.push(`El paciente tiene indicios de padecer el Síndrome de Sensibilidad Central. El CSI es de: ${sum}`);
  }

  return alerts;
};
