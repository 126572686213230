import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { PatientState } from '@/store/modules/patient/patient.types';
import api from '@/api';

type Context = ActionContext<PatientState, State>;

const actions: ActionTree<PatientState, State> = {
  async getProfessional({
    state,
    commit,
  }: Context) {
    if (state.currentPatient === null) {
      try {
        const patient = await api.professionalData.profile();
        commit('setCurrentPatient', patient);
      } catch (e) {
        commit('setCurrentPatient', null);
      }
    }
  },
};

export default actions;
