
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import VMultipleButtonSelect from '@/components/vendor/basic/button/VMultipleButtonSelect.vue';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import useCapabilities from '@/resources/capabilities';
import Form from '@/api/objects/Form';
import TheFormNutritionalAnamnesis
  from '@/views/professional/anamnesis/parts/TheFormNutritionalAnamnesis.vue';
import TheFormAnamnesis from '@/views/professional/anamnesis/parts/TheFormAnamnesis.vue';
import TheAnamnesisResultsModal from '@/views/professional/anamnesis/TheAnamnesisResultsModal.vue';
import VLoader from '@/components/vendor/VLoader.vue';

export default defineComponent({
  name: 'TheProfessionalExpedientAnamnesisTab',
  components: {
    VLoader,
    TheAnamnesisResultsModal,
    TheFormAnamnesis,
    TheFormNutritionalAnamnesis,
    VButton,
    VMultipleButtonSelect,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: '',
      view: 'nutritional_anamnesis',
      anamnesis_loaded: false,
      anamnesisAlerts: [] as string[],
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'currentNutritionalAnamnesis', 'currentAnamnesis']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('app', ['formsSettings']),
    currentAnamnesisStructure(): Form {
      const formStructures = this.formsSettings.forms.find((obj) => obj.type === 'anamnesis'
        && obj.version === this.currentAnamnesis.version);

      return formStructures.structure;
    },
    currentNutritionalAnamnesisStructure(): Form {
      const formStructures = this.formsSettings.forms.find((obj) => obj.type === 'anamnesis_nutricional'
        && obj.version === this.currentNutritionalAnamnesis.version);

      return formStructures.structure;
    },
    lastAnamnesisStructureVersion(): number {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === 'anamnesis');

      const lastFormStructure = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      const lastFormStructureVersion = lastFormStructure.structure;

      return lastFormStructureVersion.version;
    },
    lastNutritionalAnamnesisStructureVersion(): number {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === 'anamnesis_nutricional');

      const lastFormStructure = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      const lastFormStructureVersion = lastFormStructure.structure;

      return lastFormStructureVersion.version;
    },
    lastFormStructure(): Form {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === 'medidas');

      const lastFormStructureVersion = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      return lastFormStructureVersion.structure;
    },
  },
  beforeMount() {
    this.startNutritionalAnamnesis();
  },
  methods: {
    handleAnamnesisAlertsUpdatedEvent(alerts) {
      this.anamnesisAlerts = alerts;
    },
    async chooseNutritionalAnamnesis() {
      this.view = 'nutritional_anamnesis';
      await this.startNutritionalAnamnesis();
    },
    async chooseProtocolAnamnesis() {
      if (this.currentTreatment) {
        await this.$modal.info({
          title: this.$t('Tratamiento en curso'),
          // eslint-disable-next-line max-len
          text: this.$t('Para responder la anamnesis de protocolo no puede haber un tratamiento en curso. Finaliza el tratamiento actual para poder responderla nuevamente.'),
        });
      } else {
        this.view = 'protocol_anamnesis';
        await this.startAnamnesis();

        // We first force it to load alerts
        // this.checkAlerts();

        // We then search if a new version is available
        if (this.currentAnamnesisStructure.version < this.lastAnamnesisStructureVersion) {
          await this.$modal.confirm({
            title: this.$t('Hay una nueva versión de anamnesis'),
            // eslint-disable-next-line max-len
            text: this.$t('Estás respondiendo a una anamnesis que no es la última versión disponible. Puedes actualizarla y conservaremos todas las respuestas que se hayan mantenido en la nueva versión, o puedes continuar respondiendo a la antigua. ¿Quieres actualizar a la nueva versión?'),
            confirmButtonText: this.$t('Actualizar'),
            confirmButtonCallback: this.doUpgradeAnamnesis,
          });
        }
      }
    },
    openTheAnamnesisResultsModal() {
      this.modalToShow = '';

      setTimeout(() => {
        this.modalToShow = 'the-anamnesis-results-modal';
      }, 500);
    },
    async startNutritionalAnamnesis() {
      try {
        this.anamnesis_loaded = false;

        // eslint-disable-next-line max-len
        const nutritionalAnamnesis = await api.professional.expedients.anamnesis.startNutritional(this.currentExpedient.id);

        await this.$store.dispatch('expedient/setCurrentNutritionalAnamnesis', {
          nutritionalAnamnesis,
        });

        this.anamnesis_loaded = true;
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al empezar la anamnesis'));
      }
    },
    async startAnamnesis() {
      try {
        this.anamnesis_loaded = false;

        const anamnesis = await api.professional.expedients.anamnesis.start(this.currentExpedient.id);

        await this.$store.dispatch('expedient/setCurrentAnamnesis', {
          anamnesis,
        });

        this.anamnesis_loaded = true;
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al empezar la anamnesis'));
      }
    },
    async doUpgradeAnamnesis() {
      try {
        const anamnesis = await api.professional.expedients.anamnesis.upgrade(
          this.currentExpedient.id,
          this.currentAnamnesis.id,
        );

        await this.$store.dispatch('expedient/setCurrentAnamnesis', {
          anamnesis,
        });

        this.$toast.success(this.$t('Se ha actualizado tu anamnesis con la nueva versión'));
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al actualizar la anamnesis'));
      }
    },
  },
});
