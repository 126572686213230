
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalExpedientCreate',
  components: {
    VFormGroupInputDate,
    VFormGroupInputText,
    VFormGroupSelect,
    VButton,
    vSelect,
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  data() {
    return {
      patient: {
        name: null,
        surname: null,
        identity_doc_type: null,
        nif: null,
        genre: null,
        born_date: null,
        // We set a whitespace to be able to distinguish the prefix
        phone_number: '',
        email: null,
      },
    };
  },
  mounted() {
    this.phoneNumberPrefix = '34';
    // CARE: Carefull because this hardcoded value could change in API
    this.identityDocType = 'dni';
    this.genre = 'not_specific';
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('Nombre')),
      surname: yup.string()
        .required()
        .label(t('Apellidos')),
      identityDocType: yup.string()
        .required()
        .label(t('Tipo de Documento')),
      nif: yup.lazy((value, options) => {
        // initialize default rules for all options
        const rule = yup.string()
          .required()
          .label(t('Número de documento'));
        // this property retrieves the information of the datasets initialized with yup
        const { identityDocType } = options.parent;
        const messageValidation = `El ${identityDocType} registrado no es Válido`;
        switch (identityDocType) {
          case 'dni':
            return rule.matches(/^[0-9]{8,8}[A-Za-z]$/g, messageValidation);
          case 'nie':
            return rule.matches(/^[XYZ|xyz]\d{7,8}[A-Za-z]$/, messageValidation);
          default:
            return rule; // here you can decide what is the default
        }
      }),
      genre: yup.string()
        .required()
        .label(t('Género')),
      bornDate: yup.date()
        .required()
        .max(new Date(), t('La fecha de nacimiento no puede ser futura'))
        .label(t('Fecha de nacimiento')),
      phoneNumberPrefix: yup.string()
        .required()
        .label(t('Prefijo telefónico')),
      phoneNumber: yup.string()
        .required()
        .label(t('Número de teléfono'))
        .matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras')),
      email: yup.string()
        .email()
        .label(t('Correo electrónico')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: identityDocType } = useField('identityDocType');
    const { value: nif } = useField('nif');
    const { value: genre } = useField('genre');
    const { value: bornDate } = useField('bornDate');
    const { value: phoneNumberPrefix } = useField('phoneNumberPrefix');
    const { value: phoneNumber } = useField('phoneNumber');
    const { value: email } = useField('email');

    return {
      ...form,
      name,
      surname,
      nif,
      genre,
      bornDate,
      identityDocType,
      phoneNumberPrefix,
      phoneNumber,
      email,
      ...useCapabilities(),
    };
  },
  methods: {
    async createPatient() {
      this.toggleAccepted();

      this.patient = {
        name: this.values.name,
        surname: this.values.surname,
        identity_doc_type: this.values.identityDocType,
        nif: this.values.nif.toUpperCase(),
        genre: this.values.genre,
        born_date: this.values.bornDate,
        // We set a whitespace to be able to distinguish the prefix
        phone_number: `${this.values.phoneNumberPrefix} ${this.values.phoneNumber}`,
        email: this.values.email,
      };

      if (!this.patient.email) {
        // Empty strings or just blank spaces are avoid
        this.patient.email = null;
      }

      try {
        /*
        TODO: Be more exhaustive when parsing the phone number as here is the only step in which
        We will take care that is a valid phone number so the RGPD SMS goes to him
         */
        const expedient = await api.professional.expedient.create(this.patient);

        this.$toast.success(this.$t('El paciente se ha creado correctamente'));

        await this.$router.replace({
          name: 'professional.patient.rgpd',
          params: { expedient: expedient.id },
          query: { comesFromCreation: 'true' },
        });

        this.toggleAccepted();
      } catch (e) {
        // eslint-disable-next-line max-len
        if (e.response.data?.errors?.phone_number && e.response.data?.errors?.phone_number[0] === 'The phone number has already been taken.') {
          this.$toast.error(this.$t('El número de teléfono ya existe en nuestra base de datos, prueba con otro'));
        } else if (e.response.data?.code_error === 'expedient_patient_already_exists') {
          this.$toast.error(this.$t('Ya tienes registrado a un paciente con ese número de identificación.'));
        } else {
          this.$toast.error(this.$t('Ha ocurrido un error al añadir el nuevo paciente'));
        }

        this.toggleAccepted();
      }
    },
  },
});
