import AbstractService from '@/api/services/AbstractService';
import CalendarEvent from '@/api/objects/CalendarEvent';

class CalendarEventService extends AbstractService {
  public async all(): Promise<CalendarEvent[]> {
    return this.request('get', this.buildPath('calendar-events/owner'));
  }

  public async create(calendarEventData: any): Promise<CalendarEvent> {
    return this.request('post', this.buildPath('calendar-event'), [], calendarEventData);
  }

  public async update(calendarEventId: number, calendarEventData: any): Promise<CalendarEvent> {
    return this.request('put', this.buildPath('calendar-event/{calendarEventId}',
      {
        calendarEventId,
      }), [], calendarEventData);
  }

  public async delete(calendarEventId: number) {
    return this.request('delete', this.buildPath('calendar-event/{calendarEventId}', {
      calendarEventId,
    }));
  }
}

export default new CalendarEventService();
