import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { TreatmentState } from '@/store/modules/treatment/treatment.types';
import api from '@/api';

type Context = ActionContext<TreatmentState, State>;

const actions: ActionTree<TreatmentState, State> = {
  async getTreatment({
    state,
    commit,
  }: Context, {
    expedientId,
    treatmentId,
  }: { expedientId: number, treatmentId: number }) {
    if (!treatmentId || !expedientId) {
      commit('setTreatment', null);
    } else if (!state.currentTreatment || state.currentTreatment.id !== treatmentId
      || state.currentTreatment.id === treatmentId) {
      const treatment = await api.patient.expedient.treatments.retrieve(expedientId, treatmentId);

      commit('setTreatment', treatment);
    }
  },
  async setCurrentTreatment({
    commit,
  }: Context, {
    treatment,
  }: { treatment: Record<string, unknown> | null }) {
    commit('setTreatment', treatment);
  },

  async refreshExpedientTreatments({
    state,
    commit,
    dispatch,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    const expedientTreatments = await api.patient.expedient.treatments.all(expedientId);

    commit('setTreatments', expedientTreatments);

    const treatment = expedientTreatments.find((t) => t.status === 'active');

    await dispatch('treatment/setCurrentTreatment', { treatment }, { root: true });
  },
};

export default actions;
