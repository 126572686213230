import Treatment from '@/api/objects/Treatment';
import AbstractService from '@/api/services/AbstractService';

class TreatmentService extends AbstractService {
  public async all(expedientId: number): Promise<Treatment[]> {
    return this.request('get', this.buildPath('expedient/{expedientId}/treatments', { expedientId }));
  }

  public async allPaginated(expedientId: number, filters: any): Promise<Treatment[]> {
    return this.request('get', this.buildPath('expedient/{expedientId}/treatments', { expedientId }), filters);
  }

  public async retrieve(expedientId: number, treatmentId: number): Promise<Treatment> {
    return this.request('get', this.buildPath('expedient/{expedientId}/treatment/{treatmentId}', {
      expedientId,
      treatmentId,
    }));
  }

  public async create(expedientId: any): Promise<Treatment> {
    return this.request('post', this.buildPath('expedient/{expedientId}/treatment', { expedientId }));
  }

  public async finish(expedientId: number, treatmentId: number, data: any): Promise<Treatment> {
    return this.request('post', this.buildPath('expedient/{expedientId}/treatment/{treatmentId}/finish',
      {
        expedientId,
        treatmentId,
      }), [], data);
  }

  public async change(expedientId: number, treatmentId: number, protocolPhaseId: number): Promise<Treatment> {
    return this.request('post',
      this.buildPath('expedient/{expedientId}/treatment/{treatmentId}/change-phase/{protocolPhaseId}',
        {
          expedientId,
          treatmentId,
          protocolPhaseId,
        }));
  }
}

export default new TreatmentService();
