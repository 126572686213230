import AbstractService from '@/api/services/AbstractService';
import InterConsultation from '@/api/objects/InterConsultation';

class InterConsultationService extends AbstractService {
  // TODO: Maybe @deprecated?
  public async getIndexByCreator(filters?: any): Promise<InterConsultation[]> {
    return this.request('get', this.buildPath('inter-consultations/creator'), filters);
  }

  // TODO: Maybe @deprecated?
  public async getIndexByTarget(filters?: any): Promise<InterConsultation[]> {
    return this.request('get', this.buildPath('inter-consultations/target'), filters);
  }

  public async getIndexByCreatorOrTarget(filters?: any): Promise<InterConsultation[]> {
    return this.request('get', this.buildPath('inter-consultations/creator-or-target'), filters);
  }

  public async store(data: any): Promise<InterConsultation> {
    return this.request('post', this.buildPath('inter-consultation'), [], data);
  }

  public async update(interConsultationId: number, data: any): Promise<InterConsultation> {
    return this.request('put', this.buildPath('inter-consultation/{interConsultationId}',
      {
        interConsultationId,
      }), [], data);
  }

  public async finalize(interConsultationId: number): Promise<InterConsultation> {
    return this.request('post', this.buildPath('inter-consultation/{interConsultationId}/finalize',
      {
        interConsultationId,
      }));
  }

  public async delete(interConsultationId: number) {
    return this.request('delete', this.buildPath('inter-consultation/{interConsultationId}', {
      interConsultationId,
    }));
  }

  public async storeComment(interConsultationId: number, data: any): Promise<Comment> {
    return this.request('post', this.buildPath('inter-consultation/{interConsultationId}/comment',
      {
        interConsultationId,
      }), [], data);
  }

  public async deleteComment(interConsultationId: number, commentId: number) {
    return this.request('delete', this.buildPath('inter-consultation/{interConsultationId}/comment/{commentId}', {
      interConsultationId,
      commentId,
    }));
  }
}

export default new InterConsultationService();
