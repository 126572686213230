import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { TreatmentState } from '@/store/modules/treatment/treatment.types';
import api from '@/api';
import Treatment from '@/api/objects/Treatment';

type Context = ActionContext<TreatmentState, State>;

const actions: ActionTree<TreatmentState, State> = {
  async getTreatment({
    state,
    commit,
  }: Context, {
    expedientId,
    treatmentId,
  }: { expedientId: number, treatmentId: number }) {
    if (!treatmentId || !expedientId) {
      commit('setTreatment', null);
    } else if (!state.currentTreatment || state.currentTreatment.id !== treatmentId
      || state.currentTreatment.id === treatmentId) {
      const treatment = await api.professional.expedientData.treatments.retrieve(expedientId, treatmentId);

      commit('setTreatment', treatment);
    }
  },
  async setCurrentTreatment({
    commit,
  }: Context, {
    treatment,
  }: { treatment: Treatment | null }) {
    commit('setTreatment', treatment);
  },
};

export default actions;
