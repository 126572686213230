import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input = _resolveComponent("v-input")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formGroupStructure.form_values, (formValueStructure, index) => {
    return (_openBlock(), _createElementBlock("td", { key: index }, [
      _createVNode(_component_v_input, {
        class: "input-hidden",
        "yup-errors-variable": _ctx.errors[formValueStructure.key],
        "input-class": _ctx.inputClass,
        id: formValueStructure.key,
        name: formValueStructure.key,
        type: formValueStructure.type,
        suffix: formValueStructure.suffix,
        "suffix-class": _ctx.suffixClass,
        "prefix-class": _ctx.prefixClass,
        "force-save": _ctx.forceSave,
        "action-buttons": _ctx.actionButtons,
        "adjust-input-for-value": _ctx.adjustInputForValue,
        disabled: !_ctx.can('professional.expedient.form-group.update'),
        "yup-errors-class": "ps-8",
        placeholder: " - ",
        value: !!_ctx.formGroupData.formValueByType(formValueStructure.key) ?
                _ctx.formGroupData.formValueByType(formValueStructure.key).value : '',
        "onUpdateYup:value": ($event: any) => (this[formValueStructure.key] = $event),
        "onOldValue:value": ($event: any) => (_ctx.emitOldValue(_ctx.formGroupData.formValueByType(formValueStructure.key)?.id, $event)),
        "onUpdate:value": (value) => _ctx.setLocalValue(value, _ctx.formGroupData.id,
                                    _ctx.formGroupData.formValueByType(formValueStructure.key)?.id)
      }, null, 8, ["yup-errors-variable", "input-class", "id", "name", "type", "suffix", "suffix-class", "prefix-class", "force-save", "action-buttons", "adjust-input-for-value", "disabled", "value", "onUpdateYup:value", "onOldValue:value", "onUpdate:value"])
    ]))
  }), 128))
}