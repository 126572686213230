
import { defineComponent } from 'vue';
import api from '@/api';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import VFormRow from '@/views/professional/expedients/medicalData/forms/parts/VFormRow.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'TheMeasuresTableView',
  components: {
    VButton,
    VFormRow,
    VTableBody,
    VTableHeader,
    VTable,
  },
  props: {
    formName: {
      type: String,
      required: true,
    },
    measures: {
      type: Array,
      required: true,
    },
    formStructure: {
      type: Object,
      required: false,
    },
    forceSave: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('app', ['formsSettings']),
    lastFormStructure(): any {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === this.formName);

      const lastFormStructureVersion = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      return lastFormStructureVersion.structure;
    },
  },
  data() {
    return {
      formGroupIdSelected: -1,
      modalToShow: null,
    };
  },
  methods: {
    /**
     * Forces storing a formGroup when. This is to be done when fast access element is clicked.
     *
     * @param formGroupName
     * @param values
     */
    async createFormGroup(formGroupName, values) {
      let formData: any = {};

      formData = {
        form_key: this.formName,
        form_group_name: formGroupName,
        form_values: values,
      };

      // TODO: Please, refactor this, as it exist just like this in another component
      try {
        await api.professional.expedients.forms.create(this.currentExpedient.id, formData);

        this.$toast.success(this.$t('Los datos se han guardado correctamente.'));

        await this.$store.dispatch('expedient/refreshExpedientForms', {
          expedientId: this.currentExpedient.id,
        });

        this.$emit('load-expedient-forms-requested');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al guardar los datos'));
      }
    },

    // eslint-disable-next-line consistent-return
    async doDeleteFormDate(formGroupId: number) {
      try {
        await api.professional.expedients.forms.delete(
          this.currentExpedient.id,
          formGroupId,
        );

        this.$toast.success(this.$t('Los datos se han eliminado correctamente'));

        this.emitLoadExpedientFormsRequestEvent();
      } catch (e) {
        return {
          error: this.$t('Ha ocurrido un error al eliminar los datos'),
        };
      }
    },
    async confirmDeleteFormGroup(formGroupId) {
      await this.$modal.delete({
        title: this.$t('¿Seguro que desea eliminar este dato?'),
        text: this.$t('Al borrar el dato no podrás volver a visualizarlo'),
        deleteButtonText: this.$t('Eliminar'),
        textLayout: null,
        deleteButtonCallback: () => this.doDeleteFormDate(formGroupId),
      });
    },
    emitLoadExpedientFormsRequestEvent() {
      this.$emit('load-expedient-forms-requested');
    },
  },
});
