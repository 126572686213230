
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VThumb',
  props: {
    uploadInfo: {
      required: true,
      type: Object,
    },
    validationRules: {
      required: false,
      type: Object,
    },
    imgProps: {
      required: true,
      type: Object,
    },
  },
  emits: ['replaced'],
  data() {
    return {
      imageErrored: false,
      oldImgSrc: this.imgProps.src,
    };
  },
  watch: {
    imgProps: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.oldImgSrc !== val.src) {
          this.imageErrored = false;
        }
      },
    },
  },
});
