
import { defineComponent, PropType } from 'vue';
import useCapabilities from '@/resources/capabilities';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import useFormValidation from '@/resources/form';
import * as yup from 'yup';
import { useField } from 'vee-validate';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import api from '@/api';
import { DateTime } from 'luxon';
import VFormGroupInputCheckbox from '@/components/vendor/basic/form/VFormGroupInputCheckbox.vue';
import CalendarEvent from '@/api/objects/CalendarEvent';
import getPatientsOptions from '@/helpers/selectOptionsHelper';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalCalendarEventUpdate',
  components: {
    VFormGroupReadOnlyText,
    VAlert,
    VFormGroupInputCheckbox,
    VFormGroupInputDate,
    VFormGroupSelect,
    VFormGroupInputText,
    VButton,
    VModal,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    vSelect,
  },
  props: {
    calendarEvent: {
      type: Object as PropType<CalendarEvent>,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      title: yup.string()
        .required()
        .label(t('Asunto')),
      type: yup.string()
        .required()
        .label(t('Tipo')),
      date: yup.string()
        .required()
        .label(t('Fecha')),
      startTime: yup.string()
        .required()
        .label(t('Inicio')),
      endTime: yup.string()
        .required()
        .label(t('Fin')),
      description: yup.string()
        .nullable()
        .label(t('Descripción')),
      patientId: yup.number()
        .nullable()
        .label(t('Paciente')),
      clinicId: yup.number()
        .nullable()
        .label(t('Clínica')),
    });

    const form = useFormValidation(rules);

    const { value: title } = useField('title');
    const { value: type } = useField('type');
    const { value: date } = useField('date');
    const { value: startTime } = useField('startTime');
    const { value: endTime } = useField('endTime');
    const { value: description } = useField('description');
    const { value: patientId } = useField('patientId');
    const { value: clinicId } = useField('clinicId');

    return {
      ...useCapabilities(),
      ...form,
      title,
      type,
      date,
      startTime,
      endTime,
      description,
      patientId,
      clinicId,
    };
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
    startTimeChanged() {
      return this.startTime !== this.oldStartTime || this.date !== this.oldDate;
    },
    consultationType() {
      const type = this.formsSettings.calendar_event_type_options.find((o) => o.key === this.type);

      return type.label;
    },
    isAuthor() {
      return this.calendarEvent.authorId === this.authUser.id;
    },
    /**
     * Interval of minutes we set between each time space (for startTime and endTime)
     */
    minutesInterval() {
      return 15;
    },
    startTimeOptions() {
      const options = [] as any[];

      for (let hour = 0; hour < 24; hour += 1) {
        for (let minute = 0; minute < 60; minute += 15) {
          const strHour = hour < 10 ? `0${hour}` : `${hour}`;
          const strMinute = minute < 10 ? `0${minute}` : `${minute}`;

          const object = {
            key: `${strHour}:${strMinute}`,
            label: `${strHour}:${strMinute}`,
          };

          options.push(object);
        }
      }

      return options;
    },
    endTimeOptions() {
      // TODO: Preserve previously endTime chosen if possible (?) Maybe better for UX (?)
      const options = [] as any[];

      // 'HH:mm' to [HH, mm]
      const startTimeSplitted = (this.startTime as string).split(':');

      let onlyLastIntervalAvailable = false;

      // We start from the startTime
      const start = DateTime.fromISO(`${this.date}T${startTimeSplitted[0]}:${startTimeSplitted[1]}:00`);
      let iterator = DateTime.fromISO(`${this.date}T${startTimeSplitted[0]}:${startTimeSplitted[1]}:00`);
      if (start.hour === 23 && start.minute === (60 - this.minutesInterval)) {
        // If they selected 23:45, we need to push the 23:59 only
        iterator = start.plus({ minute: this.minutesInterval - 1 });
        onlyLastIntervalAvailable = true;
      } else {
        iterator = start.plus({ minute: this.minutesInterval });
      }

      while (iterator.day === start.day) {
        // We format it to 00:00 format, filling spaces with zeros
        const strHour = iterator.hour < 10 ? `0${iterator.hour}` : `${iterator.hour}`;
        const strMinute = iterator.minute < 10 ? `0${iterator.minute}` : `${iterator.minute}`;

        options.push({
          key: `${strHour}:${strMinute}`,
          label: `${strHour}:${strMinute}`,
        });

        iterator = iterator.plus({ minute: this.minutesInterval });
      }

      if (!onlyLastIntervalAvailable) {
        // Special option that is the last available on a day 23:59
        options.push({
          key: '23:59',
          label: '23:59',
        });
      }

      return options;
    },
    isConsultation() {
      return this.type === 'primera_consulta' || this.type === 'videoconsulta' || this.type === 'revision';
    },
    isVideoconsulta() {
      return this.type === 'videoconsulta';
    },
  },
  watch: {
    startTime() {
      let tmpDate: string | DateTime | null | unknown = null;

      if (this.date instanceof DateTime) {
        tmpDate = this.date.toISODate();
      } else {
        tmpDate = this.date;
      }

      let start = DateTime.fromISO(`${tmpDate}T${this.startTime}:00`);

      if (start.hour === 23 && start.minute === (60 - this.minutesInterval)) {
        start = start.plus({ minute: this.minutesInterval - 1 });
      } else {
        start = start.plus({ minute: this.minutesInterval });
      }

      this.endTime = `${this.fillWithZeroAtBegining(start.hour)}:${this.fillWithZeroAtBegining(start.minute)}`;
    },
  },
  data() {
    return {
      isAllDayActivated: false,
      availablePatients: [] as any[],
      status: 'loading',
      oldStartTime: null as any,
      oldDate: null as any,
    };
  },
  beforeMount() {
    this.type = 'primera_consulta';
    this.replicateData();
    this.loadAvailablePatients();
  },
  mounted() {
    // TODO: This is maybe unnecessary
    this.type = 'primera_consulta';
    this.replicateData();
  },
  methods: {
    async loadAvailablePatients() {
      this.availablePatients = await getPatientsOptions();

      this.status = 'loaded';
    },
    async replicateData() {
      // TODO: Replicate received data from object to update it just temporary
      this.title = this.calendarEvent.title;
      this.type = this.calendarEvent.type;
      this.description = this.calendarEvent.description;

      // Parse database date to local
      const startDate = DateTime.fromISO(this.calendarEvent.startDatetime.replace(' ', 'T'));
      const endDate = DateTime.fromISO(this.calendarEvent.endDatetime.replace(' ', 'T'));

      // Now set date, startTime and endTime
      // eslint-disable-next-line max-len
      this.date = `${startDate.year}-${this.fillWithZeroAtBegining(startDate.month)}-${this.fillWithZeroAtBegining(startDate.day)}`;
      // eslint-disable-next-line max-len
      this.startTime = `${this.fillWithZeroAtBegining(startDate.hour)}:${this.fillWithZeroAtBegining(startDate.minute)}`;
      // eslint-disable-next-line max-len
      this.oldStartTime = `${this.fillWithZeroAtBegining(startDate.hour)}:${this.fillWithZeroAtBegining(startDate.minute)}`;
      this.endTime = `${this.fillWithZeroAtBegining(endDate.hour)}:${this.fillWithZeroAtBegining(endDate.minute)}`;
      this.oldDate = this.date;

      // Relations
      this.patientId = this.calendarEvent.patientId;
      this.clinicId = this.calendarEvent.clinicId;
    },
    async updateCalendarEvent() {
      try {
        this.toggleAccepted();

        let start = DateTime.fromISO(`${this.date}T${this.startTime}:00`);
        let end = DateTime.fromISO(`${this.date}T${this.endTime}:00`);

        if (this.isAllDayActivated) {
          start = DateTime.fromISO(`${this.date}T00:00:00`);
          end = DateTime.fromISO(`${this.date}T23:59:00`);
        }

        const calendarEventData: any = {
          title: this.title,
          description: this.description ?? '',
          type: this.type,
          start_datetime: start.toUTC()
            .toFormat('yyyy-MM-dd HH:mm:ss'),
          end_datetime: end.toUTC()
            .toFormat('yyyy-MM-dd HH:mm:ss'),
        };

        if (this.isConsultation) {
          calendarEventData.patient_id = this.patientId;

          // We don't send clinic (as we also don't show it) if it is 'videoconsulta'
          if (!this.isVideoconsulta) {
            calendarEventData.clinic_id = this.clinicId;
          }
        }

        await api.professional.calendarEvent.update(this.calendarEvent.id, calendarEventData);

        this.$toast.success(this.$t('Se ha actualizado el evento de calendario con éxito.'));

        this.$emit('calendar-event-updated');

        this.closeModal();
      } catch (e) {
        this.toggleAccepted();

        this.$toast.error(this.$t('Ha ocurrido un error al modificar el evento de calendario.'));
      }
    },
    handleAllDayActivatedCheckboxClick(e) {
      if (e === 'true') {
        this.isAllDayActivated = true;
      } else if (e === 'false') {
        this.isAllDayActivated = false;
      }
    },
    async confirmDelete() {
      // eslint-disable-next-line max-len
      let text = this.$t('Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}". ¿Estás seguro?', { tipo_consulta: this.consultationType, titulo: this.title });
      // eslint-disable-next-line max-len
      if (this.calendarEvent.patientId && this.isConsultation) {
        // eslint-disable-next-line max-len
        text = this.$t('Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}" con el paciente {nombre_paciente} {apellido_paciente}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?', {
          tipo_consulta: this.consultationType,
          titulo: this.title,
          nombre_paciente: this.calendarEvent.patient?.user?.name,
          apellido_paciente: this.calendarEvent.patient?.user?.surname,
        });
      }

      await this.$modal.confirm({
        title: this.$t('Eliminar evento'),
        text,
        confirmButtonText: this.$t('Eliminar'),
        confirmButtonCallback: this.doDeleteCalendarEvent,
      });
    },
    async doDeleteCalendarEvent() {
      try {
        await api.professional.calendarEvent.delete(this.calendarEvent.id);

        this.$toast.success(this.$t('Se ha eliminado el evento de calendario con éxito.'));

        this.$emit('calendar-event-deleted');

        this.closeModal();
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al eliminar el evento de calendario.'));
      }
    },
    fillWithZeroAtBegining(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
