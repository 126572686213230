
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import { mapState } from 'vuex';
import Product from '@/api/objects/Product';
import Protocol from '@/api/objects/Protocol';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import vSelect from 'vue-select';
import TreatmentProtocolPhase from '@/api/objects/TreatmentProtocolPhase';
import VProtocolName from '@/components/vendor/VProtocolName.vue';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import { DateTime } from 'luxon';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ThePurchaseAuthorizationCreate',
  props: {
    consultationId: {
      type: Number,
      required: false,
    },
  },
  components: {
    VAlert,
    VProtocolName,
    VFormGroupInputText,
    ModalFooter,
    VFormGroupSelect,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    vSelect,
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      protocol: yup.string()
        .nullable()
        .label(t('Protocolo')),
      phase: yup.string()
        .nullable()
        .label(t('Fase')),
      treatmentDuration: yup.number()
        .required()
        .label(t('Duración del tratamiento')),
      posology: yup.number()
        .required()
        .nullable()
        .label(t('Posología')),
      productList: yup.array()
        .required()
        .nullable()
        .min(1)
        .label(t('Productos')),
    });

    const form = useFormValidation(rules, {
      productList: [],
      treatmentDuration: 15,
      posology: 5,
    });

    const { value: protocol } = useField('protocol');
    const { value: phase } = useField('phase');
    const { value: treatmentDuration } = useField('treatmentDuration');
    const { value: posology } = useField('posology');
    const { value: productList } = useField<any[]>('productList');

    return {
      ...form,
      protocol,
      phase,
      treatmentDuration,
      posology,
      productList,
      ...useCapabilities(),
    };
  },
  data() {
    return {
      allProducts: [] as Product[],
      protocols: [] as Protocol[],
      treatmentDurationOptions: [7, 10, 15, 21, 30],
      posologyOptions: [1, 2, 3, 4, 5],
      status: 'loading',
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('treatment', ['currentTreatment']),
    /**
     * These are products that we must temporary hide because they don't have stock or things like that
     */
    productToHideNames() {
      // We can hide depending on protocol name to only show supplementation here
      // return ['Botella mezcladora con funda negra'];
      return [''];
    },
    /**
     * Returns the boxes needed to fit the treatment duration and posology requirements.
     */
    suggestedBoxes() {
      // eslint-disable-next-line max-len
      let doses = (parseInt(`${this.treatmentDuration}`, 10) * parseInt(`${this.posology}`, 10)) / 10;

      // Round up always
      if (doses % 10 !== 0) {
        doses = parseInt(`${doses + 1}`, 10);
      }

      return doses + this.additionalAllowedBoxes;
    },
    cartProductsQuantity() {
      let qty = 0;

      this.productList.forEach((product) => {
        if (product.type === 'producto') {
          qty += product.quantity;
        }
      });

      return qty;
    },
    limitReached() {
      return this.cartProductsQuantity >= this.suggestedBoxes;
    },
    additionalAllowedBoxes() {
      return 1;
    },
    protocolName() {
      // eslint-disable-next-line max-len
      return this.currentTreatment ? this.currentTreatment.treatmentProtocolPhases[0].protocolPhase.protocol.name : 'Sin protocolo';
    },
    /**
     * Those protocols that should be with only PROAGE ON PLUS, but professional can decide.
     º     */
    specialTreatmentProtocols() {
      return ['DIGESTIVO', 'DIGESTIVO Y COMPOSICIÓN CORPORAL', 'LONGEVIDAD INMUNIDAD'];
    },
    recommendationMessage() {
      if (this.specialTreatmentProtocols.includes(this.protocolName)) {
        // eslint-disable-next-line max-len
        return this.$t('Para este tratamiento se recomienda el uso de la suplementación ProAge On Plus (+). Queda a la elección del profesional el uso de otras lineas.');
      }

      // eslint-disable-next-line max-len
      return this.$t('Para este tratamiento se recomienda el uso de la suplementación ProAge On. Queda a la elección del profesional el uso de otras lineas.');
    },
  },
  mounted() {
    this.loadProducts();
    this.loadProtocols();
  },
  methods: {
    hasProtocol() {
      return !!this.currentTreatment?.treatmentProtocolPhases;
    },
    // TODO: I want this as a computed, but I cannot access to another computed on it
    getCurrentProtocolPhaseTreatment() {
      let currentPPT = null as null | TreatmentProtocolPhase;
      // eslint-disable-next-line consistent-return
      this.currentTreatment.treatmentProtocolPhases.forEach((ppt: TreatmentProtocolPhase) => {
        if (!ppt.endDate) {
          currentPPT = ppt;

          this.protocol = ppt.protocolPhase.protocol.id;
          this.phase = ppt.protocolPhase.id;
        }
      });

      // TODO: This is not ok at all, because if the current protocol phase treatment changes, this
      // component changes with it

      return currentPPT;
    },
    async loadProducts() {
      const products: Product[] = await api.professional.product.all();

      for (let i = 0; i < products.length; i += 1) {
        const product = products[i];

        let doDelete = false;

        // We delete those that have no sku
        if (!product.sku) {
          doDelete = true;
        }

        // We delete any product included in 'productToHideNames'
        if (this.productToHideNames.includes(product.name)) {
          doDelete = true;
        }

        if (doDelete) {
          products.splice(i, 1);
          i -= 1;
        }
      }

      this.allProducts = products;

      this.status = 'loaded';
    },
    async loadProtocols() {
      this.protocols = await api.professional.protocol.all();
    },
    showAllProductList() {
      return !!this.posology && !!this.treatmentDuration;
    },
    checkProductInList(productId) {
      return this.productList.find((p) => p.product_id === productId);
    },
    addProduct(product) {
      // TODO: This component need refactor, please...
      if (this.limitReached && product.type === 'producto') {
        this.$toast.error(this.$t('Límite de cajas alcanzado.'));
        return;
      }

      const auxProduct = this.checkProductInList(product.id);

      if (!auxProduct) {
        const productFormat = {
          product_id: product.id,
          name: product.name,
          type: product.type,
          doses: this.posology,
          days: this.treatmentDuration,
          quantity: 1,
        };

        this.productList.push(productFormat);
      }
    },
    addQuantity(productId) {
      const product = this.checkProductInList(productId);

      if (!!product && (!this.limitReached || product.type !== 'productos')) {
        product.quantity += 1;
      }
    },
    deductQuantity(productId) {
      const product = this.checkProductInList(productId);
      const productIndex = this.productList.indexOf(product);

      if (!!product) {
        if (product.quantity > 0) {
          product.quantity -= 1;

          if (product.quantity === 0) {
            this.productList.splice(productIndex, 1);
          }
        }
      }
    },
    async confirmCreatePurchaseAuthorization() {
      await this.$modal.confirm({
        title: this.$t('Nueva autorización de compra'),
        text: this.$t('¿Seguro que quieres crear esta nueva autorización de compra?'),
        confirmButtonText: this.$t('Continuar'),
        textLayout: null,
        confirmButtonCallback: () => this.doCreatePurchaseAuthorization(),
      });
    },
    // eslint-disable-next-line consistent-return
    async doCreatePurchaseAuthorization() {
      this.toggleAccepted();

      const productFormat = this.productList.map((product) => ({ ...product }));

      productFormat.forEach((product) => {
        delete product.name;
        delete product.type;
      });

      const purchaseAuthorization = {
        due_date: this.$str.formatDate(DateTime.now()
          .plus({ days: 15 }), 'dd-MM-yyyy'),
        max_products: this.productList.length,
        // TODO: Si hay un tratamiento activo, poner ese id (puede ser null)
        treatment_id: this.$route.query.tratamiento ?? null,
        expedient_id: this.currentExpedient.id,
        // TODO: Si se está creando desde una consulta, poner ese id, que es una prop (puede ser null)
        consultation_id: this.consultationId,
        status: 'pending',
        products: productFormat,
      };

      try {
        await api.professional.purchaseAuthorizations.create(
          this.currentExpedient.id,
          purchaseAuthorization,
        );

        // TODO: Force to refresh purchase authorizations when in store

        this.$toast.success(this.$t('La autorización de compra se ha creado correctamente'));

        this.toggleAccepted();

        this.$emit('purchase-authorization-created');

        this.closeModal();

        // return { error: true };
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al añadir la autorización de compra'));

        this.toggleAccepted();
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
