import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "consultation-scroll d-flex align-items-center w-100" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_professional_consultation_show = _resolveComponent("the-professional-consultation-show")!
  const _component_the_professional_consultation_scroll_item = _resolveComponent("the-professional-consultation-scroll-item")!
  const _component_the_professional_consultation_scroll = _resolveComponent("the-professional-consultation-scroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_the_professional_consultation_show),
        _createVNode(_component_the_professional_consultation_scroll, null, {
          default: _withCtx(() => [
            _createVNode(_component_the_professional_consultation_scroll_item)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}