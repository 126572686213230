import { MutationTree } from 'vuex';
import { UserState } from '@/store/modules/user/user.types';

interface UserMutations {
  auth(state: UserState, payload: any): void;

  setGuest(state: UserState, payload: boolean): void;
}

const mutations: MutationTree<UserState> & UserMutations = {
  auth(state: UserState, user: any) {
    state.authUser = user;
  },
  setGuest(state: UserState, isGuest: boolean) {
    state.isGuest = isGuest;
  },
  setImpersonatedBy(state: UserState, userId: number) {
    state.impersonatedBy = userId;
  },
};

export default mutations;
