import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container auth-container" }
const _hoisted_2 = { class: "container-form w-100 py-56 px-32 px-sm-56" }
const _hoisted_3 = { class: "h2 title text-primary text-center align-self-center align-content-center mb-16" }
const _hoisted_4 = { class: "user-info text-center lead" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.status), 1)
      ]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.message), 1)
    ])
  ]))
}