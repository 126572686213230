import AbstractService from '@/api/services/AbstractService';
import Form from '@/api/objects/Form';

class PatientExpedientFormService extends AbstractService {
  public async all(expedientId: number): Promise<Form[]> {
    return this.request('get', this.buildPath('expedient/{expedientId}/forms', { expedientId }));
  }
}

export default new PatientExpedientFormService();
