<template>
  <Teleport to="body">
    <div
      :id="id"
      ref="modal"
      :class="modalClasses"
      :data-bs-backdrop="!!backdropStatic ? 'static' : null"
      aria-hidden="true"
      class="modal fade"
      tabindex="-1"
    >
      <div :class="[className, cSize]" class="modal-dialog modal-dialog-centered">
        <div :class="contentClass" class="modal-content">
          <slot/>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { Modal } from 'bootstrap';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VModal',
  emits: ['closed', 'hide', 'hide-prevented', 'show', 'shown'],
  props: {
    id: {
      type: String,
      required: false,
      default: 'modal',
    },
    backdropStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
    backdrop: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: false,
      default: 'lg',
    },
    className: {
      type: String,
      required: false,
      default: null,
    },
    contentClass: {
      type: String,
      required: false,
      default: null,
    },
    modalClasses: {
      type: String,
      required: false,
      default: null,
    },
    isDoubleModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    // TODO debe ser compatible con purgecss
    cSize() {
      let ret = null;

      if (!!this.size) {
        ret = `modal-${this.size}`;
      }

      return ret;
    },
  },
  mounted() {
    this.setup();
    this.open();
  },
  data() {
    return {
      modal: {},
      doEmitOnClose: true,
    };
  },
  methods: {
    setup() {
      const MODAL = this.$refs.modal;

      const config = {};

      if (this.backdropStatic) {
        config.backdrop = 'static';
      }

      if (this.backdrop === 'false') {
        config.backdrop = false;
      }

      this.modal = Modal.getOrCreateInstance(MODAL, config);

      if (this.isDoubleModal) {
        // eslint-disable-next-line no-underscore-dangle
        this.modal._element.classList.add('double-modal');

        // eslint-disable-next-line no-underscore-dangle
        this.modal._backdrop._config.className = 'modal-backdrop double-modal-backdrop';
      }

      MODAL.addEventListener('hidden.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          // TODO Replace to same BT event name: hidden
          this.$emit('closed');
        }
      });

      MODAL.addEventListener('hide.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('hide');
        }
      });

      MODAL.addEventListener('hidePrevented.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('hide-prevented');
        }
      });

      MODAL.addEventListener('show.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('show');
        }
      });

      MODAL.addEventListener('shown.bs.modal', () => {
        if (!!this.doEmitOnClose) {
          this.$emit('shown');
        }
      });
    },
    open() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    dispose() {
      this.modal.dispose();
    },
  },
  beforeUnmount() {
    this.modal.hide();
  },
});
</script>

<style lang="scss">
//.modal-backdrop:nth-child(odd) {
//  z-index: 1059;
//}
//
//.modal:nth-child(odd) {
//  z-index: 1070;
//}
</style>

<style lang="scss" scoped>
//.modal {
//  padding-left: 0;
//  padding-right: 0;
//  margin-top: 0;
//
//  .modal-fullscreen {
//    .modal-content {
//      padding: 1.5rem;
//    }
//  }
//
//  .modal-content {
//    padding: 2.75rem;
//    border-radius: .25rem;
//  }
//}
//
//.modal-backdrop {
//  z-index: 1050;
//}
//
//.modal.modal-fullscreen-index {
//  z-index: 1030;
//}
//
//.double-modal {
//  z-index: 1070;
//}
//
//.double-modal-backdrop {
//  z-index: 1060;
//}
</style>
