import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "value", "disabled", "autocomplete", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_cleave = _resolveDirective("cleave")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: _ctx.type,
    class: _normalizeClass(["form-control", [{'form-control': !['checkbox', 'radio'].includes(_ctx.type), 'form-control-sm': _ctx.size === 'sm'}]]),
    value: _ctx.value,
    disabled: _ctx.disabled,
    autocomplete: !!_ctx.autocomplete ? _ctx.autocomplete : null,
    placeholder: _ctx.placeholder,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
    ref: "input"
  }, null, 42, _hoisted_1)), [
    [_directive_cleave, _ctx.mask]
  ])
}