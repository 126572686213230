import { createLogger } from 'vuex';
import actions
  from '@/store/modules/patient/expedient/modules/purchaseAuthorization/purchaseAuthorization.actions';
import mutations
  from '@/store/modules/patient/expedient/modules/purchaseAuthorization/purchaseAuthorization.mutations';
import type {
  PurchaseAuthorizationState,
} from '@/store/modules/patient/expedient/modules/purchaseAuthorization/purchaseAuthorization.types';

export default {
  namespaced: true,
  state: (): PurchaseAuthorizationState => ({
    currentPurchaseAuthorization: null,
    purchaseAuthorizations: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
