
import { defineComponent } from 'vue';
import ThePatientConsultationScrollItem
  from '@/views/patient/expedients/consultation/ThePatientConsultationScrollItem.vue';
import ThePatientConsultationShow
  from '@/views/patient/expedients/consultation/ThePatientConsultationShow.vue';
import ThePatientConsultationScroll
  from '@/views/patient/expedients/consultation/ThePatientConsultationScroll.vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ThePatientConsultation',
  components: {
    ThePatientConsultationScroll,
    ThePatientConsultationScrollItem,
    ThePatientConsultationShow,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
  },
});
