
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ThePatientConsultationScroll',
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/consultation', ['consultations']),
  },
});
