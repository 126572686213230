
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheRGPDAcceptanceResponse',
  components: {},
  data() {
    return {
      status: this.$route.query.status,
    };
  },
  beforeMount() {
    // this.redirect();
  },
  methods: {
    redirect() {
      const sec = 4;
      setTimeout(async () => {
        await this.$router.replace({ name: 'login' });
      }, (sec * 1000));
    },
  },
});
