
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'ModalHeader',
  components: { VButton },
  props: {
    disableClose: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    closeModal() {
      this.$emit('closed');
    },
  },
});
