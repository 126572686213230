
import { defineComponent } from 'vue';
import VInput from '@/components/vendor/basic/form/parts/VInput.vue';
import VFormGroupInputCheckbox from '@/components/vendor/basic/form/VFormGroupInputCheckbox.vue';
import VFormGroupInputRadio from '@/components/vendor/basic/form/VFormGroupInputRadio.vue';
import vSelect from 'vue-select';
import { mapState } from 'vuex';
import VFormGroupInputBtnCheckbox
  from '@/components/vendor/basic/form/VFormGroupInputBtnCheckbox.vue';
import VFormGroupInputSwitch from '@/components/vendor/basic/form/VFormGroupInputSwitch.vue';
import { showComponent } from '@/helpers/anamnesisFormHelper';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import VFormGroupInputDatetime from '@/components/vendor/basic/form/VFormGroupInputDatetime.vue';

export default defineComponent({
  name: 'TheDynamicInputAnamnesis',
  components: {
    VFormGroupInputDatetime,
    VFormGroupInputDate,
    VFormGroupInputSwitch,
    VFormGroupInputBtnCheckbox,
    VFormGroupInputRadio,
    VFormGroupInputCheckbox,
    VInput,
    vSelect,
  },
  props: {
    formGroupKey: {
      type: String,
      required: true,
    },
    keyForm: {
      type: String,
      required: false,
    },
    formValueLabel: {
      type: String,
      required: true,
    },
    formValueKey: {
      type: String,
      required: true,
    },
    formValueOptions: {
      type: Array,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    formValueClass: {
      type: String,
      required: false,
      default: '',
    },
    prefix: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    taggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    // TODO: All these props must be an object
    dependency: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState('expedient', ['currentAnamnesis']),
    show() {
      const show = showComponent(this.dependency, this.currentAnamnesis);

      this.$emit('show-form-group', show);

      return show;
    },
  },
  data() {
    return {
      selectFormValueSelected: this.value,
      multiselectFormValueSelected: null,
      tempFormValueOptions: this.formValueOptions,
      loaded: false,
    };
  },
  mounted() {
    if (this.type === 'multiselect') {
      const multiselectValue: any = this.value;

      if (multiselectValue.length > 0) {
        const arrayValue = multiselectValue.replaceAll('[', '')
          .replaceAll(']', '')
          .split(',');

        // We non-option values to options array to let multiselect-draggable work well
        arrayValue.forEach((value) => {
          let optionMissing = value;
          this.formValueOptions.forEach((option: any) => {
            if (option.key === value) {
              optionMissing = false;
            }
          });

          if (optionMissing !== false) {
            this.tempFormValueOptions.push({
              key: value,
              label: value,
            });
          }
        });

        this.loaded = true;

        this.multiselectFormValueSelected = arrayValue;
      }

      this.loaded = true;

      this.$watch('multiselectFormValueSelected', (value) => {
        const aux = `[${value.join(',')}]`;

        this.emitValue(aux);
      }, { deep: true });
    }
  },
  watch: {
    selectFormValueSelected(value) {
      this.emitValue(value);
    },
  },
  methods: {
    async emitValue(value) {
      await this.$store.dispatch('expedient/setAnamnesisFormValue', {
        formGroupKey: this.formGroupKey,
        formValueKey: this.formValueKey,
        formValueValue: value,
      });

      this.$emit('update-form-group-request');
    },
  },
});
