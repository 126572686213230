import AbstractService from '@/api/services/AbstractService';
import User from '@/api/objects/User';

class AuthService extends AbstractService {
  public async cookie() {
    return this.request('get', '/sanctum/csrf-cookie');
  }

  public async authUser(): Promise<User> {
    await this.cookie();
    return this.request('get', this.buildPath('user/auth'));
  }

  public async login(form: any) {
    await this.cookie();
    await this.request('post', this.buildPath('login'), [], form);
  }

  public logout() {
    return this.request('post', '/api/logout');
  }

  public async forgotPasswords(data: any) {
    await this.cookie();

    return this.request('post', '/api/forgot-password', [], data);
  }

  public async resetPasswords(data: any) {
    await this.cookie();

    return this.request('post', '/api/reset-password', [], data);
  }
}

export default new AuthService();
