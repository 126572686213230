import { createLogger } from 'vuex';
import actions from '@/store/modules/patient/expedient/modules/treatment/treatment.actions';
import mutations from '@/store/modules/patient/expedient/modules/treatment/treatment.mutations';
import type {
  TreatmentState,
} from '@/store/modules/patient/expedient/modules/treatment/treatment.types';

export default {
  namespaced: true,
  state: (): TreatmentState => ({
    currentTreatment: null,
    treatments: [],
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
