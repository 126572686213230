import api from '@/api';

/**
 * Returns the logged in professional patients (according to it expedients).
 */
async function getExpedientsOptions() {
  const options = [] as any[];

  const expedients = await api.professional.expedient.all(null, false);

  expedients.forEach((expedient) => {
    options.push({
      key: expedient?.patient?.id,
      label: `${expedient?.patient?.user?.name} ${expedient?.patient?.user?.surname} - ${expedient?.patient?.nif}`,
    });
  });

  return options;
}

export default getExpedientsOptions;
