import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3902bf95"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "professional-video-list" }
const _hoisted_2 = { class: "d-flex mb-56 justify-content-between" }
const _hoisted_3 = { class: "h4 text-primary mb-0" }
const _hoisted_4 = { class: "fw-normal" }
const _hoisted_5 = { class: "top-section" }
const _hoisted_6 = { class: "document-type d-flex align-items-center flex-wrap col-8 col-md-6 col-xl-5 col-xxl-4" }
const _hoisted_7 = { class: "video-container" }
const _hoisted_8 = { class: "tab-content" }
const _hoisted_9 = {
  id: "grid",
  "aria-labelledby": "grid-tab",
  class: "tab-pane active",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_treeselect = _resolveComponent("treeselect")!
  const _component_the_professional_videos_grid = _resolveComponent("the-professional-videos-grid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('Listado de')), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.$t('vídeos')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_treeselect, {
          modelValue: _ctx.typeSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.typeSelected) = $event)),
          "clear-value-text": _ctx.$t('Borrar categoría'),
          multiple: true,
          "no-results-text": _ctx.$t('No se han encontrado resultados'),
          options: _ctx.categories,
          placeholder: _ctx.$t('Selecciona una categoría')
        }, null, 8, ["modelValue", "clear-value-text", "no-results-text", "options", "placeholder"])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_the_professional_videos_grid, {
            status: _ctx.status,
            videos: _ctx.videos,
            onRefreshMedia: _ctx.loadVideos
          }, null, 8, ["status", "videos", "onRefreshMedia"])
        ])
      ])
    ])
  ]))
}