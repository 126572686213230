import { createLogger } from 'vuex';
import actions from '@/store/modules/patient/expedient/modules/form/form.actions';
import mutations from '@/store/modules/patient/expedient/modules/form/form.mutations';
import type { FormState } from '@/store/modules/patient/expedient/modules/form/form.types';

export default {
  namespaced: true,
  state: (): FormState => ({
    measures: [],
    forms: [],
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
