export default {
  // src\components\structural\TheImpersonateStop.vue:
  'Regresar a panel de administrador': 'Regresar a panel de administrador',

  // src\components\structural\TheLogout.vue:
  'Cerrar sesión': 'Cerrar sesión',

  // src\components\structural\TheMenuAdministrator.vue:
  Inicio: 'Inicio',
  'Logo ProAge': 'Logo ProAge',
  Clínicas: 'Clínicas',
  Profesionales: 'Profesionales',
  Categorías: 'Categorías',
  'Categorías vídeos': 'Categorías de vídeos',
  'Categorías recursos': 'Categorías de recursos',
  Pacientes: 'Pacientes',
  Recursos: 'Recursos',
  Videoteca: 'Videoteca',
  Agenda: 'Agenda',
  Menú: 'Menú',
  Media: 'Media',
  'Aut. de compra': 'Aut. de compra',
  // eslint-disable-next-line max-len
  'Has iniciado sesión como el usuario { user } ({ email }).': 'Has iniciado sesión como el usuario { user } ({ email }).',

  // src\components\structural\TheMenuPatient.vue:
  'Toggle navigation': 'Toggle navigation',
  'Funcionalidad no disponible': 'Funcionalidad no disponible',
  // src\components\structural\TheMenuProfessional.vue:
  Interconsultas: 'Interconsultas',
  'Mi perfil': 'Mi perfil',

  // src\components\structural\VUnderConstruction.vue:
  'En construcción': 'En construcción',
  // src\components\vendor\VLoader.vue:
  'Cargando...': 'Cargando...',
  // src\components\vendor\VPagination.vue:
  'Navigation example': 'Navigation example',

  // src\components\vendor\VProfessionalConsultations.vue:
  Consulta: 'Consulta',
  '- Sin clínica asociada -': '- Sin clínica asociada -',
  'Ver consulta': 'Ver consulta',
  'No tienes ninguna consulta': 'No tienes ninguna consulta',
  'No hay consultas': 'No hay consultas',
  'Nueva consulta': 'Nueva consulta',
  'Ver paciente': 'Ver paciente',
  // src\components\vendor\modal\ModalHeader.vue:
  Cerrar: 'Cerrar',

  // src\resources\modal.ts:
  Información: 'Información',
  'Modal de información': 'Modal de información',
  Eliminar: 'Eliminar',
  'Eliminando...': 'Eliminando...',
  Cancelar: 'Cancelar',
  Confirmar: 'Confirmar',
  'Confirmando...': 'Confirmando...',

  // src\store\modules\general\index.ts:
  Título: 'Título',
  Text: 'Text',
  'Procesando...': 'Procesando...',
  ID: 'ID',

  // src\views\ThePageNotFound.vue:
  'Ups!': 'Ups!',
  'Te has perdido': 'Te has perdido',
  // eslint-disable-next-line max-len
  'No hemos podido encontrar la página que estás buscando.': 'No hemos podido encontrar la página que estás buscando.',
  // eslint-disable-next-line max-len
  'Si quieres puedes ir a la página de inicio haciendo click en el siguiente botón:': 'Si quieres puedes ir a la página de inicio haciendo click en el siguiente botón:',

  // src\views\administrator\TheAdminHome.vue:
  'Hola,': 'Hola,',
  'Crear profesional': 'Crear profesional',
  'Crear categoría': 'Crear categoría',
  'Añadir nuevo profesional': 'Añadir nuevo profesional',
  'Añadir nueva categoría': 'Añadir nueva categoría',
  'Ver profesionales': 'Ver profesionales',
  'Ver categorías': 'Ver categorías',
  'Ver pacientes': 'Ver pacientes',
  'Ver clínicas': 'Ver clínicas',
  'Ver aut. de compra': 'Ver aut. de compra',

  // src\views\administrator\calendar\parts\TheAdminCalendarEventCreate.vue:
  'evento de calendario': 'evento de calendario',
  Asunto: 'Asunto',
  Tipo: 'Tipo',
  'No se han encontrado resultados': 'No se han encontrado resultados',
  Paciente: 'Paciente',
  'Obligatorio: Se le asignará la cita a este paciente': 'Obligatorio: Se le asignará la cita a este paciente',
  Clínica: 'Clínica',
  Profesional: 'Profesional',
  Categoría: 'Categoría',
  'Obligatorio: Se le asignará la cita a este profesional': 'Obligatorio: Se le asignará la cita a este profesional',
  Fecha: 'Fecha',
  Fin: 'Fin',
  'Durante todo el día': 'Durante todo el día',
  Descripción: 'Descripción',
  // eslint-disable-next-line max-len
  'Al crear esta {tipo_consulta} se mandará un SMS al paciente con la hora indicada.': 'Al crear esta {tipo_consulta} se mandará un SMS al paciente con la hora indicada.',
  Añadir: 'Añadir',
  'Debes seleccionar una clínica donde pasar consulta': 'Debes seleccionar una clínica donde pasar consulta',
  'Primera consulta': 'Primera consulta',
  Revisión: 'Revisión',
  Videoconsulta: 'Videoconsulta',
  'Crear {tipo_consulta}': 'Crear {tipo_consulta}',
  // eslint-disable-next-line max-len
  'Vas a crear una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.': 'Vas a crear una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.',
  'Se ha creado el evento de calendario con éxito.': 'Se ha creado el evento de calendario con éxito.',
  'Ha ocurrido un error al crear el evento de calendario.': 'Ha ocurrido un error al crear el evento de calendario.',

  // src\views\administrator\calendar\parts\TheAdminCalendarEventUpdate.vue:
  'Editando evento': 'Editando evento',
  'Introduce una descripción aquí...': 'Introduce una descripción aquí...',
  'No hay clínica asociada': 'No hay clínica asociada',

  // eslint-disable-next-line max-len
  'Al editar la hora de esta {tipo_consulta} se mandará un SMS al paciente.': 'Al editar la hora de esta {tipo_consulta} se mandará un SMS al paciente.',
  'Guardar cambios': 'Guardar cambios',
  'Editar {tipo_consulta}': 'Editar {tipo_consulta}',
  // eslint-disable-next-line max-len
  'Vas a editar una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.': 'Vas a editar una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.',
  'Se ha actualizado el evento de calendario con éxito.': 'Se ha actualizado el evento de calendario con éxito.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al modificar el evento de calendario.': 'Ha ocurrido un error al modificar el evento de calendario.',
  // eslint-disable-next-line max-len
  'Vas a eliminar la {tipo_consulta} de nombre {titulo}. ¿Estás seguro?': 'Vas a eliminar la {tipo_consulta} de nombre {titulo}. ¿Estás seguro?',
  // eslint-disable-next-line max-len
  'Vas a eliminar la {tipo_consulta} de nombre {titulo} entre el profesional {profesional_nombre} {profesional_apellido} con el paciente {paciente_nombre} {paciente_apellido}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?': 'Vas a eliminar la {tipo_consulta} de nombre {titulo} entre el profesional {profesional_nombre} {profesional_apellido} con el paciente {paciente_nombre} {paciente_apellido}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?',
  'Eliminar evento': 'Eliminar evento',
  'Se ha eliminado el evento de calendario con éxito.': 'Se ha eliminado el evento de calendario con éxito.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al eliminar el evento de calendario.': 'Ha ocurrido un error al eliminar el evento de calendario.',

  // src\views\administrator\clinic\TheAdminClinicCreateModal.vue:
  'nueva clínica': 'nueva clínica',
  Nombre: 'Nombre',
  'Prefijo tel.': 'Prefijo tel.',
  'Dirección personal': 'Dirección personal',
  'Dirección profesional': 'Dirección profesional',
  Teléfono: 'Teléfono',
  Dirección: 'Dirección',
  'Dar de alta': 'Dar de alta',
  Apellidos: 'Apellidos',
  'Prefijo telefónico': 'Prefijo telefónico',
  'Número de teléfono': 'Número de teléfono',
  'Permitir acceso a la aplicación': 'Permitir acceso a la aplicación',

  // src\views\administrator\clinic\TheAdminClinicEdit.vue:
  'Debe tener entre 6 y 14 cifras': 'Debe tener entre 6 y 14 cifras',
  'Clínica {nombre_clinica} creada con éxito.': 'Clínica {nombre_clinica} creada con éxito.',
  Actualizar: 'Actualizar',
  'Clínica actualizada con éxito.': 'Clínica actualizada con éxito.',

  // src\views\administrator\clinic\TheAdminClinicList.vue:
  'Listado de': 'Listado de',
  clínicas: 'clínicas',
  'Nueva clínica': 'Nueva clínica',
  'Buscar clínica': 'Buscar clínica',
  'Limpiar filtros': 'Limpiar filtros',
  Acciones: 'Acciones',
  'No hay clínicas': 'No hay clínicas',
  Ver: 'Ver',
  Editar: 'Editar',
  'Ha ocurrido un error al cargar las clínicas': 'Ha ocurrido un error al cargar las clínicas',
  'Asociar profesional a la clínica': 'Asociar profesional a la clínica',

  // src\views\administrator\clinic\TheAdminClinicShow.vue:
  Asociar: 'Asociar',
  'Profesionales asociados a la clínica': 'Profesionales asociados a la clínica',
  Desasociar: 'Desasociar',
  'No hay profesionales asociados a esta clínica': 'No hay profesionales asociados a esta clínica',
  'Asociar profesional y clínica': 'Asociar profesional y clínica',
  // eslint-disable-next-line max-len
  'Vas a asociar el profesional con esa clínica. Esto significa que podrá utilizarla como clínica para sus consultas. ¿Estás seguro?': 'Vas a asociar el profesional con esa clínica. Esto significa que podrá utilizarla como clínica para sus consultas. ¿Estás seguro?',
  'Desasociar profesional y clínica': 'Desasociar profesional y clínica',
  // eslint-disable-next-line max-len
  'Vas a desasociar el profesional con esa clínica. Esto significa que no podrá utilizarla como clínica para sus consultas. ¿Estás seguro?': 'Vas a desasociar el profesional con esa clínica. Esto significa que no podrá utilizarla como clínica para sus consultas. ¿Estás seguro?',
  // eslint-disable-next-line max-len
  'Profesional asociado/desasociado con éxito de la clínica.': 'Profesional asociado/desasociado con éxito de la clínica.',
  // eslint-disable-next-line max-len
  'El profesional tiene alguna de sus consultas asociadas a la clínica, no podemos desasociarlo.': 'El profesional tiene alguna de sus consultas asociadas a la clínica, no podemos desasociarlo.',

  // src\views\administrator\patient\TheAdminPatientExpedientCreate.vue:
  'nuevo paciente': 'nuevo paciente',
  'Tipo de documento': 'Tipo de documento',
  'Nº documento de identidad': 'Nº documento de identidad',
  'Fecha de nacimiento': 'Fecha de nacimiento',
  Género: 'Género',
  email: 'Correo electrónico',
  'Correo electrónico': 'Correo electrónico',
  'Otros teléfonos': 'Otros teléfonos',
  // eslint-disable-next-line max-len
  'Se creará un expediente entre el profesional elegido y el paciente nuevo que vas a crear, por lo que el profesional tendrá acceso a la información del paciente y podrá comenzar a tratarlo.': 'Se creará un expediente entre el profesional elegido y el paciente nuevo que vas a crear, por lo que el profesional tendrá acceso a la información del paciente y podrá comenzar a tratarlo.',
  'Tipo de Documento': 'Tipo de Documento',
  'Número de documento': 'Número de documento',
  'El {tipo_documento} registrado no es Válido': 'El {tipo_documento} registrado no es Válido',
  'La fecha de nacimiento no puede ser futura': 'La fecha de nacimiento no puede ser futura',
  'Crear expediente': 'Crear expediente',
  // eslint-disable-next-line max-len
  'Vas a crear un nuevo paciente y adjudicárselo al profesional indicado. De esta forma, se creará ese paciente y el profesional podrá comenzar a tratarlo. Además, enviaremos un correo electrónico al paciente para que restablezca su contraseña y tenga acceso a la plataforma. ¿Estás seguro?': 'Vas a crear un nuevo paciente y adjudicárselo al profesional indicado. De esta forma, se creará ese paciente y el profesional podrá comenzar a tratarlo. Además, enviaremos un correo electrónico al paciente para que restablezca su contraseña y tenga acceso a la plataforma. ¿Estás seguro?',
  Crear: 'Crear',
  'El paciente se ha creado correctamente': 'El paciente se ha creado correctamente',
  // eslint-disable-next-line max-len
  'El número de teléfono ya existe en nuestra base de datos, prueba con otro': 'El número de teléfono ya existe en nuestra base de datos, prueba con otro',
  // eslint-disable-next-line max-len
  'Ese profesional ya tiene registrado a un paciente con ese número de identificación.': 'Ese profesional ya tiene registrado a un paciente con ese número de identificación.',
  'Ha ocurrido un error al añadir el nuevo paciente': 'Ha ocurrido un error al añadir el nuevo paciente',

  // src\views\administrator\patient\TheAdminPatientShow.vue:
  'Datos básicos': 'Datos básicos',
  'Tipo doc. identificativo': 'Tipo doc. identificativo',
  'Nº doc. identificativo': 'Nº doc. identificativo',
  Personificación: 'Personificación',
  // eslint-disable-next-line max-len
  'Puedes iniciar sesión como si fueses el paciente en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc.Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.': 'Puedes iniciar sesión como si fueses el paciente en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc.Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.',
  'Iniciar sesión como el paciente': 'Iniciar sesión como el paciente',
  Contacto: 'Contacto',
  'Profesionales relacionados': 'Profesionales relacionados',
  // eslint-disable-next-line max-len
  'Este es el listado de profesionales que han atendido o están atendiendo a este paciente.': 'Este es el listado de profesionales que han atendido o están atendiendo a este paciente.',
  'Este paciente no tiene expedientes': 'Este paciente no tiene expedientes',
  Especialidad: 'Especialidad',
  'Estado RGPD': 'Estado RGPD',
  Confirmada: 'Confirmada',
  Pendiente: 'Pendiente',
  'Iniciar sesión como': 'Iniciar sesión como',
  // eslint-disable-next-line max-len
  'Vas a iniciar sesión como el paciente {nombre_paciente} {apellido_paciente}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?': 'Vas a iniciar sesión como el paciente {nombre_paciente} {apellido_paciente}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al intentar personificar al usuario': 'Ha ocurrido un error al intentar personificar al usuario',

  // src\views\administrator\patient\TheAdminPatientsList.vue:
  'Error personificando': 'Error personificando',
  pacientes: 'pacientes',
  'Nuevo paciente': 'Nuevo paciente',
  Masculino: 'Masculino',
  Femenino: 'Femenino',
  'Buscar paciente': 'Buscar paciente',
  Apellido: 'Apellido',
  'Doc. identificativo': 'Doc. identificativo',
  'Fecha nacimiento': 'Fecha nacimiento',
  'No hay pacientes': 'No hay pacientes',
  'Ha ocurrido un error al cargar los pacientes': 'Ha ocurrido un error al cargar los pacientes',

  // src\views\administrator\patient\TheAdminPatientUpdate.vue:
  'Tipo documento': 'Tipo documento',
  'Nº documento identidad': 'Nº documento identidad',
  'Los datos se han guardado correctamente': 'Los datos se han guardado correctamente',

  // src\views\administrator\professional\TheAdminProfessionalDestroy.vue:
  'borrar profesional': 'El profesional seleccionado se eliminará. ¿Desea continuar?',

  // src\views\administrator\professional\TheAdminProfessionalCreate.vue:
  'nuevo profesional': 'nuevo profesional',
  'nueva categoría': 'nueva categoría',
  Subespecialidad: 'Subespecialidad',
  Colegio: 'Colegio',
  'Nº colegiado': 'Nº colegiado',
  // eslint-disable-next-line max-len
  'Puedes permitir que este profesional cree autorizaciones de compra sin necesidad de responder anamnesis ni iniciar un tratamiento. Si este campo está marcado podrá autorizar libremente a sus pacientes.': 'Puedes permitir que este profesional cree autorizaciones de compra sin necesidad de responder anamnesis ni iniciar un tratamiento. Si este campo está marcado podrá autorizar libremente a sus pacientes.',
  'Permitir autorizar libremente': 'Permitir autorizar libremente',
  Médico: 'Médico',
  Nutricionista: 'Nutricionista',
  'Puede autorizar libremente': 'Puede autorizar libremente',
  'Profesional creado': 'Profesional creado',
  // eslint-disable-next-line max-len
  'Se ha creado el profesional :name :surname con éxito, le hemos enviado un correo de bienvenida y otro para que establezca su contraseña. El profesional debe establecer su contraseña para poder acceder a la plataforma.': 'Se ha creado el profesional :name :surname con éxito, le hemos enviado un correo de bienvenida y otro para que establezca su contraseña. El profesional debe establecer su contraseña para poder acceder a la plataforma.',

  // src\views\administrator\professional\TheAdminProfessionalsList.vue:
  profesionales: 'profesionales',
  categorías: 'categorías',
  'Nuevo profesional': 'Nuevo profesional',
  'Nueva categoría': 'Nueva categoría',
  'Buscar profesional': 'Buscar profesional',
  'Buscar categoría': 'Buscar categoría',
  Correo: 'Correo electrónico',
  'No hay profesionales': 'No hay profesionales',
  'Ha ocurrido un error al cargar los profesionales': 'Ha ocurrido un error al cargar los profesionales',
  'No hay categorías': 'No hay categorías',
  'Ha ocurrido un error al cargar las categorías': 'Ha ocurrido un error al cargar las categorías',

  // src\views\administrator\professional\parts\TheAdminProfessionalShow.vue:
  'Visualización de': 'Visualización de',
  profesional: 'profesional',
  categoría: 'categoría',
  'Tipo documento identidad': 'Tipo documento identidad',
  'Tipo de profesional': 'Tipo de profesional',
  'Prefijo teléfono': 'Prefijo teléfono',
  'Nº teléfono': 'Nº teléfono',
  '¿Puede autorizar libremente?': '¿Puede autorizar libremente?',
  'Profesional eliminado': 'Profesional eliminado',
  Activo: 'Activo',
  'Se ha eliminado el profesional con éxito.': 'Se ha eliminado el profesional con éxito.',
  // eslint-disable-next-line max-len
  'Puedes iniciar sesión como si fueses el profesional en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc. Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.': 'Puedes iniciar sesión como si fueses el profesional en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc. Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.',
  // eslint-disable-next-line max-len
  'Iniciar sesión como el profesional {nombre_profesional}': 'Iniciar sesión como el profesional {nombre_profesional}',
  Principal: 'Principal',
  Colegiado: 'Colegiado',
  'Número de colegiado': 'Número de colegiado',
  'Lugares de trabajo': 'Lugares de trabajo',
  'Añadir nueva clínica': 'Añadir nueva clínica',
  'Seleccciona un clínica': 'Seleccciona un clínica',
  Sí: 'Sí',
  No: 'No',
  // eslint-disable-next-line max-len,no-template-curly-in-string
  'Vas a iniciar sesión como el profesional {nombre_profesional}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?': 'Vas a iniciar sesión como el profesional {nombre_profesional}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al intentar personificar al profesional': 'Ha ocurrido un error al intentar personificar al profesional',
  '- Sin tipo -': '- Sin tipo -',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar todas las clínicas de la plataforma.': 'Ha ocurrido un error al cargar todas las clínicas de la plataforma.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar los datos del profesional': 'Ha ocurrido un error al cargar los datos del profesional',

  // src\views\administrator\professional\parts\TheAdminProfessionalUpdate.vue:
  'Modificación de': 'Modificación de',
  'Debe ser un tipo de profesional permitido': 'Debe ser un tipo de profesional permitido',
  'Debe ser una especialidad válida': 'Debe ser una especialidad válida',
  'Debes introducir une fecha válida': 'Debes introducir une fecha válida',

  // src\views\administrator\purchaseAuthorization\TheAdminPurchaseAuthorizationList.vue:
  'autorizaciones de compra': 'autorizaciones de compra',
  'Fecha vencimiento': 'Fecha vencimiento',
  Vencimiento: 'Vencimiento',
  Productos: 'Productos',
  Estado: 'Estado',
  'No hay autorizaciones de compra': 'No hay autorizaciones de compra',
  '{count} productos distintos': '{count} productos distintos',
  Visualizar: 'Visualizar',
  Subtotal: 'Subtotal',
  Impuestos: 'Impuestos',
  'Descargar excel agrupado': 'Descargar excel agrupado',
  'Descargar excel detallado': 'Descargar excel detallado',

  // src\views\administrator\purchaseAuthorization\parts\TheAdminPurchaseAuthorizationShow.vue:
  'Autorización de compra': 'Autorización de compra',
  'Fecha creación:': 'Fecha creación:',
  'Datos de la autorización de compra:': 'Datos de la autorización de compra:',
  'Profesional:': 'Profesional:',
  'Categoría:': 'Category:',
  'Paciente:': 'Paciente:',
  'Fecha de vencimiento:': 'Fecha de vencimiento:',
  'Estado: ': 'Estado: ',
  'Protocolo:': 'Protocolo:',
  'Fase:': 'Fase:',
  '{doses} dosis durante {days} días': '{doses} dosis durante {days} días',
  Volver: 'Volver',
  '€': '€',

  // src\views\administrator\resources\TheAdminResources.vue:
  recursos: 'recursos',
  'Selecciona el tipo de documento': 'Selecciona el tipo de documento',
  'Selecciona el tipo de categoría': 'Selecciona el tipo de categoría',
  'Subcategoría de': 'Subcategoría de',
  'Ha ocurrido un error al cargar los documentos': 'Ha ocurrido un error al cargar los documentos',

  // src\views\administrator\resources\TheAdminResourcesGrid.vue:
  'Añadir documento': 'Añadir documento',
  'No hay documentos de esta categoría': 'No hay documentos de esta categoría',
  'Previsualizar documento': 'Previsualizar documento',

  // src\views\administrator\resources\TheAdminResourcesList.vue:
  // src\views\administrator\resources\parts\TheAdminResourcesShowModal.vue:
  'Visualizar archivo': 'Visualizar archivo',
  'Descargar archivo': 'Descargar archivo',
  'Borrar archivo': 'Borrar archivo',
  'Ha ocurrido un error al intentar descargar el archivo': 'Ha ocurrido un error al intentar descargar el archivo',
  'Eliminar recurso': 'Eliminar recurso',
  // eslint-disable-next-line max-len
  'Vas a eliminar el recurso. No podrás recuperarlo. ¿Estás seguro?': 'Vas a eliminar el recurso. No podrás recuperarlo. ¿Estás seguro?',
  'El recurso se ha eliminado correctamente.': 'El recurso se ha eliminado correctamente.',
  'Ha ocurrido un error al intentar eliminar el archivo.': 'Ha ocurrido un error al intentar eliminar el archivo.',

  // src\views\administrator\resources\parts\TheAdminResourcesUpload.vue:
  'Otros documentos': 'Otros documentos',
  'Guardar documentos': 'Guardar documentos',
  Guardar: 'Guardar',
  'Los documentos se han guardado correctamente.': 'Los documentos se han guardado correctamente.',
  'Ha ocurrido un error al guardar los documentos': 'Ha ocurrido un error al guardar los documentos',

  // src\views\administrator\videos\TheAdminVideos.vue:
  vídeos: 'vídeos',
  'Borrar categoría': 'Borrar categoría',
  'Selecciona una categoría': 'Selecciona una categoría',
  'Añadir vídeo': 'Añadir vídeo',
  'Ha ocurrido un error al cargar los vídeos': 'Ha ocurrido un error al cargar los vídeos',

  // src\views\administrator\videos\TheAdminVideosGrid.vue:
  'No hay vídeos de esta categoría': 'No hay vídeos de esta categoría',
  'Ver vídeo': 'Ver vídeo',
  'Vídeo eliminado': 'Vídeo eliminado',
  'Se ha eliminado el vídeo con éxito.': 'Se ha eliminado el vídeo con éxito.',

  // src\views\administrator\videos\category\TheAdminVideoCategoryDestroy.vue:
  'borrar categoría': 'La categoría seleccionada se eliminará. Desea continuar?',
  'Categoría eliminada': 'Categoría eliminada',
  'Se ha eliminado la categoría con éxito.': 'Se ha eliminado la categoría con éxito.',

  // src\views\administrator\videos\category\TheAdminVideoCategoryCreate.vue:
  'Añadir categoría': 'Añadir categoría',
  'Categoría creada correctamente': 'Categoría creada correctamente',

  // src\views\administrator\videos\parts\TheAdminVideoCreate.vue:
  'borrar vídeo': 'El vídeo seleccionado se eliminará. ¿Desea continuar?',

  // src\views\administrator\videos\parts\TheAdminVideoCreate.vue:
  'nuevo vídeo': 'nuevo vídeo',
  'Añadir categoria': 'Añadir categoria',
  Visibilidad: 'Visibilidad',
  Url: 'Url',
  'Vídeo creado': 'Vídeo creado',
  'Se ha creado el vídeo {nombre} con éxito.': 'Se ha creado el vídeo {nombre} con éxito.',

  // src\views\administrator\videos\parts\TheAdminVideoShow.vue:
  vídeo: 'vídeo',
  'Editar vídeo': 'Editar vídeo',
  'Ha ocurrido un error al cargar el vídeo.': 'Ha ocurrido un error al cargar el vídeo.',

  // src\views\administrator\videos\parts\TheAdminVideoUpdate.vue:
  'Vídeo editado': 'Vídeo editado',
  'Se ha editado el vídeo "{ name }" con éxito.': 'Se ha editado el vídeo "{ name }" con éxito.',

  // src\views\auth\TheForgotPassword.vue:
  'Reestablecer contraseña': 'Reestablecer contraseña',
  'Introduce tu correo': 'Introduce tu correo',
  // eslint-disable-next-line max-len
  'Te enviaremos un correo electrónico con un enlace para que puedas generar una nueva contraseña. Si no lo recibes revisa la carpeta de spam.': 'Te enviaremos un correo electrónico con un enlace para que puedas generar una nueva contraseña. Si no lo recibes revisa la carpeta de spam.',
  // eslint-disable-next-line max-len
  'Te acabamos de reenviar el correo, revisa tu carpeta de spam por si acaso.': 'Te acabamos de reenviar el correo, revisa tu carpeta de spam por si acaso.',

  // src\views\auth\TheLogin.vue:
  'Inicio de sesión': 'Inicio de sesión',
  Contraseña: 'Contraseña',
  'He olvidado mi contraseña': 'He olvidado mi contraseña',
  'Iniciar sesión': 'Iniciar sesión',

  // src\views\auth\TheResetPassword.vue:
  'Nueva contraseña': 'Nueva contraseña',
  'Confirma tu nueva contraseña': 'Confirma tu nueva contraseña',
  'Confirmación de la contraseña': 'Confirmación de la contraseña',

  // src\views\auth\TheResetPasswordSuccessful.vue:
  '¡Felicidades!': '¡Felicidades!',
  // eslint-disable-next-line max-len
  'Tu contraseña ha sido restablecida de forma satisfactoria. Ya puedes volver a iniciar sesión e introducir tus nuevos credenciales.': 'Tu contraseña ha sido restablecida de forma satisfactoria. Ya puedes volver a iniciar sesión e introducir tus nuevos credenciales.',

  // src\views\auth\TheRGPDAcceptance.vue:
  'Política de protección de datos': 'Política de protección de datos',
  // eslint-disable-next-line max-len
  'Proage by LFL, S.L. le informa que va a tratar sus datos personales con la finalised de prestar los servicios contratados y/o enviar comunicaciones comerciales. La base jurídica es la ejecución de un contrato de prestación de servicios y el interés legítimo de Proage; tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional, que puede consultar en nuestra web.': 'Proage by LFL, S.L. le informa que va a tratar sus datos personales con la finalised de prestar los servicios contratados y/o enviar comunicaciones comerciales. La base jurídica es la ejecución de un contrato de prestación de servicios y el interés legítimo de Proage; tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional, que puede consultar en nuestra web.',
  'He leído y acepto la': 'He leído y acepto la',
  'Política de Privacidad': 'Política de Privacidad',
  'de Proage': 'de Proage',
  // eslint-disable-next-line max-len
  'Consiento de forma expresa la cesión de mis datos personales al médico asignado por Proage para mi tratamiento.': 'Consiento de forma expresa la cesión de mis datos personales al médico asignado por Proage para mi tratamiento.',
  Continuar: 'Continuar',
  'Debes aceptar la política de protección de datos': 'Debes aceptar la política de protección de datos',
  // eslint-disable-next-line max-len
  'Debes aceptar las otras condiciones relacionadas con la protección de datos': 'Debes aceptar las otras condiciones relacionadas con la protección de datos',
  'Debes aceptar las condiciones para continuar.': 'Debes aceptar las condiciones para continuar.',

  // src\views\auth\TheRGPDAcceptanceResponse.vue:
  '¡Gracias por aceptar las políticas de ProAge!': '¡Gracias por aceptar las políticas de ProAge!',
  // eslint-disable-next-line max-len
  '¡Parece que tus políticas ya han sido aceptadas anteriormente!': '¡Parece que tus políticas ya han sido aceptadas anteriormente!',
  '¡Uups! Ha ocurrido un error.': '¡Uups! Ha ocurrido un error.',
  'El token provisto no es válido.': 'El token provisto no es válido.',

  // src\views\auth\TheVerifyUserResponse.vue:
  '¡Error!': '¡Error!',
  Listo: 'Listo',

  // src\views\patient\ThePatientHome.vue:
  'Modificar mi agenda': 'Modificar mi agenda',
  'Consultar mi agenda': 'Consultar mi agenda',
  Tus: 'Tus',
  'Ha ocurrido un error al cargar las consultas': 'Ha ocurrido un error al cargar las consultas',

  // src\views\patient\calendar\ThePatientCalendarEventShow.vue:
  Evento: 'Evento',

  // src\views\patient\expedients\ThePatientExpedientFile.vue:
  'Tu médico es:': 'Tu médico es:',
  'Mis datos personales': 'Mis datos personales',
  '(Finalizado)': '(Finalizado)',
  '- Sin datos -': '- Sin datos -',
  'Fecha de inicio:': 'Fecha de inicio:',
  'Última consulta:': 'Última consulta:',
  'GRPD:': 'GRPD:',
  Válido: 'Válido',
  'Sin aceptar': 'Sin aceptar',
  General: 'General',
  'Datos médicos': 'Datos médicos',
  Consultas: 'Consultas',
  Documentos: 'Documentos',

  // src\views\patient\expedients\treatments\ThePatientExpedientFileTreatmentsList.vue
  'En curso': 'En curso',

  // src\views\patient\expedients\consultation\ThePatientConsultation.vue:
  'Politicas RGPD no aceptadas en el expediente': 'Politicas RGPD no aceptadas en el expediente',

  // src\views\patient\expedients\consultation\ThePatientConsultationScroll.vue:
  'Historial de': 'Historial de',
  consultas: 'consultas',

  // src\views\patient\expedients\consultation\ThePatientConsultationScrollItem.vue:

  // src\views\patient\expedients\consultation\ThePatientConsultationShow.vue:
  'No hay niguna consulta seleccionada.': 'No hay niguna consulta seleccionada.',

  // src\views\patient\expedients\consultation\parts\ThePatientConsultationView.vue:
  'Visualización de consulta': 'Visualización de consulta',
  'Tipo de consulta': 'Tipo de consulta',
  Medio: 'Medio',
  Lugar: 'Lugar',
  Tratamiento: 'Tratamiento',
  '- Sin tratamiento asociado -': '- Sin tratamiento asociado -',
  'Autorizaciones relacionadas': 'Autorizaciones relacionadas',
  'Estado: {status}': 'Estado: {status}',
  'No hay autorizaciones relacionadas con esta consulta': 'No hay autorizaciones relacionadas con esta consulta',
  Finalizado: 'Finalizado',
  Caducado: 'Caducado',
  Deshabilitada: 'Deshabilitada',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar las autorizaciones de compra': 'Ha ocurrido un error al cargar las autorizaciones de compra',

  // src\views\patient\expedients\documents\ThePatientDocument.vue:

  // src\views\patient\expedients\documents\ThePatientDocumentGrid.vue:
  ' Añadir documento': ' Añadir documento',

  // src\views\patient\expedients\documents\parts\ThePatientDocumentShowModal.vue:
  'Previsualización del documento': 'Previsualización del documento',
  Tamaño: 'Tamaño',
  'Fecha de subida': 'Fecha de subida',
  Radiografías: 'Radiografías',
  Analíticas: 'Analíticas',
  Resonancias: 'Resonancias',
  Imágenes: 'Imágenes',
  'Ha ocurrido al intentar descargar el archivo': 'Ha ocurrido al intentar descargar el archivo',

  // src\views\patient\expedients\documents\parts\ThePatientDocumentUpload.vue:

  // src\views\patient\expedients\general\ThePatientExpedientFileGeneral.vue:
  Últimas: 'Últimas',
  'Ver todas las consultas': 'Ver todas las consultas',
  autorizaciones: 'autorizaciones',
  'Ver todas las autorizaciones': 'Ver todas las autorizaciones',
  Histórico: 'Histórico',
  Tratamientos: 'Tratamientos',
  Medidas: 'Medidas',
  'No tienes permisos para ver las medidas': 'No tienes permisos para ver las medidas',

  // src\views\patient\expedients\general\measures\parts\TheMeasuresChartView.vue:
  'No hay datos de medidas': 'No hay datos de medidas',
  'Peso (kg)': 'Peso (kg)',
  // src\views\patient\expedients\general\parts\ThePatientGeneralAuthorizations.vue:
  'Ver autorización de compra': 'Ver autorización de compra',
  'Emitida:': 'Emitida:',

  // src\views\patient\expedients\parts\ThePatientAddressFieldset.vue:
  'Dirección de facturación': 'Dirección de facturación',
  'Dirección de envío': 'Dirección de envío',
  'Dirección 1': 'Dirección 1',
  'Dirección 2': 'Dirección 2',
  'Código postal': 'Código postal',
  Ciudad: 'Ciudad',
  Provincia: 'Provincia',
  País: 'País',

  // src\views\patient\expedients\parts\ThePatientPersonalDataModal.vue:
  'datos personales': 'datos personales',
  'Información personal': 'Información personal',
  DNI: 'DNI',

  // src\views\patient\expedients\purchaseAuthorization\ThePatientPurchaseAuthorizationList.vue:

  // src\views\patient\expedients\purchaseAuthorization\parts\ThePatientPurchaseAuthorizationShow.vue:

  // src\views\patient\expedients\treatments\ThePatientExpedientFileTreatmentsList.vue:
  'No hay tratamientos en este espediente': 'No hay tratamientos en este espediente',

  // src\views\patient\expedients\videocalls\ThePatientVideoCallOverlay.vue:
  Videollamada: 'Videollamada',
  Expandir: 'Expandir',
  'Modo ventana': 'Modo ventana',
  'Videollamada con': 'Videollamada con',
  Minimizar: 'Minimizar',
  'Iniciar videollamada': 'Iniciar videollamada',
  'Entrando en la videollamada...': 'Entrando en la videollamada...',
  'Finalizar llamada': 'Finalizar llamada',
  'Has salido de la videollamada.': 'Has salido de la videollamada.',
  'Hubo un error al intentar acceder a la videollamada.': 'Hubo un error al intentar acceder a la videollamada.',

  // src\views\patient\parts\ThePatientListProfessionals.vue:
  'Ver expediente': 'Ver expediente',
  'No tienes ningún paciente': 'No tienes ningún paciente',
  'Ha ocurrido un error al cargar los expedientes': 'Ha ocurrido un error al cargar los expedientes',

  // src\views\professional\TheProfessionalHome.vue:
  'Añadir nuevo paciente': 'Añadir nuevo paciente',
  'Ver mis interconsultas': 'Ver mis interconsultas',
  'Ver todos los pacientes': 'Ver todos los pacientes',

  // src\views\professional\TheProfessionalProfile.vue:
  'Nº documento': 'Nº documento',
  'Clínicas de trabajo': 'Clínicas de trabajo',
  // eslint-disable-next-line max-len
  'No tienes clínicas asociadas a tu perfil profesional. Puedes pedírselo a tu administrador.': 'No tienes clínicas asociadas a tu perfil profesional. Puedes pedírselo a tu administrador.',

  // src\views\professional\anamnesis\TheAnamnesisResultsModal.vue:
  'Resultados de la anamnesis': 'Resultados de la anamnesis',
  // eslint-disable-next-line max-len
  '¡Enhorabuena! El cuestionario de la anamnesis ha concluido. Según los datos aportados en esta anamnesis, el programa te muestra las siguientes sugerencias:': '¡Enhorabuena! El cuestionario de la anamnesis ha concluido. Según los datos aportados en esta anamnesis, el programa te muestra las siguientes sugerencias:',
  'No hay ninguna sugerencia.': 'No hay ninguna sugerencia.',
  'Protocolos y fases disponibles:': 'Protocolos y fases disponibles:',
  'Fase seleccionada': 'Fase seleccionada',
  'Seleccionar protocolo': 'Seleccionar protocolo',
  Seleccionar: 'Seleccionar',
  'Todos los protocolos disponibles:': 'Todos los protocolos disponibles:',
  'Seleccionar fase': 'Seleccionar fase',
  'Iniciar tratamiento': 'Iniciar tratamiento',
  Fase: 'Fase',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar los protocolos sugeridos de la anamnesis': 'Ha ocurrido un error al cargar los protocolos sugeridos de la anamnesis',

  // eslint-disable-next-line max-len
  'Se ha iniciado el tratamiento correctamente. Ya puedes visualizarlo en la parte superior del expediente': 'Se ha iniciado el tratamiento correctamente. Ya puedes visualizarlo en la parte superior del expediente',
  // eslint-disable-next-line max-len
  'Ya hay un tratamiento iniciado. Por favor, finalízalo antes de comenzar uno nuevo': 'Ya hay un tratamiento iniciado. Por favor, finalízalo antes de comenzar uno nuevo',
  'Ha ocurrido un error al confirmar la anamnesis': 'Ha ocurrido un error al confirmar la anamnesis',

  // src\views\professional\anamnesis\TheProfessionalAnamnesis.vue:
  'Responder anamnesis': 'Responder anamnesis',
  Finalizar: 'Finalizar',
  'Hay una nueva versión de anamnesis': 'Hay una nueva versión de anamnesis',
  // eslint-disable-next-line max-len
  'Estás respondiendo a una anamnesis que no es la última versión disponible. Puedes actualizarla y conservaremos todas las respuestas que se hayan mantenido en la nueva versión, o puedes continuar respondiendo a la antigua. ¿Quieres actualizar a la nueva versión?': 'Estás respondiendo a una anamnesis que no es la última versión disponible. Puedes actualizarla y conservaremos todas las respuestas que se hayan mantenido en la nueva versión, o puedes continuar respondiendo a la antigua. ¿Quieres actualizar a la nueva versión?',
  'Se ha actualizado tu anamnesis con la nueva versión': 'Se ha actualizado tu anamnesis con la nueva versión',
  'Ha ocurrido un error al actualizar la anamnesis': 'Ha ocurrido un error al actualizar la anamnesis',

  // src\views\professional\anamnesis\TheProfessionalNutritionalAnamnesis.vue:
  'Responder anamnesis nutricional': 'Responder anamnesis nutricional',

  // src\views\professional\anamnesis\parts\TheFormGroupNutritionalAnamnesis.vue.vue:
  Alergias: 'Alergias',
  Observaciones: 'Observaciones',
  'Ant. familiares': 'Ant. familiares',
  Parentesco: 'Parentesco',
  Enfermedad: 'Enfermedad',
  'Ant. personales': 'Ant. personales',
  'Act. física': 'Act. física',
  Deporte: 'Deporte',
  Medicación: 'Medicación',
  Fármaco: 'Fármaco',
  Posología: 'Posología',
  Concepto: 'Concepto',
  'Altura (cm)': 'Altura (cm)',
  'Perímetro abdominal/de cintura': 'Perímetro abdominal/de cintura',
  'Perímetro cadera': 'Perímetro cadera',
  '% Grasa': '% Grasa',
  '% Músculo': '% Músculo',
  '% Agua': '% Agua',
  '% Visceral': '% Visceral',
  '% Hueso': '% Hueso',
  IMC: 'IMC',
  BMR: 'BMR',
  ICA: 'ICA',
  ICC: 'ICC',
  '¿Qué hace después de comer?': '¿Qué hace después de comer?',
  'Trabajo sedentario': 'Trabajo sedentario',
  Pasear: 'Pasear',
  'Trabajo en movimiento': 'Trabajo en movimiento',
  'Otra opción': 'Otra opción',
  Estatura: 'Estatura',
  Peso: 'Peso',
  'Objetivo de peso': 'Objetivo de peso',
  'Perímetro abdominal': 'Perímetro abdominal',
  'Perímetro de cintura': 'Perímetro de cintura',
  '¿Has hecho dieta alguna vez?': '¿Has hecho dieta alguna vez?',
  'Selecciona una opción': 'Selecciona una opción',
  '¿Te pareció difícil?': '¿Te pareció difícil?',
  'NS/NC': 'NS/NC',
  '¿Cuánto tiempo la ha seguido?': '¿Cuánto tiempo la ha seguido?',
  'Selecciona una respuesta o escribe una opción': 'Selecciona una respuesta o escribe una opción',
  'Menos de 3 meses': 'Menos de 3 meses',
  'Entre 3 y 6 meses': 'Entre 3 y 6 meses',
  'Cuenta tu experiencia': 'Cuenta tu experiencia',
  'Campo de texto corto...': 'Campo de texto corto...',
  Descansar: 'Descansar',
  '¿Quién cocina en casa?': '¿Quién cocina en casa?',
  Yo: 'Yo',
  Si: 'Si',
  'Otra persona': 'Otra persona',
  '¿Quién hace la compra?': '¿Quién hace la compra?',
  '¿Cómo te sueles desplazar al trabajo?': '¿Cómo te sueles desplazar al trabajo?',
  Andando: 'Andando',
  Coche: 'Coche',
  'Transporte público': 'Transporte público',
  'Bicicleta o patinete': 'Bicicleta o patinete',
  '¿Hay algún alimento que no le guste?': '¿Hay algún alimento que no le guste?',
  'Pescado blanco': 'Pescado blanco',
  'Pescado azul': 'Pescado azul',
  Pasta: 'Pasta',
  Arroz: 'Arroz',
  Legumbres: 'Legumbres',
  Cerdo: 'Cerdo',
  'Frutas tropicales': 'Frutas tropicales',
  Quesos: 'Quesos',
  Lácteos: 'Lácteos',
  Huevos: 'Huevos',
  // eslint-disable-next-line max-len
  'Escriba los alimentos que no aparezcan en la lista (pulse enter cuando añada uno)': 'Escriba los alimentos que no aparezcan en la lista (pulse enter cuando añada uno)',
  Ternera: 'Ternera',
  Pollo: 'Pollo',
  Fresas: 'Fresas',
  Champiñones: 'Champiñones',
  '¿Consume alcohol o tabaco?': '¿Consume alcohol o tabaco?',
  Alcohol: 'Alcohol',
  Tabaco: 'Tabaco',
  '¿Cuál es el motivo de consulta?': '¿Cuál es el motivo de consulta?',
  // eslint-disable-next-line max-len
  'Precisa una mejora de la composición corporal y de su metabolismo energético.': 'Precisa una mejora de la composición corporal y de su metabolismo energético.',
  // eslint-disable-next-line max-len
  'Presenta síntomas digestivos o sospecha de intolerancia alimentaria.': 'Presenta síntomas digestivos o sospecha de intolerancia alimentaria.',
  // eslint-disable-next-line max-len
  'Precisa fortalecer su sistema inmune o normalizar la permeabilidad intestinal.': 'Precisa fortalecer su sistema inmune o normalizar la permeabilidad intestinal.',
  // eslint-disable-next-line max-len
  '¿Presenta alguna contraindicación para iniciar el protocolo de tratamiento Proage?': '¿Presenta alguna contraindicación para iniciar el protocolo de tratamiento Proage?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado la presencia de síntomas de alarma como pérdida de peso excesiva, dolor abdominal intenso, anemia, sangrado digestivo, masa abdominal, adenopatías, fiebre o síntomas nocturnos?': '¿Se ha descartado la presencia de síntomas de alarma como pérdida de peso excesiva, dolor abdominal intenso, anemia, sangrado digestivo, masa abdominal, adenopatías, fiebre o síntomas nocturnos?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado de forma razonable enfermedades orgánicas mediante estudios analíticos?': '¿Se ha descartado de forma razonable enfermedades orgánicas mediante estudios analíticos?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado de forma razonable intolerancias alimenticias?': '¿Se ha descartado de forma razonable intolerancias alimenticias?',
  // eslint-disable-next-line max-len
  '¿Se ha valorado la necesidad de realizar una endoscopia digestiva o una prueba de imagen radiológica?': '¿Se ha valorado la necesidad de realizar una endoscopia digestiva o una prueba de imagen radiológica?',
  // eslint-disable-next-line max-len
  '¿Se ha tenido en cuenta que la edad es un factor de riesgo de enfermedades orgánicas graves, especialmente a partir de los 50 años?': '¿Se ha tenido en cuenta que la edad es un factor de riesgo de enfermedades orgánicas graves, especialmente a partir de los 50 años?',
  '¿Sufre migrañas?': '¿Sufre migrañas?',
  '¿Tiene o ha tenido acúfenos?': '¿Tiene o ha tenido acúfenos?',
  '¿Sufre de contracturas musculares de repetición?': '¿Sufre de contracturas musculares de repetición?',
  '¿Tiene piel atópica, dermografismo , o piel muy seca?': '¿Tiene piel atópica, dermografismo , o piel muy seca?',
  '¿Es bruxista?': '¿Es bruxista?',
  // eslint-disable-next-line max-len
  '¿Tiene olvidos frecuentes, niebla mental o dificultad para concentrarse, o memorizar?': '¿Tiene olvidos frecuentes, niebla mental o dificultad para concentrarse, o memorizar?',
  '¿Está triste o ansioso?': '¿Está triste o ansioso?',
  '¿Duerme bien, siente que el sueño es reparador?': '¿Duerme bien, siente que el sueño es reparador?',
  '¿Ha sufrido síncopes alguna vez?': '¿Ha sufrido síncopes alguna vez?',
  // eslint-disable-next-line max-len
  '¿Esta cansado, se levanta cansado, tiene la sensación de no poder acabar el día?': '¿Esta cansado, se levanta cansado, tiene la sensación de no poder acabar el día?',
  '¿Tiene dolores erráticos, que van y vienen?': '¿Tiene dolores erráticos, que van y vienen?',
  '¿Se le duermen los dedos de las manos o de los pies?': '¿Se le duermen los dedos de las manos o de los pies?',
  '¿Es de manos y pies fríos?': '¿Es de manos y pies fríos?',
  '¿Es friolero/a?': '¿Es friolero/a?',
  // eslint-disable-next-line max-len
  '¿Ha tenido infecciones de orina de repetición o candidiasis de repetición o molestias al orinar?': '¿Ha tenido infecciones de orina de repetición o candidiasis de repetición o molestias al orinar?',
  '¿Es sensible a los ruidos, luces u olores?': '¿Es sensible a los ruidos, luces u olores?',
  '¿Tiene piernas inquietas?': '¿Tiene piernas inquietas?',
  '¿Le salen hematomas al más mínimo roce?': '¿Le salen hematomas al más mínimo roce?',
  // eslint-disable-next-line max-len
  'En el caso de las mujeres, ¿tiene reglas dolorosas y/o endometriosis?': 'En el caso de las mujeres, ¿tiene reglas dolorosas y/o endometriosis?',
  '¿Se recupera bien cuando hace ejercicio?': '¿Se recupera bien cuando hace ejercicio?',
  '¿El paciente tiene un IMC mayor de 25?': '¿El paciente tiene un IMC mayor de 25?',
  '¿Tiene síntomas digestivos?': '¿Tiene síntomas digestivos?',
  // eslint-disable-next-line max-len
  '¿Se ha realizado una evaluación adecuada de la microbiota y de la permeabilidad intestinal del paciente mediante estudio de microbiota, determinación de DAO, determinación de zonulina, determinación de proteína de unión a lipopolisacárido bacteriano?': '¿Se ha realizado una evaluación adecuada de la microbiota y de la permeabilidad intestinal del paciente mediante estudio de microbiota, determinación de DAO, determinación de zonulina, determinación de proteína de unión a lipopolisacárido bacteriano?',
  'Anamnesis nutricional': 'Anamnesis nutricional',
  'Fecha primera consulta': 'Fecha primera consulta',
  'Peso obj.': 'Peso obj.',
  'Perímetro de cadera': 'Perímetro de cadera',
  '¿Ha hecho dieta alguna vez?': '¿Ha hecho dieta alguna vez?',
  '¿Le pareció difícil?': '¿Le pareció difícil?',
  Siesta: 'Siesta',
  Caminar: 'Caminar',
  Trabajar: 'Trabajar',
  '¿Qué hace por la tarde?': '¿Qué hace por la tarde?',
  '¿Cómo se desplaza?': '¿Cómo se desplaza?',
  Moto: 'Moto',
  Bici: 'Bici',
  Taxi: 'Taxi',
  Metro: 'Metro',
  Autobus: 'Autobus',
  Mixto: 'Mixto',
  '¿Cómo se considera, sedentario o activo?': '¿Cómo se considera, sedentario o activo?',
  Sedentario: 'Sedentario',
  '¿Utiliza ascensor o escaleras?': '¿Utiliza ascensor o escaleras?',
  Ascensor: 'Ascensor',
  Escaleras: 'Escaleras',
  '¿Cuántos pisos son?': '¿Cuántos pisos son?',
  'Número de pisos': 'Número de pisos',
  '¿Come en casa o fuera?': '¿Come en casa o fuera?',
  Fuera: 'Fuera',
  Casa: 'Casa',
  Ambos: 'Ambos',
  '¿Cuántos días fuera de casa?': '¿Cuántos días fuera de casa?',
  'Algún alimento que no le guste:': 'Algún alimento que no le guste:',
  '¿Alcohol o tabaco?': '¿Alcohol o tabaco?',
  // eslint-disable-next-line max-len
  '¿Algún momento del día , situación o estado de ánimo en el que le apetezca comer?': '¿Algún momento del día , situación o estado de ánimo en el que le apetezca comer?',
  '¿Cómo come?': '¿Cómo come?',
  Solo: 'Solo',
  Acompañado: 'Acompañado',
  '¿En casa o trabajo?': '¿En casa o trabajo?',
  Trabajo: 'Trabajo',
  '¿Deprisa o despacio?': '¿Deprisa o despacio?',
  deprisa: 'deprisa',
  Despacio: 'Despacio',
  'Con los disgustos, ¿pierde el apetito?': 'Con los disgustos, ¿pierde el apetito?',
  'Con el estrés o ansiedad, ¿come más?': 'Con el estrés o ansiedad, ¿come más?',
  '¿Come todo lo que quiere?': '¿Come todo lo que quiere?',
  '¿Está comiendo más de lo que necesita?': '¿Está comiendo más de lo que necesita?',
  // eslint-disable-next-line max-len
  'Al terminar de comer, ¿queda satisfecho o seguiría comiendo?': 'Al terminar de comer, ¿queda satisfecho o seguiría comiendo?',
  'Satisfecho/a': 'Satisfecho/a',
  'Insatisfecho/a': 'Insatisfecho/a',
  '¿Cuánto tarda en comer?': '¿Cuánto tarda en comer?',
  Mucho: 'Mucho',
  Poco: 'Poco',
  'Qué le gusta más, ¿dulce o salado': 'Qué le gusta más, ¿dulce o salado',
  Dulce: 'Dulce',
  Salado: 'Salado',
  '¿Realiza algún deporte?': '¿Realiza algún deporte?',
  '¿Cuál?': '¿Cuál?',
  '¿Cuántas veces por semana?': '¿Cuántas veces por semana?',
  'Selecciona una respuesta': 'Selecciona una respuesta',
  '1 día a la semana': '1 día a la semana',
  '2 días a la semana': '2 días a la semana',
  '3 días a la semana': '3 días a la semana',
  '4 días a la semana': '4 días a la semana',
  '5 días a la semana': '5 días a la semana',
  '6 días a la semana': '6 días a la semana',
  '7 días a la semana': '7 días a la semana',
  'Todas las veces que puedo': 'Todas las veces que puedo',
  // eslint-disable-next-line max-len
  'Me siento cansado y desanimado cuando me levanto por las mañanas': 'Me siento cansado y desanimado cuando me levanto por las mañanas',
  Nunca: 'Nunca',
  'Pocas veces': 'Pocas veces',
  'Algunas veces': 'Algunas veces',
  Continuamente: 'Continuamente',
  Siempre: 'Siempre',
  'Mis músculos están tensos y doloridos': 'Mis músculos están tensos y doloridos',
  'Tengo ataques de pánico': 'Tengo ataques de pánico',
  'Rechino los dientes o aprieto la mandíbula': 'Rechino los dientes o aprieto la mandíbula',
  'Tengo problemas de diarrea o estreñimiento': 'Tengo problemas de diarrea o estreñimiento',
  'Necesito ayuda para realizar mis actividades diarias': 'Necesito ayuda para realizar mis actividades diarias',
  'Soy sensible a la luz brillante': 'Soy sensible a la luz brillante',
  'Me canso fácilmente cuando estoy físicamente activo': 'Me canso fácilmente cuando estoy físicamente activo',
  'Siento dolor en todo mi cuerpo': 'Siento dolor en todo mi cuerpo',
  'Tengo dolores de cabeza': 'Tengo dolores de cabeza',
  // eslint-disable-next-line max-len
  'Tengo molestia en mi vejiga o sensación de quemazón al orinar': 'Tengo molestia en mi vejiga o sensación de quemazón al orinar',
  'No duermo bien': 'No duermo bien',
  'Tengo dificultad para concentrarme': 'Tengo dificultad para concentrarme',
  // eslint-disable-next-line max-len
  'Tengo problemas en la piel como resequedad, picor o sarpullido': 'Tengo problemas en la piel como resequedad, picor o sarpullido',
  'El estrés hace que mis síntomas físicos empeoren': 'El estrés hace que mis síntomas físicos empeoren',
  'Me siento triste o deprimido': 'Me siento triste o deprimido',
  'Me siento con poca energía': 'Me siento con poca energía',
  'Tengo tensión muscular en mi cuello y hombros': 'Tengo tensión muscular en mi cuello y hombros',
  'Tengo tensión en mi mandíbula': 'Tengo tensión en mi mandíbula',
  // eslint-disable-next-line max-len
  'Algunos olores, como perfumes, me hacen sentir mareado y con náuseas': 'Algunos olores, como perfumes, me hacen sentir mareado y con náuseas',
  'Tengo que orinar frecuentemente': 'Tengo que orinar frecuentemente',
  // eslint-disable-next-line max-len
  'Mis piernas se sienten incómodas e inquietas cuando intento dormir por la noche': 'Mis piernas se sienten incómodas e inquietas cuando intento dormir por la noche',
  'Tengo dificultad para recordar cosas': 'Tengo dificultad para recordar cosas',
  'Sufrí algún trauma cuando era niño(a)': 'Sufrí algún trauma cuando era niño(a)',
  'Tengo dolor en mi zona pélvica': 'Tengo dolor en mi zona pélvica',

  // src\views\professional\anamnesis\parts\TheFormGroupAnamnesis.vue:
  'Ha ocurrido un error al guardar los cambios': 'Ha ocurrido un error al guardar los cambios',

  // src\views\professional\calendar\parts\TheProfessionalCalendarEventCreate.vue:
  Nuevo: 'Nuevo',
  // eslint-disable-next-line max-len
  'Debes seleccionar un paciente para que le aparezca en la agenda': 'Debes seleccionar un paciente para que le aparezca en la agenda',
  // eslint-disable-next-line max-len
  'Al crear esta {tipo_consuta} se mandará un SMS al paciente con la hora indicada.': 'Al crear esta {tipo_consuta} se mandará un SMS al paciente con la hora indicada.',

  // src\views\professional\calendar\parts\TheProfessionalCalendarEventUpdate.vue:
  // eslint-disable-next-line max-len
  'Al editar la hora de esta "{tipo_consulta}" se mandará un SMS al paciente con la nueva hora indicada.': 'Al editar la hora de esta "{tipo_consulta}" se mandará un SMS al paciente con la nueva hora indicada.',
  // eslint-disable-next-line max-len
  'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}". ¿Estás seguro?': 'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}". ¿Estás seguro?',
  // eslint-disable-next-line max-len
  'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}" con el paciente {nombre_paciente} {apellido_paciente}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?': 'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}" con el paciente {nombre_paciente} {apellido_paciente}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?',

  // src\views\professional\documents\parts\TheProfessionalDocumentShowModal.vue:
  'Subido por': 'Subido por',
  Descargar: 'Descargar',

  // src\views\professional\expedients\TheProfessionalExpedientFile.vue:
  'Ficha del paciente:': 'Ficha del paciente:',
  'Datos personales': 'Datos personales',
  'Finalizar tratamiento': 'Finalizar tratamiento',
  'Cambiar de fase': 'Cambiar de fase',
  Reenviar: 'Reenviar',
  Anamnesis: 'Anamnesis',
  'Ha ocurrido un error al empezar la anamnesis': 'Ha ocurrido un error al empezar la anamnesis',

  // src\views\professional\expedients\TheProfessionalExpedientsList.vue:
  'NIF / DNI': 'NIF / DNI',

  // src\views\professional\expedients\anamnesis\TheProfessionalExpedientAnamnesisTab.vue:
  Nutricional: 'Nutricional',
  'De protocolo': 'De protocolo',
  'Test de Sensibilidad Central': 'Test de Sensibilidad Central',
  'Tratamiento en curso': 'Tratamiento en curso',
  // eslint-disable-next-line max-len
  'Para responder la anamnesis de protocolo no puede haber un tratamiento en curso. Finaliza el tratamiento actual para poder responderla nuevamente.': 'Para responder la anamnesis de protocolo no puede haber un tratamiento en curso. Finaliza el tratamiento actual para poder responderla nuevamente.',

  // src\views\professional\expedients\consultation\TheProfessionalConsultationShow.vue:
  'Seleccione alguna o cree una nueva.': 'Seleccione alguna o cree una nueva.',

  // src\views\professional\expedients\consultation\parts\TheProfessionalConsultationCreate.vue:
  Nueva: 'Nueva',
  consulta: 'consulta',
  'Fecha de inicio*': 'Fecha de inicio*',
  'Medio consulta*': 'Medio consulta*',
  'Consulta física': 'Consulta física',
  'Medio de consulta': 'Medio de consulta',
  'Fecha de inicio': 'Fecha de inicio',
  '- Sin observaciones -': '- Sin observaciones -',
  'La consulta se ha añadido correctamente': 'La consulta se ha añadido correctamente',
  'Ha ocurrido un error al crear la consulta': 'Ha ocurrido un error al crear la consulta',

  // src\views\professional\expedients\consultation\parts\TheProfessionalConsultationUpdate.vue:
  'Fecha de fin': 'Fecha de fin',
  'Consulta física*': 'Consulta física*',
  'Nueva autorización': 'Nueva autorización',
  'Los cambios se han guardado correctamente.': 'Los cambios se han guardado correctamente.',
  '¡Uups!': '¡Uups!',
  // eslint-disable-next-line max-len
  'El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.': 'El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.',
  'Volver al panel': 'Volver al panel',

  'Crear autorización de compra': 'Crear autorización de compra',
  'Editar consulta': 'Editar consulta',
  'Nueva autorización de compra': 'Nueva autorización de compra',

  // src\views\professional\expedients\general\TheProfessionalExpedientFileGeneral.vue:
  'No tienes permisos para ver las medidas del paciente': 'No tienes permisos para ver las medidas del paciente',
  // eslint-disable-next-line max-len
  'No tienes permisos para ver los tratamientos del paciente': 'No tienes permisos para ver los tratamientos del paciente',

  // src\views\professional\expedients\measures\TheProfessionalExpedientMeasuresList.vue:
  Tabla: 'Tabla',
  Gráfica: 'Gráfica',
  'Añadir nueva medida': 'Añadir nueva medida',
  'Añadir medida': 'Añadir medida',
  'Los datos se han guardado correctamente.': 'Los datos se han guardado correctamente.',
  'Ha ocurrido un error al guardar las medidas': 'Ha ocurrido un error al guardar las medidas',

  // src\views\professional\expedients\measures\parts\TheMeasuresTableView.vue:
  'Ha ocurrido un error al guardar los datos': 'Ha ocurrido un error al guardar los datos',
  'Los datos se han eliminado correctamente': 'Los datos se han eliminado correctamente',
  'Ha ocurrido un error al eliminar los datos': 'Ha ocurrido un error al eliminar los datos',
  '¿Seguro que desea eliminar este dato?': '¿Seguro que desea eliminar este dato?',
  'Al borrar el dato no podrás volver a visualizarlo': 'Al borrar el dato no podrás volver a visualizarlo',
  'No hay medidas.': 'No hay medidas.',

  // src\views\professional\expedients\medicalData\forms\TheProfessionalExpedientFormsList.vue:
  alergias: 'alergias',
  antecedentes_familiares: 'antecedentes familiares',
  antecedentes_personales: 'antecedentes personales',
  actividad_fisica: 'actividad Física',
  medicacion: 'medicación',
  observaciones: 'observaciones',
  'No hay datos de esta categoría': 'No hay datos de esta categoría',
  respuesta: 'respuesta',
  'Parece que ese registro ya ha sido introducido.': 'Parece que ese registro ya ha sido introducido.',
  'Parece que ese registro no existe o ya se ha eliminado.': 'Parece que ese registro no existe o ya se ha eliminado.',

  // src\views\professional\expedients\medicalData\forms\parts\VFormRow.vue:
  'Eliminar registro': 'Eliminar registro',
  Borrar: 'Borrar',

  // src/views/professional/expedients/medicalData/forms/TheProfessionalExpedientFormsList.vue
  Animales: 'Animales',
  'Ácaros del polvo': 'Ácaros del polvo',
  Moho: 'Moho',
  Insectos: 'Insectos',
  'Polen de gramíneas': 'Polen de gramíneas',
  'Polen de árbol': 'Polen de árbol',
  'Polen de malezas': 'Polen de malezas',
  Polen: 'Polen',
  Cacahuetes: 'Cacahuetes',
  Soja: 'Soja',
  'Frutos secos': 'Frutos secos',
  Trigo: 'Trigo',
  'Pescado y marisco': 'Pescado y marisco',
  'Frutas y verduras': 'Frutas y verduras',
  Leche: 'Leche',
  'No conocidas': 'No conocidas',

  // src/views/professional/expedients/medicalData/TheProfessionalExpedientFileMedicalData.vue
  'Añadir {medicalData}': 'Añadir {medicalData}',
  'Añadir nueva {nombre}': 'Añadir nueva {nombre}',

  // src\views\professional\expedients\parts\TheProfessionalExpedientCreate.vue:
  // eslint-disable-next-line max-len
  'Ya tienes registrado a un paciente con ese número de identificación.': 'Ya tienes registrado a un paciente con ese número de identificación.',

  // src\views\professional\expedients\parts\TheProfessionalPatientAddressFieldset.vue:
  Población: 'Población',
  'Actualizar dirección de facturación': 'Actualizar dirección de facturación',
  'Actualizar dirección de envío': 'Actualizar dirección de envío',
  // eslint-disable-next-line max-len
  'La dirección de facturación se ha actualizado correctamente.': 'La dirección de facturación se ha actualizado correctamente.',
  'La dirección de envío se ha actualizado correctamente.': 'La dirección de envío se ha actualizado correctamente.',

  // src\views\professional\expedients\parts\TheProfessionalPatientDataProtection.vue:
  'Protección de': 'Protección de',
  datos: 'datos',
  // eslint-disable-next-line max-len
  'Para poder continuar con el proceso es necesario que el paciente acepte la información de protección de datos. Para ello, puedes enviarla mediante las dos opciones disponibles.': 'Para poder continuar con el proceso es necesario que el paciente acepte la información de protección de datos. Para ello, puedes enviarla mediante las dos opciones disponibles.',
  // eslint-disable-next-line max-len
  'En caso de que el paciente no haya recibido notificación alguna, puede reenviarlo pinchando de nuevo en una de las opciones. En el momento en el que el paciente acepte la política, podrá acceder a su ficha para completar sus datos pinchando en “continuar”.': 'En caso de que el paciente no haya recibido notificación alguna, puede reenviarlo pinchando de nuevo en una de las opciones. En el momento en el que el paciente acepte la política, podrá acceder a su ficha para completar sus datos pinchando en “continuar”.',
  'Enviar RGPD mediante SMS': 'Enviar RGPD mediante SMS',
  'Enviar RGPD mediante Email': 'Enviar RGPD mediante Email',
  'Enviar RGPD mediante email': 'Enviar RGPD mediante email',

  // eslint-disable-next-line max-len
  'La notificación se ha enviado. En caso de no recibirla, podrás volver a enviarla dentro de:': 'La notificación se ha enviado. En caso de no recibirla, podrás volver a enviarla dentro de:',
  // eslint-disable-next-line max-len
  'También se puede imprimir en papel la RGPD y que el paciente la lea y firme en persona. Para ello, descarga e imprime el documento pulsando el botón "Descargar para imprimir", y, a continuación, pulsa en el botón "Paciente ha firmado RGPD en papel".': 'También se puede imprimir en papel la RGPD y que el paciente la lea y firme en persona. Para ello, descarga e imprime el documento pulsando el botón "Descargar para imprimir", y, a continuación, pulsa en el botón "Paciente ha firmado RGPD en papel".',
  'Descargar para imprimir': 'Descargar para imprimir',
  'Paciente ha firmado RGPD en papel': 'Paciente ha firmado RGPD en papel',
  'Ir al expediente': 'Ir al expediente',
  'El paciente aún no ha aceptado el RGPD': 'El paciente aún no ha aceptado el RGPD',
  // eslint-disable-next-line max-len
  'El paciente ya ha aceptado el RGPD, te estamos redirigiendo': 'El paciente ya ha aceptado el RGPD, te estamos redirigiendo',
  'Se ha enviado la política de datos por SMS.': 'Se ha enviado la política de datos por SMS.',
  // eslint-disable-next-line max-len
  'Se ha enviado la política de datos por correo electrónico y SMS.': 'Se ha enviado la política de datos por correo electrónico y SMS.',
  'El RGPD se ha enviado corectamente': 'El RGPD se ha enviado corectamente',
  // eslint-disable-next-line max-len
  'El envío por SMS está actualmente deshabilitado. Utiliza el correo electrónico.': 'El envío por SMS está actualmente deshabilitado. Utiliza el correo electrónico.',
  'Confirmar RGPD': 'Confirmar RGPD',
  // eslint-disable-next-line max-len
  'Si el paciente ya aceptado la RGPD de forma física, confirma para continuar y ver el expediente': 'Si el paciente ya aceptado la RGPD de forma física, confirma para continuar y ver el expediente',
  'RGPD aceptadas con éxito': 'RGPD aceptadas con éxito',
  'Ha ocurrido un error al aceptar las RGPD': 'Ha ocurrido un error al aceptar las RGPD',

  // src\views\professional\expedients\parts\TheProfessionalPatientPersonalDataModal.vue:
  'Datos actualizados con éxito.': 'Datos actualizados con éxito.',
  'El {identityDocType} registrado no es Válido': 'El {identityDocType} registrado no es Válido',

  // src\views\professional\expedients\treatments\TheProfessionalExpedientFileTreatmentsList.vue:
  'No hay tratamientos': 'No hay tratamientos',
  'Seleccionar tratamiento': 'Seleccionar tratamiento',

  // src\views\professional\expedients\treatments\parts\TheProfessionalTreatmentChangePhaseModal.vue:
  'Cambiar de': 'Cambiar de',
  fase: 'fase',
  'Selecciona una nueva fase para el tratamiento: ': 'Selecciona una nueva fase para el tratamiento: ',
  'Debes elegir una fase para poder continuar': 'Debes elegir una fase para poder continuar',
  'Se ha cambiado de fase correctamente': 'Se ha cambiado de fase correctamente',
  'Ha ocurrido un error al cambiar de fase': 'Ha ocurrido un error al cambiar de fase',

  // src\views\professional\expedients\treatments\parts\TheProfessionalTreatmentChangePhaseModal.vue:
  tratamiento: 'tratamiento',
  // eslint-disable-next-line max-len
  'Vas a finalizar el tratamiento actual, ¿estás seguro? De ser así, indica por favor el motivo:': 'Vas a finalizar el tratamiento actual, ¿estás seguro? De ser así, indica por favor el motivo:',
  label: 'label',
  // eslint-disable-next-line max-len
  'Debes elegir una de las razones dadas o escribir una propia': 'Debes elegir una de las razones dadas o escribir una propia',
  Razón: 'Razón',
  'Se ha finalizado el tratamiento correctamente': 'Se ha finalizado el tratamiento correctamente',
  'Ha ocurrido un error, el tratamiento ya está finalizado': 'Ha ocurrido un error, el tratamiento ya está finalizado',
  'Ha ocurrido un error al finalizar el tratamiento': 'Ha ocurrido un error al finalizar el tratamiento',

  // src\views\professional\expedients\videocalls\TheVideoCallOverlay.vue:
  'Compartir enlace': 'Compartir enlace',
  'Ver ayuda': 'Ver ayuda',
  'Crear sala de reuniones': 'Crear sala de reuniones',
  'Crear sala': 'Crear sala',
  'Ayuda de videollamadas': 'Ayuda de videollamadas',
  'Has llegado a tu límite': 'Has llegado a tu límite',

  // src\views\professional\expedients\videocalls\TheVideoCallShareModal.vue:
  'Compartir enlace de acceso a la videollamada': 'Compartir enlace de acceso a la videollamada',
  'Este es el enlace de invitación a la videollamada:': 'Este es el enlace de invitación a la videollamada:',
  Copiar: 'Copiar',
  // eslint-disable-next-line max-len
  'También puedes enviar el enlace al correo electrónico o a través de un SMS del paciente:': 'También puedes enviar el enlace al correo electrónico o a través de un SMS del paciente:',
  'Enviar correo electrónico': 'Enviar correo electrónico',
  'Enviar SMS': 'Enviar SMS',
  // eslint-disable-next-line max-len
  'Enlace de acceso a la videollamada copiado al portapapeles.': 'Enlace de acceso a la videollamada copiado al portapapeles.',
  'Enviar invitación por correo': 'Enviar invitación por correo',
  // eslint-disable-next-line max-len
  'Vamos a enviar un correo electrónico al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?': 'Vamos a enviar un correo electrónico al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?',
  'Enviar correo con enlace': 'Enviar correo con enlace',
  '¡Enviado!': '¡Enviado!',
  // eslint-disable-next-line max-len
  'Hemos enviado el enlace al paciente por correo electrónico. Ahora podrá unirse a la videollamada.': 'Hemos enviado el enlace al paciente por correo electrónico. Ahora podrá unirse a la videollamada.',
  'Ha ocurrido un error': 'Ha ocurrido un error',
  'Algo ha salido mal, vuelve a probar más tarde...': 'Algo ha salido mal, vuelve a probar más tarde...',

  'Enviar invitación por SMS': 'Enviar invitación por SMS',
  // eslint-disable-next-line max-len
  'Vamos a enviar un SMS al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?': 'Vamos a enviar un SMS al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?',
  'Enviar SMS con enlace': 'Enviar SMS con enlace',
  // eslint-disable-next-line max-len
  'Hemos enviado el enlace al paciente por SMS. Ahora podrá unirse a la videollamada.': 'Hemos enviado el enlace al paciente por SMS. Ahora podrá unirse a la videollamada.',

  // src\views\professional\inter-consultations\TheProfessionalInterConsultationsList.vue:
  interconsultas: 'interconsultas',
  'Nueva interconsulta': 'Nueva interconsulta',
  Con: 'Con',
  'F. Creación': 'F. Creación',
  'No hay interconsultas': 'No hay interconsultas',
  ' - Sin paciente - ': ' - Sin paciente - ',
  Finalizada: 'Finalizada',
  Abierta: 'Abierta',
  'Ver interconsulta': 'Ver interconsulta',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultation.vue:
  'Edición de': 'Edición de',
  interconsulta: 'interconsulta',
  Creador: 'Creador',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationCreate.vue:
  'Profesional*': 'Profesional*',
  'Categoría*': 'Categoría*',
  'La interconsulta se ha creado con éxito': 'La interconsulta se ha creado con éxito',
  'Ha ocurrido un error al crear la interconsulta': 'Ha ocurrido un error al crear la interconsulta',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationUpdate.vue:
  'Finalizar interconsulta': 'Finalizar interconsulta',
  De: 'De',
  Comentarios: 'Comentarios',
  'No hay comentarios': 'No hay comentarios',
  Tú: 'Tú',
  'Ha ocurrido un error al actualizar la interconsulta': 'Ha ocurrido un error al actualizar la interconsulta',
  // eslint-disable-next-line max-len
  'Si finalizas esta interconsulta podrás seguir visualizando los datos y comentarios pero no editarla ni comentar nuevamente. ¿Estás seguro?': 'Si finalizas esta interconsulta podrás seguir visualizando los datos y comentarios pero no editarla ni comentar nuevamente. ¿Estás seguro?',
  'La interconsulta se ha finalizado con éxito': 'La interconsulta se ha finalizado con éxito',
  'Ha ocurrido un error al finalizar la interconsulta': 'Ha ocurrido un error al finalizar la interconsulta',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationView.vue:
  'Escribe tu mensaje': 'Escribe tu mensaje',
  Enviar: 'Enviar',
  Mensaje: 'Mensaje',
  'Ha ocurrido un error al enviar el comentario': 'Ha ocurrido un error al enviar el comentario',
  'Eliminar comentario': 'Eliminar comentario',
  '¿Estás seguro de que quieres eliminar este comentario?': '¿Estás seguro de que quieres eliminar este comentario?',
  'Ha ocurrido un error al borrar el comentario': 'Ha ocurrido un error al borrar el comentario',

  // src\views\professional\parts\TheProfessionalHomeExpedients.vue:
  // src\views\professional\purchaseAuthorization\ThePurchaseAuthorizationList.vue:

  // src\views\professional\purchaseAuthorization\ThePurchaseAuthorizationList.vue:
  'Reenviar enlace': 'Reenviar enlace',
  'Reenviar enlace de compra': 'Reenviar enlace de compra',
  'Se ha enviado un nuevo enlace de compra correctamente.': 'Se ha enviado un nuevo enlace de compra correctamente.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al enviar un nuevo enlace de la autorización de compra': 'Ha ocurrido un error al enviar un nuevo enlace de la autorización de compra',
  // eslint-disable-next-line max-len
  'Se va a enviar un nuevo  enlace de compra al paciente y se anularán los anteriores. Desde este nuevo enlace podrá adquirir los productos. ¿Estás seguro?': 'Se va a enviar un nuevo  enlace de compra al paciente y se anularán los anteriores. Desde este nuevo enlace podrá adquirir los productos. ¿Estás seguro?',

  // src\views\professional\purchaseAuthorization\parts\TheProductListModal.vue:
  'Productos disponibles': 'Productos disponibles',
  'Añadir nuevo producto': 'Añadir nuevo producto',
  'Restar producto': 'Restar producto',
  'Sumar producto': 'Sumar producto',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  Suplementación: 'Suplementación',
  Producto: 'Producto',
  // eslint-disable-next-line max-len
  'Para {treatmentDuration} días y {posology} tomas diarias ({dose} dosis) de tratamiento, puedes autorizar hasta un máximo de': 'Para {treatmentDuration} días y {posology} tomas diarias ({dose} dosis) de tratamiento, puedes autorizar hasta un máximo de',
  '{suggestedBoxes} cajas ({productSuggestedBoxes} sobres)': '{suggestedBoxes} cajas ({productSuggestedBoxes} sobres)',
  'de producto. No hay limitación para la suplementación.': 'de producto. No hay limitación para la suplementación.',
  // src\views\professional\purchaseAuthorization\parts\TheProductListModal.vue:
  Aceptar: 'Aceptar',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  'Duración del tratamiento*': 'Duración del tratamiento*',
  'Introducir días': 'Introducir días',
  'Posología*': 'Posología*',
  'Introducir dosis': 'Introducir dosis',
  // eslint-disable-next-line max-len
  'Tienes más productos en la cesta de los permitidos. Elimina alguno.': 'Tienes más productos en la cesta de los permitidos. Elimina alguno.',
  'dosis durante': 'dosis durante',
  días: 'días',
  // eslint-disable-next-line max-len
  'Para añadir productos tienes que introducir la duración del tratamiento y la posología.': 'Para añadir productos tienes que introducir la duración del tratamiento y la posología.',
  // eslint-disable-next-line max-len
  'Selecciona los productos necesarios para crear la autorización de compra': 'Selecciona los productos necesarios para crear la autorización de compra',
  '10 sobres por caja': '10 sobres por caja',
  '0 cajas': '0 cajas',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  Protocolo: 'Protocolo',
  'Duración del tratamiento': 'Duración del tratamiento',
  Packs: 'Packs',
  Otros: 'Otros',
  'Enviar autorización de compra': 'Enviar autorización de compra',
  // eslint-disable-next-line max-len
  'Autorización botella': 'Si es el primer pedido del paciente y contiene más de 6 productos de las líneas ProAge On o ProAge On + recibirán una botella mezcladora de regalo. No es necesario añadirla en la autorización',
  // eslint-disable-next-line max-len
  'Para este tratamiento se recomienda el uso de la suplementación ProAge On Plus (+). Queda a la elección del profesional el uso de otras lineas.': 'Para este tratamiento se recomienda el uso de la suplementación ProAge On Plus (+). Queda a la elección del profesional el uso de otras lineas.',
  // eslint-disable-next-line max-len
  'Para este tratamiento se recomienda el uso de la suplementación ProAge On. Queda a la elección del profesional el uso de otras lineas.': 'Para este tratamiento se recomienda el uso de la suplementación ProAge On. Queda a la elección del profesional el uso de otras lineas.',
  'Límite de cajas alcanzado.': 'Límite de cajas alcanzado.',
  // eslint-disable-next-line max-len
  '¿Seguro que quieres crear esta nueva autorización de compra?': '¿Seguro que quieres crear esta nueva autorización de compra?',
  'La autorización de compra se ha creado correctamente': 'La autorización de compra se ha creado correctamente',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al añadir la autorización de compra': 'Ha ocurrido un error al añadir la autorización de compra',
  'Deshabilitar autorización de compra': 'Deshabilitar autorización de compra',
  Deshabilitar: 'Deshabilitar',
  // eslint-disable-next-line max-len
  '¿Estás seguro de que quieres deshabilitar esta autorización de compra? Si la deshabilitas,no podrá utilizarse para adquirir productos en la tienda.': '¿Estás seguro de que quieres deshabilitar esta autorización de compra? Si la deshabilitas,no podrá utilizarse para adquirir productos en la tienda.',
  // eslint-disable-next-line max-len
  'La autorización de compra se ha deshabilitado correctamente': 'La autorización de compra se ha deshabilitado correctamente',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al dishabilitar la autorización de compra': 'Ha ocurrido un error al dishabilitar la autorización de compra',

  // src\views\professional\resources\TheProfessionalResourcesGrid.vue:
  'Visualizar documento': 'Visualizar documento',

  // src\views\professional\videocalls\Call.vue:
  Recargar: 'Recargar',
  'Estamos esperando a que se conecte tu paciente...': 'Estamos esperando a que se conecte tu paciente...',
  'Estamos esperando a que se conecte tu médico...': 'Estamos esperando a que se conecte tu médico...',

  // src\views\professional\videocalls\Chat.vue:
  'Mostrar/Ocultar chat': 'Mostrar/Ocultar chat',
  'Escribe un mensaje...': 'Escribe un mensaje...',

  // src\views\professional\videocalls\Controls.vue:
  'Desactivar micrófono': 'Desactivar micrófono',
  'Activar micrófono': 'Activar micrófono',
  'Desactivar cámara': 'Desactivar cámara',
  'Activar cámara': 'Activar cámara',
  'Dejar de compartir pantalla': 'Dejar de compartir pantalla',
  'Compartir pantalla': 'Compartir pantalla',

  // src\views\professional\videocalls\PermissionsErrorMsg.vue:
  'Cámara o micrófono bloqueado': 'Cámara o micrófono bloqueado',
  Reintentar: 'Reintentar',

  // src\views\professional\videocalls\PermissionsErrorMsg.vue:
  'Conseguir ayuda': 'Conseguir ayuda',

  // src\views\professional\videocalls\WaitingCard.vue:
  Email: 'Email',
  SMS: 'SMS',
  'Esperando a que alguien se conecte a la videollamada...': 'Esperando a que alguien se conecte a la videollamada...',
  'Vamos a redirigirte hacia la tienda de PROAGE BY LFL®': 'Vamos a redirigirte hacia la tienda de PROAGE BY LFL®',

  // src\views\shop\TheShopOrderRedirection.vue:
  // eslint-disable-next-line max-len
  'Este proceso puede tardar unos segundos mientras preparamos tu pedido': 'Este proceso puede tardar unos segundos mientras preparamos tu pedido',
  'Ha ocurrido un error.': 'Ha ocurrido un error.',
  // eslint-disable-next-line max-len
  'Por favor, comprueba que el enlace es válido y no ha caducado, y vuelve a intentarlo más tarde.': 'Por favor, comprueba que el enlace es válido y no ha caducado, y vuelve a intentarlo más tarde.',

  // eslint-disable-next-line max-len
  'El número debe ser un valor entre 0 y 100, con máximo 2 decimales.': 'El número debe ser un valor entre 0 y 100, con máximo 2 decimales.',
};
