import BaseObject from '@/api/objects/BaseObject';
import Product from '@/api/objects/Product';
import TreatmentProtocolPhase from '@/api/objects/TreatmentProtocolPhase';

export interface PurchaseAuthorizationContract {
  consultationId: number;
  createdAt: string;
  deletedAt: string;
  dueDate: string;
  expedientId: number;
  maxProducts: number;
  status: string;
  subtotal: number;
  subtotalTaxes: number;
  treatmentId: number | null;
  updatedAt: string;
  // TODO: See in the future if this is actually needed as we access it through productPurchaseAuthorization
  patient: any;
  products: Product[];
  professional: any;
  protocolPhaseTreatment: TreatmentProtocolPhase | null;
}

export default class PurchaseAuthorization extends BaseObject implements PurchaseAuthorizationContract {
  get consultationId(): number {
    return this.attributes.consultation_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get deletedAt(): any {
    return this.attributes.deleted_at;
  }

  get dueDate(): string {
    return this.attributes.due_date;
  }

  get expedientId(): number {
    return this.attributes.expedient_id;
  }

  get maxProducts(): number {
    return this.attributes.max_products;
  }

  get status(): string {
    return this.attributes.status;
  }

  get subtotal(): number {
    return this.attributes.subtotal;
  }

  get subtotalTaxes(): number {
    return this.attributes.subtotal_taxes;
  }

  get treatmentId(): number | null {
    return this.attributes.treatment_id;
  }

  get updatedAt(): any {
    return this.attributes.updated_at;
  }

  get patient(): any {
    return this.getRelation('patient');
  }

  // TODO: See in the future if this is actually needed as we access it through productPurchaseAuthorization
  get products(): Product[] {
    return this.getRelation('products');
  }

  get professional(): any {
    return this.getRelation('professional');
  }

  get protocolPhaseTreatment(): TreatmentProtocolPhase | null {
    return this.getRelation('treatment_protocol_phase');
  }
}
