export default {
  // src\components\structural\TheImpersonateStop.vue:
  'Regresar a panel de administrador': 'Return to admin panel',

  // src\components\structural\TheLogout.vue:
  'Cerrar sesión': 'Logout',

  // src\components\structural\TheMenuAdministrator.vue:
  Inicio: 'Home',
  'Logo ProAge': 'ProAge logo',
  Clínicas: 'Clinics',
  Profesionales: 'Professionals',
  Categorías: 'Categories',
  'Categorías vídeos': 'Video categories',
  'Categorías recursos': 'Resource categories',
  Pacientes: 'Patients',
  Recursos: 'Resources',
  Videoteca: 'Video Library',
  Agenda: 'Calendar',
  Menú: 'Menu',
  Media: 'Media',
  'Aut. de compra': 'Purchase authorizations',
  // eslint-disable-next-line max-len
  'Has iniciado sesión como el usuario { user } ({ email }).': 'You are logged in as user { user } ({ email }).',

  // src\components\structural\TheMenuPatient.vue:
  'Toggle navigation': 'Toggle navigation',
  'Funcionalidad no disponible': 'Functionality not available',
  // src\components\structural\TheMenuProfessional.vue:
  Interconsultas: 'Inter-consultations',
  'Mi perfil': 'My profile',

  // src\components\structural\VUnderConstruction.vue:
  'En construcción': 'Under Construction',
  // src\components\vendor\VLoader.vue:
  'Cargando...': 'Loading...',
  // src\components\vendor\VPagination.vue:
  'Navigation example': 'Navigation example',

  // src\components\vendor\VProfessionalConsultations.vue:
  Consulta: 'Consultation',
  '- Sin clínica asociada -': '- No associated clinic -',
  'Ver consulta': 'View consultation',
  'No tienes ninguna consulta': 'You have no consultations',
  'No hay consultas': 'No consultations founded',
  'Nueva consulta': 'New consultation',
  'Ver paciente': 'View patient',
  // src\components\vendor\modal\ModalHeader.vue:
  Cerrar: 'Close',

  // src\resources\modal.ts:
  Información: 'Information',
  'Modal de información': 'Information modal',
  Eliminar: 'Delete',
  'Eliminando...': 'Deleting...',
  Cancelar: 'Cancel',
  Confirmar: 'Confirm',
  'Confirmando...': 'Confirming...',

  // src\store\modules\general\index.ts:
  Título: 'Title',
  Text: 'Text',
  'Procesando...': 'Processing...',
  ID: 'ID',

  // src\views\ThePageNotFound.vue:
  'Ups!': 'Oops!',
  'Te has perdido': 'You are lost',
  // eslint-disable-next-line max-len
  'No hemos podido encontrar la página que estás buscando.': 'We could not find the page you are looking for',
  // eslint-disable-next-line max-len
  'Si quieres puedes ir a la página de inicio haciendo click en el siguiente botón:': 'If you want you can go to the home page by clicking on the following button:',

  // src\views\administrator\TheAdminHome.vue:
  'Hola,': 'Hello,',
  'Crear profesional': 'Create professional',
  'Crear categoría': 'Create category',
  'Añadir nuevo profesional': 'Add new professional',
  'Añadir nueva categoría': 'Add new category',
  'Ver profesionales': 'View professionals',
  'Ver categorías': 'View categories',
  'Ver pacientes': 'View patients',
  'Ver clínicas': 'View clinics',
  'Ver aut. de compra': 'View purchase auth.',

  // src\views\administrator\calendar\parts\TheAdminCalendarEventCreate.vue:
  'evento de calendario': 'calendar event',
  Asunto: 'Subject',
  Tipo: 'Type',
  'No se han encontrado resultados': 'No results found',
  Paciente: 'Patient',
  'Obligatorio: Se le asignará la cita a este paciente': 'Mandatory: this patient\'s appointment will be asigned',
  Clínica: 'Clinic',
  Profesional: 'Professional',
  Category: 'Category',
  // eslint-disable-next-line max-len
  'Obligatorio: Se le asignará la cita a este profesional': 'Mandatory: appointment will be assigned to this professional',
  Fecha: 'Date',
  Fin: 'End',
  'Durante todo el día': 'Throughout the day',
  Descripción: 'Description',
  // eslint-disable-next-line max-len
  'Al crear esta {tipo_consulta} se mandará un SMS al paciente con la hora indicada.': 'When creating this {tipo_consulta} an SMS will be sent to the patient with the indicated time.',
  Añadir: 'Add',
  'Debes seleccionar una clínica donde pasar consulta': 'You must select a clinic where to have a consultation',
  'Primera consulta': 'First consultation',
  Revisión: 'Review',
  Videoconsulta: 'Videoconsultation',
  'Crear {tipo_consulta}': 'Create {tipo_consulta}',
  // eslint-disable-next-line max-len
  'Vas a crear una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.': 'You are going to create a {tipo_consulta} appointment between a professional and a patient. In addition, if the professional did not have access to that patient before, a new record will be created in which the patient will have to authorize the use of his data so that the professional can treat him.',
  'Se ha creado el evento de calendario con éxito.': 'The calendar event has been created successfully.',
  'Ha ocurrido un error al crear el evento de calendario.': 'An error has ocurred while creating the calendar event.',

  // src\views\administrator\calendar\parts\TheAdminCalendarEventUpdate.vue:
  'Editando evento': 'Editing event',
  'Introduce una descripción aquí...': 'Enter description here',
  'No hay clínica asociada': 'No associated clinic',

  // eslint-disable-next-line max-len
  'Al editar la hora de esta {tipo_consulta} se mandará un SMS al paciente.': 'Editing this time of this {tipo_consulta} will be send an SMS to the patient.',
  'Guardar cambios': 'Save changes',
  'Editar {tipo_consulta}': 'Edit {tipo_consulta}',
  // eslint-disable-next-line max-len
  'Vas a editar una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.': 'You are going to edit a {tipo_consulta} between a professional and a patient. Both will be able to view it in their agenda. In addition, if the professional did not have access to that patient before, a new record will be created in which the patient will have to authorize the use of his data so that the professional can treat him.',
  'Se ha actualizado el evento de calendario con éxito.': 'Calendar event has been updated successfully.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al modificar el evento de calendario.': 'An error has occurred while modifying the calendar event.',
  // eslint-disable-next-line max-len
  'Vas a eliminar la {tipo_consulta} de nombre {titulo}. ¿Estás seguro?': 'You are going to delete the {tipo_consulta} of name {titulo}. Are you sure?',
  // eslint-disable-next-line max-len
  'Vas a eliminar la {tipo_consulta} de nombre {titulo} entre el profesional {profesional_nombre} {profesional_apellido} con el paciente {paciente_nombre} {paciente_apellido}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?': 'Vas a eliminar la {tipo_consulta} de nombre {titulo} entre el profesional {profesional_nombre} {profesional_apellido} con el paciente {paciente_nombre} {paciente_apellido}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?',
  'Eliminar evento': 'Delete event',
  'Se ha eliminado el evento de calendario con éxito.': 'Calendar event has been deleted successfully.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al eliminar el evento de calendario.': 'An error has occurred while deleting the calendar event.',

  // src\views\administrator\clinic\TheAdminClinicCreateModal.vue:
  'nueva clínica': 'new clinic',
  Nombre: 'name',
  'Prefijo tel.': 'phone prefix',
  'Dirección personal': 'Personal address',
  'Dirección profesional': 'Professional address',
  Teléfono: 'Phone',
  Dirección: 'Address',
  'Dar de alta': 'Register',
  Apellidos: 'Surname',
  'Prefijo telefónico': 'Telephone prefix',
  'Número de teléfono': 'Phone number',
  'Permitir acceso a la aplicación': 'Allow access to the app',

  // src\views\administrator\clinic\TheAdminClinicEdit.vue:
  'Debe tener entre 6 y 14 cifras': 'Must be between 6 and 14 digits',
  'Clínica {nombre_clinica} creada con éxito.': 'Clinic {nombre_clinica} successfully created.',
  Actualizar: 'Update',
  'Clínica actualizada con éxito.': 'Clinic successfully updated.',

  // src\views\administrator\clinic\TheAdminClinicList.vue:
  'Listado de': 'Listing of',
  clínicas: 'clinics',
  'Nueva clínica': 'New clinic',
  'Buscar clínica': 'Search clinic',
  'Limpiar filtros': 'Clear filters',
  Acciones: 'Actions',
  'No hay clínicas': 'No clinics',
  Ver: 'View',
  Editar: 'Edit',
  'Ha ocurrido un error al cargar las clínicas': 'An error occurred while loading clinics',
  'Asociar profesional a la clínica': 'Associate professional to clinic',

  // src\views\administrator\clinic\TheAdminClinicShow.vue:
  Asociar: 'Associate',
  'Profesionales asociados a la clínica': 'Professionals associated to the clinic',
  Desasociar: 'Disassociate',
  'No hay profesionales asociados a esta clínica': 'There are no professionals associated with this clinic',
  'Asociar profesional y clínica': 'Associate professional and clinic',
  // eslint-disable-next-line max-len
  'Vas a asociar el profesional con esa clínica. Esto significa que podrá utilizarla como clínica para sus consultas. ¿Estás seguro?': 'You are going to associate the professional with that clinic. This means that he will be able to use it as a clinic for his consultations. Are you sure?',
  'Desasociar profesional y clínica': 'Desasociar profesional y clínica',
  // eslint-disable-next-line max-len
  'Vas a desasociar el profesional con esa clínica. Esto significa que no podrá utilizarla como clínica para sus consultas. ¿Estás seguro?': 'You are going to disassociate the professional with that clinic. This means that he will not be able to use it as a clinic for his consultations. Are you sure?',
  // eslint-disable-next-line max-len
  'Profesional asociado/desasociado con éxito de la clínica.': 'Successfull associate/unassociate professional of the clinic.',
  // eslint-disable-next-line max-len
  'El profesional tiene alguna de sus consultas asociadas a la clínica, no podemos desasociarlo.': 'Professional has some of his consultations associated with the clinic, we cannot disassociate him.',

  // src\views\administrator\patient\TheAdminPatientExpedientCreate.vue:
  'nuevo paciente': 'new patient',
  'Tipo de documento': 'Document type',
  'Nº documento de identidad': 'ID card no.',
  'Fecha de nacimiento': 'Date of birth',
  Género: 'gender',
  email: 'email',
  'Correo electrónico': 'Email',
  'Otros teléfonos': 'Other phones',
  // eslint-disable-next-line max-len
  'Se creará un expediente entre el profesional elegido y el paciente nuevo que vas a crear, por lo que el profesional tendrá acceso a la información del paciente y podrá comenzar a tratarlo.': 'A record will be created between the chosen professional and the new patient you are going to create, so the professional will have access to the patient\'s information and will be able to start treating the patient',
  'Tipo de Documento': 'Type of document',
  'Número de documento': 'Document number',
  'El {tipo_documento} registrado no es Válido': 'The {tipo_documento} registered is not valid',
  'La fecha de nacimiento no puede ser futura': 'Date of birth cannot be in the future',
  'Crear expediente': 'Create expedient',
  // eslint-disable-next-line max-len
  'Vas a crear un nuevo paciente y adjudicárselo al profesional indicado. De esta forma, se creará ese paciente y el profesional podrá comenzar a tratarlo. Además, enviaremos un correo electrónico al paciente para que restablezca su contraseña y tenga acceso a la plataforma. ¿Estás seguro?': 'You are going to create a new patient and assign it to the indicated professional. In this way, this patient will be created and the professional will be able to start treating him/her. In addition, we will send an email to the patient to reset their password and give them access to the platform. Are you sure?',
  Crear: 'Create',
  'El paciente se ha creado correctamente': 'Patient has been successfully created',
  // eslint-disable-next-line max-len
  'El número de teléfono ya existe en nuestra base de datos, prueba con otro': 'The phone number already exist in our database, try another',
  // eslint-disable-next-line max-len
  'Ese profesional ya tiene registrado a un paciente con ese número de identificación.': 'That professional already has a patient registered with that ID number.',
  'Ha ocurrido un error al añadir el nuevo paciente': 'An error has occurred while adding the new patient',

  // src\views\administrator\patient\TheAdminPatientShow.vue:
  'Datos básicos': 'Basic data',
  'Tipo doc. identificativo': 'ID doc type',
  'Nº doc. identificativo': 'ID doc no.',
  Personificación: 'Personification',
  // eslint-disable-next-line max-len
  'Puedes iniciar sesión como si fueses el paciente en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc.Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.': 'You can log in as if you were the patient in question to see the same things that this professional sees and thus be able to check errors reported, validate functionalities, etc. You will enter the platform as if you had logged in with his or her credentials. Be careful because the changes you make will also be definitive as if they had been theirs.',
  'Iniciar sesión como el paciente': 'Log in as the patient',
  Contacto: 'Contact',
  'Profesionales relacionados': 'Related professionals',
  // eslint-disable-next-line max-len
  'Este es el listado de profesionales que han atendido o están atendiendo a este paciente.': 'This is the list of professional who have seen or are seeing this patient.',
  'Este paciente no tiene expedientes': 'This patient has no expedients',
  Especialidad: 'Specialty',
  'Estado RGPD': 'RGPD status',
  Confirmada: 'Confirmed',
  Pendiente: 'Pending',
  'Iniciar sesión como': 'Log in as',
  // eslint-disable-next-line max-len
  'Vas a iniciar sesión como el paciente {nombre_paciente} {apellido_paciente}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?': 'You are going to log in as patient {nombre_paciente} {apellido_paciente}. You will be able to see and do everything he and return to your administrator profile whenever you want. Do you want to continue?',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al intentar personificar al usuario': 'An error occurred while trying to impersonate the user',

  // src\views\administrator\patient\TheAdminPatientsList.vue:
  'Error personificando': 'Error impersonating',
  pacientes: 'patients',
  'Nuevo paciente': 'New patient',
  Masculino: 'Male',
  Femenino: 'Female',
  'Buscar paciente': 'Search patient',
  Apellido: 'Last name',
  'Doc. identificativo': 'Identifying document',
  'Fecha nacimiento': 'Date of birth',
  'No hay pacientes': 'No patients',
  'Ha ocurrido un error al cargar los pacientes': 'An error has occurred while loading patients',

  // src\views\administrator\patient\TheAdminPatientUpdate.vue:
  'Tipo documento': 'Document type',
  'Nº documento identidad': 'ID document no.',
  'Los datos se han guardado correctamente': 'Data has been saved successfully',

  // src\views\administrator\professional\TheAdminProfessionalDestroy.vue:
  'borrar profesional': 'The selected professional will be deleted. Do you wish to continue?',

  // src\views\administrator\professional\TheAdminProfessionalCreate.vue:
  'nuevo profesional': 'new professional',
  'Nueva categoría': 'New category',
  'nueva categoría': 'new category',
  Subespecialidad: 'subspecialty',
  Colegio: 'College',
  'Nº colegiado': 'collegiate no.',
  // eslint-disable-next-line max-len
  'Puedes permitir que este profesional cree autorizaciones de compra sin necesidad de responder anamnesis ni iniciar un tratamiento. Si este campo está marcado podrá autorizar libremente a sus pacientes.': 'You can allow this professional to create purchase authorizations without answering anamnesis or initiating treatment. If this field is checked you can freely authorize your patients',
  'Permitir autorizar libremente': 'Allow to authorize freely',
  Médico: 'Medic',
  Nutricionista: 'Nutritionist',
  'Puede autorizar libremente': 'Allow to authorize freely',
  'Profesional creado': 'Created professional',
  'Categoría creada': 'Created category',
  // TODO: Check this :name :surname instead of {name} {surname}
  // eslint-disable-next-line max-len
  'Se ha creado el profesional :name :surname con éxito, le hemos enviado un correo de bienvenida y otro para que establezca su contraseña. El profesional debe establecer su contraseña para poder acceder a la plataforma.': 'The professional :name :surname has been successfully created, we have sent him a welcome email and another one to set his password. The professional must set his password to be able to access the platform.',

  // src\views\administrator\professional\TheAdminProfessionalsList.vue:
  profesionales: 'professionals',
  categorías: 'categories',
  'Nuevo profesional': 'New professional',
  'Buscar profesional': 'Search professional',
  'Buscar categoría': 'Search category',
  Correo: 'Mail',
  'No hay profesionales': 'No professionals',
  'Ha ocurrido un error al cargar los profesionales': 'An error has occurred while loading professionals',
  'No hay categorías': 'No categories',
  'Ha ocurrido un error al cargar los categorías': 'An error has occurred while loading categories',

  // src\views\administrator\professional\parts\TheAdminProfessionalShow.vue:
  'Visualización de': 'Display of',
  profesional: 'professional',
  'Tipo documento identidad': 'Identity document type',
  'Tipo de profesional': 'Type of professional',
  'Prefijo teléfono': 'Phone prefix',
  'Nº teléfono': 'Phone number',
  '¿Puede autorizar libremente?': 'Can him authorize freely?',
  'Profesional eliminado': 'Professional deleted',
  'Se ha eliminado el profesional con éxito.': 'Professional was deleted successfully.',
  Activo: 'Active',
  // eslint-disable-next-line max-len
  'Puedes iniciar sesión como si fueses el profesional en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc. Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.': 'You can log in as if you were the professional in question to visualize the same as that professional sees and thus be able to check errors he/she reports, validate functionalities, etc. You will enter the platform as if you had logged in with their credentials. Be careful because the changes you make will also be definitive as if they had been theirs.',
  // eslint-disable-next-line max-len
  'Iniciar sesión como el profesional {nombre_profesional}': 'Log in as the professional {nombre_profesional}',
  Principal: 'Principal',
  Colegiado: 'Member',
  'Número de colegiado': 'Collegiate number',
  'Lugares de trabajo': 'Workplaces',
  'Añadir nueva clínica': 'Add new clinic',
  'Seleccciona un clínica': 'Select a clinic',
  Sí: 'Yes',
  No: 'No',
  // eslint-disable-next-line max-len,no-template-curly-in-string
  'Vas a iniciar sesión como el profesional {nombre_profesional}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?': 'You are going to log in as the professional {nombre_profesional}. You will be able to see and do everything he and return to your admin profile whenever you want. Do you want to continue?',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al intentar personificar al profesional': 'An error occurred while trying to impersonate the professional',
  '- Sin tipo -': '- No type -',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar todas las clínicas de la plataforma.': 'An error has occurred while loading all clinics on the platform.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar los datos del profesional': 'An error has occurred while loading the professional data.',

  // src\views\administrator\professional\parts\TheAdminProfessionalUpdate.vue:
  'Modificación de': 'Modification of',
  'Debe ser un tipo de profesional permitido': 'Must be a permitted professional type',
  'Debe ser una especialidad válida': 'Must be a valid specialty',
  'Debes introducir une fecha válida': 'Must enter a valid date',

  // src\views\administrator\purchaseAuthorization\TheAdminPurchaseAuthorizationList.vue:
  'autorizaciones de compra': 'purchase authorizations',
  'Fecha vencimiento': 'Expiration date',
  Vencimiento: 'Expiration',
  Productos: 'Products',
  Estado: 'Status',
  'No hay autorizaciones de compra': 'No purchase authorizations',
  '{count} productos distintos': '{count} different products',
  Visualizar: 'Display',
  Subtotal: 'Subtotal',
  Impuestos: 'Taxes',
  'Descargar excel agrupado': 'Download grouped excel',
  'Descargar excel detallado': 'Download detailed excel',

  // src\views\administrator\purchaseAuthorization\parts\TheAdminPurchaseAuthorizationShow.vue:
  'Autorización de compra': 'Purchase authorization',
  'Fecha creación:': 'Creation date:',
  'Datos de la autorización de compra:': 'Purchase authorization data: ',
  'Profesional:': 'Professional:',
  'Categoría:': 'Category:',
  'Paciente:': 'Patient: ',
  'Fecha de vencimiento:': 'Expiration date:',
  'Estado:': 'Status:',
  'Protocolo:': 'Protocol:',
  'Fase:': 'Phase:',
  '{doses} dosis durante {days} días': '{doses} doses for {days} days',
  Volver: 'Return',

  // src\views\administrator\resources\TheAdminResources.vue:
  recursos: 'resources',
  'Selecciona el tipo de documento': 'Select document type',
  'Selecciona el tipo de categoría': 'Select category type',
  'Subcategoría de': 'Subcategory of',
  'Ha ocurrido un error al cargar los documentos': 'An error has occurred while loading documents',

  // src\views\administrator\resources\TheAdminResourcesGrid.vue:
  'Añadir documento': 'Add document',
  'No hay documentos de esta categoría': 'No documents in this category',
  'Previsualizar documento': 'Preview document',

  // src\views\administrator\resources\TheAdminResourcesList.vue:
  // src\views\administrator\resources\parts\TheAdminResourcesShowModal.vue:
  'Visualizar archivo': 'Display file',
  'Descargar archivo': 'Download file',
  'Borrar archivo': 'Delete file',
  'Ha ocurrido un error al intentar descargar el archivo': 'An error has occurred while trying to download the file',
  'Eliminar recurso': 'Delete resource',
  // eslint-disable-next-line max-len
  'Vas a eliminar el recurso. No podrás recuperarlo. ¿Estás seguro?': 'You are going to delete the resource. You won\'t be able to recover it. Are you sure?',
  'El recurso se ha eliminado correctamente.': 'The resource has been successfully deleted.',
  'Ha ocurrido un error al intentar eliminar el archivo.': 'An error has occurred while trying to delete the file.',

  // src\views\administrator\resources\parts\TheAdminResourcesUpload.vue:
  'Otros documentos': 'Other documents',
  'Guardar documentos': 'Save documents',
  Guardar: 'Save',
  'Los documentos se han guardado correctamente.': 'Documents have been saved successfully',
  'Ha ocurrido un error al guardar los documentos': 'An error has occurred while saving the documents',

  // src\views\administrator\videos\TheAdminVideos.vue:
  vídeos: 'videos',
  'Borrar categoría': 'Delete category',
  'Selecciona una categoría': 'Select category',
  'Añadir vídeo': 'Add video',
  'Ha ocurrido un error al cargar los vídeos': 'An error has occurred while loading videos',

  // src\views\administrator\videos\TheAdminVideosGrid.vue:
  'No hay vídeos de esta categoría': 'There are no videos in this category',
  'Ver vídeo': 'View video',
  'Vídeo eliminado': 'Video deleted',
  'Se ha eliminado el vídeo con éxito.': 'Video was deleted successfully.',

  // src\views\administrator\videos\category\TheAdminVideoCategoryDestroy.vue:
  'borrar categoría': 'The selected category will be deleted. Do you wish to continue?',
  'Categoría eliminada': 'Category deleted',
  'Se ha eliminado la categoría con éxito.': 'Category was deleted successfully.',

  // src\views\administrator\videos\category\TheAdminVideoCategoryCreate.vue:
  'Añadir categoría': 'Add category',
  'Categoría creada correctamente': 'Category created successfully',

  // src\views\administrator\videos\parts\TheAdminVideoCreate.vue:
  'borrar vídeo': 'The selected video will be deleted. Do you wish to continue?',

  // src\views\administrator\videos\parts\TheAdminVideoCreate.vue:
  'nuevo vídeo': 'new video',
  'Añadir categoria': 'Add category',
  Visibilidad: 'Visibility',
  Url: 'Url',
  Categoría: 'Category',
  'Vídeo creado': 'Video created',
  'Se ha creado el vídeo {nombre} con éxito.': 'Video {nombre} has been created successfully.',

  // src\views\administrator\videos\parts\TheAdminVideoShow.vue:
  vídeo: 'video',
  'Editar vídeo': 'Edit video',
  'Ha ocurrido un error al cargar el vídeo.': 'An error has occurred while loading video.',

  // src\views\administrator\videos\parts\TheAdminVideoUpdate.vue:
  'Vídeo editado': 'Edited video',
  'Se ha editado el vídeo "{ name }" con éxito.': 'Video "{ name }" has been edited successfully.',

  // src\views\auth\TheForgotPassword.vue:
  'Reestablecer contraseña': 'Reset password',
  'Introduce tu correo': 'Enter your email',
  // eslint-disable-next-line max-len
  'Te enviaremos un correo electrónico con un enlace para que puedas generar una nueva contraseña. Si no lo recibes revisa la carpeta de spam.': 'We will send you an email with a link to generate a new password. If you don\'t receive it check your spam folder.',
  // eslint-disable-next-line max-len
  'Te acabamos de reenviar el correo, revisa tu carpeta de spam por si acaso.': 'We just forwarded the email to you, check your spam folder just in case.',

  // src\views\auth\TheLogin.vue:
  'Inicio de sesión': 'Login',
  Contraseña: 'Password',
  'He olvidado mi contraseña': 'I forgot my password',
  'Iniciar sesión': 'Login',

  // src\views\auth\TheResetPassword.vue:
  'Nueva contraseña': 'New password',
  'Confirma tu nueva contraseña': 'Confirm your new password',
  'Confirmación de la contraseña': 'Confirm password',

  // src\views\auth\TheResetPasswordSuccessful.vue:
  '¡Felicidades!': 'Congratulations!',
  // eslint-disable-next-line max-len
  'Tu contraseña ha sido restablecida de forma satisfactoria. Ya puedes volver a iniciar sesión e introducir tus nuevos credenciales.': 'Your password has been successfully reset. You can now log in again and enter your new credentials.',

  // src\views\auth\TheRGPDAcceptance.vue:
  'Política de protección de datos': 'Data protection policy',
  // eslint-disable-next-line max-len
  'Proage by LFL, S.L. le informa que va a tratar sus datos personales con la finalised de prestar los servicios contratados y/o enviar comunicaciones comerciales. La base jurídica es la ejecución de un contrato de prestación de servicios y el interés legítimo de Proage; tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional, que puede consultar en nuestra web.': 'Proage by LFL, S.L. informs you that it will process your personal data for the purpose of providing the contracted services and/or sending commercial communications. The legal basis is the execution of a contract for the provision of services and the legitimate interest of Proage; you have the right to access, rectify and delete the data, as well as other rights, as explained in the additional information, which you can consult on our website.',
  'He leído y acepto la': 'I have read and accept the',
  'Política de Privacidad': 'Privacy Policy',
  'de Proage': 'from Proage',
  // eslint-disable-next-line max-len
  'Consiento de forma expresa la cesión de mis datos personales al médico asignado por Proage para mi tratamiento.': 'I expressly consent to the transfer of my personal data to the physician assigned by Proage for my treatment',
  Continuar: 'Continue',
  'Debes aceptar la política de protección de datos': 'You must accept the data protection policy',
  // eslint-disable-next-line max-len
  'Debes aceptar las otras condiciones relacionadas con la protección de datos': 'You must accept the other conditions related to data protection',
  'Debes aceptar las condiciones para continuar.': 'You must accept the conditions to continue',

  // src\views\auth\TheRGPDAcceptanceResponse.vue:
  '¡Gracias por aceptar las políticas de ProAge!': 'Thank you for accepting the ProAge policies!',
  // eslint-disable-next-line max-len
  '¡Parece que tus políticas ya han sido aceptadas anteriormente!': 'It looks like your policies have been accepted before!',
  '¡Uups! Ha ocurrido un error.': 'Oops! An error has occurred.',
  'El token provisto no es válido.': 'The token provided is invalid.',

  // src\views\auth\TheVerifyUserResponse.vue:
  '¡Error!': 'Error!',
  Listo: 'Done',

  // src\views\patient\ThePatientHome.vue:
  'Modificar mi agenda': 'Modify my schedule',
  'Consultar mi agenda': 'View my schedule',
  Tus: 'Yours',
  'Ha ocurrido un error al cargar las consultas': 'An error has occurred while loading consultations',

  // src\views\patient\calendar\ThePatientCalendarEventShow.vue:
  Evento: 'Event',

  // src\views\patient\expedients\ThePatientExpedientFile.vue:
  'Tu médico es:': 'Your medic is:',
  'Mis datos personales': 'My personal data',
  '(Finalizado)': '(Finished)',
  '- Sin datos -': '- No data -',
  'Fecha de inicio:': 'Start date:',
  'Última consulta:': 'Last consultation:',
  'GRPD:': 'GRPD:',
  Válido: 'Valid',
  'Sin aceptar': 'Not accepted',
  General: 'General',
  'Datos médicos': 'Medical data',
  Consultas: 'Consultations',
  Documentos: 'Documents',

  // src\views\patient\expedients\treatments\ThePatientExpedientFileTreatmentsList.vue
  'En curso': 'In progress',

  // src\views\patient\expedients\consultation\ThePatientConsultation.vue:
  'Politicas RGPD no aceptadas en el expediente': 'RGPD policies not accepted in the expedient',

  // src\views\patient\expedients\consultation\ThePatientConsultationScroll.vue:
  'Historial de': 'History of',
  consultas: 'consultations',

  // src\views\patient\expedients\consultation\ThePatientConsultationScrollItem.vue:

  // src\views\patient\expedients\consultation\ThePatientConsultationShow.vue:
  'No hay niguna consulta seleccionada.': 'No consultation selected.',

  // src\views\patient\expedients\consultation\parts\ThePatientConsultationView.vue:
  'Visualización de consulta': 'Consultation view',
  'Tipo de consulta': 'Consultation type',
  Medio: 'Way',
  Lugar: 'Location',
  Tratamiento: 'Treatment',
  '- Sin tratamiento asociado -': '- No associated treatment -',
  'Autorizaciones relacionadas': 'Related authorizations',
  'Estado: {status}': 'Status: {status}',
  'No hay autorizaciones relacionadas con esta consulta': 'No authorizations related to this query',
  Finalizado: 'Finished',
  Caducado: 'Expired',
  Deshabilitada: 'Disabled',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar las autorizaciones de compra': 'An error has occurred while loading purchase authorizations',

  // src\views\patient\expedients\documents\ThePatientDocument.vue:

  // src\views\patient\expedients\documents\ThePatientDocumentGrid.vue:
  ' Añadir documento': ' Add document',

  // src\views\patient\expedients\documents\parts\ThePatientDocumentShowModal.vue:
  'Previsualización del documento': 'Document preview',
  Tamaño: 'Size',
  'Fecha de subida': 'Upload date',
  Radiografías: 'Radiographs',
  Analíticas: 'Analytical',
  Resonancias: 'Resonances',
  Imágenes: 'Images',
  'Ha ocurrido al intentar descargar el archivo': 'An error has occurred while trying to download the file',

  // src\views\patient\expedients\documents\parts\ThePatientDocumentUpload.vue:

  // src\views\patient\expedients\general\ThePatientExpedientFileGeneral.vue:
  Últimas: 'Last',
  'Ver todas las consultas': 'View all consultations',
  autorizaciones: 'authorizations',
  'Ver todas las autorizaciones': 'View all authorizations',
  Histórico: 'Historical',
  Tratamientos: 'Treatments',
  Medidas: 'Measures',
  'No tienes permisos para ver las medidas': 'You do not have permissions to view measures',

  // src\views\patient\expedients\general\measures\parts\TheMeasuresChartView.vue:
  'No hay datos de medidas': 'No measurement data',
  'Peso (kg)': 'Weight (kg)',
  // src\views\patient\expedients\general\parts\ThePatientGeneralAuthorizations.vue:
  'Ver autorización de compra': 'View purchase authorization',
  'Emitida:': 'Emitted:',

  // src\views\patient\expedients\parts\ThePatientAddressFieldset.vue:
  'Dirección de facturación': 'Billing address',
  'Dirección de envío': 'Shipping address',
  'Dirección 1': 'Address 1',
  'Dirección 2': 'Address 2',
  'Código postal': 'Postal code',
  Ciudad: 'City',
  Provincia: 'Province',
  País: 'Country',

  // src\views\patient\expedients\parts\ThePatientPersonalDataModal.vue:
  'datos personales': 'personal data',
  'Información personal': 'personal information',
  DNI: 'DNI',

  // src\views\patient\expedients\purchaseAuthorization\ThePatientPurchaseAuthorizationList.vue:

  // src\views\patient\expedients\purchaseAuthorization\parts\ThePatientPurchaseAuthorizationShow.vue:

  // src\views\patient\expedients\treatments\ThePatientExpedientFileTreatmentsList.vue:
  'No hay tratamientos en este espediente': 'There are no treatments in this expedient',

  // src\views\patient\expedients\videocalls\ThePatientVideoCallOverlay.vue:
  Videollamada: 'Videocall',
  Expandir: 'Expand',
  'Modo ventana': 'Window mode',
  'Videollamada con': 'Videocall with',
  Minimizar: 'Minimize',
  'Iniciar videollamada': 'Start video call',
  'Entrando en la videollamada...': 'Entering video call...',
  'Finalizar llamada': 'End call',
  'Has salido de la videollamada.': 'You have exited the video call.',
  'Hubo un error al intentar acceder a la videollamada.': 'There was an error trying to access the video call.',

  // src\views\patient\parts\ThePatientListProfessionals.vue:
  'Ver expediente': 'View expedient',
  'No tienes ningún paciente': 'You have no patient',
  'Ha ocurrido un error al cargar los expedientes': 'An error has occurred while loading expedients',

  // src\views\professional\TheProfessionalHome.vue:
  'Añadir nuevo paciente': 'Add new patient',
  'Ver mis interconsultas': 'View my inter-consultations',
  'Ver todos los pacientes': 'Ver todos los View all patients',

  // src\views\professional\TheProfessionalProfile.vue:
  'Nº documento': 'Document no.',
  'Clínicas de trabajo': 'Work clinics',
  // eslint-disable-next-line max-len
  'No tienes clínicas asociadas a tu perfil profesional. Puedes pedírselo a tu administrador.': 'You have no clinics associated with your professional profile. You can ask your administrator',

  // src\views\professional\anamnesis\TheAnamnesisResultsModal.vue:
  'Resultados de la anamnesis': 'Anamnesis results',
  // eslint-disable-next-line max-len
  '¡Enhorabuena! El cuestionario de la anamnesis ha concluido. Según los datos aportados en esta anamnesis, el programa te muestra las siguientes sugerencias:': 'Congratulations! The anamnesis questionnaire has been completed. According to the data provided in this anamnesis, the program shows you the following suggestions:',
  'No hay ninguna sugerencia.': 'No suggestion',
  'Protocolos y fases disponibles:': 'Protocols and phases available',
  'Fase seleccionada': 'Selected phase',
  'Seleccionar protocolo': 'Select protocol',
  Seleccionar: 'Select',
  'Todos los protocolos disponibles:': 'All available protocols',
  'Seleccionar fase': 'Select phase',
  'Iniciar tratamiento': 'Star treatment',
  Fase: 'Phase',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar los protocolos sugeridos de la anamnesis': 'An error has occurred while loading suggested anamnesis protocols',

  // eslint-disable-next-line max-len
  'Se ha iniciado el tratamiento correctamente. Ya puedes visualizarlo en la parte superior del expediente': 'The treatment has been successfully started. You can now view it at the top of the file',
  // eslint-disable-next-line max-len
  'Ya hay un tratamiento iniciado. Por favor, finalízalo antes de comenzar uno nuevo': 'A treatment has already been started. Please end it before starting a new one',
  'Ha ocurrido un error al confirmar la anamnesis': 'An error has occurred while confirming the anamnesis',

  // src\views\professional\anamnesis\TheProfessionalAnamnesis.vue:
  'Responder anamnesis': 'Reply anamnesis',
  Finalizar: 'Finish',
  'Hay una nueva versión de anamnesis': 'There is a new version of anamnesis',
  // eslint-disable-next-line max-len
  'Estás respondiendo a una anamnesis que no es la última versión disponible. Puedes actualizarla y conservaremos todas las respuestas que se hayan mantenido en la nueva versión, o puedes continuar respondiendo a la antigua. ¿Quieres actualizar a la nueva versión?': 'You are responding to an anamnesis that is not the latest version available. You can update it and we will keep all the answers that have been kept in the new version, or you can continue responding to the old one. Do you want to update to the new version?',
  'Se ha actualizado tu anamnesis con la nueva versión': 'Se ha actualizado tu anamnesis con la nueva versión',
  'Ha ocurrido un error al actualizar la anamnesis': 'An error has occurred while updating the anamnesis',

  // src\views\professional\anamnesis\TheProfessionalNutritionalAnamnesis.vue:
  'Responder anamnesis nutricional': 'Respond nutritional anamnesis',

  // src\views\professional\anamnesis\parts\TheFormGroupNutritionalAnamnesis.vue.vue:
  Alergias: 'Allergies',
  Observaciones: 'Observations',
  'Ant. familiares': 'Family history',
  Parentesco: 'Kinship',
  Enfermedad: 'Illness',
  'Ant. personales': 'Personal history',
  'Act. física': 'Physical Act.',
  Deporte: 'Sport',
  Medicación: 'Medication',
  Fármaco: 'Drug', // TODO review
  Posología: 'Posology',
  Concepto: 'Concept',
  'Altura (cm)': 'Height (cm)',
  'Perímetro abdominal/de cintura': 'Abdominal/waist circumference',
  'Perímetro cadera': 'Hip circumference',
  '% Grasa': '% Fat',
  '% Músculo': '% Muscle',
  '% Agua': '% Water',
  '% Visceral': '% Visceral',
  '% Hueso': '% Bone',
  IMC: 'BMI',
  BMR: 'BMR',
  ICA: 'ICA',
  ICC: 'ICC',
  Anamnesis: 'Anamnesis',
  '¿Qué hace después de comer?': 'What do you do after eating?',
  'Trabajo sedentario': 'Sedentary work',
  Pasear: 'Walking',
  'Trabajo en movimiento': 'Work in motion',
  'Otra opción': 'Other option',
  Estatura: 'Height',
  Peso: 'Weight',
  'Objetivo de peso': 'Target weight',
  'Perímetro abdominal': 'Abdominal circumference',
  'Perímetro de cintura': 'Waist circumference',
  '¿Has hecho dieta alguna vez?': 'Have you ever been on a diet?',
  'Selecciona una opción': 'Select an option',
  '¿Te pareció difícil?': 'Did you find it difficult?',
  'NS/NC': 'NS/NC',
  '¿Cuánto tiempo la ha seguido?': 'How long have you followed it?',
  'Selecciona una respuesta o escribe una opción': 'Select an answer or write down an option',
  'Menos de 3 meses': 'Less than 3 months',
  'Entre 3 y 6 meses': 'Between 3 and 6 months',
  'Cuenta tu experiencia': 'Tell about your experience',
  'Campo de texto corto...': 'Short text field...',
  Descansar: 'Rest',
  '¿Quién cocina en casa?': 'Who cooks at home?',
  Yo: 'Me',
  Si: 'Yes',
  'Otra persona': 'Someone else',
  '¿Quién hace la compra?': 'Who does the shopping?',
  '¿Cómo te sueles desplazar al trabajo?': 'How do you usually commute to work?',
  Andando: 'Walking',
  Coche: 'Car',
  'Transporte público': 'Public transportation',
  'Bicicleta o patinete': 'Bike or scooter',
  '¿Hay algún alimento que no le guste?': 'Are there any foods you don\'t like?',
  'Pescado blanco': 'White fish',
  'Pescado azul': 'Blue fish',
  'Frutos secos': 'Nuts',
  Pasta: 'Pasta',
  Arroz: 'Rice',
  Legumbres: 'Legumes',
  Cerdo: 'Pork',
  'Frutas tropicales': 'Tropical fruits',
  Quesos: 'Cheeses',
  Lácteos: 'Dairy',
  Huevos: 'Eggs',
  // eslint-disable-next-line max-len
  'Escriba los alimentos que no aparezcan en la lista (pulse enter cuando añada uno)': 'Type in any foods not listed (press enter when you add one).',
  Ternera: 'Beef',
  Pollo: 'Chicken',
  Fresas: 'Strawberries',
  Champiñones: 'Mushrooms',
  '¿Consume alcohol o tabaco?': 'Do you consume alcohol or tobacco?',
  Alcohol: 'Alcohol',
  Tabaco: 'Tobacco',
  '¿Cuál es el motivo de consulta?': 'What is the reason for consultation?',
  // eslint-disable-next-line max-len
  'Precisa una mejora de la composición corporal y de su metabolismo energético.': 'You require improvement of your body composition and energy metabolism.',
  // eslint-disable-next-line max-len
  'Presenta síntomas digestivos o sospecha de intolerancia alimentaria.': 'Presents digestive symptoms or suspicion of food intolerance.',
  // eslint-disable-next-line max-len
  'Precisa fortalecer su sistema inmune o normalizar la permeabilidad intestinal.': 'You need to strengthen your immune system or normalize intestinal permeability.',
  // eslint-disable-next-line max-len
  '¿Presenta alguna contraindicación para iniciar el protocolo de tratamiento Proage?': 'Do you present any contraindications to initiate the Proage treatment protocol?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado la presencia de síntomas de alarma como pérdida de peso excesiva, dolor abdominal intenso, anemia, sangrado digestivo, masa abdominal, adenopatías, fiebre o síntomas nocturnos?': 'Have alarm symptoms such as excessive weight loss, severe abdominal pain, anemia, gastrointestinal bleeding, abdominal mass, lymphadenopathy, fever or nocturnal symptoms been ruled out?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado de forma razonable enfermedades orgánicas mediante estudios analíticos?': 'Has organic disease been reasonably ruled out by analytical studies?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado de forma razonable intolerancias alimenticias?': 'Have food intolerances been reasonably ruled out?',
  // eslint-disable-next-line max-len
  '¿Se ha valorado la necesidad de realizar una endoscopia digestiva o una prueba de imagen radiológica?': 'Has the need for digestive endoscopy or radiological imaging been assessed?',
  // eslint-disable-next-line max-len
  '¿Se ha tenido en cuenta que la edad es un factor de riesgo de enfermedades orgánicas graves, especialmente a partir de los 50 años?': 'Has age been taken into account as a risk factor for serious organic diseases, especially after the age of 50?',
  '¿Sufre migrañas?': 'Do you suffer from migraines?',
  '¿Tiene o ha tenido acúfenos?': 'Do you have or have you had tinnitus?', // TODO review
  '¿Sufre de contracturas musculares de repetición?': 'Do you suffer from repetitive muscle contractures?',
  '¿Tiene piel atópica, dermografismo , o piel muy seca?': 'Do you have atopic skin, dermographism , or very dry skin?',
  '¿Es bruxista?': 'Are you a bruxist?',
  // eslint-disable-next-line max-len
  '¿Tiene olvidos frecuentes, niebla mental o dificultad para concentrarse, o memorizar?': 'Do you have frequent forgetfulness, mental fog or difficulty concentrating, or memorizing?',
  '¿Está triste o ansioso?': 'Are you sad or anxious?',
  '¿Duerme bien, siente que el sueño es reparador?': 'Do you sleep well, do you feel that the sleep is restful?',
  '¿Ha sufrido síncopes alguna vez?': 'Have you ever had syncope?',
  // eslint-disable-next-line max-len
  '¿Tiene dolores erráticos, que van y vienen?': 'Do you have erratic pains, that come and go?',
  // eslint-disable-next-line max-len
  '¿Esta cansado, se levanta cansado, tiene la sensación de no poder acabar el día?': 'Are you tired, do you wake up tired, do you have the feeling that you can\'t finish the day?',
  '¿Se le duermen los dedos de las manos o de los pies?': 'Do your fingers or toes go to sleep?',
  '¿Es de manos y pies fríos?': 'Are your hands and feet cold?',
  '¿Es friolero/a?': 'Are you chilly person?', // TODO review
  // eslint-disable-next-line max-len
  '¿Ha tenido infecciones de orina de repetición o candidiasis de repetición o molestias al orinar?': 'Have you had repeated urinary tract infections or repeated thrush or discomfort when urinating?',
  '¿Es sensible a los ruidos, luces u olores?': 'Are you sensitive to noises, lights or smells?',
  '¿Tiene piernas inquietas?': 'Do you have restless legs?',
  '¿Le salen hematomas al más mínimo roce?': 'Do you bruise at the slightest rubbing?',
  // eslint-disable-next-line max-len
  'En el caso de las mujeres, ¿tiene reglas dolorosas y/o endometriosis?': 'For women, do you have painful periods and/or endometriosis?',
  '¿Se recupera bien cuando hace ejercicio?': 'Does she recover well when she exercises?',
  '¿El paciente tiene un IMC mayor de 25?': 'Does the patient have a BMI greater than 25?',
  '¿Tiene síntomas digestivos?': 'Does she have digestive symptoms?',
  // eslint-disable-next-line max-len
  '¿Se ha realizado una evaluación adecuada de la microbiota y de la permeabilidad intestinal del paciente mediante estudio de microbiota, determinación de DAO, determinación de zonulina, determinación de proteína de unión a lipopolisacárido bacteriano?': 'Has an adequate evaluation of the patient\'s microbiota and intestinal permeability been performed by microbiota study, DAO determination, zonulin determination, bacterial lipopolysaccharide binding protein determination?',
  'Anamnesis nutricional': 'Nutritional anamnesis',
  'Fecha primera consulta': 'Date of first consultation',
  'Peso obj.': 'Weight obj.',
  'Perímetro de cadera': 'Hip circumference',
  '¿Ha hecho dieta alguna vez?': 'Have you ever been on a diet?',
  '¿Le pareció difícil?': 'Did you find it difficult?',
  Siesta: 'Napping',
  Caminar: 'Walking',
  Trabajar: 'Work',
  '¿Qué hace por la tarde?': 'What do you do in the afternoon?',
  '¿Cómo se desplaza?': 'How do you get around?',
  Moto: 'Motorcycle',
  Bici: 'Bike',
  Taxi: 'Taxi',
  Metro: 'Subway',
  Autobus: 'Bus',
  Mixto: 'Mixed',
  '¿Cómo se considera, sedentario o activo?': 'How do you consider yourself, sedentary or active?',
  Sedentario: 'Sedentary',
  '¿Utiliza ascensor o escaleras?': 'Do you use elevator or stairs?',
  Ascensor: 'Elevator',
  Escaleras: 'Stairs',
  '¿Cuántos pisos son?': 'How many floors are there?',
  'Número de pisos': 'Number of floors',
  '¿Come en casa o fuera?': 'Do you eat at home or outside?',
  Fuera: 'Outside',
  Casa: 'Home',
  Ambos: 'Both',
  '¿Cuántos días fuera de casa?': 'How many days away from home?',
  'Algún alimento que no le guste:': 'Any food you don\'t like:',
  '¿Alcohol o tabaco?': 'Alcohol or tobacco?',
  // eslint-disable-next-line max-len
  '¿Algún momento del día , situación o estado de ánimo en el que le apetezca comer?': 'Any time of day , situation or mood when you feel like eating?',
  '¿Cómo come?': 'How do you eat?',
  Solo: 'Alone.',
  Acompañado: 'Accompanied',
  '¿En casa o trabajo?': 'At home or work?',
  Trabajo: 'Work',
  '¿Deprisa o despacio?': 'Fast or slow?',
  deprisa: 'Hurry',
  Despacio: 'Slowly',
  'Con los disgustos, ¿pierde el apetito?': 'With upsets, do you lose your appetite?',
  'Con el estrés o ansiedad, ¿come más?': 'With stress or anxiety, do you eat more?',
  '¿Come todo lo que quiere?': 'Do you eat as much as you want?',
  '¿Está comiendo más de lo que necesita?': 'Are you eating more than you need?',
  // eslint-disable-next-line max-len
  'Al terminar de comer, ¿queda satisfecho o seguiría comiendo?': 'When you finish eating, are you satisfied or would you continue eating?',
  'Satisfecho/a': 'Satisfied',
  'Insatisfecho/a': 'Unsatisfied',
  '¿Cuánto tarda en comer?': 'How long does it take you to eat?',
  Mucho: 'A lot',
  Poco: 'A little',
  'Qué le gusta más, ¿dulce o salado': 'Which do you like better, sweet or salty',
  Dulce: 'Sweet',
  Salado: 'Salty',
  '¿Realiza algún deporte?': 'Do you play any sports?',
  '¿Cuál?': 'Which one?',
  '¿Cuántas veces por semana?': 'How many times a week?',
  'Selecciona una respuesta': 'Select one answer',
  '1 día a la semana': '1 day a week',
  '2 días a la semana': '2 days a week',
  '3 días a la semana': '3 days a week',
  '4 días a la semana': '4 days a week',
  '5 días a la semana': '5 days a week',
  '6 días a la semana': '6 days a week',
  '7 días a la semana': '7 days a week',
  'Todas las veces que puedo': 'As often as I can',
  // eslint-disable-next-line max-len
  'Me siento cansado y desanimado cuando me levanto por las mañanas': 'I feel tired and discouraged when I get up in the mornings.',
  Nunca: 'Never',
  'Pocas veces': 'Few times',
  'Algunas veces': 'Sometimes',
  Continuamente: 'Continuously',
  Siempre: 'Always',
  'Mis músculos están tensos y doloridos': 'My muscles are tense and sore.',
  'Tengo ataques de pánico': 'I have panic attacks',
  'Rechino los dientes o aprieto la mandíbula': 'I grind my teeth or clench my jaw.',
  'Tengo problemas de diarrea o estreñimiento': 'I have problems with diarrhea or constipation.',
  'Necesito ayuda para realizar mis actividades diarias': 'I need help performing my daily activities',
  'Soy sensible a la luz brillante': 'I am sensitive to bright light',
  'Me canso fácilmente cuando estoy físicamente activo': 'I tire easily when I am physically active.',
  'Siento dolor en todo mi cuerpo': 'I feel pain all over my body',
  'Tengo dolores de cabeza': 'I have headaches',
  // eslint-disable-next-line max-len
  'Tengo molestia en mi vejiga o sensación de quemazón al orinar': 'I have discomfort in my bladder or a burning sensation when I urinate.',
  'No duermo bien': 'I don\'t sleep well',
  'Tengo dificultad para concentrarme': 'I have difficulty concentrating',
  // eslint-disable-next-line max-len
  'Tengo problemas en la piel como resequedad, picor o sarpullido': 'I have skin problems such as dryness, itching, or rashes.',
  'El estrés hace que mis síntomas físicos empeoren': 'Stress makes my physical symptoms worse.',
  'Me siento triste o deprimido': 'I feel sad or depressed',
  'Me siento con poca energía': 'I feel low in energy',
  'Tengo tensión muscular en mi cuello y hombros': 'I have muscle tension in my neck and shoulders.',
  'Tengo tensión en mi mandíbula': 'I have tension in my jaw',
  // eslint-disable-next-line max-len
  'Algunos olores, como perfumes, me hacen sentir mareado y con náuseas': 'Some smells, such as perfumes, make me feel dizzy and nauseous',
  'Tengo que orinar frecuentemente': 'I have to urinate frequently',
  // eslint-disable-next-line max-len
  'Mis piernas se sienten incómodas e inquietas cuando intento dormir por la noche': 'My legs feel uncomfortable and restless when I try to sleep at night.',
  'Tengo dificultad para recordar cosas': 'I have difficulty remembering things',
  'Sufrí algún trauma cuando era niño(a)': 'I suffered some trauma when I was a child.',
  'Tengo dolor en mi zona pélvica': 'I have pain in my pelvic area.',

  // src\views\professional\anamnesis\parts\TheFormGroupAnamnesis.vue:
  'Ha ocurrido un error al guardar los cambios': 'An error has occurred while saving changes',

  // src\views\professional\calendar\parts\TheProfessionalCalendarEventCreate.vue:
  Nuevo: 'New',
  // eslint-disable-next-line max-len
  'Debes seleccionar un paciente para que le aparezca en la agenda': 'You must select a patient to appear in the schedule',
  // eslint-disable-next-line max-len
  'Al crear esta {tipo_consuta} se mandará un SMS al paciente con la hora indicada.': 'Editing the time of this "{tipo_consulta}" will send an SMS to the patient with the new time specified.',

  // src\views\professional\calendar\parts\TheProfessionalCalendarEventUpdate.vue:
  // eslint-disable-next-line max-len
  'Al editar la hora de esta "{tipo_consulta}" se mandará un SMS al paciente con la nueva hora indicada.': 'Editing the time of this "{tipo_consulta}" will send an SMS to the patient with the new time specified.',
  // eslint-disable-next-line max-len
  'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}". ¿Estás seguro?': 'You are going to delete the "{tipo_consulta}" named "{titulo}". are you sure?',
  // eslint-disable-next-line max-len
  'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}" con el paciente {nombre_paciente} {apellido_paciente}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?': 'You are going to delete the "{tipo_consulta}" of name "{titulo}" with patient {nombre_paciente} {apellido_paciente}. We will send you SMS indicating the cancellation. Are you sure?',

  // src\views\professional\documents\parts\TheProfessionalDocumentShowModal.vue:
  'Subido por': 'Uploaded by',
  Descargar: 'Download',

  // src\views\professional\expedients\TheProfessionalExpedientFile.vue:
  'Ficha del paciente:': 'Patient file:',
  'Datos personales': 'Personal data',
  'Finalizar tratamiento': 'End treatment',
  'Cambiar de fase': 'Change phase',
  Reenviar: 'Resend',
  'Ha ocurrido un error al empezar la anamnesis': 'An error has occurred while starting the anamnesis',

  // src\views\professional\expedients\TheProfessionalExpedientsList.vue:
  'NIF / DNI': 'NIF / DNI',

  // src\views\professional\expedients\anamnesis\TheProfessionalExpedientAnamnesisTab.vue:
  Nutricional: 'Nutritional',
  'De protocolo': 'Of protocol',
  'Test de Sensibilidad Central': 'Central Sensitization Inventory',
  'Tratamiento en curso': 'Ongoing treatment',
  // eslint-disable-next-line max-len
  'Para responder la anamnesis de protocolo no puede haber un tratamiento en curso. Finaliza el tratamiento actual para poder responderla nuevamente.': 'To answer the protocol anamnesis there cannot be an ongoing treatment. End the current treatment in order to be able to answer it again',

  // src\views\professional\expedients\consultation\TheProfessionalConsultationShow.vue:
  'Seleccione alguna o cree una nueva.': 'Select some or create a new one',

  // src\views\professional\expedients\consultation\parts\TheProfessionalConsultationCreate.vue:
  Nueva: 'New',
  consulta: 'consultation',
  'Fecha de inicio*': 'Start date*',
  'Medio consulta*': 'Consultation way*',
  'Consulta física': 'Physical consultation',
  'Medio de consulta': 'Consultation way',
  'Fecha de inicio': 'Start date',
  '- Sin observaciones -': '- No observations -',
  'La consulta se ha añadido correctamente': 'Consultation was successfully added',
  'Ha ocurrido un error al crear la consulta': 'An error has occurred while creating the consultation',

  // src\views\professional\expedients\consultation\parts\TheProfessionalConsultationUpdate.vue:
  'Fecha de fin': 'End date',
  'Consulta física*': 'Physical consultation*',
  'Nueva autorización': 'New authorization',
  'Los cambios se han guardado correctamente.': 'The changes have been saved correctly.',
  '¡Uups!': 'Oops!',
  // eslint-disable-next-line max-len
  'El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.': 'The patient does not have an active treatment, so we cannot create an authorization. Start a treatment in order to create one. You can start a treatment by answering an anamnesis.',
  'Volver al panel': 'Back to panel',

  'Crear autorización de compra': 'Create purchase authorization',
  'Editar consulta': 'Edit consultation',
  'Nueva autorización de compra': 'New purchase authorization',

  // src\views\professional\expedients\general\TheProfessionalExpedientFileGeneral.vue:
  'No tienes permisos para ver las medidas del paciente': 'You do not have permissions to view patient measurements',
  // eslint-disable-next-line max-len
  'No tienes permisos para ver los tratamientos del paciente': 'You do not have permissions to view patient treatments',

  // src\views\professional\expedients\measures\TheProfessionalExpedientMeasuresList.vue:
  Tabla: 'Table',
  Gráfica: 'Graph',
  'Añadir nueva medida': 'Add new measure',
  'Añadir medida': 'Add measure',
  'Los datos se han guardado correctamente.': 'The data has been saved correctly',
  'Ha ocurrido un error al guardar las medidas': 'An error has occurred while saving the measurements',

  // src\views\professional\expedients\measures\parts\TheMeasuresTableView.vue:
  'Ha ocurrido un error al guardar los datos': 'An error has occurred while saving the data',
  'Los datos se han eliminado correctamente': 'The data has been successfully deleted',
  'Ha ocurrido un error al eliminar los datos': 'An error occurred while deleting the data',
  '¿Seguro que desea eliminar este dato?': 'Are you sure you want to delete this data?',
  'Al borrar el dato no podrás volver a visualizarlo': 'By deleting the data you will not be able to view it again',
  'No hay medidas.': 'No measurements.',

  // src\views\professional\expedients\medicalData\forms\TheProfessionalExpedientFormsList.vue:
  alergias: 'allergies',
  antecedentes_familiares: 'Family History',
  antecedentes_personales: 'Personal History',
  actividad_fisica: 'physical activity',
  medicacion: 'medication',
  observaciones: 'observations',
  'No hay datos de esta categoría': 'No data for this category',
  respuesta: 'response',
  'Parece que ese registro ya ha sido introducido.': 'It looks like that record has already been entered',
  // eslint-disable-next-line max-len
  'Parece que ese registro no existe o ya se ha eliminado.': 'It appears that this record does not exist or has already been deleted',

  // src\views\professional\expedients\medicalData\forms\parts\VFormRow.vue:
  'Eliminar registro': 'Delete record',
  Borrar: 'Delete',

  // src/views/professional/expedients/medicalData/forms/TheProfessionalExpedientFormsList.vue
  Animales: 'Animals',
  'Ácaros del polvo': 'Dust mites',
  Moho: 'Mold',
  Insectos: 'Insects',
  'Polen de gramíneas': 'Grass pollen',
  'Polen de árbol': 'Tree pollen',
  'Polen de malezas': 'Weed pollen',
  Polen: 'Pollen',
  Cacahuetes: 'Peanuts',
  Soja: 'Soybeans',
  Trigo: 'Wheat',
  'Pescado y marisco': 'Fish and seafood',
  'Frutas y verduras': 'Fruits and vegetables',
  Leche: 'Milk',
  'No conocidas': 'Unknown',

  // src/views/professional/expedients/medicalData/TheProfessionalExpedientFileMedicalData.vue
  'Añadir {medicalData}': 'Add {medicalData}',
  'Añadir nueva {nombre}': 'Add new {nombre}',

  // src\views\professional\expedients\parts\TheProfessionalExpedientCreate.vue:
  // eslint-disable-next-line max-len
  'Ya tienes registrado a un paciente con ese número de identificación.': 'You already have a patient registered with that ID number',

  // src\views\professional\expedients\parts\TheProfessionalPatientAddressFieldset.vue:
  Población: 'Population',
  'Actualizar dirección de facturación': 'Update billing address',
  'Actualizar dirección de envío': 'Update shipping address',
  // eslint-disable-next-line max-len
  'La dirección de facturación se ha actualizado correctamente.': 'Billing address has been updated successfully.',
  'La dirección de envío se ha actualizado correctamente.': 'Shipping address has been updated successfully.',

  // src\views\professional\expedients\parts\TheProfessionalPatientDataProtection.vue:
  'Protección de': 'Protection of',
  datos: 'data',
  // eslint-disable-next-line max-len
  'Para poder continuar con el proceso es necesario que el paciente acepte la información de protección de datos. Para ello, puedes enviarla mediante las dos opciones disponibles.': 'In order to continue with the process, it is necessary that the patient accepts the data protection information. To do so, you can send it via the two available options.',
  // eslint-disable-next-line max-len
  'En caso de que el paciente no haya recibido notificación alguna, puede reenviarlo pinchando de nuevo en una de las opciones. En el momento en el que el paciente acepte la política, podrá acceder a su ficha para completar sus datos pinchando en “continuar”.': 'In case the patient has not received any notification, you can resend it by clicking again on one of the options. As soon as the patient accepts the policy, he can access his file to complete his data by clicking on "continue".',
  'Enviar RGPD mediante SMS': 'Send RGPD by SMS',
  'Enviar RGPD mediante Email': 'Send RGPD by Email',
  'Enviar RGPD mediante email': 'Send RGPD via email',

  // eslint-disable-next-line max-len
  'La notificación se ha enviado. En caso de no recibirla, podrás volver a enviarla dentro de:': 'The notification has been sent. If you do not receive it, you can send it again within:',
  // eslint-disable-next-line max-len
  'También se puede imprimir en papel la RGPD y que el paciente la lea y firme en persona. Para ello, descarga e imprime el documento pulsando el botón "Descargar para imprimir", y, a continuación, pulsa en el botón "Paciente ha firmado RGPD en papel".': 'ou can also print out the GDPR on paper and have the patient read and sign it in person. To do this, download and print the document by clicking on the "Download to print" button, and then click on the "Patient has signed GDPR on paper" button.',
  'Descargar para imprimir': 'Download to print',
  'Paciente ha firmado RGPD en papel': 'Patient has signed GDPR on paper',
  'Ir al expediente': 'Go to expedient',
  'El paciente aún no ha aceptado el RGPD': 'The patient has not yet accepted the GDPR',
  // eslint-disable-next-line max-len
  'El paciente ya ha aceptado el RGPD, te estamos redirigiendo': 'The patient has already accepted the GDPR, we are redirecting you',
  'Se ha enviado la política de datos por SMS.': 'The data policy has been sent by SMS.',
  // eslint-disable-next-line max-len
  'Se ha enviado la política de datos por correo electrónico y SMS.': 'The data policy has been sent by email and SMS.',
  'El RGPD se ha enviado corectamente': 'The GDPR has been sent correctly',
  // eslint-disable-next-line max-len
  'El envío por SMS está actualmente deshabilitado. Utiliza el correo electrónico.': 'Sending by SMS is currently disabled. Use email.',
  'Confirmar RGPD': 'Confirm GDPR',
  // eslint-disable-next-line max-len
  'Si el paciente ya aceptado la RGPD de forma física, confirma para continuar y ver el expediente': 'If patient already accepted GDPR physically, confirm to continue and view file',
  'RGPD aceptadas con éxito': 'GDPR successfully accepted',
  'Ha ocurrido un error al aceptar las RGPD': 'An error has occurred while accepting the GDPR',

  // src\views\professional\expedients\parts\TheProfessionalPatientPersonalDataModal.vue:
  'Datos actualizados con éxito.': 'Data successfully updated',
  'El {identityDocType} registrado no es Válido': 'The {identityDocType} registered is not valid',

  // src\views\professional\expedients\treatments\TheProfessionalExpedientFileTreatmentsList.vue:
  'No hay tratamientos': 'No treatments',
  'Seleccionar tratamiento': 'Select treatment',

  // src\views\professional\expedients\treatments\parts\TheProfessionalTreatmentChangePhaseModal.vue:
  'Cambiar de': 'Change from',
  fase: 'phase',
  'Selecciona una nueva fase para el tratamiento:': 'Select a new phase for treatment',
  'Debes elegir una fase para poder continuar': 'You must select a phase in order to continue',
  'Se ha cambiado de fase correctamente': 'Successfully changed phase',
  'Ha ocurrido un error al cambiar de fase': 'An error has occurred while changing phase',

  // src\views\professional\expedients\treatments\parts\TheProfessionalTreatmentChangePhaseModal.vue:
  tratamiento: 'treatment',
  // eslint-disable-next-line max-len
  'Vas a finalizar el tratamiento actual, ¿estás seguro? De ser así, indica por favor el motivo:': 'You are going to end the current treatment, are you sure? If so, please indicate the reason:',
  label: 'label',
  // eslint-disable-next-line max-len
  'Debes elegir una de las razones dadas o escribir una propia': 'You must choose one of the given reasons or write one of your own',
  Razón: 'Reason',
  'Se ha finalizado el tratamiento correctamente': 'Treatment has been successfully completed',
  'Ha ocurrido un error, el tratamiento ya está finalizado': 'An error has occurred, the treatment is now finished',
  'Ha ocurrido un error al finalizar el tratamiento': 'An error has occurred at the end of the treatment',

  // src\views\professional\expedients\videocalls\TheVideoCallOverlay.vue:
  'Compartir enlace': 'Share link',
  'Ver ayuda': 'View help',
  'Crear sala de reuniones': 'Create meeting room',
  'Crear sala': 'Create room',
  'Ayuda de videollamadas': 'Video call help',
  'Has llegado a tu límite': 'You ahve reached your limit',

  // src\views\professional\expedients\videocalls\TheVideoCallShareModal.vue:
  'Compartir enlace de acceso a la videollamada': 'Share video call access link',
  'Este es el enlace de invitación a la videollamada:': 'This is the video call invitation link:',
  Copiar: 'Copy',
  // eslint-disable-next-line max-len
  'También puedes enviar el enlace al correo electrónico o a través de un SMS del paciente:': 'You can also send the link to email or via SMS from the patient:',
  'Enviar correo electrónico': 'Send email',
  'Enviar SMS': 'Send SMS',
  // eslint-disable-next-line max-len
  'Enlace de acceso a la videollamada copiado al portapapeles.': 'Video call access link copied to clipboard.',
  'Enviar invitación por correo': 'Send invitation by mail',
  // eslint-disable-next-line max-len
  'Vamos a enviar un correo electrónico al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?': 'We are going to send an email to the patient with the link to access the video call. From that link you will be able to access with a simple click. Are you sure?',
  'Enviar correo con enlace': 'Send email with link',
  '¡Enviado!': 'Sent!',
  // eslint-disable-next-line max-len
  'Hemos enviado el enlace al paciente por correo electrónico. Ahora podrá unirse a la videollamada.': 'We have sent the link to the patient by email. You will now be able to join the video call.',
  'Ha ocurrido un error': 'An error has occurred',
  'Algo ha salido mal, vuelve a probar más tarde...': 'Something went wrong, try again later...',

  'Enviar invitación por SMS': 'Send invitation by SMS',
  // eslint-disable-next-line max-len
  'Vamos a enviar un SMS al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?': 'We are going to send an SMS to the patient with the link to access the video call. From that link you will be able to access with a simple click. Are you sure?',
  'Enviar SMS con enlace': 'Send SMS with link',
  // eslint-disable-next-line max-len
  'Hemos enviado el enlace al paciente por SMS. Ahora podrá unirse a la videollamada.': 'We have sent the link to the patient by SMS. Now you can join the video call',

  // src\views\professional\inter-consultations\TheProfessionalInterConsultationsList.vue:
  interconsultas: 'interconsultations',
  'Nueva interconsulta': 'New interconsultation',
  Con: 'With',
  'F. Creación': 'Creation date',
  'No hay interconsultas': 'No interconsultations',
  ' - Sin paciente - ': ' - No patient - ',
  Finalizada: 'Finished',
  Abierta: 'Open',
  'Ver interconsulta': 'View interconsultation',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultation.vue:
  'Edición de': 'Editing ',
  interconsulta: 'interconsultation',
  Creador: 'Creator',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationCreate.vue:
  'Profesional*': 'Professional*',
  'Categoría*': 'Category*',
  'La interconsulta se ha creado con éxito': 'The interconsultation was created successfully',
  'Ha ocurrido un error al crear la interconsulta': 'An error has occurred while creating the interconsultation',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationUpdate.vue:
  'Finalizar interconsulta': 'End interconsultation',
  De: 'From',
  Comentarios: 'Comments',
  'No hay comentarios': 'No comments',
  Tú: 'You',
  'Ha ocurrido un error al actualizar la interconsulta': 'An error has occurred while updating the interconsultation',
  // eslint-disable-next-line max-len
  'Si finalizas esta interconsulta podrás seguir visualizando los datos y comentarios pero no editarla ni comentar nuevamente. ¿Estás seguro?': 'If you end this query you can still view the data and comments but not edit or comment again. Are you sure',
  'La interconsulta se ha finalizado con éxito': 'The interconsultation has been completed successfully',
  'Ha ocurrido un error al finalizar la interconsulta': 'An error has occurred when finishing the interconsultation',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationView.vue:
  'Escribe tu mensaje': 'Write your message',
  Enviar: 'Send',
  Mensaje: 'Message',
  'Ha ocurrido un error al enviar el comentario': 'An error has occurred while sending the comment',
  'Eliminar comentario': 'Delete comment',
  '¿Estás seguro de que quieres eliminar este comentario?': 'Are you sure you want to delete this comment?',
  'Ha ocurrido un error al borrar el comentario': 'An error has occurred while deleting the comment',

  // src\views\professional\parts\TheProfessionalHomeExpedients.vue:
  // src\views\professional\purchaseAuthorization\ThePurchaseAuthorizationList.vue:

  // src\views\professional\purchaseAuthorization\ThePurchaseAuthorizationList.vue:
  'Reenviar enlace': 'Resend link',
  'Reenviar enlace de compra': 'Resend purchase link',
  'Se ha enviado un nuevo enlace de compra correctamente.': 'New purchase link successfully sent',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al enviar un nuevo enlace de la autorización de compra': 'An error has occurred while sending a new purchase authorization link',
  // eslint-disable-next-line max-len
  'Se va a enviar un nuevo  enlace de compra al paciente y se anularán los anteriores. Desde este nuevo enlace podrá adquirir los productos. ¿Estás seguro?': 'A new purchase link will be sent to the patient and the previous ones will be cancelled. From this new link you will be able to purchase the products. Are you sure?',

  // src\views\professional\purchaseAuthorization\parts\TheProductListModal.vue:
  'Productos disponibles': 'Available products',
  'Añadir nuevo producto': 'Add new product',
  'Restar producto': 'Subtract product',
  'Sumar producto': 'Add product',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  Suplementación: 'Supplementation',
  Producto: 'Product',
  // eslint-disable-next-line max-len
  'Para {treatmentDuration} días y {posology} tomas diarias ({dose} dosis) de tratamiento, puedes autorizar hasta un máximo de': 'For {treatmentDuration} days and {posology} daily doses ({dose} dosis) of treatment, you can authorize up to a maximum of',
  '{suggestedBoxes} cajas ({productSuggestedBoxes} sobres)': '{suggestedBoxes} boxes ({productSuggestedBoxes} sachets)',
  'de producto. No hay limitación para la suplementación.': 'of product. There is no limitation for supplementation.',

  // src\views\professional\purchaseAuthorization\parts\TheProductListModal.vue:
  Aceptar: 'Accept',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  'Duración del tratamiento*': 'Treatment duration*',
  'Introducir días': 'Enter days',
  'Posología*': 'Posology*',
  'Introducir dosis': 'Enter doses',
  // eslint-disable-next-line max-len
  'Tienes más productos en la cesta de los permitidos. Elimina alguno.': 'You have more products in the basket than allowed. Remove some',
  'dosis durante': 'doses during',
  días: 'days',
  // eslint-disable-next-line max-len
  'Para añadir productos tienes que introducir la duración del tratamiento y la posología.': 'To add products you have to enter the treatment duration and dosage.',
  // eslint-disable-next-line max-len
  'Selecciona los productos necesarios para crear la autorización de compra': 'Select the products needed to create the purchase authorization',
  '10 sobres por caja': '10 envelops per box',
  '0 cajas': '0 boxes',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  Protocolo: 'Protocol',
  'Duración del tratamiento': 'Treatment duration',
  Packs: 'Packs',
  Otros: 'Others',
  'Enviar autorización de compra': 'Send purchase authorization',
  // eslint-disable-next-line max-len
  'Autorización botella': 'If it is the patient\'s first order and contains more than 6 products from the ProAge On or ProAge On + lines, they will receive a free shaker bottle. It is not necessary to add it to the authorization',
  // eslint-disable-next-line max-len
  'Para este tratamiento se recomienda el uso de la suplementación ProAge On Plus (+). Queda a la elección del profesional el uso de otras lineas.': 'For this treatment the use of ProAge On Plus (+) supplementation is recommended. It is up to the practitioner\'s choice to use other lines.',
  // eslint-disable-next-line max-len
  'Para este tratamiento se recomienda el uso de la suplementación ProAge On. Queda a la elección del profesional el uso de otras lineas.': ' For this treatment the use of ProAge On supplementation is recommended. It is up to the practitioner\'s choice to use other lines.',
  'Límite de cajas alcanzado.': 'Boxes limit reached',
  // eslint-disable-next-line max-len
  '¿Seguro que quieres crear esta nueva autorización de compra?': 'Are you sure you want to create this new purchase authorization?',
  'La autorización de compra se ha creado correctamente': 'The purchase authorization has been created successfully',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al añadir la autorización de compra': 'An error has occurred while adding the purchase authorization',
  'Deshabilitar autorización de compra': 'Disable purchase authorization',
  Deshabilitar: 'Disable',
  // eslint-disable-next-line max-len
  '¿Estás seguro de que quieres deshabilitar esta autorización de compra? Si la deshabilitas,no podrá utilizarse para adquirir productos en la tienda.': 'Are you sure you want to disable this purchase authorization? If you disable it, it cannot be used to purchase products in the store.',
  // eslint-disable-next-line max-len
  'La autorización de compra se ha deshabilitado correctamente': 'The purchase authorization has been successfully disabled',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al dishabilitar la autorización de compra': 'An error occurred while disabling purchase authorization',

  // src\views\professional\resources\TheProfessionalResourcesGrid.vue:
  'Visualizar documento': 'View document',

  // src\views\professional\videocalls\Call.vue:
  Recargar: 'Reload',
  'Estamos esperando a que se conecte tu paciente...': 'We are waiting for your patient to connect...',
  'Estamos esperando a que se conecte tu médico...': 'We are waiting for your medic to connect...',

  // src\views\professional\videocalls\Chat.vue:
  'Mostrar/Ocultar chat': 'Show/Hide chat',
  'Escribe un mensaje...': 'Write a message...',

  // src\views\professional\videocalls\Controls.vue:
  'Desactivar micrófono': 'Disable microphone',
  'Activar micrófono': 'Enable microphone',
  'Desactivar cámara': 'Disable camera',
  'Activar cámara': 'Enable camera',
  'Dejar de compartir pantalla': 'Stop screen sharing',
  'Compartir pantalla': 'Share screen',

  // src\views\professional\videocalls\PermissionsErrorMsg.vue:
  'Cámara o micrófono bloqueado': 'Camera or microphone blocked',
  Reintentar: 'Retry',

  // src\views\professional\videocalls\PermissionsErrorMsg.vue:
  'Conseguir ayuda': 'Get help',

  // src\views\professional\videocalls\WaitingCard.vue:
  Email: 'Email',
  SMS: 'SMS',
  'Esperando a que alguien se conecte a la videollamada...': 'Waiting for someone to connect to the video call...',
  'Vamos a redirigirte hacia la tienda de PROAGE BY LFL®': 'We are going to redirect you to the PROAGE BY LFL® store',

  // src\views\shop\TheShopOrderRedirection.vue:
  // eslint-disable-next-line max-len
  'Este proceso puede tardar unos segundos mientras preparamos tu pedido': 'This process may take a few seconds while we prepare your order',
  'Ha ocurrido un error.': 'An error has occurred',
  // eslint-disable-next-line max-len
  'Por favor, comprueba que el enlace es válido y no ha caducado, y vuelve a intentarlo más tarde.': 'Please check that the link is valid and has not expired, and try again later.',

  // eslint-disable-next-line max-len
  'El número debe ser un valor entre 0 y 100, con máximo 2 decimales.': 'The number must be a value between 0 and 100, with a max. of 2 decimal places.',
};
