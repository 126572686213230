
import { defineComponent } from 'vue';
import api from '@/api';
import VLoader from '@/components/vendor/VLoader.vue';

export default defineComponent({
  name: 'TheShopOrderRedirection',
  components: {
    VLoader,
  },
  data() {
    return {
      title: this.$t('Vamos a redirigirte hacia la tienda de PROAGE BY LFL®'),
      subtitle: this.$t('Este proceso puede tardar unos segundos mientras preparamos tu pedido'),
      authorizationCode: this.$route.params.authorizationCode as string,
      showLoader: true,
    };
  },
  mounted() {
    this.getWCLink();
  },
  methods: {
    async getWCLink() {
      try {
        const response = await api.professional.purchaseAuthorizations.getCartRedirectionLink(
          this.authorizationCode,
        );

        if (response && response.redirect_uri) {
          // We redirect user to the shop
          setTimeout(() => {
            window.location.assign(response.redirect_uri);
          }, 2000);
        }
      } catch (e) {
        this.title = this.$t('Ha ocurrido un error.');
        // eslint-disable-next-line max-len
        this.subtitle = this.$t('Por favor, comprueba que el enlace es válido y no ha caducado, y vuelve a intentarlo más tarde.');
        this.showLoader = false;
      }
    },
  },
});
