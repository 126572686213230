import AbstractService from '@/api/services/AbstractService';
import Patient from '@/api/objects/Patient';

class AdminPatientService extends AbstractService {
  public async all(filters: any, paginate = false): Promise<Patient[] | any> {
    const path = paginate ? this.buildPath('admin/patients-paginated') : this.buildPath('admin/patients');

    return this.request(
      'get',
      path,
      filters,
      {},
      undefined,
      paginate,
    );
  }

  public async show(patientId: number): Promise<Patient> {
    return this.request('get', this.buildPath('admin/patient/{patient}', {
      patient: patientId,
    }));
  }

  public async update(patientId: number, data: any): Promise<Patient> {
    return this.request('put', this.buildPath('admin/patient/{patientId}', {
      patientId,
    }), [], data);
  }
}

export default new AdminPatientService();
