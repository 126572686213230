import AbstractService from '@/api/services/AbstractService';
import Form from '@/api/objects/Form';
import Treatment from '@/api/objects/Treatment';
import ProtocolPhase from '@/api/objects/ProtocolPhase';

class ExpedientFormAnamnesisService extends AbstractService {
  public async start(expedientId: number): Promise<Form[]> {
    return this.request('post', this.buildPath('expedient/{expedientId}/anamnesis/start', { expedientId }));
  }

  public async startNutritional(expedientId: number): Promise<Form[]> {
    return this.request('post', this.buildPath('expedient/{expedientId}/nutritional-anamnesis/start', { expedientId }));
  }

  public async startCSI(expedientId: number): Promise<Form[]> {
    return this.request('post', this.buildPath(
      'expedient/{expedientId}/central-sensitization-inventory/start', { expedientId },
    ));
  }

  public async upgrade(expedientId: number, formId: number): Promise<Form[]> {
    return this.request('post', this.buildPath('expedient/{expedientId}/anamnesis/{formId}/upgrade', {
      expedientId,
      formId,
    }));
  }

  public async suggest(expedientId: number, formId: number): Promise<ProtocolPhase[]> {
    return this.request('post', this.buildPath('expedient/{expedientId}/anamnesis/{formId}/suggest', {
      expedientId,
      formId,
    }));
  }

  public async confirm(expedientId: number, formId: number, protocolPhase: number): Promise<Treatment> {
    return this.request('post', this.buildPath('expedient/{expedientId}/anamnesis/{formId}/confirm/{protocolPhase}', {
      expedientId,
      formId,
      protocolPhase,
    }));
  }
}

export default new ExpedientFormAnamnesisService();
