
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'TheProfessionalPatientDataProtection',
  components: { VButton },
  data() {
    return {
      timer: 60,
      interval: 0,
      expedientId: Number(this.$route.params.expedient),
      comesFromCreation: this.$route.query.comesFromCreation,
      rgpdSent: false,
      requestSent: false,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'status']),
    ...mapState('app', ['formsSettings']),
    formattedTimer() {
      // TODO: Please, modify the $str to make it able to format an amount of seconds
      const time = this.$str.parseSeconds(this.timer.toString());

      const minutes = `0${time.minutes.toString()}`;
      const seconds = time.seconds < 10 ? `0${time.seconds.toString()}` : time.seconds.toString();

      return `${minutes}:${seconds}`;
    },
    patientDoesntHaveEmail(): boolean {
      return !this.currentExpedient?.patient?.user?.email.startsWith('no-reply+');
    },
  },
  mounted() {
    this.showTimerWhenComingFromCreation();
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  watch: {
    currentExpedient() {
      if (this.currentExpedient) {
        if (!this.currentExpedient.rgpdConfirmedAt) {
          this.$toast.error(this.$t('El paciente aún no ha aceptado el RGPD'));
        } else {
          this.$toast.success(this.$t('El paciente ya ha aceptado el RGPD, te estamos redirigiendo'));

          this.$router.replace({
            name: 'professional.expedient.show',
            params: { expedient: this.currentExpedient.id },
          });
        }
      }
    },
  },
  methods: {
    /**
     * Shows the timer if we are here just by a redirect from the expedient creation, as when we
     * create the new expedient a notification is automatically sent, so if we allow trying to send
     * it again, it will fail because of the time between requests we set.
     */
    showTimerWhenComingFromCreation() {
      if (!!this.comesFromCreation) {
        // We remote that query param
        this.$router.push(this.$route.path);

        // We force the timer to be shown (as it has been sent in back that notification)
        this.rgpdSent = true;
        this.startCounter();

        if (this.patientDoesntHaveEmail) {
          this.$toast.success(this.$t('Se ha enviado la política de datos por SMS.'));
        } else {
          this.$toast.success(this.$t('Se ha enviado la política de datos por correo electrónico y SMS.'));
        }
      }
    },
    /**
     * Resets the timer variable to 'rgpd_time_between_requests' settings variable, or to 60 if it
     * doesn't exist.
     */
    resetTimer() {
      this.timer = this.formsSettings?.rgpd_time_between_requests ?? 60;
    },
    startCounter() {
      this.resetTimer();
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.timer -= 1;
        if (this.timer === 0) {
          this.rgpdSent = false;
          clearInterval(this.interval);
          this.resetTimer();
        }
      }, 1000);
    },
    async sendNotification(type: string) {
      try {
        await api.professional.patient.sendRGPD(Number(this.currentExpedient.patientId), type);

        this.rgpdSent = true;
        await this.startCounter();

        this.$toast.success(this.$t('El RGPD se ha enviado corectamente'));
      } catch (e) {
        if (e.response.data.code_error === 'rgpd_time_between_requests_not_passed') {
          this.$toast.error(e.response.data.message);
        } else if (e.response.data.code_error === 'sms_currently_disabled') {
          // TODO: This exceptions seems to be deleted in back, check it
          this.$toast.error(this.$t('El envío por SMS está actualmente deshabilitado. Utiliza el correo electrónico.'));
        } else {
          this.$toast.error(e.response.data.message);
          this.rgpdSent = false;

          this.resetTimer();
        }
      }
    },
    async refreshCurrentExpedient() {
      await this.$store.dispatch('expedient/getCurrentExpedient', {
        expedientId: this.currentExpedient.id,
      });
    },
    async acceptRGPD() {
      await this.$modal.confirm({
        title: this.$t('Confirmar RGPD'),
        // eslint-disable-next-line max-len
        text: this.$t('Si el paciente ya aceptado la RGPD de forma física, confirma para continuar y ver el expediente'),
        confirmButtonText: this.$t('Confirmar'),
        confirmButtonCallback: this.doAcceptRGPD,
      });
    },
    async doAcceptRGPD() {
      try {
        await api.professional.patient.acceptManualRGPD(this.currentExpedient.id);

        this.$toast.success(this.$t('RGPD aceptadas con éxito'));

        await this.refreshCurrentExpedient();
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al aceptar las RGPD'));
      }
    },
  },
});
