import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { ExpedientState } from '@/store/modules/patient/expedient/expedient.types';
import api from '@/api';

type Context = ActionContext<ExpedientState, State>;

const actions: ActionTree<ExpedientState, State> = {
  async getCurrentExpedient({
    state,
    dispatch,
    commit,
  }: Context, {
    expedientId,
    treatmentId,
  }: { expedientId: number, treatmentId?: number }) {
    if (!expedientId) {
      await dispatch('cleanCurrentExpedient');
    } else if (!state.currentExpedient
      || state?.currentExpedient?.id !== expedientId
      || state.currentExpedient.id === expedientId) {
      try {
        const expedient = await api.patient.expedients.retrieve(expedientId);

        commit('setStatus', 'loading');
        commit('setExpedient', expedient);

        // Load and set measures
        await dispatch('form/refreshExpedientMeasures', {
          expedientId,
        });

        // Load and set consultations
        await dispatch('consultation/refreshExpedientConsultations', {
          expedientId,
        });

        await dispatch('purchaseAuthorization/refreshPurchaseAuthorizations', {
          expedientId,
        });

        // Load and set treatments
        await dispatch('treatment/refreshExpedientTreatments', {
          expedientId,
        });

        // Load and set current room if existing
        // FIXME: Esto llama al de profesional, no sé por qué
        await dispatch('room/getRoomByExpedient', {
          expedientId,
        });

        commit('setStatus', 'loaded');
      } catch (e) {
        commit('setStatus', 'loaded');
      }
    }
  },
  async setExpedientSection({
    commit,
  }: Context, {
    expedientSection,
  }: { expedientSection: string }) {
    commit('setExpedientSection', expedientSection);
  },

  async refreshExpedientForms({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    const expedientForms = await api.patient.expedient.forms.all(expedientId);

    commit('form/setForms', expedientForms);
  },

  async cleanCurrentExpedient({
    commit,
  }: Context) {
    commit('setExpedient', null);
    // commit('form/setForms', []);
    // commit('form/setMeasures', null);
    commit('treatment/setTreatments', []);
    commit('consultation/setConsultations', []);
    commit('consultation/setCurrentConsultation', null, { root: true });
  },
};

export default actions;
