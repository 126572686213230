import { MutationTree } from 'vuex';
import type { GeneralModalType, GeneralState } from '@/store/modules/general/general.types';

export interface GeneralMutations {
  modal(state: GeneralState, payload: Partial<GeneralModalType>): void;

  enableModalLeftButton(state: GeneralState): void;

  disableModalLeftButton(state: GeneralState): void;

  enableModalRightButton(state: GeneralState): void;

  disableModalRightButton(state: GeneralState): void;

  hideModal(state: GeneralState): void;
}

const mutations: MutationTree<GeneralState> & GeneralMutations = {
  modal(state: GeneralState, payload: Partial<GeneralModalType>) {
    const { modal } = state;

    state.modal = {
      ...modal,
      ...payload,
      leftButtonDisabled: false,
      rightButtonDisabled: false,
      show: true,
    };
  },
  enableModalLeftButton(state: GeneralState) {
    state.modal.leftButtonDisabled = false;
  },
  disableModalLeftButton(state: GeneralState) {
    state.modal.leftButtonDisabled = true;
  },
  enableModalRightButton(state: GeneralState) {
    state.modal.rightButtonDisabled = false;
  },
  disableModalRightButton(state: GeneralState) {
    state.modal.rightButtonDisabled = true;
  },
  hideModal(state: GeneralState) {
    state.modal.show = false;
  },
};

export default mutations;
