
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import VProfessionalConsultations from '@/components/vendor/VProfessionalConsultations.vue';
import TheProfessionalGeneralAuthorizations
  from '@/views/professional/expedients/general/parts/TheProfessionalGeneralAuthorizations.vue';
import TheProfessionalExpedientFileTreatmentsList
  from '@/views/professional/expedients/treatments/TheProfessionalExpedientFileTreatmentsList.vue';
import TheProfessionalExpedientMeasuresList
  from '@/views/professional/expedients/measures/TheProfessionalExpedientMeasuresList.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import TheProfessionalConsultationCreate
  from '@/views/professional/expedients/consultation/parts/TheProfessionalConsultationCreate.vue';
import ThePurchaseAuthorizationCreate
  from '@/views/professional/purchaseAuthorization/parts/ThePurchaseAuthorizationCreate.vue';
import { POSITION } from 'vue-toastification';

export default defineComponent({
  name: 'TheProfessionalExpedientFileGeneral',
  components: {
    ThePurchaseAuthorizationCreate,
    TheProfessionalConsultationCreate,
    VButton,
    TheProfessionalExpedientMeasuresList,
    TheProfessionalExpedientFileTreatmentsList,
    TheProfessionalGeneralAuthorizations,
    VProfessionalConsultations,
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'consultations']),
    ...mapState('treatment', ['currentTreatment']),
    canIndexConsultation(): boolean {
      return this.can('professional.expedient.consultation.index')
        || this.can('patient.expedient.consultation.index');
    },
    canIndexPurchaseAuthorization(): boolean {
      return this.can('professional.expedient.purchase-authorization.index')
        || this.can('patient.expedient.purchase-authorization.index');
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: null as string | null,
    };
  },
  methods: {
    async showPurchaseAuthorizationCreateModal() {
      // If no current treatment we cannot create it
      // TODO: Move this to a helper as it is repeated in different components
      if (!this.currentTreatment && !this.currentExpedient.professional.canFreelyAuthorize) {
        await this.$modal.info({
          title: this.$t('¡Uups!'),
          // eslint-disable-next-line max-len
          text: this.$t('El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.'),
          rightButtonClasses: 'btn-secondary',
          acceptButtonText: this.$t('Cerrar'),
          leftButtonText: this.$t('Volver al panel'),
        });
      } else {
        this.modalToShow = 'the-purchase-authorization-create';
      }
    },
    async loadPurchaseAuthorizations() {
      try {
        await this.$store.dispatch('expedient/refreshPurchaseAuthorizations', {
          expedientId: this.currentExpedient.id,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las autorizaciones de compra'), {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    },
  },
});
