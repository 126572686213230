
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import api from '@/api';
import Protocol from '@/api/objects/Protocol';
import VLoader from '@/components/vendor/VLoader.vue';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import ProtocolPhase from '@/api/objects/ProtocolPhase';
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAnamnesisResultsModal',
  components: {
    VAlert,
    VLoader,
    ModalFooter,
    VButton,
    ModalBody,
    VModal,
    ModalTitle,
    ModalHeader,
  },
  props: {
    alerts: {
      type: Array,
      required: false,
    },
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      phaseSelected: yup.number()
        .required()
        .label(t('Fase')),
    });

    const form = useFormValidation(rules);

    const { value: phaseSelected } = useField<number>('phaseSelected');

    return {
      ...form,
      phaseSelected,
      ...useCapabilities(),
    };
  },
  data() {
    return {
      anamnesisResults: [] as ProtocolPhase[],
      protocols: [] as Protocol[],
      showAllProtocols: false,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'currentAnamnesis']),
  },
  mounted() {
    this.loadAnamnesisResults();
    this.loadProtocols();
  },
  methods: {
    async loadAnamnesisResults() {
      try {
        this.anamnesisResults = await api.professional.expedients.anamnesis.suggest(
          this.currentExpedient.id,
          this.currentAnamnesis.id,
        );
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar los protocolos sugeridos de la anamnesis'));
      }
    },
    async loadProtocols() {
      this.protocols = await api.professional.protocol.all();
    },
    selectPhase(phaseId) {
      this.phaseSelected = phaseId;
    },
    async confirmAnamnesisProtocol() {
      try {
        this.toggleAccepted();

        // TODO: Api devuelve sólo el tratamiento. ¿Que devuelva tambien la fase asignada al tratamiento?
        const treatment = await api.professional.expedients.anamnesis.confirm(
          this.currentExpedient.id,
          this.currentAnamnesis.id,
          this.phaseSelected,
        );

        // eslint-disable-next-line max-len
        this.$toast.success(this.$t('Se ha iniciado el tratamiento correctamente. Ya puedes visualizarlo en la parte superior del expediente'));

        // Force it to refresh treatments
        await this.$store.dispatch('expedient/refreshExpedientTreatments', {
          expedientId: this.currentExpedient.id,
        });

        await this.$router.push({
          name: 'professional.expedient.show',
          params: {
            expedient: this.currentExpedient.id,
            tabName: this.$route.params.tabName,
          },
          query: { tratamiento: treatment.id },
        });

        this.$emit('treatment-started');

        this.toggleAccepted();
        this.closeModal();
      } catch (e) {
        if (e.response.data.code_error === 'active_treatment_exist') {
          // eslint-disable-next-line max-len
          this.$toast.error(this.$t('Ya hay un tratamiento iniciado. Por favor, finalízalo antes de comenzar uno nuevo'));
        } else {
          this.$toast.error(this.$t('Ha ocurrido un error al confirmar la anamnesis'));
        }

        this.toggleAccepted();
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
