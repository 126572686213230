
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import TheAdminVideosGrid from '@/views/administrator/videos/TheAdminVideosGrid.vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import Treeselect from 'vue3-treeselect';
import Category from '@/api/objects/Category';
import Video from '@/api/objects/Video';

export default defineComponent({
  name: 'TheAdminVideos',
  components: {
    TheAdminVideosGrid,
    Treeselect,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      typeSelected: null as string | null,
      videos: [] as Video[],
      status: 'loading' as string,
      loadingCategories: false as boolean,
      categoryOptions: [] as Category[],
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    categories() {
      const array: Record<string, any>[] = [];

      // TODO: Refactorizar si se añaden subcategorias
      this.categoryOptions.forEach((value) => {
        array.push({
          id: value.name,
          label: value.name,
        });
      });

      return array;
    },
  },
  watch: {
    typeSelected() {
      this.loadVideos();
    },
  },
  async mounted() {
    await this.loadVideos();
  },
  created() {
    this.loadCategories();
  },
  methods: {
    async loadCategories() {
      try {
        this.loadingCategories = true;

        this.categoryOptions = await api.admin.videoCategory.all();

        this.loadingCategories = false;
      } catch (e: any) {
        this.loadingCategories = false;

        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);
      }
    },
    async loadVideos() {
      try {
        this.status = 'loading';

        const filters = {
          category: this.typeSelected,
        };

        this.videos = await api.admin.video.all(filters);

        this.status = 'loaded';
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar los vídeos'));
        this.status = 'loaded';
      }
    },
  },
});
