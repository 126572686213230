import { createLogger } from 'vuex';
import actions from '@/store/modules/patient/expedient/expedient.actions';
import mutations from '@/store/modules/patient/expedient/expedient.mutations';
import type { ExpedientState } from '@/store/modules/patient/expedient/expedient.types';
import form from '@/store/modules/patient/expedient/modules/form';
import consultation from '@/store/modules/patient/expedient/modules/consultation';
import purchaseAuthorization from '@/store/modules/patient/expedient/modules/purchaseAuthorization';
import treatment from '@/store/modules/patient/expedient/modules/treatment';
import room from '@/store/modules/patient/expedient/modules/room';

export default {
  namespaced: true,
  state: (): ExpedientState => ({
    status: null,
    expedientSection: 'general',
    currentExpedient: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
  modules: {
    form,
    consultation,
    purchaseAuthorization,
    treatment,
    room,
  },
};
