
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import TheProfessionalPatientPersonalDataModal
  from '@/views/professional/expedients/parts/TheProfessionalPatientPersonalDataModal.vue';
import TheProfessionalExpedientFileGeneral
  from '@/views/professional/expedients/general/TheProfessionalExpedientFileGeneral.vue';
import TheProfessionalExpedientFileMedicalData
  from '@/views/professional/expedients/medicalData/TheProfessionalExpedientFileMedicalData.vue';
import TheProfessionalConsultation
  from '@/views/professional/expedients/consultation/TheProfessionalConsultation.vue';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ThePurchaseAuthorizationList
  from '@/views/professional/purchaseAuthorization/ThePurchaseAuthorizationList.vue';
import TreatmentProtocolPhase from '@/api/objects/TreatmentProtocolPhase';
import TheProfessionalTreatmentFinishConfirmModal
  from '@/views/professional/expedients/treatments/parts/TheProfessionalTreatmentFinishConfirmModal.vue';
import TheProfessionalTreatmentChangePhaseModal
  from '@/views/professional/expedients/treatments/parts/TheProfessionalTreatmentChangePhaseModal.vue';
import TheProfessionalAnamnesis from '@/views/professional/anamnesis/TheProfessionalAnamnesis.vue';
import TheAnamnesisResultsModal from '@/views/professional/anamnesis/TheAnamnesisResultsModal.vue';
import api from '@/api';
import TheProfessionalExpedientAnamnesisTab
  from '@/views/professional/expedients/anamnesis/TheProfessionalExpedientAnamnesisTab.vue';
import TheProfessionalDocument from '@/views/professional/documents/TheProfessionalDocument.vue';
import Protocol from '@/api/objects/Protocol';
import VProtocolName from '@/components/vendor/VProtocolName.vue';
import Consultation from '@/api/objects/Consultation';
import TheVideoCallOverlay
  from '@/views/professional/expedients/videocalls/TheVideoCallOverlay.vue';
import { POSITION } from 'vue-toastification';

export default defineComponent({
  name: 'TheProfessionalExpedientFile',
  components: {
    VProtocolName,
    TheProfessionalDocument,
    TheProfessionalExpedientAnamnesisTab,
    TheAnamnesisResultsModal,
    TheProfessionalAnamnesis,
    TheProfessionalTreatmentChangePhaseModal,
    TheProfessionalTreatmentFinishConfirmModal,
    ThePurchaseAuthorizationList,
    VButton,
    TheProfessionalConsultation,
    TheProfessionalExpedientFileMedicalData,
    TheProfessionalExpedientFileGeneral,
    TheProfessionalPatientPersonalDataModal,
    TheVideoCallOverlay,
  },
  data() {
    return {
      expedientId: Number(this.$route.params.expedient),
      modalToShow: null as string | null,
      medicalData: 'alergias',
      anamnesisAlerts: [] as string[],
    };
  },
  watch: {
    expedientSection(newValue) {
      // We redirect to 404 page if they force unexpected section router parameters
      if (!this.acceptedSections.includes(newValue)) {
        this.$router.push({ name: 'not-found' });
      }
    },
    async currentExpedient() {
      await this.checkIfRGPDAccepted();
    },
    async currentTreatment() {
      if (!!this.currentTreatment) {
        await this.$router.push({
          path: this.$route.fullPath,
          query: { tratamiento: this.currentTreatment?.id },
        });
      }
    },
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'status', 'expedientSection', 'consultations']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('protocol', ['protocols']),
    ...mapState('consultation', ['currentConsultation']),
    ...mapState('app', ['formsSettings']),
    protocolPhases(): any {
      if (this.protocols) {
        // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
        // eslint-disable-next-line max-len
        const protocol = this.protocols.find((p: Protocol) => p.id === this.currentTreatment.treatmentProtocolPhases[0].protocolPhase.protocol.id);

        return protocol?.phases;
      }

      return null;
    },
    acceptedSections() {
      // TODO: Move this to other site maybe? Please...
      return [
        'general',
        'anamnesis',
        'datos-medicos',
        'consultas',
        'autorizacion-compra',
        'documentos',
      ];
    },
    lastConsultation() {
      let lastConsultation: null | Consultation = null;

      this.consultations.forEach((consultation) => {
        if (!lastConsultation || consultation.initDate > lastConsultation.initDate) {
          lastConsultation = consultation;
        }
      });

      return lastConsultation;
    },
    protocolPhasePublicResource(): any {
      // eslint-disable-next-line max-len
      let protocolName = this.protocolName().toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
      protocolName = protocolName.replaceAll(' ', '_');
      const protocolPhaseName = this.phaseActive().protocolPhase.name.toLowerCase().replaceAll(' ', '_');
      const name = `protocolo_${protocolName}_${protocolPhaseName}.pdf`;

      return this.formsSettings.public_resources_links.find((o) => o.name === name);
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  async mounted() {
    await this.checkIfRGPDAccepted();
  },
  methods: {
    getProtocolPhaseUrl() {
      return this.protocolPhasePublicResource.link;
    },
    async downloadProtocolPhase() {
      try {
        // eslint-disable-next-line max-len
        window.open(this.protocolPhasePublicResource.download_link, '_blank');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al intentar descargar el archivo'));
      }
    },
    /**
     * Iterates through the existin public resources to find the one with the provided 'name'.
     * @param name
     */
    getResourceLink(name) {
      const resource = this.formsSettings.public_resources_links.find((o) => o.name === name);

      if (resource) {
        return resource.link;
      }

      return '';
    },
    handleAlertsUpdatedEvent(alerts) {
      this.anamnesisAlerts = alerts;
    },
    openTheAnamnesisResultsModal() {
      this.modalToShow = '';

      setTimeout(() => {
        this.modalToShow = 'the-anamnesis-results-modal';
      }, 500);
    },
    async startAnamnesis() {
      try {
        const anamnesis = await api.professional.expedients.anamnesis.start(this.currentExpedient.id);

        await this.$store.dispatch('expedient/setCurrentAnamnesis', {
          anamnesis,
        });

        this.modalToShow = 'the-professional-anamnesis';
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al empezar la anamnesis'));
      }
    },
    protocolName() {
      // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
      return this.currentTreatment.treatmentProtocolPhases[0].protocolPhase.protocol.name;
    },
    protocolType() {
      // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
      return this.currentTreatment.treatmentProtocolPhases[0].protocolPhase.protocol.type;
    },
    phaseActive(): TreatmentProtocolPhase {
      // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
      return this.currentTreatment.treatmentProtocolPhases.find((ppt: TreatmentProtocolPhase) => !ppt.endDate);
    },
    isActive(menuItem: string) {
      return this.expedientSection === menuItem;
    },
    async changeExpedientSection(expedientSection: string, redirect = true) {
      await this.$store.dispatch('expedient/setExpedientSection', {
        expedientSection,
      });

      if (redirect) {
        await this.$router.push({
          name: 'professional.expedient.show',
          params: {
            expedient: this.currentExpedient.id,
            tabName: expedientSection,
          },
          query: {
            tratamiento: this.currentTreatment?.id,
          },
        });
      }
    },
    handleTreatmentPhaseChangedEvent() {
      // Any purchase authorization related to previously active phase is disabled, so refresh them
      this.loadPurchaseAuthorizations();
    },
    handleTreatmentFinishedEvent() {
      // Any purchase authorization related to that protocol is disabled, so refresh tem
      this.loadPurchaseAuthorizations();
    },
    async loadPurchaseAuthorizations() {
      try {
        await this.$store.dispatch('expedient/refreshPurchaseAuthorizations', {
          expedientId: this.currentExpedient.id,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las autorizaciones de compra'), {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    },
    async checkIfRGPDAccepted() {
      if (this.currentExpedient && !this.currentExpedient.rgpdConfirmedAt) {
        await this.$router.replace({ name: 'professional.patient.rgpd' });
      }
    },
  },
});
