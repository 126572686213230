
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import TheLogout from '@/components/structural/TheLogout.vue';
import Dropdown from '@/components/vendor/basic/dropdown/Dropdown.vue';

export default defineComponent({
  name: 'TheMenuPatient',
  components: {
    Dropdown,
    TheLogout,
  },
  computed: {
    ...mapState('user', ['authUser']),
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  methods: {
    toggleMenu() {
      // eslint-disable-next-line no-unused-expressions
      document.getElementById('html')
        ?.classList
        .toggle('menu-open');

      // eslint-disable-next-line no-unused-expressions
      document.getElementById('body')
        ?.classList
        .toggle('menu-open');
    },
    closeMenu() {
      const html = document.getElementById('html');

      if (html?.classList.contains('menu-open')) {
        html.classList.remove('menu-open');
      }

      const body = document.getElementById('body');

      if (body?.classList.contains('menu-open')) {
        body.classList.remove('menu-open');
      }
    },
    closeNavbarCollapse() {
      this.closeMenu();

      // eslint-disable-next-line no-unused-expressions
      document.getElementById('navbarToggleExternalContent')
        ?.classList
        .remove('show');

      // eslint-disable-next-line no-unused-expressions
      document.getElementById('navbar-custom-toggler')
        ?.classList
        .add('collapsed');
    },
  },
});
