
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import VLoader from '@/components/vendor/VLoader.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';

export default defineComponent({
  name: 'VGeneralModal',
  components: {
    VLoader,
    VModal,
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
  },
  computed: {
    ...mapState('general', ['modal']),
  },
  data() {
    return {
      leftButtonIsLoading: false,
      rightButtonIsLoading: false,
    };
  },
  methods: {
    async dispatchHideModal() {
      await this.$store.dispatch('general/hideModal');
    },
    async rightButtonClicked() {
      await this.manageCallback(false);
    },
    async leftButtonClicked() {
      await this.manageCallback(true);
    },
    async manageCallback(isLeft) {
      let close = true;

      // We disable button
      if (isLeft === true) {
        this.leftButtonIsLoading = true;
        await this.$store.dispatch('general/disableModalLeftButton');
      } else {
        this.rightButtonIsLoading = true;
        await this.$store.dispatch('general/disableModalRightButton');
      }

      // Manage each callback if existing
      if ((!!this.modal.leftButtonCallback && isLeft === true)
        || (!!this.modal.rightButtonCallback && isLeft === false)) {
        let response: any | null = null;

        // Here we execute the callback
        if (isLeft === true) {
          response = await this.modal.leftButtonCallback();
        } else {
          response = await this.modal.rightButtonCallback();
        }

        // If it returns an .error item, it won't be closed and, an error will be toasted and button will be re-enabled
        if (!!response && !!response.error) {
          close = false;

          if (response.error !== true) {
            this.$toast.error(response.error);
          }

          if (isLeft === true) {
            this.leftButtonIsLoading = false;
            await this.$store.dispatch('general/enableModalLeftButton');
          } else {
            this.rightButtonIsLoading = false;
            await this.$store.dispatch('general/enableModalRightButton');
          }
        }
      }

      // If everything ok => close
      if (close) {
        const $modal: any = this.$refs.modal;
        $modal.hide();
      }
    },
  },
});
