import { vModelRadio as _vModelRadio, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-123a88dc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "name", "value", "disabled", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    type: "radio",
    id: _ctx.id,
    name: _ctx.name,
    class: _normalizeClass(["form-check-input", _ctx.inputClass]),
    value: _ctx.value,
    disabled: _ctx.disabled,
    checked: _ctx.checked,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitValue && _ctx.emitValue(...args)))
  }, null, 42, _hoisted_1)), [
    [_vModelRadio, _ctx.inputValue]
  ])
}