import { Store } from 'vuex';
import { State } from '@/store/types';
import expedient from '@/store/modules/expedient';
import consultation from '@/store/modules/consultation';
import treatment from '@/store/modules/treatment';
import user from '@/store/modules/user';
import general from '@/store/modules/general';
import app from '@/store/modules/app';
import protocol from '@/store/modules/protocol';
import professional from '@/store/modules/professional';
import purchaseAuthorization from '@/store/modules/purchaseAuthorization';
import patient from '@/store/modules/patient';
import interConsultation from '@/store/modules/interconsultation';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $store: Store<State>;
  }
}

export default new Store<State>({
  modules: {
    user,
    app,
    expedient,
    consultation,
    treatment,
    general,
    protocol,
    professional,
    purchaseAuthorization,
    patient,
    interConsultation,
  },
});
