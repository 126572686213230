import { MutationTree } from 'vuex';
import { RoomState } from '@/store/modules/patient/expedient/modules/room/room.types';
import Room from '@/api/objects/Room';

interface RoomMutations {
  setCurrentRoom(state: RoomState, payload: Room): void;
}

const mutations: MutationTree<RoomState> & RoomMutations = {
  setCurrentRoom(state: RoomState, room: Room) {
    state.currentRoom = room;
  },
};

export default mutations;
