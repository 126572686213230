export default {
  mixed: {
    default: 'No es válido',
    // eslint-disable-next-line no-template-curly-in-string
    required: '${label} es obligatorio',
    oneOf: 'Las contraseñas deben coincidir',
  },
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'Debes introducir un mínimo de {min} caracteres',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'Debes introducir un máximo de {max} caracteres',
    email: 'Debes introducir un correo válido',
  },
  number: {
    // eslint-disable-next-line no-template-curly-in-string
    min: 'El valor debe ser mayor que {min}',
    // eslint-disable-next-line no-template-curly-in-string
    max: 'El valor debe ser menor que {max}',
  },
};
