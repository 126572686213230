import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row gy-24" }
const _hoisted_2 = { class: "col-12 col-lg-4" }
const _hoisted_3 = { class: "media-container" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 1,
  class: "icon icon-pdf"
}
const _hoisted_6 = {
  key: 2,
  class: "icon icon-doc"
}
const _hoisted_7 = { class: "col-12 col-lg-8" }
const _hoisted_8 = { class: "file-info-container" }
const _hoisted_9 = { class: "mb-8 fw-bold" }
const _hoisted_10 = { class: "fw-normal" }
const _hoisted_11 = { class: "mb-8 fw-bold" }
const _hoisted_12 = { class: "fw-normal" }
const _hoisted_13 = { class: "mb-8 fw-bold" }
const _hoisted_14 = { class: "fw-normal" }
const _hoisted_15 = { class: "mb-8 fw-bold" }
const _hoisted_16 = { class: "fw-normal" }
const _hoisted_17 = ["href", "aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    size: "lg",
    "backdrop-static": false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Previsualización del documento')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_ctx.isImage)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: _ctx.media.storageUrl,
                      alt: _ctx.media.name
                    }, null, 8, _hoisted_4))
                  : (_ctx.isPdf)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6))
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("p", _hoisted_9, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Nombre')) + ": ", 1),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.media.name), 1)
                ]),
                _createElementVNode("p", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Categoría')) + ": ", 1),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.collectionName), 1)
                ]),
                _createElementVNode("p", _hoisted_13, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Tamaño')) + ": ", 1),
                  _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.media.humanReadableSize), 1)
                ]),
                _createElementVNode("p", _hoisted_15, [
                  _createTextVNode(_toDisplayString(_ctx.$t('Fecha de subida')) + ": ", 1),
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.media.createdAt), 1)
                ])
              ]),
              (_ctx.isImage || _ctx.isPdf)
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: _ctx.media.storageUrl,
                    target: "_blank",
                    "aria-label": _ctx.$t('Visualizar archivo'),
                    title: "Visualizar archivo",
                    class: "btn btn-secondary btn-sm me-16"
                  }, _toDisplayString(_ctx.$t('Visualizar')), 9, _hoisted_17))
                : _createCommentVNode("", true),
              _createVNode(_component_v_button, {
                variant: "secondary",
                "aria-label": _ctx.$t('Descargar archivo'),
                title: "Descargar archivo",
                onClick: _ctx.download
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Descargar')), 1)
                ]),
                _: 1
              }, 8, ["aria-label", "onClick"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "secondary",
            class: "me-24",
            "data-bs-dismiss": "modal",
            "aria-label": _ctx.$t('Cerrar'),
            title: "Cerrar",
            onClick: _ctx.closeModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cerrar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}