import BaseObject from '@/api/objects/BaseObject';

export interface VideoContract {
  name: string;
  description: string;
  categories: string[];
  duration: string;
  visibility: string;
  thumbnailUrl: string | null;
  url: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export default class Video extends BaseObject implements VideoContract {
  get name(): string {
    return this.attributes.name;
  }

  get description(): string {
    return this.attributes.description;
  }

  get categories(): string[] {
    return this.attributes.categories;
  }

  get duration(): string {
    return this.attributes.duration;
  }

  get visibility(): string {
    return this.attributes.visibility;
  }

  get thumbnailUrl(): string | null {
    // TODO: De momento sólo habrá un Thumbnail. Cuando pueda haber varios, quitar '[0]'
    if (!!this.attributes.thumbnail_url) {
      return this.attributes.thumbnail_url[0];
    }
    return this.attributes.thumbnail_url;
  }

  get url(): string {
    return this.attributes.url;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get deletedAt(): string {
    return this.attributes.deleted_at;
  }
}
