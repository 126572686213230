import AbstractService from '@/api/services/AbstractService';
import Form from '@/api/objects/Form';

class PatientTreatmentMeasureService extends AbstractService {
  public async all(expedient: number, treatment: number): Promise<Form[]> {
    return this.request('get', this.buildPath('patient-zone/expedient/{expedient}/treatment/{treatment}/measures', {
      expedient,
      treatment,
    }));
  }
}

export default new PatientTreatmentMeasureService();
