
import { defineComponent } from 'vue';
import VLoader from '@/components/vendor/VLoader.vue';
import { mapState } from 'vuex';
import Call from '@/views/professional/videocalls/Call.vue';
import Room from '@/api/objects/Room';
import TheVideoCallShareModal
  from '@/views/professional/expedients/videocalls/TheVideoCallShareModal.vue';
import useCapabilities from '../../../../resources/capabilities';
import VButton from '../../../../components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'TheVideoCallOverlay',
  components: {
    TheVideoCallShareModal,
    Call,
    VLoader,
    VButton,
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('expedient/room', ['currentRoom']),
    ...mapState('user', ['authUser']),
  },
  watch: {
    currentExpedient(newValue) {
      if (newValue) {
        this.loadRoomInVuexStore(true);
      }
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      status: 'waiting',
      userName: '',
      roomUrl: '',
      room: null as null | Room,
      windowMaximized: false,
      windowMinimized: true,
      windowNormal: false,
      modalToShow: null as string | null,
      availableRoomsToCreate: 0,
    };
  },
  beforeMount() {
    this.loadRoomInVuexStore(true);
  },
  methods: {
    async startVideoCall() {
      if (this.currentRoom) {
        this.windowNormal = true;
        this.windowMinimized = false;

        this.joinRoom(this.currentRoom);
      } else {
        this.$toast.error(this.$t('Hubo un error al intentar acceder a la videollamada.'));
      }
    },
    /**
     * Loads the current expedient room in the vuex store. If the room doesn't exist, it creates
     * a new one and returns it.
     *
     * @param force Forces to dispatch the action in order to get the room through an API request.
     */
    async loadRoomInVuexStore(force: boolean) {
      if (!this.currentRoom || force) {
        try {
          await this.$store.dispatch('expedient/room/getRoomByExpedient', {
            expedientId: this.currentExpedient.id,
          });
        } catch (e) {
          // We get the remaining rooms to create
          // TODO: Handle 'message' instead of 'description' when received
          this.availableRoomsToCreate = e.response.data.description.split(':')[1].trim();
        }
      }
    },
    joinRoom(room) {
      let name = 'alguien';
      if (this.authUser.role.name === 'Paciente') {
        // Here is the patient user trying to connect with the professional so:
        name = `${this.currentExpedient.patient.user.name} ${this.currentExpedient.patient.user.surname}`;
      } else if (this.authUser.role.name === 'Medico' || this.authUser.role.name === 'Nutricionista') {
        name = `${this.currentExpedient.professional.name} ${this.currentExpedient.professional.surname}`;
      }

      this.userName = name;
      this.roomUrl = room.url;
      this.status = 'in-call';
    },
    leaveCall() {
      this.status = 'idle';
      this.windowNormal = false;
      this.windowMinimized = true;
    },
    async openShareModal() {
      if (!this.currentRoom) {
        await this.loadRoomInVuexStore(true);
      }

      if (this.currentRoom) {
        this.modalToShow = 'the-video-call-share-modal';
      } else {
        await this.$modal.confirm({
          title: this.$t('Crear sala de reuniones'),
          // eslint-disable-next-line max-len
          text: this.$t(`Puedes crear una sala de reuniones. Podrás utilizar esta sala para reunirte con tu paciente ${this.currentExpedient.patient.user.name} ${this.currentExpedient.patient.user.surname}. Hoy puedes crear ${this.availableRoomsToCreate} salas más.`),
          confirmButtonText: this.$t('Crear sala'),
          confirmButtonCallback: this.doCreateVideoCallRoom,
        });
      }
    },
    async openHelpModal() {
      await this.$modal.info({
        title: this.$t('Ayuda de videollamadas'),
        // eslint-disable-next-line max-len
        text: this.$t('Las salas de videollamadas permiten reunirse al profesional con el paciente. '
          + 'Como médico, tienes que crear la sala cuando realmente vayas a reunirte con el paciente, '
          + 'y podrás invitarle mediante un enlace directo con el que podrá conectarse en un par de '
          + 'sencillos pasos. Hay un límite de 3 salas por día por paciente, y las salas tienen una '
          + 'duración máxima de 30 minutos desde que se crea. Podrás enviarle el enlace por correo o '
          + 'por SMS.'),
      });
    },
    /**
     * Tries to start a video call if there is a room available or asks the user to create a new one
     * if unavailable.
     */
    async askForStartingVideocall() {
      // We first check if this.currentRoom exist
      await this.loadRoomInVuexStore(true);

      // Check if currentRoom is available
      if (this.currentRoom) {
        await this.startVideoCall();
      } else {
        await this.$modal.confirm({
          title: this.$t('Crear sala de reuniones'),
          // eslint-disable-next-line max-len
          text: this.$t(`Puedes crear una sala de reuniones. Podrás utilizar esta sala para reunirte con tu paciente ${this.currentExpedient.patient.user.name} ${this.currentExpedient.patient.user.surname}. Hoy puedes crear ${this.availableRoomsToCreate} salas más.`),
          confirmButtonText: this.$t('Crear sala'),
          confirmButtonCallback: this.doCreateVideoCallRoom,
        });
      }
    },
    /**
     * Calls the API to create a new room and returns it if possible or an error if not.
     */
    async doCreateVideoCallRoom() {
      try {
        await this.$store.dispatch('expedient/room/createRoomByExpedient', {
          expedientId: this.currentExpedient.id,
        });

        await this.startVideoCall();
      } catch (e) {
        await this.$modal.info({
          title: this.$t('Has llegado a tu límite'),
          text: this.$t(e.response.data.message),
        });

        return { error: true };
      }

      return true;
    },
  },
});
