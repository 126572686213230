import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_form_group_input_text = _resolveComponent("v-form-group-input-text")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    "is-double-modal": true,
    "class-name": "modal-fullscreen-lg-down",
    size: "xl",
    onClosed: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Añadir categoría')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_form_group_input_text, {
              label: _ctx.$t('Nombre'),
              value: _ctx.name,
              "yup-errors-variable": _ctx.errors.name,
              autocomplete: "off",
              class: "mb-24",
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.name = $event))
            }, null, 8, ["label", "value", "yup-errors-variable"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createElementVNode("button", {
            "aria-label": _ctx.$t('Cancelar'),
            class: "btn btn-sm btn-link-secondary me-24",
            "data-bs-dismiss": "modal",
            type: "button"
          }, _toDisplayString(_ctx.$t('Cancelar')), 9, _hoisted_2),
          _createVNode(_component_v_button, {
            "aria-label": _ctx.$t('Añadir'),
            disabled: !_ctx.meta.valid || _ctx.accepted,
            "is-loading": _ctx.accepted,
            variant: "secondary",
            onClick: _ctx.createCategory
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Añadir')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "disabled", "is-loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["onClosed"]))
}