import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { AppState } from '@/store/modules/app/app.types';
import api from '@/api';

type Context = ActionContext<AppState, State>;

const actions: ActionTree<AppState, State> = {
  async getFormsSettings({ commit }) {
    const formsSettings = await api.professional.settings.all();

    commit('setFormsSettings', formsSettings);
  },

  async cleanFormsSettings({
    commit,
  }: Context) {
    commit('setFormsSettings', {});
  },
};

export default actions;
