
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import { mapState } from 'vuex';
import api from '@/api';
import Category from '@/api/objects/Category';

export default defineComponent({
  name: 'TheAdminVideoCategoryDestroy',
  components: {
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
  },
  emits: ['closed', 'videoCategoryDestroyed'],
  props: {
    category: {
      type: Category,
      required: true,
    },
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('app', ['formsSettings']),
  },
  methods: {
    async destroyVideoCategory() {
      try {
        // TODO: Send the request to update that video
        await api.admin.videoCategory.delete(this.category.id);

        await this.$modal.info({
          title: this.$t('Categoría eliminada'),
          text: this.$t('Se ha eliminado la categoría con éxito.'),
        });

        this.$emit('videoCategoryDestroyed');
        this.closeModal();
        await this.$router.push({ name: 'admin.video.index' });
        await this.$router.push({ name: 'admin.video-category.index' });
      } catch (e) {
        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
