import { MutationTree } from 'vuex';
import { ProtocolState } from '@/store/modules/protocol/protocol.types';
import Protocol from '@/api/objects/Protocol';

interface ProtocolMutations {
  setStatus(state: ProtocolState, status: string): void;

  setProtocols(state: ProtocolState, payload: Protocol[]): void;

  setCurrentProtocol(state: ProtocolState, payload: any): void;
}

const mutations: MutationTree<ProtocolState> & ProtocolMutations = {
  setStatus(state: ProtocolState, status: string) {
    state.status = status;
  },
  setProtocols(state: ProtocolState, protocols: Protocol[]) {
    state.protocols = protocols;
  },
  setCurrentProtocol(state: ProtocolState, protocol: Protocol) {
    state.currentProtocol = protocol;
  },
};

export default mutations;
