
import { defineComponent, PropType } from 'vue';
import useCapabilities from '@/resources/capabilities';
import Consultation from '@/api/objects/Consultation';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import TheProfessionalConsultationCreate
  from '@/views/professional/expedients/consultation/parts/TheProfessionalConsultationCreate.vue';

export default defineComponent({
  name: 'VProfessionalConsultations',
  components: {
    TheProfessionalConsultationCreate,
    VButton,
  },
  props: {
    consultations: {
      type: Array as PropType<Consultation[]>,
      required: false,
    },
    goShowConsultation: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPatient: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCreateButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('app', ['formsSettings']),
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: null as string | null,
    };
  },
  methods: {
    type(consultation) {
      const type = this.formsSettings.consultation_type_options.find((o) => o.key === consultation.type);

      return type.label;
    },
    async setConsultation(consultation) {
      await this.$store.dispatch('consultation/getCurrentConsultation', {
        expedientId: consultation.expedientId,
        consultationId: consultation.id,
      });

      await this.$store.dispatch('expedient/setExpedientSection', {
        expedientSection: 'consultas',
      });
    },
  },
});
