
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import VGeneralModal from '@/components/structural/modals/VGeneralModal.vue';

export default defineComponent({
  name: 'TheGeneralComponents',
  components: {
    VGeneralModal,
  },
  computed: {
    ...mapState('general', ['modal']),
  },
});
