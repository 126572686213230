import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_form_group_anamnesis = _resolveComponent("the-form-group-anamnesis")!
  const _component_v_alert = _resolveComponent("v-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastFormStructure.form_groups, (formGroup, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: _normalizeClass(formGroup.class)
        }, [
          _createVNode(_component_the_form_group_anamnesis, {
            "form-group-structure": formGroup,
            onFormGroupUpdated: _ctx.checkAlerts
          }, null, 8, ["form-group-structure", "onFormGroupUpdated"])
        ], 2))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert) => {
        return (_openBlock(), _createBlock(_component_v_alert, {
          variant: "warning",
          role: "alert",
          key: alert
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(alert), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ]))
}