import { createLogger } from 'vuex';
import actions from '@/store/modules/consultation/consultation.actions';
import mutations from '@/store/modules/consultation/consultation.mutations';
import type { ConsultationState } from '@/store/modules/consultation/consultation.types';

export default {
  namespaced: true,
  state: (): ConsultationState => ({
    currentConsultation: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
