
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import VFormGroupTextarea from '@/components/vendor/basic/form/VFormGroupTextarea.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import Video from '@/api/objects/Video';
import api from '@/api';
import Category from '@/api/objects/Category';
import TheAdminVideoCategoryCreate
  from '@/views/administrator/videos/category/TheAdminVideoCategoryCreate.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminVideoUpdate',
  components: {
    TheAdminVideoCategoryCreate,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VFormGroupTextarea,
    VButton,
    VFormGroupSelect,
    VFormGroupInputText,
    vSelect,
  },
  emits: ['closed', 'videoUpdated'],
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  props: {
    video: {
      type: Video,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('Nombre')),
      description: yup.string()
        .nullable()
        .label(t('Descripción')),
      categories: yup.array()
        .required()
        .min(1)
        .label(t('Categoría')),
      visibility: yup.string()
        .required()
        .label(t('Visibilidad')),
      url: yup.string()
        .required()
        .label(t('Url')),
    });

    const initialValues: Record<string, any> = {
      name: props.video.name,
      description: props.video.description,
      categories: props.video.categories,
      visibility: props.video.visibility,
      url: props.video.url,
    };

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField('name');
    const { value: description } = useField('description');
    const { value: categories } = useField<[]>('categories');
    const { value: visibility } = useField('visibility');
    const { value: url } = useField('url');

    return {
      ...form,

      name,
      description,
      categories,
      visibility,
      url,

      ...useCapabilities(),
    };
  },
  data() {
    return {
      isLoading: true,
      loadingCategories: false as boolean,
      categoryOptions: [] as Category[],
      modalToShow: null as null | string,
    };
  },
  async created() {
    await this.loadCategories();
    await this.setCategories();
  },
  methods: {
    async loadCategories() {
      try {
        this.loadingCategories = true;

        this.categoryOptions = await api.admin.videoCategory.all();

        this.loadingCategories = false;
      } catch (e: any) {
        this.loadingCategories = false;

        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);
      }
    },
    async setCategories() {
      const categoriesAux: any = [];

      this.categories.forEach((category: string) => {
        const categoryFound = this.categoryOptions.find((o) => o.name === category);

        if (!!categoryFound) {
          categoriesAux.push(categoryFound.id);
        }
      });

      this.categories = categoriesAux;
    },
    async updateVideo() {
      this.toggleAccepted();

      const data = {
        name: this.name,
        description: this.description,
        categories: this.categories,
        visibility: this.visibility,
        url: this.url,
      };

      try {
        // TODO: Send the request to update that video
        await api.admin.video.update(this.video.id, data);

        await this.$modal.info({
          title: this.$t('Vídeo editado'),
          text: this.$t('Se ha editado el vídeo "{ name }" con éxito.', { name: this.name }),
        });

        this.resetForm();

        this.toggleAccepted();

        this.$emit('videoUpdated');
        this.closeModal();
      } catch (e) {
        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);

        this.toggleAccepted();
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
