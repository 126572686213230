import BaseObject from '@/api/objects/BaseObject';
import FormGroup from '@/api/objects/FormGroup';

export interface FormContract {
  type: string;
  version: number;
  createdAt: string;
  updatedAt: string;
}

export default class Form extends BaseObject implements FormContract {
  get type(): string {
    return this.attributes.type;
  }

  get version(): number {
    return Number(this.attributes.version);
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get formGroups(): FormGroup[] | null {
    return this.getRelation('form_groups');
  }

  formGroupByName(name: string): FormGroup | undefined {
    return this.formGroups?.find((fg) => fg.name === name);
  }

  formGroupById(id: number): FormGroup | undefined {
    return this.formGroups?.find((fg) => fg.id === id);
  }
}
