import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75ba166e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12 col-lg-4" }
const _hoisted_2 = { class: "h4 title fw-normal mb-16 text-primary" }
const _hoisted_3 = { class: "fw-bold" }
const _hoisted_4 = { class: "consultations-list" }
const _hoisted_5 = {
  key: 0,
  class: "h-100 w-100 d-flex flex-column align-items-center justify-content-center text-center"
}
const _hoisted_6 = { class: "h5 title fw-bold text-primary" }
const _hoisted_7 = {
  key: 1,
  class: "scroll-component-container"
}
const _hoisted_8 = { class: "scroll-component" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", _hoisted_2, [
      _createTextVNode(_toDisplayString(_ctx.$t('Historial de')) + " ", 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('consultas')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.consultations.length)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('No hay consultas')), 1)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]))
    ])
  ]))
}