import BaseObject from '@/api/objects/BaseObject';
import Expedient from '@/api/objects/Expedient';
import Treatment from '@/api/objects/Treatment';
import Clinic from '@/api/objects/Clinic';

export interface ConsultationContract {
  initDate: string;
  endDate: string;
  type: string;
  via: string;
  observations: string;
  clinicId: number;
  expedientId: number;
  treatmentId: number;
  createdAt: string;
  updatedAt: string;
  expedient: Expedient | null;
  treatment: Treatment | null;
  expedientData: Expedient | null;
}

export default class Consultation extends BaseObject implements ConsultationContract {
  get initDate(): string {
    return this.attributes.init_date;
  }

  get endDate(): string {
    return this.attributes.end_date;
  }

  get type(): string {
    return this.attributes.type;
  }

  get via(): string {
    return this.attributes.via;
  }

  get observations(): string {
    return this.attributes.observations;
  }

  get clinicId(): number {
    return this.attributes.clinic_id;
  }

  get expedientId(): number {
    return this.attributes.expedient_id;
  }

  get treatmentId(): number {
    return this.attributes.treatment_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get expedient(): Expedient | null {
    return this.getRelation('expedient');
  }

  get expedientData(): Expedient | null {
    return this.attributes.expedientData;
  }

  set expedientData(expedient: Expedient | null) {
    this.attributes.expedientData = expedient;
  }

  get treatment(): Treatment | null {
    return this.getRelation('treatment');
  }

  get clinic(): Clinic | null {
    return this.getRelation('clinic');
  }
}
