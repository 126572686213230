
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import { mapState } from 'vuex';
import Form from '@/api/objects/Form';
import api from '@/api';
import TheFormAnamnesis from '@/views/professional/anamnesis/parts/TheFormAnamnesis.vue';

export default defineComponent({
  name: 'TheProfessionalAnamnesis',
  components: {
    TheFormAnamnesis,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'currentAnamnesis']),
    ...mapState('app', ['formsSettings']),
    currentAnamnesisStructure(): Form {
      const formStructures = this.formsSettings.forms.find((obj) => obj.type === 'anamnesis'
        && obj.version === this.currentAnamnesis.version);

      return formStructures.structure;
    },
    lastAnamnesisStructureVersion(): number {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === 'anamnesis');

      const lastFormStructure = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      const lastFormStructureVersion = lastFormStructure.structure;

      return lastFormStructureVersion.version;
    },
  },
  async mounted() {
    if (this.currentAnamnesisStructure.version < this.lastAnamnesisStructureVersion) {
      await this.$modal.confirm({
        title: this.$t('Hay una nueva versión de anamnesis'),
        // eslint-disable-next-line max-len
        text: this.$t('Estás respondiendo a una anamnesis que no es la última versión disponible. Puedes actualizarla y conservaremos todas las respuestas que se hayan mantenido en la nueva versión, o puedes continuar respondiendo a la antigua. ¿Quieres actualizar a la nueva versión?'),
        confirmButtonText: this.$t('Actualizar'),
        confirmButtonCallback: this.doUpgradeAnamnesis,
      });
    }
  },
  methods: {
    emitAlertsUpdatedEvent(alerts) {
      this.$emit('alerts-updated', alerts);
    },
    anamnesisSuggest() {
      this.$emit('suggest-protocols-from-anamnesis-requested');
    },
    async doUpgradeAnamnesis() {
      try {
        const anamnesis = await api.professional.expedients.anamnesis.upgrade(
          this.currentExpedient.id,
          this.currentAnamnesis.id,
        );

        await this.$store.dispatch('expedient/setCurrentAnamnesis', {
          anamnesis,
        });

        this.$toast.success(this.$t('Se ha actualizado tu anamnesis con la nueva versión'));

        this.closeModal();
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al actualizar la anamnesis'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
