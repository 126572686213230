
import { defineComponent } from 'vue';
import * as yup from 'yup';
import useCapabilities from '@/resources/capabilities';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import Patient from '@/api/objects/Patient';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import { mapState } from 'vuex';
import TheProfessionalPatientAddressFieldset
  from '@/views/professional/expedients/parts/TheProfessionalPatientAddressFieldset.vue';
import api from '@/api';
import VFormGroupInputDate from '@/components/vendor/basic/form/VFormGroupInputDate.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalPatientPersonalDataModal',
  components: {
    VFormGroupInputDate,
    TheProfessionalPatientAddressFieldset,
    VFormGroupSelect,
    VFormGroupInputText,
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    vSelect,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      name: yup.string()
        .required()
        .max(60)
        .label(t('Nombre')),
      surname: yup.string()
        .required()
        .max(60)
        .label(t('Apellidos')),
      genre: yup.string()
        .required()
        .max(60)
        .label(t('Género')),
      bornDate: yup.string()
        .required()
        .max(60)
        .label(t('Fecha de nacimiento')),
      identityDocType: yup.string()
        .required()
        .label(t('Tipo de Documento')),
      nif: yup.lazy((value, options) => {
        // initialize default rules for all options
        const rule = yup.string()
          .required()
          .label(t('Número de documento'));
        // this property retrieves the information of the datasets initialized with yup
        const { identityDocType } = options.parent;
        // TODO: Check translation of this
        const messageValidation = t('El {tipo_documento} registrado no es Válido', { tipo_documento: identityDocType });
        switch (identityDocType) {
          case 'dni':
            return rule.matches(/^[0-9]{8,8}[A-Za-z]$/g, messageValidation);
          case 'nie':
            return rule.matches(/^[XYZ|xyz]\d{7,8}[A-Za-z]$/, messageValidation);
          default:
            return rule; // here you can decide what is the default
        }
      }),
      phoneNumberPrefix: yup.string()
        .required()
        .label(t('Prefijo telefónico')),
      phoneNumber: yup.string()
        .required()
        .label(t('Número de teléfono'))
        .matches(/^\d{6,14}$/, t('Debe tener entre 6 y 14 cifras')),
    });

    const form = useFormValidation(rules);

    const { value: name } = useField('name');
    const { value: surname } = useField('surname');
    const { value: genre } = useField('genre');
    const { value: bornDate } = useField('bornDate');
    const { value: identityDocType } = useField('identityDocType');
    const { value: nif } = useField('nif');
    const { value: phoneNumberPrefix } = useField('phoneNumberPrefix');
    const { value: phoneNumber } = useField('phoneNumber');

    return {
      ...form,
      ...useCapabilities(),
      name,
      surname,
      genre,
      bornDate,
      identityDocType,
      nif,
      phoneNumberPrefix,
      phoneNumber,
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('app', ['formsSettings']),
    billingAddress() {
      return this.currentExpedient?.patient?.user?.addresses?.find((address) => address.type === 'billing');
    },
    shippingAddress() {
      return this.currentExpedient?.patient?.user?.addresses?.find((address) => address.type === 'shipping');
    },
  },
  beforeMount() {
    this.setPatientData();
  },
  methods: {
    setPatientData() {
      this.name = this.patient.user?.name;
      this.surname = this.patient.user?.surname;
      this.genre = this.patient.genre;
      this.bornDate = this.patient.bornDate;
      this.identityDocType = this.patient.identityDocType;
      this.nif = this.patient.nif;
      const phone = this.patient?.user?.phone?.split(' ');
      this.phoneNumberPrefix = phone ? phone[0] : '';
      this.phoneNumber = phone ? phone[1] : '';
    },
    async onSubmit() {
      this.toggleAccepted();

      const patientData = {
        name: this.name,
        surname: this.surname,
        genre: this.genre,
        born_date: this.bornDate,
        // We send and store it with a blank space between prefix and phone number
        phone_number: `${this.values.phoneNumberPrefix} ${this.values.phoneNumber}`,
        identity_doc_type: this.identityDocType,
        // NIF is editable but cannot be duplicated
        nif: this.nif,
      };

      try {
        await api.professional.patient.updatePatientData(this.currentExpedient.id, patientData);

        await this.refreshCurrentExpedient();

        this.$toast.success(this.$t('Datos actualizados con éxito.'));

        this.closeModal();
      } catch (e) {
        this.$toast.error(e.response?.data?.message);
      }

      this.toggleAccepted();
    },
    async handleAddressUpdatedEvent() {
      await this.refreshCurrentExpedient();
    },
    async refreshCurrentExpedient() {
      await this.$store.dispatch('expedient/getCurrentExpedient', {
        expedientId: this.currentExpedient.id,
        showLoader: false,
      });
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
