import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["type", "disabled", "id", "data-bs-dismiss", "data-bs-toggle", "data-bs-target", "aria-labelledby", "aria-label", "aria-haspopup", "aria-expanded", "aria-controls", "title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_loader = _resolveComponent("v-loader")!

  return (_openBlock(), _createElementBlock("button", {
    type: _ctx.type,
    class: _normalizeClass(["btn", [_ctx.cSize, _ctx.cVariant]]),
    disabled: _ctx.disabled,
    id: _ctx.id,
    "data-bs-dismiss": _ctx.dataBsDismiss,
    "data-bs-toggle": _ctx.dataBsToggle,
    "data-bs-target": _ctx.dataBsTarget,
    "aria-labelledby": _ctx.ariaLabelledby,
    "aria-label": _ctx.ariaLabel,
    "aria-haspopup": _ctx.ariaHaspopup,
    "aria-expanded": _ctx.ariaExpanded,
    "aria-controls": _ctx.ariaControls,
    title: _ctx.title,
    ref: "button"
  }, [
    (!_ctx.isLoading)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createBlock(_component_v_loader, { key: 1 }))
  ], 10, _hoisted_1))
}