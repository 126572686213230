
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';
import InterConsultation from '@/api/objects/InterConsultation';
import Professional from '@/api/objects/Professional';
import TheProfessionalInterConsultationView
  from '@/views/professional/inter-consultations/parts/TheProfessionalInterConsultationView.vue';
import TheProfessionalInterConsultationUpdate
  from '@/views/professional/inter-consultations/parts/TheProfessionalInterConsultationUpdate.vue';
import VOffCanvas from '@/components/vendor/offcanvas/VOffCanvas.vue';
import OffCanvasHeader from '@/components/vendor/offcanvas/OffCanvasHeader.vue';
import OffCanvasBody from '@/components/vendor/offcanvas/OffCanvasBody.vue';

export default defineComponent({
  name: 'TheProfessionalInterConsultation',
  components: {
    OffCanvasBody,
    OffCanvasHeader,
    VOffCanvas,
    TheProfessionalInterConsultationUpdate,
    TheProfessionalInterConsultationView,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('interConsultation', ['currentInterConsultation', 'interConsultations']),
    ...mapState('user', ['authUser']),
  },
  data() {
    return {
      professionals: [] as Professional[],
      modalToShow: null as null | string,
      isLoading: false,
      selectedInterConsultation: null as InterConsultation | null,
      mode: 'view',
    };
  },
  methods: {
    async emitRefreshInterConsultation() {
      this.$emit('emit-refresh-inter-consultation');
    },
  },
});
