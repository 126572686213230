import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { ConsultationState } from '@/store/modules/consultation/consultation.types';

type Context = ActionContext<ConsultationState, State>;

const actions: ActionTree<ConsultationState, State> = {
  async getCurrentConsultation({
    state,
    rootState,
    commit,
  }: Context, {
    expedientId,
    consultationId,
  }: { expedientId: number, consultationId: number }) {
    if (!expedientId || !consultationId) {
      commit('setCurrentConsultation', null);
    } else if (!state.currentConsultation || state.currentConsultation.id !== consultationId
      || state.currentConsultation.id === consultationId) {
      try {
        const consultation = rootState.expedient.consultations.find((o) => o.id === consultationId);

        commit('setCurrentConsultation', consultation);
      } catch (e) {
        commit('setCurrentConsultation', null);
      }
    }
  },
};

export default actions;
