import i18n from '@/lang';

const { t } = i18n.global;

function addRule(rule: any, field: string, labelName: string, message?: string, number?: number) {
  let add = rule;
  switch (field) {
    case 'string':
      if (message) {
        add = add.string(message);
      } else {
        add = add.string();
      }
      break;
    case 'email':
      if (message) {
        add = add.email(message);
      } else {
        add = add.email();
      }
      break;
    case 'number':
      if (message) {
        add = add.number(message);
      } else {
        add = add.number();
      }
      break;
    case 'required':
      if (message) {
        add = add.required(message);
      } else {
        add = add.required();
      }
      break;
    case 'max':
      if (message) {
        add = add.max(number, message);
      } else {
        add = add.max(number);
      }
      break;
    case 'min':
      if (message) {
        add = add.min(number, message);
      } else {
        add = add.min(number);
      }
      break;
    case 'transform':
      add = add.transform((v, o) => (o === '' ? null : v));
      break;
    case 'label':
      if (labelName) {
        add = add.label(labelName);
      }
      break;
    case 'nullable':
      add = add.nullable();
      break;
    // Custom rules come here below:
    case 'nullable-percentage':
      add = add.string()
        // eslint-disable-next-line max-len
        .matches(/^([0-9]?[0-9]((\.|,)[0-9][0-9]?$)?)?$|100$/g, t('El número debe ser un valor entre 0 y 100, con máximo 2 decimales.'));
      break;
    default:
      break;
  }

  if (labelName) {
    add = add.label(labelName);
  }

  return add;
}

export default addRule;
