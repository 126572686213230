
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import TheProfessionalExpedientFileMedicalData
  from '@/views/professional/expedients/medicalData/TheProfessionalExpedientFileMedicalData.vue';
import { mapState } from 'vuex';
import TreatmentProtocolPhase from '@/api/objects/TreatmentProtocolPhase';
import VProtocolName from '@/components/vendor/VProtocolName.vue';
import ThePatientConsultation
  from '@/views/patient/expedients/consultation/ThePatientConsultation.vue';
import ThePatientDocument from '@/views/patient/expedients/documents/ThePatientDocument.vue';
import ThePatientExpedientFileGeneral
  from '@/views/patient/expedients/general/ThePatientExpedientFileGeneral.vue';
import ThePatientPersonalDataModal
  from '@/views/patient/expedients/parts/ThePatientPersonalDataModal.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ThePatientVideoCallOverlay
  from '@/views/patient/expedients/videocalls/ThePatientVideoCallOverlay.vue';
import ThePatientPurchaseAuthorizationList
  from '@/views/patient/expedients/purchaseAuthorization/ThePatientPurchaseAuthorizationList.vue';
import Consultation from '@/api/objects/Consultation';

export default defineComponent({
  name: 'TheProfessionalExpedientFile',
  components: {
    ThePatientPurchaseAuthorizationList,
    ThePatientVideoCallOverlay,
    VButton,
    ThePatientPersonalDataModal,
    ThePatientExpedientFileGeneral,
    ThePatientDocument,
    ThePatientConsultation,
    VProtocolName,
    TheProfessionalExpedientFileMedicalData,
  },
  data() {
    return {
      expedientId: Number(this.$route.params.expedient),
      modalToShow: null as string | null,
      medicalData: 'alergias',
    };
  },
  watch: {
    expedientSection(newValue) {
      // We redirect to 404 page if they force unexpected section router parameters
      if (!this.acceptedSections.includes(newValue)) {
        this.$router.push({ name: 'not-found' });
      }
    },
    async currentExpedient() {
      await this.checkIfRGPDAccepted();
    },
    async currentTreatment() {
      if (!!this.currentTreatment) {
        await this.$router.push({
          path: this.$route.fullPath,
          query: { tratamiento: this.currentTreatment?.id },
        });
      }
    },
  },
  computed: {
    ...mapState('patient/expedient/room', ['currentRoom']),
    ...mapState('patient/expedient', ['currentExpedient', 'expedientSection', 'status']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('protocol', ['protocols']),
    ...mapState('patient/expedient/consultation', ['currentConsultation', 'consultations']),
    ...mapState('app', ['formsSettings']),
    acceptedSections() {
      // TODO: Move this to other site maybe? Please...
      return [
        'general',
        'anamnesis',
        'datos-medicos',
        'consultas',
        'autorizacion-compra',
        'documentos',
      ];
    },
    lastConsultation() {
      let lastConsultation: null | Consultation = null;

      this.consultations.forEach((consultation) => {
        if (!lastConsultation || consultation.initDate > lastConsultation.initDate) {
          lastConsultation = consultation;
        }
      });

      return lastConsultation;
    },
    protocolPhasePublicResource(): any {
      // eslint-disable-next-line max-len
      let protocolName = this.protocolName().toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
      protocolName = protocolName.replaceAll(' ', '_');
      const protocolPhaseName = this.phaseActive().protocolPhase.name.toLowerCase().replaceAll(' ', '_');
      const name = `protocolo_${protocolName}_${protocolPhaseName}.pdf`;

      return this.formsSettings.public_resources_links.find((o) => o.name === name);
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  async mounted() {
    await this.checkIfRGPDAccepted();
  },
  methods: {
    getProtocolPhaseUrl() {
      return this.protocolPhasePublicResource.link;
    },
    async downloadProtocolPhase() {
      try {
        // eslint-disable-next-line max-len
        window.open(this.protocolPhasePublicResource.download_link, '_blank');
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al intentar descargar el archivo'));
      }
    },
    protocolName() {
      // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
      return this.currentTreatment.treatmentProtocolPhases[0].protocolPhase.protocol.name;
    },
    protocolType() {
      // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
      return this.currentTreatment.treatmentProtocolPhases[0].protocolPhase.protocol.type;
    },
    phaseActive(): TreatmentProtocolPhase {
      // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
      return this.currentTreatment.treatmentProtocolPhases.find((ppt: TreatmentProtocolPhase) => !ppt.endDate);
    },
    isActive(menuItem: string) {
      return this.expedientSection === menuItem;
    },
    async changeExpedientSection(expedientSection: string, redirect = true) {
      await this.$store.dispatch('expedient/setExpedientSection', {
        expedientSection,
      });

      if (redirect) {
        await this.$router.push({
          name: 'patient.expedient.show',
          params: {
            expedient: this.currentExpedient.id,
            tabName: expedientSection,
          },
          query: {
            tratamiento: this.currentTreatment?.id,
          },
        });
      }
    },
    async checkIfRGPDAccepted() {
      // TODO: Redirect to RGPD acceptance page with its token
    },
  },
});
