import { createLogger } from 'vuex';
import actions from '@/store/modules/expedient/expedient.actions';
import mutations from '@/store/modules/expedient/expedient.mutations';
import type { ExpedientState } from '@/store/modules/expedient/expedient.types';
import room from '@/store/modules/expedient/modules/room';

export default {
  namespaced: true,
  state: (): ExpedientState => ({
    status: null,
    expedientSection: 'general',
    currentExpedient: null,
    forms: [],
    currentAnamnesis: null,
    currentNutritionalAnamnesis: null,
    measures: null,
    consultations: [],
    treatments: [],
    purchaseAuthorizations: [],
    media: [],
  }),
  actions,
  mutations,
  plugins: [createLogger()],
  modules: { room },
};
