
import { defineComponent } from 'vue';
import Cleave from 'cleave.js';

export default defineComponent({
  name: 'VInputMask',
  props: {
    type: {
      type: String,
      required: true,
      default: 'text',
    },
    size: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    mask: {
      type: Object,
      required: false,
      default: null,
    },
  },
  directives: {
    cleave: {
      mounted: (el: any, binding: any) => {
        el.cleave = new Cleave(el, binding.value || {});
      },
      beforeUpdate: (el) => {
        const event = new Event('input', { bubbles: true });

        setTimeout(() => {
          el.value = el.cleave.properties.result;

          el.dispatchEvent(event);
        }, 100);
      },
    },
  },
  methods: {
    updateValue(e) {
      this.$emit('update:value', e.currentTarget.value);
    },
  },
});
