import AbstractService from '@/api/services/AbstractService';
import Professional from '@/api/objects/Professional';

class ProfessionalService extends AbstractService {
  public async profile(): Promise<Professional> {
    return this.request('get', this.buildPath('profile/professional'));
  }

  public async indexByPatient(): Promise<Professional> {
    return this.request('get', this.buildPath('profile/professional'));
  }

  public async getProfessionalsList(): Promise<Professional[]> {
    return this.request('get', this.buildPath('professional/list'));
  }
}

export default new ProfessionalService();
