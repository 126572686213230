
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';

export default defineComponent({
  name: 'Dropdown',
  components: {
    VButton,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    defaultOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonClasses: {
      type: [String, Object],
      required: false,
      default: '',
    },
    dropdownClasses: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      default: 'expand-right',
    },
    iconSize: {
      type: String,
      default: 'xxs',
    },
    disableIcon: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    darkVariant: {
      type: Boolean,
      required: false,
      default: false,
    },
    flat: Boolean,
    active: Boolean,
    preventClose: Boolean,
  },
  computed: {
    classes() {
      return {
        'pe-32': !this.disableIcon,
      };
    },
    collapse() {
      return {
        'px-0 py-8': this.flat,
        show: this.defaultOpen,
      };
    },
    styles() {
      return {
        width: !!this.width ? `${this.width}px` : '',
        'min-width': !!this.width ? `${this.width}px` : '',
        height: !!this.height ? `${this.height}px` : '',
      };
    },
  },
});
