
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import vSelect from 'vue-select';
import api from '@/api';
import { mapState } from 'vuex';
import Protocol from '@/api/objects/Protocol';
import ProtocolPhase from '@/api/objects/ProtocolPhase';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheProfessionalTreatmentChangePhaseModal',
  components: {
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
    vSelect,
  },
  props: {
    phaseId: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const rules = yup.object({
      phaseIdAux: yup.string()
        .required(t('Debes elegir una fase para poder continuar'))
        .nullable()
        .label(t('Fase')),
    });

    const form = useFormValidation(rules, { phaseIdAux: props.phaseId });

    const { value: phaseIdAux } = useField<number>('phaseIdAux');

    return {
      ...form,
      phaseIdAux,
      ...useCapabilities(),
    };
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('protocol', ['protocols']),
    protocolPhases(): any {
      if (this.protocols) {
        const protocol = this.protocols.find((p: Protocol) => p.id === this.protocolId());

        return protocol?.phases;
      }

      return null;
    },
    protocolPhasesOptions(): any[] | null {
      if (this.protocolPhases) {
        const tempProtocolPhasesOptions: any[] = [];

        this.protocolPhases.forEach((phase: ProtocolPhase) => {
          tempProtocolPhasesOptions.push({
            key: Number(phase.id),
            label: phase.name,
            disabled: phase.id === this.phaseId,
          });
        });

        return tempProtocolPhasesOptions;
      }

      return null;
    },
  },
  methods: {
    protocolId() {
      // FIXME Remove [0] or change to currentTreatmentProtocolPhase if it's possible
      return this.currentTreatment.treatmentProtocolPhases[0].protocolPhase.protocol.id;
    },
    async changePhase() {
      try {
        this.toggleAccepted();

        const treatment = await api.professional.expedients.treatments.change(this.currentExpedient.id,
          this.currentTreatment.id, this.phaseIdAux);

        await this.$store.dispatch('treatment/setCurrentTreatment', {
          treatment,
        });

        await this.$store.dispatch('expedient/refreshExpedientTreatments', {
          expedientId: this.currentExpedient.id,
        });

        this.$toast.success(this.$t('Se ha cambiado de fase correctamente'));

        this.$emit('treatment-phase-changed');

        this.closeModal();

        this.toggleAccepted();
      } catch (e) {
        this.toggleAccepted();

        this.$toast.error(this.$t('Ha ocurrido un error al cambiar de fase'));
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
