import { createLogger } from 'vuex';
import actions from '@/store/modules/patient/patient.actions';
import mutations from '@/store/modules/patient/patient.mutations';
import type { PatientState } from '@/store/modules/patient/patient.types';
import expedient from '@/store/modules/patient/expedient';
import protocol from '@/store/modules/protocol';

export default {
  namespaced: true,
  state: (): PatientState => ({
    currentPatient: null,
  }),
  actions,
  mutations,
  plugins: [createLogger()],
  modules: { expedient, protocol },
};
