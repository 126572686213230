
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import * as yup from 'yup';
import useFormValidation from '@/resources/form';
import { useField } from 'vee-validate';
import VFormGroupInputText from '@/components/vendor/basic/form/VFormGroupInputText.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import vSelect from 'vue-select';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import VFormGroupTextarea from '@/components/vendor/basic/form/VFormGroupTextarea.vue';
import api from '@/api';
import Category from '@/api/objects/Category';
import TheAdminVideoCategoryCreate
  from '@/views/administrator/videos/category/TheAdminVideoCategoryCreate.vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'TheAdminVideoCreate',
  components: {
    TheAdminVideoCategoryCreate,
    VFormGroupTextarea,
    VButton,
    VFormGroupSelect,
    VFormGroupInputText,
    vSelect,
  },
  setup() {
    const { t } = useI18n();
    const rules = yup.object({
      name: yup.string()
        .required()
        .label(t('Nombre')),
      description: yup.string()
        .nullable()
        .label(t('Descripción')),
      categories: yup.array()
        .required()
        .min(1)
        .label(t('Categoría')),
      visibility: yup.string()
        .required()
        .label(t('Visibilidad')),
      url: yup.string()
        .required()
        .label(t('Url')),
      // .matches(/^"https://vimeo.com/"$/g, 'messageValidation');
      // TODO: Apply RegExp with https://vimeo.com/$id format
    });

    const initialValues: Record<string, any> = {
      categories: [],
    };

    const form = useFormValidation(rules, initialValues);

    const { value: name } = useField('name');
    const { value: description } = useField('description');
    const { value: categories } = useField<any[]>('categories');
    const { value: visibility } = useField('visibility');
    const { value: url } = useField('url');

    return {
      ...form,

      name,
      description,
      categories,
      visibility,
      url,

      ...useCapabilities(),
    };
  },
  data() {
    return {
      video: {},
      loadingCategories: false as boolean,
      categoryOptions: [] as Category[],
      modalToShow: null as null | string,
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  created() {
    this.loadCategories();
  },
  methods: {
    async loadCategories(newCategory = '') {
      try {
        this.loadingCategories = true;

        this.categoryOptions = await api.admin.videoCategory.all();

        if (!!newCategory) {
          this.categories.push(newCategory);
        }

        this.loadingCategories = false;
      } catch (e: any) {
        this.loadingCategories = false;

        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);
      }
    },
    async createVideo() {
      this.toggleAccepted();

      this.video = {
        name: this.name,
        description: this.description,
        categories: this.categories,
        visibility: this.visibility,
        url: this.url,
      };

      try {
        await api.admin.video.create(this.video);

        await this.$modal.info({
          title: this.$t('Vídeo creado'),
          text: this.$t('Se ha creado el vídeo {nombre} con éxito.', { nombre: this.name }),
        });

        this.resetForm();

        this.toggleAccepted();

        await this.$router.push({ name: 'admin.video.index' });
      } catch (e) {
        // TODO: Handle possible exceptions
        this.$toast.error(e.response?.data?.message);

        this.toggleAccepted();
      }
    },
  },
});
