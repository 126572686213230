import getRouteHome from '@/helpers/redirectToHome';

export default async function auth({
  next,
  store,
}: any) {
  if (store.state.user.isGuest) {
    try {
      await store.dispatch('user/setAuthUser');

      const { authUser } = store.state.user;

      if (authUser) {
        const routeName = getRouteHome(authUser.role.name);
        next({ name: routeName });
      } else {
        next();
      }
    } catch (e) {
      next();
    }
  } else {
    const routeName = getRouteHome(store.state.user.authUser.role.name);
    next({ name: routeName });
  }
}
