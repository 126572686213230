import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_loader = _resolveComponent("v-loader")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    ref: "modal",
    "backdrop-static": !!_ctx.modal.backdropStatic ? true : null,
    onClosed: _ctx.dispatchHideModal
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, {
        "disable-close": _ctx.modal.showCloseX === true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.modal.title), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["disable-close"]),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          (!!_ctx.modal.textLayout)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modal.textLayout), { key: 0 }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.modal.text), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(_toDisplayString(_ctx.modal.text), 1)
              ], 64))
        ]),
        _: 1
      }),
      (!!_ctx.modal.leftButtonText || !!_ctx.modal.rightButtonText)
        ? (_openBlock(), _createBlock(_component_modal_footer, { key: 0 }, {
            default: _withCtx(() => [
              (!!_ctx.modal.leftButtonText)
                ? (_openBlock(), _createBlock(_component_v_button, {
                    key: 0,
                    class: _normalizeClass(["btn-sm me-24", _ctx.modal.leftButtonClasses]),
                    disabled: _ctx.modal.leftButtonDisabled === true ? true : null,
                    onClick: _ctx.leftButtonClicked
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "close-button", {}, () => [
                        (_ctx.leftButtonIsLoading)
                          ? (_openBlock(), _createBlock(_component_v_loader, { key: 0 }))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.modal.leftButtonText), 1)
                            ], 64))
                      ], true)
                    ]),
                    _: 3
                  }, 8, ["class", "disabled", "onClick"]))
                : _createCommentVNode("", true),
              (!!_ctx.modal.rightButtonText)
                ? (_openBlock(), _createBlock(_component_v_button, {
                    key: 1,
                    class: _normalizeClass([_ctx.modal.rightButtonClasses, "btn-sm"]),
                    disabled: _ctx.modal.rightButtonDisabled === true ? true : null,
                    onClick: _ctx.rightButtonClicked
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "accept-button", {}, () => [
                        (_ctx.rightButtonIsLoading)
                          ? (_openBlock(), _createBlock(_component_v_loader, { key: 0 }))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                              _createTextVNode(_toDisplayString(_ctx.modal.rightButtonText), 1)
                            ], 64))
                      ], true)
                    ]),
                    _: 3
                  }, 8, ["class", "disabled", "onClick"]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["backdrop-static", "onClosed"]))
}