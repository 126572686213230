import AbstractService from '@/api/services/AbstractService';

class AdminImpersonateService extends AbstractService {
  public async start(userId: any) {
    await this.request('post', this.buildPath('admin/impersonate/{user}/start', {
      user: userId,
    }));
  }

  public async stop() {
    await this.request('get', this.buildPath('admin/impersonate/stop'));
  }
}

export default new AdminImpersonateService();
