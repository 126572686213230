import { createLogger } from 'vuex';
import actions from '@/store/modules/general/general.actions';
import mutations from '@/store/modules/general/general.mutations';
import type { GeneralState } from '@/store/modules/general/general.types';
import i18n from '@/lang';

const $t = i18n.global.t;

export default {
  namespaced: true,
  state: (): GeneralState => ({
    modal: {
      show: false,
      title: $t('Título'),
      text: $t('Text'),
      textLayout: null,
      showCloseX: true,
      backdrop: false,
      rightButtonText: $t('Confirmar'),
      rightButtonClasses: 'btn-primary',
      rightButtonLoadingText: $t('Procesando...'),
      rightButtonDisabled: false,
      rightButtonCallback: null,
      leftButtonText: $t('Cerrar'),
      leftButtonClasses: 'btn-secondary',
      leftButtonLoadingText: $t('Procesando...'),
      leftButtonCallback: null,
      leftButtonDisabled: false,
    },
  }),
  actions,
  mutations,
  plugins: [createLogger()],
};
