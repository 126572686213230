import BaseObject from '@/api/objects/BaseObject';

export interface FormValueContract {
  formGroupId: number;
  type: string;
  value: string;
  createdAt: string;
  updatedAt: string;
}

export default class FormValue extends BaseObject implements FormValueContract {
  get formGroupId(): number {
    return this.attributes.form_group_id;
  }

  get type(): string {
    return this.attributes.type;
  }

  get value(): string {
    return this.attributes.value;
  }

  set value(value: string) {
    this.attributes.value = value;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }
}
