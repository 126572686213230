import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "text-lowercase" }
const _hoisted_2 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_form_group_input_datetime = _resolveComponent("v-form-group-input-datetime")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form_group_select = _resolveComponent("v-form-group-select")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    "class-name": "modal-fullscreen-lg-down",
    onKeypress: _cache[5] || (_cache[5] = _withKeys(_withModifiers(($event: any) => (_ctx.meta.valid ? _ctx.createConsultation() : null), ["prevent"]), ["enter"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Nueva')) + " ", 1),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('consulta')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            false
              ? (_openBlock(), _createBlock(_component_v_form_group_input_datetime, {
                  key: 0,
                  value: _ctx.initDate,
                  label: _ctx.$t('Fecha de inicio*'),
                  class: "mb-24",
                  "yup-errors-variable": _ctx.errors.initDate,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.initDate = $event))
                }, null, 8, ["value", "label", "yup-errors-variable"]))
              : _createCommentVNode("", true),
            _createVNode(_component_v_form_group_select, {
              label: _ctx.$t('Tipo de consulta'),
              class: "mb-24",
              "yup-errors-variable": _ctx.errors.type
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  class: _normalizeClass({'is-invalid': !!_ctx.errors.type}),
                  options: _ctx.formsSettings.consultation_type_options,
                  reduce: returnValue => returnValue.key,
                  "select-on-key-codes": [188, 13],
                  clearable: false,
                  modelValue: _ctx.type,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event))
                }, {
                  "no-options": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                  ]),
                  _: 1
                }, 8, ["class", "options", "reduce", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "yup-errors-variable"]),
            _createVNode(_component_v_form_group_select, {
              label: _ctx.$t('Medio consulta*'),
              class: "mb-24",
              "yup-errors-variable": _ctx.errors.via
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  class: _normalizeClass({'is-invalid': !!_ctx.errors.via}),
                  options: _ctx.formsSettings.consultation_via_options,
                  reduce: returnValue => returnValue.key,
                  "select-on-key-codes": [188, 13],
                  clearable: false,
                  modelValue: _ctx.via,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.via) = $event))
                }, {
                  "no-options": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                  ]),
                  _: 1
                }, 8, ["class", "options", "reduce", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "yup-errors-variable"]),
            _createVNode(_component_v_form_group_select, {
              label: _ctx.$t('Consulta física'),
              class: "mb-24",
              "yup-errors-variable": _ctx.errors.clinicId
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  class: _normalizeClass({'is-invalid': !!_ctx.errors.clinicId}),
                  options: _ctx.clinicOptions,
                  reduce: returnValue => returnValue.key,
                  "select-on-key-codes": [188, 13],
                  clearable: true,
                  modelValue: _ctx.clinicId,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.clinicId) = $event))
                }, {
                  "no-options": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                  ]),
                  _: 1
                }, 8, ["class", "options", "reduce", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "yup-errors-variable"]),
            _createVNode(_component_v_form_group_select, {
              label: _ctx.$t('Tratamiento'),
              class: "mb-24",
              "yup-errors-variable": _ctx.errors.treatmentId
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  class: _normalizeClass({'is-invalid': !!_ctx.errors.treatmentId}),
                  options: _ctx.treatmentOptions,
                  placeholder: "- Sin tratamiento asociado -",
                  reduce: returnValue => returnValue.key,
                  "select-on-key-codes": [188, 13],
                  modelValue: _ctx.treatmentId,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.treatmentId) = $event))
                }, {
                  "no-options": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                  ]),
                  _: 1
                }, 8, ["class", "options", "reduce", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "yup-errors-variable"])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "link-secondary",
            class: "me-24",
            "aria-label": _ctx.$t('Cancelar'),
            "data-bs-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cancelar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"]),
          _createVNode(_component_v_button, {
            variant: "secondary",
            "aria-label": _ctx.$t('Añadir'),
            disabled: !_ctx.meta.valid || _ctx.accepted,
            "is-loading": _ctx.accepted,
            onClick: _ctx.createConsultation
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Añadir')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "disabled", "is-loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}