
import { defineComponent, ref } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import withTimeout from '@/resources/timeOut';
// eslint-disable-next-line import/no-cycle
import { setQueryParameters } from '@/helpers/Url';
import TreeTable from 'primevue/treetable';
import Column from 'primevue/column';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ResourceCategory from '@/api/objects/ResourceCategory';
import TheAdminResourceCategoryCreate from './TheAdminResourceCategoryCreate.vue';
import TheAdminResourceCategoryDestroy from './TheAdminResourceCategoryDestroy.vue';

export default defineComponent({
  name: 'TheAdminResourceCategoryShow',
  components: {
    TheAdminResourceCategoryCreate,
    TheAdminResourceCategoryDestroy,
    VButton,
    TreeTable,
    Column,
  },
  data() {
    return {
      // eslint-disable-next-line no-return-assign
      nodes: {},
      categories: [] as ResourceCategory[],
      selectedCategory: null as null | ResourceCategory,
      modalToShow: null as null | string,
      filters: {
        search: [] as any,
      },
      do_search: true,
      timers: {
        search: '',
      },
    };
  },
  setup() {
    const selectionKeys = ref([]);
    const onSelectionChange = (event) => {
      selectionKeys.value = event.value;
    };
    return {
      onSelectionChange,
      ...useCapabilities(),
    };
  },
  async mounted() {
    await this.setQueryParametersAsFilters();
    await this.doSearch();
  },
  methods: {
    transformJson(input) {
      const output: any = [];

      // Create a map to store references to parent items by key
      const parentMap = {};

      for (let i = 0; i < input.length; i += 1) {
        const item = input[i];
        /* eslint-disable camelcase */
        const { key, label, label_select } = item.attributes;
        const outputItem = {
          data: {
            id: item.id, key, label, label_select,
          },
          children: [],
        };

        // Check if this item has a parent
        const parentKeySegments = key.split('.').slice(0, -1);
        const parentKey = parentKeySegments.join('.');

        if (parentKey in parentMap) {
          parentMap[parentKey].children.push(outputItem);
        } else {
          output.push(outputItem);
        }

        // Update the parentMap with this item as the current parent
        parentMap[key] = outputItem;
      }

      return output;
    },

    openCategoryModal(category, modalName) {
      this.selectedCategory = category.node.data;
      this.modalToShow = modalName;
    },
    search() {
      this.timers.search = withTimeout(async () => {
        await this.doSearch();
      }, this.timers.search, 250);
    },
    /**
     * Sets the local filters as url query parameters.
     */
    setFiltersAsQueryParameters() {
      setQueryParameters('admin.resource-category.index', this.filters);
    },
    /**
     * Detects any query parameter in the url and loads it in the filters (if available)
     *
     * @returns {Promise<void>}
     */
    async setQueryParametersAsFilters() {
      const queryParameters = this.$route.query;

      if (queryParameters.search) {
        this.filters.search = queryParameters.search;
      } else {
        this.filters.search = '';
      }
    },
    async loadCategories() {
      try {
        this.categories = await api.admin.resourceCategory.all();

        this.nodes = this.transformJson(this.categories);
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las categorías'));
      }
    },
    async doSearch() {
      this.do_search = false;
      await this.setFiltersAsQueryParameters();
      await this.loadCategories();
    },
    async handlePageChangedEvent(page) {
      // We handle page only on the method call to avoid malfunctioning with page filter
      // await this.loadCategories(page);
    },
    emptyFilters() {
      this.filters = {
        search: '',
      };

      this.doSearch();
    },
  },
});
