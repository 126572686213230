<template>
  <media-library-renderless
    ref="mediaLibraryRenderless"
    :initial-value="initialValue"
    :validation-errors="validationErrors"
    :route-prefix="routePrefix"
    :validation-rules="validationRules"
    :translations="translations"
    :before-upload="beforeUpload"
    :after-upload="afterUpload"
    :name="name"
    :max-items="computedMaxItems"
    :max-size-for-preview-in-bytes="maxSizeForPreviewInBytes"
    :vapor="vapor"
    :vapor-signed-storage-url="vaporSignedStorageUrl"
    :upload-domain="uploadDomain"
    :with-credentials="withCredentials"
    @changed="$emit('change', $event)"
    @is-ready-to-submit-change="$emit('is-ready-to-submit-change', $event)"
    @has-uploads-in-progress-change="$emit('has-uploads-in-progress-change', $event)"
    v-slot="{
            state,
            getImgProps,
            getCustomPropertyInputProps,
            getCustomPropertyInputListeners,
            getCustomPropertyInputErrors,
            getNameInputProps,
            getNameInputListeners,
            getNameInputErrors,
            getDropZoneProps,
            getDropZoneListeners,
            getFileInputProps,
            getFileInputListeners,
            replaceMedia,
            getErrors,
            clearObjectErrors,
            clearInvalidMedia,
        }">
    <icons/>

    <div :class="`media-library media-library-multiple
      ${state.media.length == 0 ? 'media-library-empty' : 'media-library-filled'}
      ${sortable && 'media-library-sortable'}`">
      <list-errors
        :invalid-media="state.invalidMedia"
        :top-level-errors="validationErrors[name]"
        @cleared="clearInvalidMedia()"
      />

      <div v-show="!computedMaxItems || state.media.length < computedMaxItems"
           class="media-library-uploader">
        <uploader
          :multiple="multiple"
          v-bind="{ ...getDropZoneProps(), ...getFileInputProps() }"
          v-on="{ ...getDropZoneListeners(), ...getFileInputListeners() }"
          add
          :file-type-help-text="fileTypeHelpText"
        />
      </div>

      <component
        :is="sortable ? 'draggable' : 'div'"
        :list="state.media"
        v-show="state.media && state.media.length"
        class="media-library-items"
        handle=".dragula-handle"
        @update="handleSortEnd">
        <div
          v-for="(object, index) in state.media"
          :key="index"
          class="media-library-item media-library-item-row"
          :data-media-library-uuid="object.attributes.uuid">
          <div v-if="sortable" class="dragula-handle media-library-row-drag">
            <icon v-if="state.media.length" icon="drag"/>
          </div>

          <v-thumb :uploadInfo="object.upload"
                   :validation-rules="validationRules"
                   :img-props="getImgProps(object)"
                   @replaced="replaceMedia(object, $event)"/>

          <item-errors v-if="getErrors(object).length"
                       :object-errors="getErrors(object)"
                       @back="clearObjectErrors(object)"/>

          <template v-else>
            <slot name="properties" :object="object">
              <div class="media-library-properties media-library-properties-fixed">
                <div v-if="object.attributes.name" class="media-library-property">
                  {{ object.attributes.name }}
                </div>

                <div v-if="object.attributes.extension" class="media-library-property">
                  {{ object.attributes.extension.toUpperCase() }}
                </div>

                <div v-if="object.attributes.size" class="media-library-property">
                  {{ getHummanSize(object.attributes.size) }}
                </div>
              </div>
            </slot>

            <slot
              name="fields"
              :object="object"
              :getCustomPropertyInputProps="
                                (propertyName) => getCustomPropertyInputProps(object, propertyName)
                            "
              :getCustomPropertyInputListeners="
                                (propertyName) => getCustomPropertyInputListeners(object, propertyName)
                            "
              :getCustomPropertyInputErrors="
                                (propertyName) => getCustomPropertyInputErrors(object, propertyName)
                            "
              :getNameInputProps="() => getNameInputProps(object)"
              :getNameInputListeners="() => getNameInputListeners(object)"
              :getNameInputErrors="() => getNameInputErrors(object)"
            >
              <div class="media-library-properties">
                <div class="media-library-field">
                  <label class="media-library-label">
                    {{ window.mediaLibraryTranslations.name }}
                  </label>
                  <input
                    class="media-library-input"
                    v-bind="getNameInputProps(object)"
                    v-on="getNameInputListeners(object)"
                    dusk="media-library-field-name"
                  />

                  <p v-for="(error, index) in getNameInputErrors(object)"
                     :key="index"
                     class="media-library-field-error">
                    {{ error }}
                  </p>
                </div>
              </div>
            </slot>
          </template>

          <div class="media-library-row-remove" @click.stop="remove(object)" dusk="remove">
            <icon icon="remove"/>
          </div>
        </div>
      </component>

      <hidden-fields :name="name" :media-state="state.media"/>
    </div>
  </media-library-renderless>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import {
  DropZone,
  HiddenFields,
  Icon,
  Icons,
  ItemErrors,
  ListErrors,
  MediaLibraryRenderless,
  Uploader,
} from '@spatie/media-library-pro-vue3';

import { defineComponent } from 'vue';
import { getHummanSize } from '@/helpers/General';
import VThumb from '@/components/upload/VThumb.vue';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';

window.mediaLibraryTranslations = {
  dropFile: 'Soltar aquí',
  dragHere: 'Arrastre aquí',
  selectOrDrag: 'Selecciona o arrastre uno o varios archivos ',
};

export default defineComponent({
  props: {
    name: {
      required: false,
      type: String,
    },
    initialValue: {
      required: false,
      type: [Array, Object],
    },
    validationErrors: {
      default: () => ({}),
      type: [Object, Array],
    },
    routePrefix: {
      required: false,
      type: String,
    },
    translations: {
      type: Object,
    },
    validationRules: {
      required: false,
      type: Object,
    },
    multiple: {
      default: true,
      type: Boolean,
    },
    sortable: {
      default: true,
      type: Boolean,
    },
    maxItems: {
      required: false,
      type: Number,
    },
    maxSizeForPreviewInBytes: {
      required: false,
      type: Number,
    },
    vapor: {
      required: false,
      type: Boolean,
    },
    vaporSignedStorageUrl: {
      required: false,
      type: String,
    },
    uploadDomain: {
      required: false,
      type: String,
    },
    withCredentials: {
      required: false,
      type: Boolean,
    },
    fileTypeHelpText: {
      required: false,
      type: String,
    },
    beforeUpload: {
      type: Function,
    },
    afterUpload: {
      type: Function,
    },
  },
  emits: [
    'change',
    'is-ready-to-submit-change',
    'has-uploads-in-progress-change',
    'isReadyToSubmitChange',
    'hasUploadsInProgressChange',
  ],
  components: {
    VFormGroupSelect,
    VThumb,
    draggable: VueDraggableNext,
    MediaLibraryRenderless,
    HiddenFields,
    DropZone,
    ListErrors,
    ItemErrors,
    Icons,
    Icon,
    Uploader,
  },
  data() {
    return {
      mediaLibrary: null,
      window,
    };
  },
  computed: {
    computedMaxItems() {
      return this.multiple ? this.maxItems : 1;
    },
  },
  mounted() {
    this.mediaLibrary = this.$refs.mediaLibraryRenderless.mediaLibrary;
    const help = document.querySelector('.media-library-help');

    help.innerHTML = '<span class="media-library-help--text gray">'
      + `${window.mediaLibraryTranslations.selectOrDrag}</span>`
      + `<span class="media-library-help--file-types">${this.fileTypeHelpText}</span>`;
  },
  methods: {
    getHummanSize,
    remove(object) {
      this.mediaLibrary.removeMedia(object.attributes.uuid);

      this.$emit('removed', null);
    },
    handleSortEnd(event) {
      this.$refs.mediaLibraryRenderless.setOrder(
        Array.from(event.from.children || [])
          .map((element) => element.getAttribute('data-media-library-uuid')),
        false,
      );
    },
  },
});
</script>

<style lang="scss">
.media-library {
  grid-template-areas: "uploader" "errors" "items" !important;
}

.media-library-add {
  .media-library-dropzone {
    flex-direction: column;
    justify-content: center;
    padding: 4.5rem 2.5rem;
    min-height: 22rem;

    .media-library-placeholder {
      display: none;
    }

    .media-library-help {
      padding: 1.25rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      .media-library-help--text {
        font-size: 1.875rem;
      }

      .media-library-help--file-types {
        font-size: 1.25rem;
      }
    }
  }
}

.media-library-progress-wrap-loading {
  overflow: hidden;
  height: .625rem;
  padding: {
    left: 0 !important;
    right: 0 !important;
  };

  .media-library-progress {
    width: 100%;
    height: .625rem;
    border-radius: 0;
    max-width: 100%;
  }
}

.media-library-properties-fixed {
  width: 100%;
}
</style>
