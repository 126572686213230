
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VTable',
  props: {
    tableClass: {
      type: String,
      required: false,
      default: '',
    },
  },
});
