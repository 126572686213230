
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { POSITION } from 'vue-toastification';
import ThePatientPurchaseAuthorizationShow
  from '@/views/patient/expedients/purchaseAuthorization/parts/ThePatientPurchaseAuthorizationShow.vue';
import api from '@/api';

export default defineComponent({
  name: 'ThePatientPurchaseAuthorizationList',
  components: {
    ThePatientPurchaseAuthorizationShow,
    VButton,
    VTableBody,
    VTableHeader,
    VTable,
  },
  data() {
    return {
      modalToShow: null as any,
    };
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  beforeMount() {
    this.loadPurchaseAuthorizations();
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/purchaseAuthorization', ['purchaseAuthorizations']),
    ...mapState('patient/expedient/treatment', ['currentTreatment']),
    ...mapState('app', ['formsSettings']),
  },
  methods: {
    async showPurchaseAuthorizationShowModal(purchaseAuthorization) {
      // Force to load the purchase authorization due to a problem with pivot models
      // eslint-disable-next-line max-len
      purchaseAuthorization = await api.patient.expedient.purchaseAuthorizations.get(this.currentExpedient.id, purchaseAuthorization.id);

      await this.setCurrentPurchase(purchaseAuthorization);
      this.modalToShow = 'the-patient-purchase-authorization-show';
    },
    purchase(purchaseId) {
      return this.purchaseAuthorizations.find((pa) => pa.id === purchaseId);
    },
    async loadPurchaseAuthorizations() {
      try {
        await this.$store.dispatch('patient/expedient/purchaseAuthorization/refreshPurchaseAuthorizations', {
          expedientId: this.currentExpedient.id,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las autorizaciones de compra'), {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    },
    async setCurrentPurchase(purchaseAuthorization) {
      await this.$store.dispatch('patient/expedient/purchaseAuthorization/getCurrentPurchaseAuthorization', {
        purchaseAuthorization,
      });
    },
    status(purchaseAuthorization) {
      // eslint-disable-next-line max-len
      const type = this.formsSettings.purchase_authorization_statuses.find((o) => o.key === purchaseAuthorization?.status);

      return type.label;
    },
  },
});
