export default {
  // src\components\structural\TheImpersonateStop.vue:
  'Regresar a panel de administrador': 'Retornar ao painel do administrador',

  // src\components\structural\TheLogout.vue:
  'Cerrar sesión': 'Sair',

  // src\components\structural\TheMenuAdministrator.vue:
  Inicio: 'Início',
  'Logo ProAge': 'Logo ProAge',
  Clínicas: 'Clínicas',
  Profesionales: 'Profissionais',
  Categorías: 'Categorias',
  'Categorías vídeos': 'Categorias de vídeo',
  'Categorías recursos': 'Categorias de recursos',
  Pacientes: 'Pacientes',
  Recursos: 'Recursos',
  Videoteca: 'Videoteca',
  Agenda: 'Agenda',
  Menú: 'Menu',
  Media: 'Media',
  'Aut. de compra': 'Aut. de compra',
  // eslint-disable-next-line max-len
  'Has iniciado sesión como el usuario { user } ({ email }).': 'Tem sessão iniciada como utilizador { user } ({ email }).',

  // src\components\structural\TheMenuPatient.vue:
  'Toggle navigation': 'Alternar navegação',
  'Funcionalidad no disponible': 'Funcionalidade não disponível',
  // src\components\structural\TheMenuProfessional.vue:
  Interconsultas: 'Interquestões',
  'Mi perfil': 'Meu perfil',

  // src\components\structural\VUnderConstruction.vue:
  'En construcción': 'Em Construção',
  // src\components\vendor\VLoader.vue:
  'Cargando...': 'Carregando...',
  // src\components\vendor\VPagination.vue:
  'Navigation example': 'Exemplo de navegação',

  // src\components\vendor\VProfessionalConsultations.vue:
  Consulta: 'Consulta',
  '- Sin clínica asociada -': '- Nenhuma clínica associada -',
  'Ver consulta': 'Ver consulta',
  'No tienes ninguna consulta': 'Não tem nenhuma consulta',
  'No hay consultas': 'Sem consultas',
  'Nueva consulta': 'Nova Consulta',
  'Ver paciente': 'Visualizar paciente',
  // src\components\vendor\modal\ModalHeader.vue:
  Cerrar: 'Fechar',

  // src\resources\modal.ts:
  Información: 'Informação',
  'Modal de información': 'Modal de informação',
  Eliminar: 'Excluir',
  'Eliminando...': 'Excluindo...',
  Cancelar: 'Cancelar',
  Confirmar: 'Confirmar',
  'Confirmando...': 'Confirmando...',

  // src\store\modules\general\index.ts:
  Título: 'Título',
  Text: 'Text',
  'Procesando...': 'Processando...',
  ID: 'ID',

  // src\views\ThePageNotFound.vue:
  'Ups!': 'Opa!',
  'Te has perdido': 'Você perdeu',
  // eslint-disable-next-line max-len
  'No hemos podido encontrar la página que estás buscando.': 'Não foi possível encontrar a página que você está procurando.',
  // eslint-disable-next-line max-len
  'Si quieres puedes ir a la página de inicio haciendo click en el siguiente botón:': 'Se você quiser, pode ir para a página inicial clicando no seguinte botão:',

  // src\views\administrator\TheAdminHome.vue:
  'Hola,': 'Olá,',
  'Crear profesional': 'Criar profissional',
  'Crear categoría': 'Criar categoria',
  'Añadir nuevo profesional': 'Adicionar novo profissional',
  'Añadir nueva categoría': 'Adicionar nova categoria',
  'Ver profesionales': 'Ver profissionais',
  'Ver categorías': 'Ver categorias',
  'Ver pacientes': 'Visualizar pacientes',
  'Ver clínicas': 'Ver clínicas',
  'Ver aut. de compra': 'Ver aut. de compra',

  // src\views\administrator\calendar\parts\TheAdminCalendarEventCreate.vue:
  'evento de calendario': 'evento de calendário',
  Asunto: 'Assunto',
  Tipo: 'Tipo',
  'No se han encontrado resultados': 'Nenhum resultado encontrado',
  Paciente: 'Paciente',
  'Obligatorio: Se le asignará la cita a este paciente': 'Obrigatório: A consulta será atribuída a este paciente',
  Clínica: 'Clínica',
  Profesional: 'Profissional',
  Categoria: 'Categoria',
  // eslint-disable-next-line max-len
  'Obligatorio: Se le asignará la cita a este profesional': 'Obrigatório: A consulta será atribuída a este profissional',
  Fecha: 'Data',
  Fin: 'Fim',
  'Durante todo el día': 'Todo o dia',
  Descripción: 'Descrição',
  // eslint-disable-next-line max-len
  'Al crear esta {tipo_consulta} se mandará un SMS al paciente con la hora indicada.': 'Ao criar esta {tipo_consulta} será enviado um SMS ao paciente com a hora indicada.',
  Añadir: 'Adicionar',
  'Debes seleccionar una clínica donde pasar consulta': 'Você deve selecionar uma clínica onde pode consultar',
  'Primera consulta': 'Primeira Consulta',
  Revisión: 'Revisão',
  Videoconsulta: 'Videoconsulta',
  'Crear {tipo_consulta}': 'Crear {tipo_consulta}',
  // eslint-disable-next-line max-len
  'Vas a crear una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.': 'Você vai marcar uma consulta de {tipo_consulta} entre um profissional e um paciente. Ambos poderão visualizá-lo em sua agenda. Além disso, caso o profissional não tenha acesso anteriormente a esse paciente, será criada uma nova ficha na qual o paciente deverá autorizar o uso de seus dados para que o profissional possa tratá-lo.',
  'Se ha creado el evento de calendario con éxito.': 'Evento de calendário criado com sucesso.',
  'Ha ocurrido un error al crear el evento de calendario.': 'Ocorreu um erro ao criar o evento de calendário.',

  // src\views\administrator\calendar\parts\TheAdminCalendarEventUpdate.vue:
  'Editando evento': 'Editando evento',
  'Introduce una descripción aquí...': 'Digite uma descrição aqui...',
  'No hay clínica asociada': 'Não há clínica associada',

  // eslint-disable-next-line max-len
  'Al editar la hora de esta {tipo_consulta} se mandará un SMS al paciente.': 'Ao editar o horário desta {tipo_consulta} um SMS será enviado ao paciente.',
  'Guardar cambios': 'Salvar alterações',
  'Editar {tipo_consulta}': 'Editar {tipo_consulta}',
  // eslint-disable-next-line max-len
  'Vas a editar una cita de {tipo_consulta} entre un profesional y un paciente. Ambos podrán visualizarla en su agenda. Además, si el profesional no tenía acceso a ese paciente anteriormente, se creará un nuevo expediente en el que el paciente tendrá que autorizar el uso de sus datos para que el profesional pueda tratarlo.': 'Você vai editar um compromisso de {tipo_consulta} entre um profissional e um paciente. Ambos poderão visualizá-lo em sua agenda. Além disso, se o profissional não teve acesso anteriormente a esse paciente, será criado um novo arquivo no qual o paciente deverá autorizar o uso de seus dados para que o profissional possa tratá-lo.',
  'Se ha actualizado el evento de calendario con éxito.': 'Evento de calendário atualizado com sucesso.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al modificar el evento de calendario.': 'Ocorreu um erro ao modificar o evento do calendário.',
  // eslint-disable-next-line max-len
  'Vas a eliminar la {tipo_consulta} de nombre {titulo}. ¿Estás seguro?': 'Você vai eliminar o {tipo_consulta} do nome {titulo}. Tem certeza?',
  // eslint-disable-next-line max-len
  'Vas a eliminar la {tipo_consulta} de nombre {titulo} entre el profesional {profesional_nombre} {profesional_apellido} con el paciente {paciente_nombre} {paciente_apellido}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?': 'Você vai eliminar a {tipo_consulta} de nome {titulo} entre o profissional {profesional_nombre} {profesional_apellido} com o paciente {paciente_nombre} {paciente_apellido}. Iremos enviar-lhe um SMS a indicar o cancelamento. Tem certeza?',
  'Eliminar evento': 'Excluir evento',
  'Se ha eliminado el evento de calendario con éxito.': 'Evento de calendário excluído com sucesso.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al eliminar el evento de calendario.': 'Ocorreu um erro ao excluir o evento do calendário.',

  // src\views\administrator\clinic\TheAdminClinicCreateModal.vue:
  'nueva clínica': 'nova clínica',
  Nombre: 'Nome',
  'Prefijo tel.': 'Prefixo do telefone',
  'Dirección personal': 'Direçao pessoal',
  'Dirección profesional': 'Direçao profissional',
  Teléfono: 'Telefone',
  Dirección: 'Direção',
  'Dar de alta': 'Registrar',
  Apellidos: 'Sobrenome',
  'Prefijo telefónico': 'Prefixo do telefone',
  'Número de teléfono': 'Número de telefone',
  'Permitir acceso a la aplicación': 'Permitir acesso ao aplicativo',

  // src\views\administrator\clinic\TheAdminClinicEdit.vue:
  'Debe tener entre 6 y 14 cifras': 'Deve ter entre 6 e 14 algarismos',
  'Clínica {nombre_clinica} criada com sucesso.': 'Clínica {nombre_clinica} creada con éxito.',
  Actualizar: 'Atualizar',
  'Clínica actualizada con éxito.': 'Clínica atualizada com sucesso.',

  // src\views\administrator\clinic\TheAdminClinicList.vue:
  'Listado de': 'Lista de',
  clínicas: 'clínicas',
  'Nueva clínica': 'Nova clínica',
  'Buscar clínica': 'Pesquisar clínica',
  'Limpiar filtros': 'Limpar filtros',
  Acciones: 'Ações',
  'No hay clínicas': 'Não há clínicas',
  Ver: 'Olhe',
  Editar: 'Editar',
  'Ha ocurrido un error al cargar las clínicas': 'Ocorreu um erro ao carregar as clínicas',
  'Asociar profesional a la clínica': 'Profissional associado à clínica',

  // src\views\administrator\clinic\TheAdminClinicShow.vue:
  Asociar: 'Associado',
  'Profesionales asociados a la clínica': 'Profissionais associados à clínica',
  Desasociar: 'Separar',
  'No hay profesionales asociados a esta clínica': 'Não há profissionais associados a esta clínica',
  'Asociar profesional y clínica': 'Profissional associado e clínica',
  // eslint-disable-next-line max-len
  'Vas a asociar el profesional con esa clínica. Esto significa que podrá utilizarla como clínica para sus consultas. ¿Estás seguro?': 'Você vai associar o profissional àquela clínica. Isso significa que você pode usá-lo como uma clínica para suas consultas. Tem certeza?',
  'Desasociar profesional y clínica': 'Desassociar profissional e clínica',
  // eslint-disable-next-line max-len
  'Vas a desasociar el profesional con esa clínica. Esto significa que no podrá utilizarla como clínica para sus consultas. ¿Estás seguro?': 'Você vai desassociar o profissional daquela clínica. Isso significa que você não poderá usá-lo como clínica para suas consultas. Tem certeza?',
  // eslint-disable-next-line max-len
  'Profesional asociado/desasociado con éxito de la clínica.': 'Profissional associado/desassociado da clínica com sucesso.',
  // eslint-disable-next-line max-len
  'El profesional tiene alguna de sus consultas asociadas a la clínica, no podemos desasociarlo.': 'O profissional tem uma das suas consultas associada à clínica, não podemos dissociá-la.',

  // src\views\administrator\patient\TheAdminPatientExpedientCreate.vue:
  'nuevo paciente': 'novo paciente',
  'Tipo de documento': 'Tipo de documento',
  'Nº documento de identidad': 'Número do documento de identidade',
  'Fecha de nacimiento': 'Data de nascimento',
  Género: 'Gênero',
  email: 'e-mail',
  'Correo electrónico': 'E-mail',
  'Otros teléfonos': 'Outros telefones',
  // eslint-disable-next-line max-len
  'Se creará un expediente entre el profesional elegido y el paciente nuevo que vas a crear, por lo que el profesional tendrá acceso a la información del paciente y podrá comenzar a tratarlo.': 'Será criado um arquivo entre o profissional escolhido e o novo paciente que você vai criar, assim o profissional terá acesso as informações do paciente e poderá começar a tratá-lo.',
  'Tipo de Documento': 'Tipo de Documento',
  'Número de documento': 'Número do documento',
  'El {tipo_documento} registrado no es Válido': 'O registrado {tipo_documento} não é válido',
  'La fecha de nacimiento no puede ser futura': 'A data de nascimento não pode estar no futuro',
  'Crear expediente': 'Criar arquivo',
  // eslint-disable-next-line max-len
  'Vas a crear un nuevo paciente y adjudicárselo al profesional indicado. De esta forma, se creará ese paciente y el profesional podrá comenzar a tratarlo. Además, enviaremos un correo electrónico al paciente para que restablezca su contraseña y tenga acceso a la plataforma. ¿Estás seguro?': 'Você vai criar um novo paciente e atribuí-lo ao profissional indicado. Assim, aquele paciente estará criado e o profissional poderá começar a tratá-lo. Além disso, enviaremos um e-mail ao paciente para redefinir sua senha e ter acesso à plataforma. Tem certeza?',
  Crear: 'Criar',
  'El paciente se ha creado correctamente': 'O paciente foi criado com sucesso',
  // eslint-disable-next-line max-len
  'El número de teléfono ya existe en nuestra base de datos, prueba con otro': 'O número de telefone já existe em nosso banco de dados, tente outro',
  // eslint-disable-next-line max-len
  'Ese profesional ya tiene registrado a un paciente con ese número de identificación.': 'Aquele profissional já tem um paciente cadastrado com esse número de identificação.',
  'Ha ocurrido un error al añadir el nuevo paciente': 'Ocorreu um erro ao adicionar o novo paciente',

  // src\views\administrator\patient\TheAdminPatientShow.vue:
  'Datos básicos': 'Dados básicos',
  'Tipo doc. identificativo': 'Digite doc. identificação',
  'Nº doc. identificativo': 'Doc No. identificação',
  Personificación: 'Personificação',
  // eslint-disable-next-line max-len
  'Puedes iniciar sesión como si fueses el paciente en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc.Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.': 'Você pode fazer o login como se fosse o paciente em questão para ver o que esse profissional vê e assim poder verificar os erros que ele reporta, validar funcionalidades, etc. Você entrará na plataforma como se tivesse feito o login com as credenciais dele. Fique atento porque as alterações que você fizer também serão definitivas como se fossem deles.',
  'Iniciar sesión como el paciente': 'Login como paciente',
  Contacto: 'Contacto',
  'Profesionales relacionados': 'Profissionais relacionados',
  // eslint-disable-next-line max-len
  'Este es el listado de profesionales que han atendido o están atendiendo a este paciente.': 'Esta é a lista de profissionais que trataram ou estão tratando este paciente.',
  'Este paciente no tiene expedientes': 'Este paciente não possui registros',
  Especialidad: 'Especialidade',
  'Estado RGPD': 'Status GDPR',
  Confirmada: 'Confirmado',
  Pendiente: 'Pendente',
  'Iniciar sesión como': 'Entrar como',
  // eslint-disable-next-line max-len
  'Vas a iniciar sesión como el paciente {nombre_paciente} {apellido_paciente}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?': 'Você vai fazer login como paciente {nombre_paciente} {apellido_paciente}. Você poderá ver e fazer tudo o que ele faz e voltar ao seu perfil de administrador quando quiser. Deseja continuar?',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al intentar personificar al usuario': 'Ocorreu um erro ao tentar representar o usuário',

  // src\views\administrator\patient\TheAdminPatientsList.vue:
  'Error personificando': 'Erro de personificaçao',
  pacientes: 'pacientes',
  'Nuevo paciente': 'Novo paciente',
  Masculino: 'Masculino',
  Femenino: 'Feminino',
  'Buscar paciente': 'Pesquisar paciente',
  Apellido: 'Sobrenome',
  'Doc. identificativo': 'Doc. identificação',
  'Fecha nacimiento': 'Data de nascimento',
  'No hay pacientes': 'Não há pacientes',
  'Ha ocurrido un error al cargar los pacientes': 'Ocorreu um erro ao carregar pacientes',

  // src\views\administrator\patient\TheAdminPatientUpdate.vue:
  'Tipo documento': 'Tipo de documento',
  'Nº documento identidad': 'Número do documento de identidade',
  'Los datos se han guardado correctamente': 'Os dados foram salvos com sucesso',

  // src\views\administrator\professional\TheAdminProfessionalDestroy.vue:
  'borrar profesional': 'O profissional selecionado será excluído. Você quer continuar?',

  // src\views\administrator\professional\TheAdminProfessionalCreate.vue:
  'nuevo profesional': 'novo profissional',
  'nueva categoría': 'nova categoria',
  Subespecialidad: 'Subespecialidade',
  Colegio: 'Faculdade',
  'Nº colegiado': 'Número Colegial',
  // eslint-disable-next-line max-len
  'Puedes permitir que este profesional cree autorizaciones de compra sin necesidad de responder anamnesis ni iniciar un tratamiento. Si este campo está marcado podrá autorizar libremente a sus pacientes.': 'Você pode permitir que esse profissional crie autorizações de compra sem precisar responder a uma anamnese ou iniciar o tratamento. Se este campo estiver marcado, ele poderá autorizar livremente seus pacientes.',
  'Permitir autorizar libremente': 'Permitir autorização livre',
  Médico: 'Doutor',
  Nutricionista: 'Nutricionista',
  'Puede autorizar libremente': 'Você pode autorizar livremente',
  'Profesional creado': 'Profissional criado',
  'Categoría creada': 'Categoria criada',
  // TODO: Review this :name and :surname
  // eslint-disable-next-line max-len
  'Se ha creado el profesional :name :surname con éxito, le hemos enviado un correo de bienvenida y otro para que establezca su contraseña. El profesional debe establecer su contraseña para poder acceder a la plataforma.': 'O :name :surname profissional foi criado com sucesso, enviamos um e-mail de boas-vindas e outro para estabelecer sua senha. O profissional deve estabelecer sua senha para poder acessar a plataforma.',

  // src\views\administrator\professional\TheAdminProfessionalsList.vue:
  profesionales: 'profissionais',
  categorías: 'categorias',
  'Nuevo profesional': 'Novo profissional',
  'Nueva categoría': 'Nova categoria',
  'Buscar profesional': 'Pesquisar profissional',
  'Buscar categoría': 'Pesquisar categoria',
  Correo: 'Correio',
  'No hay profesionales': 'Não há profissionais',
  'Ha ocurrido un error al cargar los profesionales': 'Ocorreu um erro ao carregar profissionais',
  'No hay categorías': 'Não há categorias',
  'Ha ocurrido un error al cargar los categorías': 'Ocorreu um erro ao carregar categorias',

  // src\views\administrator\professional\parts\TheAdminProfessionalShow.vue:
  'Visualización de': 'Exibição de',
  profesional: 'profissional',
  'Tipo documento identidad': 'Tipo de documento de identidade',
  'Tipo de profesional': 'Tipo de profissional',
  'Prefijo teléfono': 'Prefixo do telefone',
  'Profesional eliminado': 'Profissional eliminado',
  'Se ha eliminado el profesional con éxito.': 'Professional foi removido com sucesso.',
  Activo: 'Ativo',
  'Nº teléfono': 'Número de telefone',
  '¿Puede autorizar libremente?': 'Você pode autorizar livremente?',
  // eslint-disable-next-line max-len
  'Puedes iniciar sesión como si fueses el profesional en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc. Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.': 'Você pode logar como se fosse o profissional em questão para visualizar a mesma coisa que o profissional vê e assim poder verificar os erros reportados, validar funcionalidades, etc. Você entrará na plataforma como se tivesse feito login com suas credenciais. Fique atento porque as alterações que você fizer também serão definitivas como se fossem deles.',
  // eslint-disable-next-line max-len
  'Iniciar sesión como el profesional {nombre_profesional}': 'Login como o profissional {nombre_professional}',
  Principal: 'Principal',
  Colegiado: 'Colegial',
  'Número de colegiado': 'Número de registro',
  'Lugares de trabajo': 'Locais de trabalho',
  'Añadir nueva clínica': 'Adicionar nova clínica',
  'Seleccciona un clínica': 'Selecione uma clínica',
  Sí: 'Sim',
  No: 'Não',
  // eslint-disable-next-line max-len,no-template-curly-in-string
  'Vas a iniciar sesión como el profesional {nombre_profesional}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?': 'Você vai fazer login como profissional {professional_name}. Você poderá ver e fazer tudo o que ele faz e voltar ao seu perfil de administrador quando quiser. Deseja continuar?',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al intentar personificar al profesional': 'Ocorreu um erro ao tentar se passar pelo profissional',
  '- Sin tipo -': '- Não digitado -',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar todas las clínicas de la plataforma.': 'Ocorreu um erro ao carregar todas as clínicas na plataforma.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar los datos del profesional': 'Ocorreu um erro ao carregar os dados do profissional',

  // src\views\administrator\professional\parts\TheAdminProfessionalUpdate.vue:
  'Modificación de': 'Modificação de',
  'Debe ser un tipo de profesional permitido': 'Deve ser um tipo de profissional permitido',
  'Debe ser una especialidad válida': 'Deve ser uma especialidade válida',
  'Debes introducir une fecha válida': 'Você deve inserir uma data válida',

  // src\views\administrator\purchaseAuthorization\TheAdminPurchaseAuthorizationList.vue:
  'autorizaciones de compra': 'autorizações de compra',
  'Fecha vencimiento': 'Data de Vencimento',
  Vencimiento: 'Vencimento',
  Productos: 'Produtos',
  Estado: 'Estado',
  'No hay autorizaciones de compra': 'Não há autorizações de compra',
  '{count} productos distintos': '{count} produtos diferentes',
  Visualizar: 'Visualizar',
  Subtotal: 'Subtotal',
  Impuestos: 'Impostos',
  'Descargar excel agrupado': 'Download do Excel agrupado',
  'Descargar excel detallado': 'Download do Excel detalhado',

  // src\views\administrator\purchaseAuthorization\parts\TheAdminPurchaseAuthorizationShow.vue:
  'Autorización de compra': 'Autorização de compra',
  'Fecha creación:': 'Data de criação: ',
  'Datos de la autorización de compra:': 'Dados de autorização de compra:',
  'Profesional:': 'Profissional:',
  'Categoría:': 'Categoria:',
  'Paciente:': 'Paciente: ',
  'Fecha de vencimiento:': 'Data de vencimento: ',
  'Estado:': 'Estado: ',
  'Protocolo:': 'Protocolo: ',
  'Fase:': 'Fase: ',
  '{doses} dosis durante {days} días': '{doses} doses por {days} dias',
  Volver: 'Retorno',

  // src\views\administrator\resources\TheAdminResources.vue:
  recursos: 'recursos',
  'Selecciona el tipo de documento': 'Selecione o tipo de documento',
  'Selecciona el tipo de categoría': 'Selecionar tipo de categoria',
  'Subcategoría de': 'Subcategoria de',
  'Ha ocurrido un error al cargar los documentos': 'Ocorreu um erro ao carregar os documentos',

  // src\views\administrator\resources\TheAdminResourcesGrid.vue:
  'Añadir documento': 'Adicionar documento',
  'No hay documentos de esta categoría': 'Não existem documentos desta categoria',
  'Previsualizar documento': 'Visualizar documento',

  // src\views\administrator\resources\TheAdminResourcesList.vue:
  // src\views\administrator\resources\parts\TheAdminResourcesShowModal.vue:
  'Visualizar archivo': 'Exibir arquivo',
  'Descargar archivo': 'Baixar arquivo',
  'Borrar archivo': 'Excluir arquivo',
  'Ha ocurrido un error al intentar descargar el archivo': 'Ocorreu um erro ao tentar baixar o arquivo',
  'Eliminar recurso': 'Excluir recurso',
  // eslint-disable-next-line max-len
  'Vas a eliminar el recurso. No podrás recuperarlo. ¿Estás seguro?': 'Você vai deletar o recurso. Você não será capaz de recuperá-lo. Tem certeza?',
  'El recurso se ha eliminado correctamente.': 'O recurso foi deletado com sucesso.',
  'Ha ocurrido un error al intentar eliminar el archivo.': 'Ocorreu um erro ao tentar excluir o arquivo.',

  // src\views\administrator\resources\parts\TheAdminResourcesUpload.vue:
  'Otros documentos': 'Outros documentos',
  'Guardar documentos': 'Salvar documentos',
  Guardar: 'Salvar',
  'Los documentos se han guardado correctamente.': 'Os documentos foram salvos com sucesso.',
  'Ha ocurrido un error al guardar los documentos': 'Ocorreu um erro ao salvar documentos',

  // src\views\administrator\videos\TheAdminVideos.vue:
  vídeos: 'vídeos',
  'Borrar categoría': 'Excluir categoria',
  'Selecciona una categoría': 'Selecione uma categoria',
  'Añadir vídeo': 'Adicionar vídeo',
  'Ha ocurrido un error al cargar los vídeos': 'Ocorreu um erro ao carregar vídeos',

  // src\views\administrator\videos\TheAdminVideosGrid.vue:
  'No hay vídeos de esta categoría': 'Não há vídeos nesta categoria',
  'Ver vídeo': 'Assista ao vídeo',
  'Vídeo eliminado': 'Vídeo excluído',
  'Se ha eliminado el vídeo con éxito.': 'O vídeo foi excluído com sucesso.',

  // src\views\administrator\videos\category\TheAdminVideoCategoryDestroy.vue:
  'borrar categoría': 'A categoria selecionada será excluída. Você deseja continuar?',

  // src\views\administrator\videos\category\TheAdminVideoCategoryCreate.vue:
  'Añadir categoría': 'Adicionar categoria',
  'Categoría creada correctamente': 'Categoria criada com sucesso',
  'Categoría eliminada': 'categoria excluída',
  'Se ha eliminado la categoría con éxito.': 'A categoria foi excluída com sucesso.',

  // src\views\administrator\videos\parts\TheAdminVideoCreate.vue:
  'borrar vídeo': 'O vídeo selecionado será excluído. Você deseja continuar?',

  // src\views\administrator\videos\parts\TheAdminVideoCreate.vue:
  'nuevo vídeo': 'novo vídeo',
  'Añadir categoria': 'Adicionar categoria',
  Visibilidad: 'Visibilidade',
  Url: 'Url',
  Categoría: 'Categoria',
  'Vídeo creado': 'Vídeo criado',
  'Se ha creado el vídeo {nombre} con éxito.': 'Vídeo {nombre} criado com sucesso.',

  // src\views\administrator\videos\parts\TheAdminVideoShow.vue:
  vídeo: 'vídeo',
  'Editar vídeo': 'Editar vídeo',
  'Ha ocurrido un error al cargar el vídeo.': 'Ocorreu um erro ao carregar o vídeo.',

  // src\views\administrator\videos\parts\TheAdminVideoUpdate.vue:
  'Vídeo editado': 'Vídeo editado',
  'Se ha editado el vídeo "{ name }" con éxito.': 'Vídeo editado "{ name }" com sucesso.',

  // src\views\auth\TheForgotPassword.vue:
  'Reestablecer contraseña': 'Redefinir senha',
  'Introduce tu correo': 'Digite seu e-mail',
  // eslint-disable-next-line max-len
  'Te enviaremos un correo electrónico con un enlace para que puedas generar una nueva contraseña. Si no lo recibes revisa la carpeta de spam.': 'Enviaremos um e-mail com um link para que você possa gerar uma nova senha. Se você não receber, verifique sua pasta de spam.',
  // eslint-disable-next-line max-len
  'Te acabamos de reenviar el correo, revisa tu carpeta de spam por si acaso.': 'Acabamos de encaminhar o e-mail para você, verifique sua pasta de spam por precaução.',

  // src\views\auth\TheLogin.vue:
  'Inicio de sesión': 'Entrar',
  Contraseña: 'Senha',
  'He olvidado mi contraseña': 'Esqueci minha senha',
  'Iniciar sesión': 'Entrar',

  // src\views\auth\TheResetPassword.vue:
  'Nueva contraseña': 'Nova Senha',
  'Confirma tu nueva contraseña': 'Confirme sua nova senha',
  'Confirmación de la contraseña': 'Confirmação de senha',

  // src\views\auth\TheResetPasswordSuccessful.vue:
  '¡Felicidades!': 'Parabéns!',
  // eslint-disable-next-line max-len
  'Tu contraseña ha sido restablecida de forma satisfactoria. Ya puedes volver a iniciar sesión e introducir tus nuevos credenciales.': 'Sua senha foi redefinida com sucesso. Agora você pode entrar novamente e inserir suas novas credenciais.',

  // src\views\auth\TheRGPDAcceptance.vue:
  'Política de protección de datos': 'Política de proteção de dados',
  // eslint-disable-next-line max-len
  'Proage by LFL, S.L. le informa que va a tratar sus datos personales con la finalised de prestar los servicios contratados y/o enviar comunicaciones comerciales. La base jurídica es la ejecución de un contrato de prestación de servicios y el interés legítimo de Proage; tiene derecho a acceder, rectificar y suprimir los datos, así como otros derechos, como se explica en la información adicional, que puede consultar en nuestra web.': 'Proage by LFL, S.L. informa que procederá ao tratamento dos seus dados pessoais com vista à prestação dos serviços contratados e/ou envio de comunicações comerciais. A base legal é a celebração de contrato de prestação de serviços e o interesse legítimo da Proage; Tem o direito de acesso, retificação e eliminação dos dados, bem como outros direitos, conforme explicado na informação adicional, que pode consultar no nosso site.',
  'He leído y acepto la': 'Li e aceito o',
  'Política de Privacidad': 'Política de Privacidade',
  'de Proage': 'da Proage',
  // eslint-disable-next-line max-len
  'Consiento de forma expresa la cesión de mis datos personales al médico asignado por Proage para mi tratamiento.': 'Consinto expressamente na cedência dos meus dados pessoais ao médico designado pela Proage para o meu tratamento.',
  Continuar: 'Continuar',
  'Debes aceptar la política de protección de datos': 'Você deve aceitar a política de proteção de dados',
  // eslint-disable-next-line max-len
  'Debes aceptar las otras condiciones relacionadas con la protección de datos': 'Você deve aceitar as outras condições relacionadas à proteção de dados',
  'Debes aceptar las condiciones para continuar.': 'Você deve aceitar as condições para continuar.',

  // src\views\auth\TheRGPDAcceptanceResponse.vue:
  '¡Gracias por aceptar las políticas de ProAge!': 'Obrigado por aceitar as políticas da ProAge!',
  // eslint-disable-next-line max-len
  '¡Parece que tus políticas ya han sido aceptadas anteriormente!': 'Parece que suas apólices foram aceitas antes!',
  '¡Uups! Ha ocurrido un error.': 'Ops! Ocorreu um erro.',
  'El token provisto no es válido.': 'O token fornecido é inválido.',

  // src\views\auth\TheVerifyUserResponse.vue:
  '¡Error!': 'Falha!',
  Listo: 'Pronto',

  // src\views\patient\ThePatientHome.vue:
  'Modificar mi agenda': 'Modificar minha agenda',
  'Consultar mi agenda': 'Verificar minha agenda',
  Tus: 'Seu',
  'Ha ocurrido un error al cargar las consultas': 'Ocorreu um erro ao carregar as consultas',

  // src\views\patient\calendar\ThePatientCalendarEventShow.vue:
  Evento: 'Evento',

  // src\views\patient\expedients\ThePatientExpedientFile.vue:
  'Tu médico es: ': 'Seu médico é:',
  'Mis datos personales': 'Meus dados pessoais',
  '(Finalizado)': '(Concluído)',
  '- Sin datos -': '- Sem dados -',
  'Fecha de inicio:': 'Data de início:',
  'Última consulta:': 'Última Consulta:',
  'GRPD:': 'GRPD:',
  Válido: 'Válido',
  'Sin aceptar': 'Não aceito',
  General: 'Geral',
  'Datos médicos': 'Dados médicos',
  Consultas: 'Consultas',
  Documentos: 'Documentos',

  // src\views\patient\expedients\treatments\ThePatientExpedientFileTreatmentsList.vue
  'En curso': 'Em andamento',

  // src\views\patient\expedients\consultation\ThePatientConsultation.vue:
  'Politicas RGPD no aceptadas en el expediente': 'Políticas GDPR não aceitas no arquivo',

  // src\views\patient\expedients\consultation\ThePatientConsultationScroll.vue:
  'Historial de': 'História de',
  consultas: 'consultas',

  // src\views\patient\expedients\consultation\ThePatientConsultationScrollItem.vue:

  // src\views\patient\expedients\consultation\ThePatientConsultationShow.vue:
  'No hay niguna consulta seleccionada.': 'Nenhuma consulta selecionada.',

  // src\views\patient\expedients\consultation\parts\ThePatientConsultationView.vue:
  'Visualización de consulta': 'Exibição de consulta',
  'Tipo de consulta': 'Tipo de consulta',
  Medio: 'Médio',
  Lugar: 'Local',
  Tratamiento: 'Tratamento',
  '- Sin tratamiento asociado -': '- Sem tratamento associado -',
  'Autorizaciones relacionadas': 'Autorizações relacionadas',
  'Estado: {status}': 'Status: {status}',
  'No hay autorizaciones relacionadas con esta consulta': 'Não há autorizações relacionadas a esta consulta',
  Finalizado: 'Concluído',
  Caducado: 'Expirado',
  Deshabilitada: 'Desativado',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar las autorizaciones de compra': 'Ocorreu um erro ao carregar as autorizações de compra',

  // src\views\patient\expedients\documents\ThePatientDocument.vue:

  // src\views\patient\expedients\documents\ThePatientDocumentGrid.vue:
  ' Añadir documento': 'Adicionar documento',

  // src\views\patient\expedients\documents\parts\ThePatientDocumentShowModal.vue:
  'Previsualización del documento': 'Visualização do documento',
  Tamaño: 'Tamanho',
  'Fecha de subida': 'Data de upload',
  Radiografías: 'raios-X',
  Analíticas: 'Análise',
  Resonancias: 'Ressonâncias',
  Imágenes: 'Imagens',
  'Ha ocurrido al intentar descargar el archivo': 'Aconteceu ao tentar baixar o arquivo',

  // src\views\patient\expedients\documents\parts\ThePatientDocumentUpload.vue:

  // src\views\patient\expedients\general\ThePatientExpedientFileGeneral.vue:
  Últimas: 'Mais recente',
  'Ver todas las consultas': 'Ver todas as consultas',
  autorizaciones: 'autorizações',
  'Ver todas las autorizaciones': 'Ver todas as autorizações',
  Histórico: 'Histórico',
  Tratamientos: 'Tratamentos',
  Medidas: 'Medidas',
  'No tienes permisos para ver las medidas': 'Você não tem permissão para ver as medidas',

  // src\views\patient\expedients\general\measures\parts\TheMeasuresChartView.vue:
  'No hay datos de medidas': 'Sem dados de medição',
  'Peso (kg)': 'Peso (kg)',
  // src\views\patient\expedients\general\parts\ThePatientGeneralAuthorizations.vue:
  'Ver autorización de compra': 'Visualizar autorização de compra',
  'Emitida:': 'Emitido:',

  // src\views\patient\expedients\parts\ThePatientAddressFieldset.vue:
  'Dirección de facturación': 'Endereço de cobrança',
  'Dirección de envío': 'Endereço de envio',
  'Dirección 1': 'Endereço 1',
  'Dirección 2': 'Endereço 2',
  'Código postal': 'Código postal',
  Ciudad: 'Cidade',
  Provincia: 'Província',
  País: 'País',

  // src\views\patient\expedients\parts\ThePatientPersonalDataModal.vue:
  'datos personales': 'dados pessoais',
  'Información personal': 'Informações pessoais',
  DNI: 'DNI',

  // src\views\patient\expedients\purchaseAuthorization\ThePatientPurchaseAuthorizationList.vue:

  // src\views\patient\expedients\purchaseAuthorization\parts\ThePatientPurchaseAuthorizationShow.vue:

  // src\views\patient\expedients\treatments\ThePatientExpedientFileTreatmentsList.vue:
  'No hay tratamientos en este espediente': 'Não há tratamentos neste arquivo',

  // src\views\patient\expedients\videocalls\ThePatientVideoCallOverlay.vue:
  Videollamada: 'Chamada de vídeo',
  Expandir: 'Expandir',
  'Modo ventana': 'Modo janela',
  'Videollamada con': 'Chamada de vídeo com',
  Minimizar: 'Minimizar',
  'Iniciar videollamada': 'Iniciar chamada de vídeo',
  'Entrando en la videollamada...': 'Entrando na videochamada...',
  'Finalizar llamada': 'Encerrar chamada',
  'Has salido de la videollamada.': 'Você saiu da videochamada.',
  'Hubo un error al intentar acceder a la videollamada.': 'Ocorreu um erro ao tentar acessar a videochamada.',

  // src\views\patient\parts\ThePatientListProfessionals.vue:
  'Ver expediente': 'Visualizar arquivo',
  'No tienes ningún paciente': 'Você não tem nenhum paciente',
  'Ha ocurrido un error al cargar los expedientes': 'Ocorreu um erro ao carregar os arquivos',

  // src\views\professional\TheProfessionalHome.vue:
  'Añadir nuevo paciente': 'Adicionar novo paciente',
  'Ver mis interconsultas': 'Ver minhas interconsultas',
  'Ver todos los pacientes': 'Ver todos os pacientes',

  // src\views\professional\TheProfessionalProfile.vue:
  'Nº documento': 'Número do documento',
  'Clínicas de trabajo': 'Clínicas de trabalho',
  // eslint-disable-next-line max-len
  'No tienes clínicas asociadas a tu perfil profesional. Puedes pedírselo a tu administrador.': 'Você não tem clínicas associadas ao seu perfil profissional. Pode solicitar ao seu administrador.',

  // src\views\professional\anamnesis\TheAnamnesisResultsModal.vue:
  'Resultados de la anamnesis': 'Resultados da anamnese',
  // eslint-disable-next-line max-len
  '¡Enhorabuena! El cuestionario de la anamnesis ha concluido. Según los datos aportados en esta anamnesis, el programa te muestra las siguientes sugerencias:': 'Parabéns! O questionário de anamnese foi preenchido. De acordo com os dados fornecidos nesta anamnese, o programa apresenta as seguintes sugestões:',
  'No hay ninguna sugerencia.': 'Não há sugestões.',
  'Protocolos y fases disponibles:': 'Protocolos e fases disponíveis:',
  'Fase seleccionada': 'Fase selecionada',
  'Seleccionar protocolo': 'Selecionar protocolo',
  Seleccionar: 'Selecionar',
  'Todos los protocolos disponibles:': 'Todos os protocolos disponíveis:',
  'Seleccionar fase': 'Selecionar fase',
  'Iniciar tratamiento': 'Iniciar tratamento',
  Fase: 'Fase',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al cargar los protocolos sugeridos de la anamnesis': 'Erro ao carregar protocolos de histórico sugeridos',

  // eslint-disable-next-line max-len
  'Se ha iniciado el tratamiento correctamente. Ya puedes visualizarlo en la parte superior del expediente': 'O tratamento foi iniciado com sucesso. Agora você pode visualizá-lo no topo do arquivo',
  // eslint-disable-next-line max-len
  'Ya hay un tratamiento iniciado. Por favor, finalízalo antes de comenzar uno nuevo': 'Já existe um tratamento iniciado. Termine-o antes de iniciar um novo',
  'Ha ocurrido un error al confirmar la anamnesis': 'Ocorreu um erro ao confirmar o histórico',

  // src\views\professional\anamnesis\TheProfessionalAnamnesis.vue:
  'Responder anamnesis': 'Resposta anamnese',
  Finalizar: 'Finalizar',
  'Hay una nueva versión de anamnesis': 'Existe uma nova versão da anamnese',
  // eslint-disable-next-line max-len
  'Estás respondiendo a una anamnesis que no es la última versión disponible. Puedes actualizarla y conservaremos todas las respuestas que se hayan mantenido en la nueva versión, o puedes continuar respondiendo a la antigua. ¿Quieres actualizar a la nueva versión?': 'Você está respondendo a uma anamnese que não é a última versão disponível. Você pode atualizá-lo e manteremos todas as respostas que foram mantidas na nova versão, ou você pode continuar respondendo à versão antiga. Deseja atualizar para a nova versão?',
  'Se ha actualizado tu anamnesis con la nueva versión': 'Seu histórico foi atualizado com a nova versão',
  'Ha ocurrido un error al actualizar la anamnesis': 'Ocorreu um erro ao atualizar o histórico',

  // src\views\professional\anamnesis\TheProfessionalNutritionalAnamnesis.vue:
  'Responder anamnesis nutricional': 'Responder histórico nutricional',

  // src\views\professional\anamnesis\parts\TheFormGroupNutritionalAnamnesis.vue.vue:
  Alergias: 'Alergias',
  Observaciones: 'Observações',
  'Ant. familiares': 'parentes antigos',
  Parentesco: 'Relação',
  Enfermedad: 'Doença',
  'Ant. personales': 'Antecedentes pessoais',
  'Act. física': 'Atividade física',
  Deporte: 'Esporte',
  Medicación: 'Medicamento',
  Fármaco: 'Medicamento',
  Posología: 'Dosagem',
  Concepto: 'Conceito',
  'Altura (cm)': 'Altura (cm)',
  'Perímetro abdominal/de cintura': 'Circunferência abdominal/cintura',
  'Perímetro cadera': 'circunferência do quadril',
  '% Grasa': '% Gordo',
  '% Músculo': '% Músculo',
  '% Agua': '% Água',
  '% Visceral': '%Visceral',
  '% Hueso': '% Osso',
  IMC: 'IMC',
  BMR: 'TMB',
  ICA: 'ICA',
  ICC: 'CCI',
  Anamnesis: 'Anamnese',
  '¿Qué hace después de comer?': 'O que você faz depois de comer?',
  'Trabajo sedentario': 'trabalho sedentário',
  Pasear: 'Passeio turístico',
  'Trabajo en movimiento': 'Trabalho em movimento',
  'Otra opción': 'Outra opção',
  Estatura: 'Altura',
  Peso: 'Peso',
  'Objetivo de peso': 'Peso da Meta',
  'Perímetro abdominal': 'Perímetro abdominal',
  'Perímetro de cintura': 'Circunferência da cintura',
  '¿Has hecho dieta alguna vez?': 'Você já fez dieta?',
  'Selecciona una opción': 'Selecione uma opção',
  '¿Te pareció difícil?': 'Você achou difícil?',
  'NS/NC': 'NS/NA',
  '¿Cuánto tiempo la ha seguido?': 'Há quanto tempo você a segue?',
  'Selecciona una respuesta o escribe una opción': 'Selecione uma resposta ou escreva uma opção',
  'Menos de 3 meses': 'Menos de 3 meses',
  'Entre 3 y 6 meses': 'Entre 3 e 6 meses',
  'Cuenta tu experiencia': 'Conte sua experiência',
  'Campo de texto corto...': 'Campo de texto curto...',
  Descansar: 'Descansar',
  '¿Quién cocina en casa?': 'Quem cozinha em casa?',
  Yo: 'EU',
  Si: 'Sim',
  'Otra persona': 'Outra pessoa',
  '¿Quién hace la compra?': 'Quem faz as compras?',
  '¿Cómo te sueles desplazar al trabajo?': 'Como você costuma chegar ao trabalho?',
  Andando: 'Andando',
  Coche: 'Carro',
  'Transporte público': 'Transporte público',
  'Bicicleta o patinete': 'Bicicleta ou Skate',
  '¿Hay algún alimento que no le guste?': 'Existe alguma comida que você não gosta?',
  'Pescado blanco': 'peixe branco',
  'Pescado azul': 'Peixe Azul',
  'Frutos secos': 'nozes',
  Pasta: 'Massa',
  Arroz: 'Arroz',
  Legumbres: 'Legumes',
  Cerdo: 'Porco',
  'Frutas tropicales': 'Frutas tropicais',
  Quesos: 'queijos',
  Lácteos: 'Laticínio',
  Huevos: 'Ovos',
  // eslint-disable-next-line max-len
  'Escriba los alimentos que no aparezcan en la lista (pulse enter cuando añada uno)': 'Digite os alimentos que não aparecem na lista (pressione enter ao adicionar um)',
  Ternera: 'Vitela',
  Pollo: 'Frango',
  Fresas: 'Morangos',
  Champiñones: 'Cogumelos',
  '¿Consume alcohol o tabaco?': 'Você consome álcool ou tabaco?',
  Alcohol: 'Álcool',
  Tabaco: 'Tabaco',
  '¿Cuál es el motivo de consulta?': 'Qual é o motivo da consulta?',
  // eslint-disable-next-line max-len
  'Precisa una mejora de la composición corporal y de su metabolismo energético.': 'Requer uma melhora na composição corporal e no metabolismo energético.',
  // eslint-disable-next-line max-len
  'Presenta síntomas digestivos o sospecha de intolerancia alimentaria.': 'Você tem sintomas digestivos ou suspeita de intolerância alimentar.',
  // eslint-disable-next-line max-len
  'Precisa fortalecer su sistema inmune o normalizar la permeabilidad intestinal.': 'Você precisa fortalecer seu sistema imunológico ou normalizar a permeabilidade intestinal.',
  // eslint-disable-next-line max-len
  '¿Presenta alguna contraindicación para iniciar el protocolo de tratamiento Proage?': 'Você tem alguma contra-indicação para iniciar o protocolo de tratamento Proage?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado la presencia de síntomas de alarma como pérdida de peso excesiva, dolor abdominal intenso, anemia, sangrado digestivo, masa abdominal, adenopatías, fiebre o síntomas nocturnos?': 'A presença de sintomas de alarme, como perda excessiva de peso, dor abdominal intensa, anemia, sangramento gastrointestinal, massa abdominal, linfadenopatia, febre ou sintomas noturnos foi descartada?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado de forma razonable enfermedades orgánicas mediante estudios analíticos?': 'A doença orgânica foi razoavelmente descartada por estudos de laboratório?',
  // eslint-disable-next-line max-len
  '¿Se ha descartado de forma razonable intolerancias alimenticias?': 'As intolerâncias alimentares foram razoavelmente descartadas?',
  // eslint-disable-next-line max-len
  '¿Se ha valorado la necesidad de realizar una endoscopia digestiva o una prueba de imagen radiológica?': 'Foi avaliada a necessidade de realização de endoscopia digestiva ou exame de imagem radiológica?',
  // eslint-disable-next-line max-len
  '¿Se ha tenido en cuenta que la edad es un factor de riesgo de enfermedades orgánicas graves, especialmente a partir de los 50 años?': 'Leva-se em consideração que a idade é fator de risco para doenças orgânicas graves, principalmente a partir dos 50 anos?',
  '¿Sufre migrañas?': 'Você sofre de enxaqueca?',
  '¿Tiene o ha tenido acúfenos?': 'Você tem ou já teve zumbido?',
  '¿Sufre de contracturas musculares de repetición?': 'Você sofre de contraturas musculares repetitivas?',
  '¿Tiene piel atópica, dermografismo , o piel muy seca?': 'Você tem pele atópica, dermografismo ou pele muito seca?',
  '¿Es bruxista?': 'Você é bruxista?',
  // eslint-disable-next-line max-len
  '¿Tiene olvidos frecuentes, niebla mental o dificultad para concentrarse, o memorizar?': 'Você tem esquecimento frequente, nevoeiro cerebral ou dificuldade de concentração ou memorização?',
  '¿Está triste o ansioso?': 'Você está triste ou ansioso?',
  '¿Duerme bien, siente que el sueño es reparador?': 'Você dorme bem, sente que o sono é reparador?',
  '¿Ha sufrido síncopes alguna vez?': 'Você já teve uma síncope?',
  // eslint-disable-next-line max-len
  '¿Esta cansado, se levanta cansado, tiene la sensación de no poder acabar el día?': 'Você está cansado, acorda cansado, tem a sensação de não conseguir terminar o dia?',
  '¿Tiene dolores erráticos, que van y vienen?': 'Você tem dores errantes que vêm e vão?',
  '¿Se le duermen los dedos de las manos o de los pies?': 'Seus dedos das mãos ou dos pés adormecem?',
  '¿Es de manos y pies fríos?': 'É mãos e pés frios?',
  '¿Es friolero/a?': 'Está frio?',
  // eslint-disable-next-line max-len
  '¿Ha tenido infecciones de orina de repetición o candidiasis de repetición o molestias al orinar?': 'Você já teve infecções repetidas do trato urinário ou infecções fúngicas repetidas ou desconforto ao urinar?',
  '¿Es sensible a los ruidos, luces u olores?': 'Você é sensível a ruídos, luzes ou cheiros?',
  '¿Tiene piernas inquietas?': 'Você tem pernas inquietas?',
  '¿Le salen hematomas al más mínimo roce?': 'Ele se machuca ao menor toque?',
  // eslint-disable-next-line max-len
  'En el caso de las mujeres, ¿tiene reglas dolorosas y/o endometriosis?': 'No caso das mulheres, você tem menstruação dolorosa e/ou endometriose?',
  '¿Se recupera bien cuando hace ejercicio?': 'Você se recupera bem quando se exercita?',
  '¿El paciente tiene un IMC mayor de 25?': 'O paciente tem um IMC maior que 25?',
  '¿Tiene síntomas digestivos?': 'Você tem sintomas digestivos?',
  // eslint-disable-next-line max-len
  '¿Se ha realizado una evaluación adecuada de la microbiota y de la permeabilidad intestinal del paciente mediante estudio de microbiota, determinación de DAO, determinación de zonulina, determinación de proteína de unión a lipopolisacárido bacteriano?': 'Foi realizada uma avaliação adequada da microbiota e da permeabilidade intestinal do paciente por meio de estudo de microbiota, determinação de DAO, determinação de zonulina, determinação de proteína de ligação de lipopolissacarídeo bacteriano?',
  'Anamnesis nutricional': 'História nutricional',
  'Fecha primera consulta': 'Data da primeira consulta',
  'Peso obj.': 'peso do objeto',
  'Perímetro de cadera': 'circunferência do quadril',
  '¿Ha hecho dieta alguna vez?': 'Você já fez dieta?',
  '¿Le pareció difícil?': 'Você achou difícil?',
  Siesta: 'Foto',
  Caminar: 'Andar',
  Trabajar: 'Trabalhar',
  '¿Qué hace por la tarde?': 'O que você faz à tarde?',
  '¿Cómo se desplaza?': 'Como ele se move?',
  Moto: 'Motocicleta',
  Bici: 'Bicicleta',
  Taxi: 'Táxi',
  Metro: 'Metro',
  Autobus: 'Ônibus',
  Mixto: 'Misturado',
  '¿Cómo se considera, sedentario o activo?': 'Como você se considera, sedentário ou ativo?',
  Sedentario: 'Sedentário',
  '¿Utiliza ascensor o escaleras?': 'Você usa elevador ou escada?',
  Ascensor: 'Elevador',
  Escaleras: 'Escadaria',
  '¿Cuántos pisos son?': 'Quantos andares são?',
  'Número de pisos': 'Número de pisos',
  '¿Come en casa o fuera?': 'Você come em casa ou fora?',
  Fuera: 'Fora',
  Casa: 'Lar',
  Ambos: 'Ambos',
  '¿Cuántos días fuera de casa?': 'Quantos dias longe de casa?',
  'Algún alimento que no le guste:': 'Algumas comidas que você não gosta:',
  '¿Alcohol o tabaco?': 'Álcool ou tabaco?',
  // eslint-disable-next-line max-len
  '¿Algún momento del día , situación o estado de ánimo en el que le apetezca comer?': 'Existe alguma hora do dia, situação ou humor em que você sente vontade de comer?',
  '¿Cómo come?': 'Como você come?',
  Solo: 'Apenas',
  Acompañado: 'Acompanhado',
  '¿En casa o trabajo?': 'Em casa ou no trabalho?',
  Trabajo: 'Trabalho',
  '¿Deprisa o despacio?': 'Rápido ou devagar?',
  deprisa: 'rapidamente',
  Despacio: 'Devagar',
  'Con los disgustos, ¿pierde el apetito?': 'Com os transtornos, você perde o apetite?',
  'Con el estrés o ansiedad, ¿come más?': 'Com estresse ou ansiedade, você come mais?',
  '¿Come todo lo que quiere?': 'Você come o quanto quiser?',
  '¿Está comiendo más de lo que necesita?': 'Você está comendo mais do que precisa?',
  // eslint-disable-next-line max-len
  'Al terminar de comer, ¿queda satisfecho o seguiría comiendo?': 'Ao terminar de comer, você fica satisfeito ou continuaria comendo?',
  'Satisfecho/a': 'Satisfeito',
  'Insatisfecho/a': 'Insatisfeito',
  '¿Cuánto tarda en comer?': 'Quanto tempo leva para comer?',
  Mucho: 'Bastante',
  Poco: 'Pedaço',
  'Qué le gusta más, ¿dulce o salado': 'O que você gosta mais, doce ou salgado',
  Dulce: 'Doce',
  Salado: 'Salgado',
  '¿Realiza algún deporte?': 'Você pratica algum esporte?',
  '¿Cuál?': 'Qual?',
  '¿Cuántas veces por semana?': 'Quantas vezes por semana?',
  'Selecciona una respuesta': 'Selecione uma resposta',
  '1 día a la semana': '1 dia por semana',
  '2 días a la semana': '2 dias por semana',
  '3 días a la semana': '3 dias por semana',
  '4 días a la semana': '4 dias por semana',
  '5 días a la semana': '5 dias por semana',
  '6 días a la semana': '6 dias por semana',
  '7 días a la semana': '7 dias por semana',
  'Todas las veces que puedo': 'Sempre que posso',
  // eslint-disable-next-line max-len
  'Me siento cansado y desanimado cuando me levanto por las mañanas': 'Sinto-me cansado e desanimado quando me levanto de manhã',
  Nunca: 'Nunca',
  'Pocas veces': 'Poucas vezes',
  'Algunas veces': 'Algumas vezes',
  Continuamente: 'Continuamente',
  Siempre: 'Sempre',
  'Mis músculos están tensos y doloridos': 'Meus músculos estão tensos e doloridos',
  'Tengo ataques de pánico': 'Eu tenho ataques de pânico',
  'Rechino los dientes o aprieto la mandíbula': 'Eu ranjo os dentes ou cerro o maxilar',
  'Tengo problemas de diarrea o estreñimiento': 'Tenho problemas com diarreia ou obstipação',
  'Necesito ayuda para realizar mis actividades diarias': 'Preciso de ajuda para realizar minhas atividades diárias',
  'Soy sensible a la luz brillante': 'Sou sensível à luz brilhante',
  'Me canso fácilmente cuando estoy físicamente activo': 'Eu me canso facilmente quando estou fisicamente ativo',
  'Siento dolor en todo mi cuerpo': 'Sinto dores no corpo todo',
  'Tengo dolores de cabeza': 'Tenho dores de cabeça',
  // eslint-disable-next-line max-len
  'Tengo molestia en mi vejiga o sensación de quemazón al orinar': 'Tenho desconforto na bexiga ou sensação de ardor ao urinar',
  'No duermo bien': 'não durmo bem',
  'Tengo dificultad para concentrarme': 'Tenho dificuldade em me concentrar',
  // eslint-disable-next-line max-len
  'Tengo problemas en la piel como resequedad, picor o sarpullido': 'Tenho problemas de pele como ressecamento, coceira ou erupção cutânea',
  'El estrés hace que mis síntomas físicos empeoren': 'O estresse piora meus sintomas físicos',
  'Me siento triste o deprimido': 'Sinto-me triste ou deprimido',
  'Me siento con poca energía': 'Eu me sinto com pouca energia',
  'Tengo tensión muscular en mi cuello y hombros': 'Tenho tensão muscular no pescoço e ombros',
  'Tengo tensión en mi mandíbula': 'Tenho tensão no maxilar',
  // eslint-disable-next-line max-len
  'Algunos olores, como perfumes, me hacen sentir mareado y con náuseas': 'Alguns cheiros, como perfumes, me deixam tonto e enjoado',
  'Tengo que orinar frecuentemente': 'Tenho que urinar com frequência',
  // eslint-disable-next-line max-len
  'Mis piernas se sienten incómodas e inquietas cuando intento dormir por la noche': 'Minhas pernas ficam desconfortáveis ​​e inquietas quando tento dormir à noite',
  'Tengo dificultad para recordar cosas': 'Tenho dificuldade em lembrar das coisas',
  'Sufrí algún trauma cuando era niño(a)': 'Eu sofri alguns traumas quando criança',
  'Tengo dolor en mi zona pélvica': 'Tenho dores na região pélvica',

  // src\views\professional\anamnesis\parts\TheFormGroupAnamnesis.vue:
  'Ha ocurrido un error al guardar los cambios': 'Ocorreu um erro ao salvar as alterações',

  // src\views\professional\calendar\parts\TheProfessionalCalendarEventCreate.vue:
  Nuevo: 'Novo',
  // eslint-disable-next-line max-len
  'Debes seleccionar un paciente para que le aparezca en la agenda': 'Você deve selecionar um paciente para aparecer na agenda',
  // eslint-disable-next-line max-len
  'Al crear esta {tipo_consuta} se mandará un SMS al paciente con la hora indicada.': 'Ao criar este {tipo_consuta} será enviado um SMS ao paciente com a hora indicada.',

  // src\views\professional\calendar\parts\TheProfessionalCalendarEventUpdate.vue:
  // eslint-disable-next-line max-len
  'Al editar la hora de esta "{tipo_consulta}" se mandará un SMS al paciente con la nueva hora indicada.': 'Ao editar o horário desta "{tipo_consulta}" será enviado um SMS ao paciente com o novo horário indicado.',
  // eslint-disable-next-line max-len
  'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}". ¿Estás seguro?': 'Você vai remover o "{tipo_consulta}" do nome "{titulo}". Tem certeza?',
  // eslint-disable-next-line max-len
  'Vas a eliminar la "{tipo_consulta}" de nombre "{titulo}" con el paciente {nombre_paciente} {apellido_paciente}. Le mandaremos SMS indicando la cancelación. ¿Estás seguro?': 'Você vai eliminar o "{tipo_consulta}" do nome "{titulo}" com o paciente {nombre_paciente} {apellido_paciente}. Iremos enviar-lhe um SMS a indicar o cancelamento. Tem certeza?',

  // src\views\professional\documents\parts\TheProfessionalDocumentShowModal.vue:
  'Subido por': 'Enviado por',
  Descargar: 'Baixar',

  // src\views\professional\expedients\TheProfessionalExpedientFile.vue:
  'Ficha del paciente:': 'Ficha do paciente:',
  'Datos personales': 'Dados pessoais',
  'Finalizar tratamiento': 'Tratamento final',
  'Cambiar de fase': 'Mudança de fase',
  Reenviar: 'Reenviar',
  'Ha ocurrido un error al empezar la anamnesis': 'Ocorreu um erro ao iniciar o histórico',

  // src\views\professional\expedients\TheProfessionalExpedientsList.vue:
  'NIF / DNI': 'NIF / DNI',

  // src\views\professional\expedients\anamnesis\TheProfessionalExpedientAnamnesisTab.vue:
  Nutricional: 'Nutricional',
  'De protocolo': 'Do protocolo',
  'Test de Sensibilidad Central': 'Teste de Sensibilidade Central',
  'Tratamiento en curso': 'Tratamento atual',
  // eslint-disable-next-line max-len
  'Para responder la anamnesis de protocolo no puede haber un tratamiento en curso. Finaliza el tratamiento actual para poder responderla nuevamente.': 'Para responder à anamnese do protocolo não pode haver tratamento contínuo. Termine o tratamento atual para poder respondê-lo novamente.',

  // src\views\professional\expedients\consultation\TheProfessionalConsultationShow.vue:
  'Seleccione alguna o cree una nueva.': 'Selecione um ou crie um novo.',

  // src\views\professional\expedients\consultation\parts\TheProfessionalConsultationCreate.vue:
  Nueva: 'Nova',
  consulta: 'consulta',
  'Fecha de inicio*': 'Data de início*',
  'Medio consulta*': 'Consulta média*',
  'Consulta física': 'Consulta física',
  'Medio de consulta': 'Consulta média',
  'Fecha de inicio': 'Data de início',
  '- Sin observaciones -': '- Sem comentários -',
  'La consulta se ha añadido correctamente': 'Consulta adicionada com sucesso',
  'Ha ocurrido un error al crear la consulta': 'Ocorreu um erro ao criar a consulta',

  // src\views\professional\expedients\consultation\parts\TheProfessionalConsultationUpdate.vue:
  'Fecha de fin': 'Data final',
  'Consulta física*': 'Consulta física*',
  'Nueva autorización': 'Nova autorização',
  'Los cambios se han guardado correctamente.': 'As alterações foram salvas com sucesso.',
  '¡Uups!': 'Opa!',
  // eslint-disable-next-line max-len
  'El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.': 'O paciente não tem um tratamento ativo, então não podemos criar uma autorização. Inicie um tratamento para poder criá-lo. Você pode iniciar um tratamento respondendo a uma anamnese.',
  'Volver al panel': 'Voltar ao Painel',

  'Crear autorización de compra': 'Criar autorização de compra',
  'Editar consulta': 'Editar consulta',
  'Nueva autorización de compra': 'Nova autorização de compra',

  // src\views\professional\expedients\general\TheProfessionalExpedientFileGeneral.vue:
  'No tienes permisos para ver las medidas del paciente': 'Você não tem permissão para ver as medidas do paciente',
  // eslint-disable-next-line max-len
  'No tienes permisos para ver los tratamientos del paciente': 'Você não tem permissão para ver os tratamentos do paciente',

  // src\views\professional\expedients\measures\TheProfessionalExpedientMeasuresList.vue:
  Tabla: 'Tabla',
  Gráfica: 'Gráfico',
  'Añadir nueva medida': 'Adicionar nova medida',
  'Añadir medida': 'Adicionar medida',
  'Los datos se han guardado correctamente.': 'Os dados foram salvos com sucesso.',
  'Ha ocurrido un error al guardar las medidas': 'Ocorreu um erro ao salvar as medições',

  // src\views\professional\expedients\measures\parts\TheMeasuresTableView.vue:
  'Ha ocurrido un error al guardar los datos': 'Ocorreu um erro ao salvar os dados',
  'Los datos se han eliminado correctamente': 'Os dados foram excluídos com sucesso',
  'Ha ocurrido un error al eliminar los datos': 'Erro ao excluir dados',
  '¿Seguro que desea eliminar este dato?': 'Tem certeza de que deseja excluir esses dados?',
  'Al borrar el dato no podrás volver a visualizarlo': 'Ao deletar os dados não será possível visualizá-los novamente',
  'No hay medidas.': 'No hay medidas.',

  // src\views\professional\expedients\medicalData\forms\TheProfessionalExpedientFormsList.vue:
  alergias: 'alergias',
  antecedentes_familiares: 'antecedentes familiares',
  antecedentes_personales: 'antecedentes personales',
  actividad_fisica: 'actividad Física',
  medicacion: 'medicación',
  observaciones: 'observación',
  'No hay datos de esta categoría': 'Sem dados para esta categoria',
  respuesta: 'resposta',
  'Parece que ese registro ya ha sido introducido.': 'Parece que esse registro já foi inserido.',
  'Parece que ese registro no existe o ya se ha eliminado.': 'Parece que o registro não existe ou já foi excluído.',

  // src\views\professional\expedients\medicalData\forms\parts\VFormRow.vue:
  'Eliminar registro': 'Excluir registro',
  Borrar: 'Excluir',

  // src/views/professional/expedients/medicalData/forms/TheProfessionalExpedientFormsList.vue
  Animales: 'Animais',
  'Ácaros del polvo': 'Ácaros do pó',
  Moho: 'Molde',
  Insectos: 'Insetos',
  'Polen de gramíneas': 'Pólen de grama',
  'Polen de árbol': 'Árvore Pólen',
  'Polen de malezas': 'Pólen de ervas daninhas',
  Polen: 'Pólen',
  Cacahuetes: 'Amendoins',
  Soja: 'Soja',
  Trigo: 'Trigo',
  'Pescado y marisco': 'Peixe e marisco',
  'Frutas y verduras': 'Frutas e legumes',
  Leche: 'Leite',
  'No conocidas': 'Desconhecido',

  // src/views/professional/expedients/medicalData/TheProfessionalExpedientFileMedicalData.vue
  'Añadir {medicalData}': 'Adicionar {medicalData}',

  // src\views\professional\expedients\parts\TheProfessionalExpedientCreate.vue:
  // eslint-disable-next-line max-len
  'Ya tienes registrado a un paciente con ese número de identificación.': 'Você já tem um paciente cadastrado com esse número de identificação.',

  // src\views\professional\expedients\parts\TheProfessionalPatientAddressFieldset.vue:
  Población: 'População',
  'Actualizar dirección de facturación': 'Atualizar endereço de cobrança',
  'Actualizar dirección de envío': 'Atualizar endereço de entrega',
  // eslint-disable-next-line max-len
  'La dirección de facturación se ha actualizado correctamente.': 'O endereço de cobrança foi atualizado com sucesso.',
  'La dirección de envío se ha actualizado correctamente.': 'Endereço de entrega atualizado com sucesso.',

  // src\views\professional\expedients\parts\TheProfessionalPatientDataProtection.vue:
  'Protección de': 'Proteção de',
  datos: 'dados',
  // eslint-disable-next-line max-len
  'Para poder continuar con el proceso es necesario que el paciente acepte la información de protección de datos. Para ello, puedes enviarla mediante las dos opciones disponibles.': 'Para continuar com o processo, é necessário que o paciente aceite as informações de proteção de dados. Para fazer isso, você pode enviá-lo usando as duas opções disponíveis.',
  // eslint-disable-next-line max-len
  'En caso de que el paciente no haya recibido notificación alguna, puede reenviarlo pinchando de nuevo en una de las opciones. En el momento en el que el paciente acepte la política, podrá acceder a su ficha para completar sus datos pinchando en “continuar”.': 'Caso o paciente não tenha recebido nenhuma notificação, ele pode reenviá-la clicando novamente em uma das opções. Assim que o paciente aceitar a apólice, ele poderá acessar sua ficha para completar seus dados clicando em "continuar".',
  'Enviar RGPD mediante SMS': 'Enviar GDPR via SMS',
  'Enviar RGPD mediante Email': 'Enviar GDPR por e-mail',
  'Enviar RGPD mediante email': 'Enviar GDPR por email',

  // eslint-disable-next-line max-len
  'La notificación se ha enviado. En caso de no recibirla, podrás volver a enviarla dentro de:': 'A notificação foi enviada. Se você não o receber, poderá reenviá-lo dentro de:',
  // eslint-disable-next-line max-len
  'También se puede imprimir en papel la RGPD y que el paciente la lea y firme en persona. Para ello, descarga e imprime el documento pulsando el botón "Descargar para imprimir", y, a continuación, pulsa en el botón "Paciente ha firmado RGPD en papel".': 'O GDPR também pode ser impresso em papel e lido e assinado pelo paciente pessoalmente. Para fazer isso, baixe e imprima o documento clicando no botão "Baixar para imprimir" e, em seguida, clique no botão "O paciente assinou o GDPR em papel".',
  'Descargar para imprimir': 'Baixar para imprimir',
  'Paciente ha firmado RGPD en papel': 'O paciente assinou o GDPR no papel',
  'Ir al expediente': 'Ir para arquivo',
  'El paciente aún no ha aceptado el RGPD': 'O paciente ainda não aceitou o GDPR',
  // eslint-disable-next-line max-len
  'El paciente ya ha aceptado el RGPD, te estamos redirigiendo': 'O paciente já aceitou o RGPD, estamos a redirecioná-lo',
  'Se ha enviado la política de datos por SMS.': 'A política de dados foi enviada por SMS.',
  // eslint-disable-next-line max-len
  'Se ha enviado la política de datos por correo electrónico y SMS.': 'A política de dados foi enviada por e-mail e SMS.',
  'El RGPD se ha enviado corectamente': 'GDPR foi enviado com sucesso',
  // eslint-disable-next-line max-len
  'El envío por SMS está actualmente deshabilitado. Utiliza el correo electrónico.': 'O envio por SMS está desabilitado no momento. Use email.',
  'Confirmar RGPD': 'Confirmar GDPR',
  // eslint-disable-next-line max-len
  'Si el paciente ya aceptado la RGPD de forma física, confirma para continuar y ver el expediente': 'Se o paciente já aceitou fisicamente o RGPD, confirme para continuar e visualizar o arquivo',
  'RGPD aceptadas con éxito': 'GDPR aceito com sucesso',
  'Ha ocurrido un error al aceptar las RGPD': 'Ocorreu um erro ao aceitar o GDPR',

  // src\views\professional\expedients\parts\TheProfessionalPatientPersonalDataModal.vue:
  'Datos actualizados con éxito.': 'Dados atualizados com sucesso.',
  'El {identityDocType} registrado no es Válido': 'Registrado {identityDocType} é inválido',

  // src\views\professional\expedients\treatments\TheProfessionalExpedientFileTreatmentsList.vue:
  'No hay tratamientos': 'Sem tratamentos',
  'Seleccionar tratamiento': 'Selecionar tratamento',

  // src\views\professional\expedients\treatments\parts\TheProfessionalTreatmentChangePhaseModal.vue:
  'Cambiar de': 'Mudar de',
  fase: 'fase',
  'Selecciona una nueva fase para el tratamiento:': 'Selecione uma nova fase para o tratamento:',
  'Debes elegir una fase para poder continuar': 'Você deve escolher uma fase para poder continuar',
  'Se ha cambiado de fase correctamente': 'Faseado com sucesso',
  'Ha ocurrido un error al cambiar de fase': 'Ocorreu um erro ao mudar de fase',

  // src\views\professional\expedients\treatments\parts\TheProfessionalTreatmentChangePhaseModal.vue:
  tratamiento: 'tratamento',
  // eslint-disable-next-line max-len
  'Vas a finalizar el tratamiento actual, ¿estás seguro? De ser así, indica por favor el motivo:': 'Você vai terminar o tratamento atual, tem certeza? Em caso afirmativo, indique o motivo:',
  label: 'rótulo',
  // eslint-disable-next-line max-len
  'Debes elegir una de las razones dadas o escribir una propia': 'Você deve escolher um dos motivos apresentados ou escrever o seu próprio',
  Razón: 'Motivo',
  'Se ha finalizado el tratamiento correctamente': 'Tratamento concluído com sucesso',
  'Ha ocurrido un error, el tratamiento ya está finalizado': 'Ocorreu um erro, o tratamento já foi finalizado',
  'Ha ocurrido un error al finalizar el tratamiento': 'Ocorreu um erro no final do tratamento',

  // src\views\professional\expedients\videocalls\TheVideoCallOverlay.vue:
  'Compartir enlace': 'Compartilhar link',
  'Ver ayuda': 'Ver ajuda',
  'Crear sala de reuniones': 'Criar sala de reunião',
  'Crear sala': 'Criar sala',
  'Ayuda de videollamadas': 'Ajuda de chamada de vídeo',
  'Has llegado a tu límite': 'Você atingiu seu limite',

  // src\views\professional\expedients\videocalls\TheVideoCallShareModal.vue:
  'Compartir enlace de acceso a la videollamada': 'Compartilhar link de acesso à videochamada',
  'Este es el enlace de invitación a la videollamada:': 'Este é o link do convite da videochamada:',
  Copiar: 'Copiar',
  // eslint-disable-next-line max-len
  'También puedes enviar el enlace al correo electrónico o a través de un SMS del paciente:': 'Você também pode enviar o link para o e-mail ou SMS do paciente:',
  'Enviar correo electrónico': 'Enviar e-mail',
  'Enviar SMS': 'Enviar SMS',
  // eslint-disable-next-line max-len
  'Enlace de acceso a la videollamada copiado al portapapeles.': 'Link de acesso à videochamada copiado para a área de transferência.',
  'Enviar invitación por correo': 'Enviar convite por correio',
  // eslint-disable-next-line max-len
  'Vamos a enviar un correo electrónico al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?': 'Vamos enviar um e-mail para o paciente com o link de acesso à videochamada. A partir desse link você pode acessar com um simples clique. Tem certeza?',
  'Enviar correo con enlace': 'Enviar e-mail com link',
  '¡Enviado!': 'Enviado!',
  // eslint-disable-next-line max-len
  'Hemos enviado el enlace al paciente por correo electrónico. Ahora podrá unirse a la videollamada.': 'Enviamos o link para o paciente via e-mail. Agora você poderá entrar na videochamada.',
  'Ha ocurrido un error': 'Ocorreu um erro',
  'Algo ha salido mal, vuelve a probar más tarde...': 'Algo deu errado, tente novamente mais tarde...',

  'Enviar invitación por SMS': 'Enviar convite por SMS',
  // eslint-disable-next-line max-len
  'Vamos a enviar un SMS al paciente con el enlace de acceso a la videollamada. Desde ese enlace podrá acceder con un simple clic. ¿Estás seguro?': 'Vamos enviar um SMS para o paciente com o link de acesso à videochamada. A partir desse link você pode acessar com um simples clique. Tem certeza?',
  'Enviar SMS con enlace': 'Enviar SMS com link',
  // eslint-disable-next-line max-len
  'Hemos enviado el enlace al paciente por SMS. Ahora podrá unirse a la videollamada.': 'Enviamos o link para o paciente por SMS. Agora você poderá entrar na videochamada.',

  // src\views\professional\inter-consultations\TheProfessionalInterConsultationsList.vue:
  interconsultas: 'interconsultas',
  'Nueva interconsulta': 'Nova consulta',
  Con: 'Com',
  'F. Creación': 'F. Criação',
  'No hay interconsultas': 'Não há interconsultas',
  ' - Sin paciente - ': ' - Sem paciente - ',
  Finalizada: 'Concluído',
  Abierta: 'Aberto',
  'Ver interconsulta': 'Visualizar consulta',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultation.vue:
  'Edición de': 'Edição de',
  interconsulta: 'interconsulta',
  Creador: 'Criador',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationCreate.vue:
  'Profesional*': 'Profissional*',
  'Categoría*': 'Categoría*',
  'La interconsulta se ha creado con éxito': 'A consulta foi criada com sucesso',
  'Ha ocurrido un error al crear la interconsulta': 'Ocorreu um erro ao criar a interconsulta',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationUpdate.vue:
  'Finalizar interconsulta': 'Finalizar interconsulta',
  De: 'De',
  Comentarios: 'Comentários',
  'No hay comentarios': 'Sem comentários',
  Tú: 'Você',
  'Ha ocurrido un error al actualizar la interconsulta': 'Ocorreu um erro ao atualizar a consulta',
  // eslint-disable-next-line max-len
  'Si finalizas esta interconsulta podrás seguir visualizando los datos y comentarios pero no editarla ni comentar nuevamente. ¿Estás seguro?': 'Se você terminar esta interconsulta, poderá continuar visualizando os dados e comentários, mas não poderá editá-los ou comentá-los novamente. Tem certeza?',
  'La interconsulta se ha finalizado con éxito': 'A interconsulta foi concluída com sucesso',
  'Ha ocurrido un error al finalizar la interconsulta': 'Ocorreu um erro no final da consulta',

  // src\views\professional\inter-consultations\parts\TheProfessionalInterConsultationView.vue:
  'Escribe tu mensaje': 'Escreva sua mensagem',
  Enviar: 'Enviar',
  Mensaje: 'Mensagem',
  'Ha ocurrido un error al enviar el comentario': 'Ocorreu um erro ao enviar o comentário',
  'Eliminar comentario': 'Excluir comentário',
  '¿Estás seguro de que quieres eliminar este comentario?': 'Tem certeza de que deseja excluir este comentário?',
  'Ha ocurrido un error al borrar el comentario': 'Ocorreu um erro ao excluir o comentário',

  // src\views\professional\parts\TheProfessionalHomeExpedients.vue:
  // src\views\professional\purchaseAuthorization\ThePurchaseAuthorizationList.vue:

  // src\views\professional\purchaseAuthorization\ThePurchaseAuthorizationList.vue:
  'Reenviar enlace': 'Link de encaminhamento',
  'Reenviar enlace de compra': 'Reenviar link de compra',
  'Se ha enviado un nuevo enlace de compra correctamente.': 'Um novo link de compra foi enviado com sucesso.',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al enviar un nuevo enlace de la autorización de compra': 'Ocorreu um erro ao enviar um novo link de autorização de compra',
  // eslint-disable-next-line max-len
  'Se va a enviar un nuevo  enlace de compra al paciente y se anularán los anteriores. Desde este nuevo enlace podrá adquirir los productos. ¿Estás seguro?': 'Um novo link de compra será enviado ao paciente e os anteriores serão cancelados. A partir deste novo link você pode comprar os produtos. Tem certeza?',

  // src\views\professional\purchaseAuthorization\parts\TheProductListModal.vue:
  'Productos disponibles': 'Produtos disponíveis',
  'Añadir nuevo producto': 'Adicionar novo produto',
  'Restar producto': 'Subtrair produto',
  'Sumar producto': 'Adicionar produto',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  Suplementación: 'Suplementação',
  Producto: 'Produto',

  // src\views\professional\purchaseAuthorization\parts\TheProductListModal.vue:
  Aceptar: 'Aceitar',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  'Duración del tratamiento*': 'Duração do tratamento*',
  'Introducir días': 'Inserir dias',
  'Posología*': 'Dosagem*',
  'Introducir dosis': 'Digite a dose',
  // eslint-disable-next-line max-len
  'Tienes más productos en la cesta de los permitidos. Elimina alguno.': 'Você tem mais produtos na cesta do que o permitido. Delete some.',
  'dosis durante': 'dose durante',
  días: 'dias',
  // eslint-disable-next-line max-len
  'Para añadir productos tienes que introducir la duración del tratamiento y la posología.': 'Para adicionar produtos você deve inserir a duração do tratamento e a dosagem.',
  // eslint-disable-next-line max-len
  'Selecciona los productos necesarios para crear la autorización de compra': 'Selecione os produtos necessários para criar a autorização de compra',
  '10 sobres por caja': '10 envelopes por caixa',
  '0 cajas': '0 caixas',

  // src\views\professional\purchaseAuthorization\parts\ThePurchaseAuthorizationCreate.vue:
  Protocolo: 'Protocolo',
  'Duración del tratamiento': 'Duração do tratamento',
  Packs: 'Pacotes',
  Otros: 'Outros',
  'Enviar autorización de compra': 'Enviar autorização de compra',
  // eslint-disable-next-line max-len
  'Autorización botella': 'Caso seja o primeiro pedido do paciente e contenha mais de 6 produtos das linhas ProAge On ou ProAge On+, ele receberá um shaker grátis. Não é necessário adicioná-lo à autorização',
  // eslint-disable-next-line max-len
  'Para este tratamiento se recomienda el uso de la suplementación ProAge On Plus (+). Queda a la elección del profesional el uso de otras lineas.': 'Para este tratamento, recomenda-se o uso da suplementação ProAge On Plus (+). O uso de outras linhas fica a critério do profissional.',
  // eslint-disable-next-line max-len
  'Para este tratamiento se recomienda el uso de la suplementación ProAge On. Queda a la elección del profesional el uso de otras lineas.': 'Para este tratamento, é recomendado o uso de suplementação ProAge On. O uso de outras linhas fica a critério do profissional.',
  'Límite de cajas alcanzado.': 'Limite de caixas atingido.',
  // eslint-disable-next-line max-len
  '¿Seguro que quieres crear esta nueva autorización de compra?': 'Tem certeza de que deseja criar esta nova autorização de compra?',
  'La autorización de compra se ha creado correctamente': 'Autorização de compra criada com sucesso',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al añadir la autorización de compra': 'Ocorreu um erro ao adicionar autorização de compra',
  'Deshabilitar autorización de compra': 'Desativar autorização de compra',
  Deshabilitar: 'Desativar',
  // eslint-disable-next-line max-len
  '¿Estás seguro de que quieres deshabilitar esta autorización de compra? Si la deshabilitas,no podrá utilizarse para adquirir productos en la tienda.': 'Tem certeza de que deseja desativar esta autorização de compra? Se você desativá-lo, não poderá ser usado para comprar produtos na loja.',
  // eslint-disable-next-line max-len
  'La autorización de compra se ha deshabilitado correctamente': 'A autorização de compra foi desativada com sucesso',
  // eslint-disable-next-line max-len
  'Ha ocurrido un error al dishabilitar la autorización de compra': 'Ocorreu um erro ao desabilitar a autorização de compra',

  // src\views\professional\resources\TheProfessionalResourcesGrid.vue:
  'Visualizar documento': 'Visualizar documento',

  // src\views\professional\videocalls\Call.vue:
  Recargar: 'Recarregar',
  'Estamos esperando a que se conecte tu paciente...': 'Estamos aguardando a conexão do seu paciente...',
  'Estamos esperando a que se conecte tu médico...': 'Estamos aguardando a ligação do seu médico...',

  // src\views\professional\videocalls\Chat.vue:
  'Mostrar/Ocultar chat': 'Mostrar/Ocultar chat',
  'Escribe un mensaje...': 'Escreva uma mensagem...',

  // src\views\professional\videocalls\Controls.vue:
  'Desactivar micrófono': 'Desativar microfone',
  'Activar micrófono': 'Ativar microfone',
  'Desactivar cámara': 'Desativar câmera',
  'Activar cámara': 'Ativar câmera',
  'Dejar de compartir pantalla': 'Parar compartilhamento de tela',
  'Compartir pantalla': 'Compartilhar tela',

  // src\views\professional\videocalls\PermissionsErrorMsg.vue:
  'Cámara o micrófono bloqueado': 'Câmera ou microfone bloqueado',
  Reintentar: 'Repetir',

  // src\views\professional\videocalls\PermissionsErrorMsg.vue:
  'Conseguir ayuda': 'Obter ajuda',

  // src\views\professional\videocalls\WaitingCard.vue:
  Email: 'E-mail',
  SMS: 'SMS',
  'Esperando a que alguien se conecte a la videollamada...': 'Aguardando alguém se conectar à videochamada...',
  'Vamos a redirigirte hacia la tienda de PROAGE BY LFL®': 'Vamos redirecioná-lo para a loja PROAGE BY LFL®',

  // src\views\shop\TheShopOrderRedirection.vue:
  // eslint-disable-next-line max-len
  'Este proceso puede tardar unos segundos mientras preparamos tu pedido': 'Este processo pode demorar alguns segundos enquanto preparamos o seu pedido',
  'Ha ocurrido un error.': 'Ocorreu um erro.',
  // eslint-disable-next-line max-len
  'Por favor, comprueba que el enlace es válido y no ha caducado, y vuelve a intentarlo más tarde.': 'Verifique se o link é válido e não expirou e tente novamente mais tarde.',

  // eslint-disable-next-line max-len
  'El número debe ser un valor entre 0 y 100, con máximo 2 decimales.': 'The number must be a value between 0 and 100, with a max. of 2 decimal places.',
};
