
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VFormColumn from '@/views/professional/expedients/medicalData/forms/parts/VFormColumn.vue';
import api from '@/api';
import { mapState } from 'vuex';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import withTimeout from '@/resources/timeOut';
import FormGroup from '@/api/objects/FormGroup';
import {
  calculateBMR,
  calculateICA,
  calculateICC,
  calculateIMC,
} from '@/helpers/formCalculationHelper';

export default defineComponent({
  name: 'VFormRow',
  components: {
    VFormColumn,
    VButton,
  },
  props: {
    formType: {
      type: String,
      required: true,
    },
    formGroup: {
      type: FormGroup,
      required: true,
    },
    formId: {
      type: Number,
      required: true,
    },
    forceSave: {
      type: Boolean,
      required: false,
      default: true,
    },
    formStructure: {
      type: Object,
      required: true,
    },
    actionButtons: {
      type: Object,
      required: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    suffixClass: {
      type: String,
      required: false,
    },
    prefixClass: {
      type: String,
      required: false,
    },
    adjustInputForValue: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      oldValue: '',
      formValueId: '',
      cancelToken: undefined as CancelTokenSource | undefined,
      timers: {
        updateFormGroup: '',
      },
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
  },
  methods: {
    async updateFormGroup(formGroupId) {
      // With a timer and a timeout of about 250 it should send a request of updating a form-group
      this.timers.updateFormGroup = withTimeout(async () => {
        // The cancelToken should be unique according to the passed formGroupId
        if (this.cancelToken !== undefined) {
          this.cancelToken.cancel();
        }

        this.cancelToken = axios.CancelToken.source();

        await this.doUpdateFormGroup(formGroupId);
      }, this.timers.updateFormGroup, 250);
    },
    async doUpdateFormGroup(formGroupId) {
      // const formGroup: FormGroup | undefined = this.formData.formGroupById(formGroupId);

      // We, before sending it to be stored, calculate the percentages based on existing data
      // TODO: Please, apply this only when 'medidas' form
      this.doAutomations();

      const formGroupJSON = {
        _method: 'PUT',
        form_values: this.formGroup.formValuesFormatted(this.formStructure.form_groups[0].form_values),
      };

      const cancelToken: CancelToken | undefined | any = this.cancelToken?.token;

      try {
        await api.professional.expedients.forms.update(
          this.currentExpedient.id,
          formGroupId,
          formGroupJSON,
          cancelToken,
        );
      } catch (e) {
        if (this.formType === 'medidas') {
          await this.$store.dispatch('expedient/setMeasureFormValue', {
            formId: this.formId,
            formGroupId,
            formValueId: this.formValueId,
            formValueValue: this.oldValue,
          });
        } else {
          await this.$store.dispatch('expedient/setFormValue', {
            formId: this.formId,
            formGroupId,
            formValueId: this.formValueId,
            formValueValue: this.oldValue,
          });
        }

        if (e.response) {
          this.$toast.error(this.$t('Ha ocurrido un error al guardar los cambios'));
        }

        if (!axios.isCancel(e)) {
          this.cancelToken = undefined;

          throw e;
        }
      }
    },
    /**
     * Calculates the percentages according to the existing involved data, settings those new values
     * in the local formGroup to be sent later to the API in order to be stored.
     */
    doAutomations() {
      const weight = this.formGroup.formValueByType('peso');
      const height = this.formGroup.formValueByType('altura');
      const perimetroAbdominal = this.formGroup.formValueByType('perimetro_abdominal');
      const perimetroCadera = this.formGroup.formValueByType('perimetro_cadera');

      const imcFv = this.formGroup.formValueByType('imc');
      const bmrFv = this.formGroup.formValueByType('bmr');
      const icaFv = this.formGroup.formValueByType('ica');
      const iccFv = this.formGroup.formValueByType('icc');

      let imc: string | number = '';
      let bmr: string | number = '';
      let ica: string | number = '';
      let icc: string | number = '';

      // We calculate
      if (weight?.value && height?.value) {
        imc = calculateIMC(parseFloat(weight.value), parseFloat(height.value));
        // eslint-disable-next-line max-len
        bmr = calculateBMR(parseFloat(weight.value), parseFloat(height.value), this.currentExpedient.patient.genre, this.currentExpedient.patient.age);
      }

      if (height?.value && perimetroAbdominal?.value) {
        ica = calculateICA(parseFloat(perimetroAbdominal.value), parseFloat(height.value));
      }

      if (perimetroAbdominal?.value && perimetroCadera?.value) {
        icc = calculateICC(parseFloat(perimetroAbdominal.value), parseFloat(perimetroCadera.value));
      }

      // We update
      if (imcFv) {
        imcFv.value = `${imc}`;
      }
      if (bmrFv) {
        bmrFv.value = `${bmr}`;
      }

      if (icaFv) {
        icaFv.value = `${ica}`;
      }

      if (iccFv) {
        iccFv.value = `${icc}`;
      }
    },
    saveOldValue({
      formValueId,
      value,
    }) {
      this.oldValue = value;
      this.formValueId = formValueId;
    },
  },
});
