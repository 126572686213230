import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b618cbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "nav-container position-fixed bottom-0 start-0"
}
const _hoisted_2 = { class: "nav-actions vertical" }
const _hoisted_3 = { class: "language-selector position-relative" }
const _hoisted_4 = {
  class: "btn btn-primary rounded-pill text-decoration-none p-4 me-12",
  href: "#"
}
const _hoisted_5 = { class: "text text-uppercase" }
const _hoisted_6 = { class: "dropdown" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.formsSettings?.language_options)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("nav", _hoisted_2, [
          _createElementVNode("ul", null, [
            _createElementVNode("li", _hoisted_3, [
              _createElementVNode("a", _hoisted_4, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(// eslint-disable-next-line max-len
                  !!_ctx.formsSettings?.language_options?.find((localeItem) => localeItem.key === _ctx.locale).key
                    // eslint-disable-next-line max-len
                    ? _ctx.formsSettings?.language_options?.find((localeItem) => localeItem.key === _ctx.locale).key
                    : 'es'), 1)
              ]),
              _createElementVNode("ul", _hoisted_6, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formsSettings.language_options, (localeItem, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                    (localeItem.key !== _ctx.locale)
                      ? (_openBlock(), _createElementBlock("li", _hoisted_7, [
                          _createElementVNode("a", {
                            class: "btn btn-primary rounded-pill text-decoration-none p-4 me-8",
                            href: "#",
                            onClick: ($event: any) => (_ctx.locale = localeItem.key)
                          }, [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(localeItem.key), 1)
                          ], 8, _hoisted_8)
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}