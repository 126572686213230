import BaseObject from '@/api/objects/BaseObject';
import Professional from '@/api/objects/Professional';
import Comment from '@/api/objects/Comment';
import Expedient from '@/api/objects/Expedient';

export interface InterConsultationContract {
  title: string;
  description: string;
  status: string;
  statusReason: string;
  statusChangedAt: string;
  patientId: number;
  creatorProfessionalId: number;
  targetProfessionalId: number;
  createdAt: string;
  updatedAt: string;
  expedient: Expedient | null;
  creatorProfessional: Professional | null;
  targetProfessional: Professional | null;
  comments: Comment[] | null;
}

export default class InterConsultation extends BaseObject implements InterConsultationContract {
  get title(): string {
    return this.attributes.title;
  }

  get description(): string {
    return this.attributes.description;
  }

  get status(): string {
    return this.attributes.status;
  }

  get statusReason(): string {
    return this.attributes.status_reason;
  }

  get statusChangedAt(): string {
    return this.attributes.status_changed_at;
  }

  get patientId(): number {
    return this.attributes.patient_id;
  }

  get creatorProfessionalId(): number {
    return this.attributes.creator_professional_id;
  }

  get targetProfessionalId(): number {
    return this.attributes.target_professional_id;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get expedient(): Expedient | null {
    return this.getRelation('expedient');
  }

  get creatorProfessional(): Professional | null {
    return this.getRelation('creator_professional');
  }

  get targetProfessional(): Professional | null {
    return this.getRelation('target_professional');
  }

  get comments(): Comment[] | null {
    return this.getRelation('comments');
  }
}
