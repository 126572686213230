
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import Patient from '@/api/objects/Patient';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import { mapState } from 'vuex';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import ThePatientAddressFieldset
  from '@/views/patient/expedients/parts/ThePatientAddressFieldset.vue';

export default defineComponent({
  name: 'ThePatientPersonalDataModal',
  components: {
    ThePatientAddressFieldset,
    VFormGroupReadOnlyText,
    VButton,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
    ...mapState('app', ['formsSettings']),
    billingAddress() {
      return this.currentExpedient?.patient?.addresses?.find((address) => address.type === 'billing');
    },
    shippingAddress() {
      return this.currentExpedient?.patient?.addresses?.find((address) => address.type === 'shipping');
    },
    identityDocType() {
      // eslint-disable-next-line max-len
      return this.formsSettings.accepted_identification_document_types.find((item) => item.key === this.patient.identityDocType);
    },
    genre() {
      return this.formsSettings.accepted_genre_options.find((item) => item.key === this.patient.genre);
    },
  },
  data() {
    return {
      isLoading: false,
      cleaveOptions: {
        onlyNumeral: {
          numeral: true,
          numericOnly: true,
          delimiter: '',
        },
      },
    };
  },
});
