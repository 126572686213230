import { MutationTree } from 'vuex';
import { PatientState } from '@/store/modules/patient/patient.types';
import Patient from '@/api/objects/Patient';

interface PatientMutations {
  setCurrentPatient(state: PatientState, payload: any): void;
}

const mutations: MutationTree<PatientState> & PatientMutations = {
  setCurrentPatient(state: PatientState, patient: Patient) {
    state.currentPatient = patient;
  },
};

export default mutations;
