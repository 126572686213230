export default function getRouteHome(role: any): string {
  switch (role) {
    case 'Administrador': {
      return 'admin.home';
    }
    case 'Medico': {
      return 'professional.home';
    }
    case 'Nutricionista': {
      return 'professional.home';
    }
    default: {
      return 'patient.home';
    }
  }
}
