import BaseObject from '@/api/objects/BaseObject';
// eslint-disable-next-line import/no-cycle
import ProtocolPhase from '@/api/objects/ProtocolPhase';

export interface ProtocolContract {
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export default class Protocol extends BaseObject implements ProtocolContract {
  get name(): string {
    return this.attributes.name;
  }

  get type(): string {
    return this.attributes.type;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): string {
    return this.attributes.updated_at;
  }

  get phases(): ProtocolPhase[] {
    return this.getRelation('phases');
  }
}
