
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import withTimeout from '@/resources/timeOut';
// eslint-disable-next-line import/no-cycle
import { filtersToQueryParameters, setQueryParameters } from '@/helpers/Url';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VInput from '@/components/vendor/basic/form/parts/VInput.vue';
import VPagination from '@/components/vendor/VPagination.vue';
import Category from '@/api/objects/Category';
import TheAdminVideoCategoryCreate from './TheAdminVideoCategoryCreate.vue';
import TheAdminVideoCategoryDestroy from './TheAdminVideoCategoryDestroy.vue';

export default defineComponent({
  name: 'TheAdminVideoCategoryShow',
  components: {
    VPagination,
    TheAdminVideoCategoryCreate,
    TheAdminVideoCategoryDestroy,
    VInput,
    VButton,
    VTableBody,
    VTableHeader,
    VTable,
  },
  data() {
    return {
      categories: [] as Category[],
      pagination: null as any,
      selectedCategory: null as null | Category,
      modalToShow: null as null | string,
      filters: {
        search: [] as any,
      },
      do_search: true,
      timers: {
        search: '',
      },
    };
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  async mounted() {
    await this.setQueryParametersAsFilters();
    await this.doSearch();
  },
  methods: {
    openProfessionalModal(professional, modalName) {
      this.selectedCategory = professional;
      this.modalToShow = modalName;
    },
    search() {
      this.timers.search = withTimeout(async () => {
        await this.doSearch();
      }, this.timers.search, 250);
    },
    /**
     * Sets the local filters as url query parameters.
     */
    setFiltersAsQueryParameters() {
      setQueryParameters('admin.video-category.index', this.filters);
    },
    /**
     * Detects any query parameter in the url and loads it in the filters (if available)
     *
     * @returns {Promise<void>}
     */
    async setQueryParametersAsFilters() {
      const queryParameters = this.$route.query;

      if (queryParameters.search) {
        this.filters.search = queryParameters.search;
      } else {
        this.filters.search = '';
      }
    },
    async loadCategories(page?: number) {
      try {
        const filters = { ...this.filters } as any;

        // We manage page filter if provided
        if (!!page) {
          filters.page = page;
        }

        const query = filtersToQueryParameters(filters);

        const resp = await api.admin.videoCategory.list(query, true);

        if (!!resp.meta) {
          this.categories = resp.data;

          this.pagination = {
            meta: resp.meta,
            pagination: resp.links,
          };
        } else {
          this.categories = resp;
        }
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las categorías'));
      }
    },
    async doSearch() {
      this.do_search = false;
      await this.setFiltersAsQueryParameters();
      await this.loadCategories();
    },
    async handlePageChangedEvent(page) {
      // We handle page only on the method call to avoid malfunctioning with page filter
      await this.loadCategories(page);
    },
    emptyFilters() {
      this.filters = {
        search: '',
      };

      this.doSearch();
    },
  },
});
