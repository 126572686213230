
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import Form from '@/api/objects/Form';
import TheMeasuresChartView
  from '@/views/patient/expedients/general/measures/parts/TheMeasuresChartView.vue';

export default defineComponent({
  name: 'ThePatientExpedientMeasuresList',
  components: {
    TheMeasuresChartView,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      modalToShow: '',
      error: null,
    };
  },
  computed: {
    ...mapState('patient/expedient', ['currentExpedient']),
    ...mapState('patient/expedient/form', ['measures']),
    ascendentlyOrderedMeasures() {
      /*
       TODO: Please, make this work in just one method. I can't access a method from here nor
       use another computed from another one
       */
      const orderedMeasuresFormGroups = [] as any[];

      // Iterate through all measures forms
      this.measures.forEach((form: Form) => {
        const formGroups = form?.formGroups;
        if (formGroups) {
          formGroups.forEach((formGroup) => {
            orderedMeasuresFormGroups.push({
              formGroup,
              form,
            });
          });
        }
      });

      orderedMeasuresFormGroups.sort((fgFirst, fgSecond) => {
        const firstDate = fgFirst.formGroup.formValueByType('fecha').value;
        const secondDate = fgSecond.formGroup.formValueByType('fecha').value;

        if (firstDate < secondDate) {
          return 1;
        }

        if (firstDate > secondDate) {
          return -1;
        }

        return 0;
      });

      return orderedMeasuresFormGroups;
    },
    descendentlyOrderedMeasures() {
      /*
       TODO: Please, make this work in just one method. I can't access a method from here nor
       use another computed from another one
       */
      const orderedMeasuresFormGroups = [] as any[];

      // Iterate through all measures forms
      this.measures.forEach((form: Form) => {
        const formGroups = form?.formGroups;
        if (formGroups) {
          formGroups.forEach((formGroup) => {
            orderedMeasuresFormGroups.push({
              formGroup,
              form,
            });
          });
        }
      });

      orderedMeasuresFormGroups.sort((fgFirst, fgSecond) => {
        const firstDate = fgFirst.formGroup.formValueByType('fecha').value;
        const secondDate = fgSecond.formGroup.formValueByType('fecha').value;

        if (firstDate < secondDate) {
          return -1;
        }

        if (firstDate > secondDate) {
          return 1;
        }

        return 0;
      });

      return orderedMeasuresFormGroups;
    },
  },
  methods: {
    async loadExpedientMeasures() {
      await this.$store.dispatch('expedient/refreshExpedientMeasures', {
        expedientId: this.currentExpedient.id,
      });
    },
  },
});
