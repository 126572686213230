/**
 * Rounds the provided number to only 2 decimals
 *
 * @param value Value to round.
 */
export const round = (value: number) => Math.round(value * 100) / 100;

/**
 * Calculates the ICC coeficient by the provided EL CLIENTE formula on 20220425.
 * Tamano cintura (waist) / tamano cadera (hip)
 *
 * @param hipPerimeter 'Cadera' perimeter in centimeters.
 * @param waistPerimeter 'Abdomen/cintura' perimeter in centimeters.
 */
export const calculateICC = (hipPerimeter: number, waistPerimeter: number) => {
  let result = 0;

  if (waistPerimeter > 0 && hipPerimeter > 0) {
    result = waistPerimeter / hipPerimeter;
  }

  return round(result);
};

/**
 * Calculates the IMC indicator by the provided EL CLIENTE formula on 20220425.
 *
 * @param weight Weight in kilograms.
 * @param height Height in centimeters.
 */
export const calculateIMC = (weight: number, height: number) => {
  let result = 0;

  if (weight > 0 && height > 0) {
    // Conversion according to the type of measurement in this case from centimeters to meters
    const heightMts = height / 100;
    result = weight / (heightMts * heightMts);
  }

  return round(result);
};

/**
 * Calculates the BMR coeficient by the provided EL CLIENTE formula on 20220425.
 * 'male' gender => 66 + (13,7 x peso en kg) + (5 x altura en cm) - (6,75 x edad en años)
 * 'female' gender => 655 + (9,6 x peso en kg) + (1,8 x altura en cm) - (4,7 x edad en años) by EL CLIENTE 20220425
 *
 * @param weight Weight in kilograms.
 * @param height Height in centimeters.
 * @param genre Only 'male' or 'female' values has calculations.
 * @param age Years of life.
 */
export const calculateBMR = (weight: number, height: number, genre: string, age: number) => {
  let bmr = 0;

  if (!genre || !age) {
    bmr = 0;
  } else {
    switch (genre) {
      case 'male':
        bmr = 66 + (13.7 * weight) + (5 * height) - (6.75 * age);
        break;
      case 'female':
        bmr = 655 + (9.6 * weight) + (1.8 * height) - (4.7 * age);
        break;
      default:
        // We cannot calculate it if a genre that is not 'male' or 'female'
        bmr = 0;
    }
  }

  return round(bmr);
};

/**
 * Calculates the ICA coeficient by an online formula that says it is waistPerimeter / height,
 * because the one provided by EL CLIENTE is the same as IMC.
 *
 * @param waistPerimeter 'Abdomen/Cintura' perimeter.
 * @param height Height in centimeters.
 */
export const calculateICA = (waistPerimeter: number, height: number) => {
  let result = 0;

  if (waistPerimeter > 0 && height > 0) {
    result = waistPerimeter / height;
  }

  return round(result);
};
