
import { defineComponent } from 'vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import { mapState } from 'vuex';
import Form from '@/api/objects/Form';
import TheFormNutritionalAnamnesis
  from '@/views/professional/anamnesis/parts/TheFormNutritionalAnamnesis.vue';

export default defineComponent({
  name: 'TheProfessionalNutritionalAnamnesis',
  components: {
    TheFormNutritionalAnamnesis,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
  },
  data() {
    return {
      modalToShow: '',
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'currentNutritionalAnamnesis']),
    ...mapState('app', ['formsSettings']),
    currentNutritionalAnamnesisStructure(): Form {
      const formStructures = this.formsSettings.forms.find((obj) => obj.type === 'anamnesis_nutricional'
        && obj.version === this.currentNutritionalAnamnesis.version);

      return formStructures.structure;
    },
    lastAnamnesisStructureVersion(): number {
      const formStructures = this.formsSettings.forms.filter((obj) => obj.type === 'anamnesis_nutricional');

      const lastFormStructure = formStructures.reduce((objA, objB) => (objA.version > objB.version
        ? objA : objB));

      const lastFormStructureVersion = lastFormStructure.structure;

      return lastFormStructureVersion.version;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closed');
    },
  },
});
