export default async function auth({
  next,
  store,
}: { next: any, store: any }): Promise<void> {
  const { authUser } = store.state.user;
  if (!authUser) {
    try {
      await store.dispatch('user/setAuthUser');

      if (!authUser) {
        next({ name: 'login' });
      } else {
        next();
      }
    } catch (e) {
      next();
    }
  } else {
    next();
  }
}
