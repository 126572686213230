
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModalTitle',
  props: {
    as: {
      type: String,
      required: false,
      default: 'h5',
    },
  },
});
