
import { defineComponent } from 'vue';
import VLoader from '@/components/vendor/VLoader.vue';

export default defineComponent({
  name: 'VButton',
  components: { VLoader },
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    variant: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: String,
      required: false,
      default: 'sm',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    dataBsDismiss: {
      type: String,
      required: false,
      default: null,
    },
    dataBsToggle: {
      type: String,
      required: false,
      default: null,
    },
    dataBsTarget: {
      type: String,
      required: false,
      default: null,
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
    ariaLabelledby: {
      type: String,
      required: false,
      default: null,
    },
    ariaHaspopup: {
      type: String,
      required: false,
      default: null,
    },
    ariaExpanded: {
      type: String,
      required: false,
      default: null,
    },
    ariaControls: {
      type: String,
      required: false,
      default: null,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    cSize() {
      let ret: string | null = null;

      if (!!this.size) {
        ret = `btn-${this.size}`;
      }

      return ret;
    },
    cVariant() {
      let ret: string | null = null;

      if (!!this.variant) {
        ret = `btn-${this.variant}`;
      }

      return ret;
    },
  },
});
