import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-lowercase" }
const _hoisted_2 = { class: "form" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "col-6"
}
const _hoisted_5 = {
  key: 0,
  class: "yup-errors text-danger p-8"
}
const _hoisted_6 = {
  key: 1,
  class: "col-6"
}
const _hoisted_7 = {
  key: 2,
  class: "col-6"
}
const _hoisted_8 = {
  key: 0,
  class: "yup-errors text-danger p-8"
}
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-4" }
const _hoisted_11 = { class: "col-4" }
const _hoisted_12 = { class: "col-4" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "form-group" }
const _hoisted_17 = { class: "form-label" }
const _hoisted_18 = ["value", "placeholder"]
const _hoisted_19 = {
  key: 0,
  class: "row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_form_group_input_text = _resolveComponent("v-form-group-input-text")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form_group_select = _resolveComponent("v-form-group-select")!
  const _component_v_form_group_input_date = _resolveComponent("v-form-group-input-date")!
  const _component_v_form_group_input_checkbox = _resolveComponent("v-form-group-input-checkbox")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    "class-name": "modal-fullscreen-lg-down",
    onKeypress: _cache[12] || (_cache[12] = _withKeys(_withModifiers(($event: any) => (_ctx.meta.valid ? _ctx.createCalendarEvent() : null), ["prevent"]), ["enter"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Nuevo')) + " ", 1),
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('evento de calendario')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_v_form_group_input_text, {
              value: _ctx.title,
              label: _ctx.$t('Asunto'),
              class: "mb-24",
              "yup-errors-variable": _ctx.errors.title,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.title = $event))
            }, null, 8, ["value", "label", "yup-errors-variable"]),
            _createVNode(_component_v_form_group_select, {
              label: _ctx.$t('Tipo'),
              class: "mb-24",
              "yup-errors-variable": _ctx.errors.type
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_select, {
                  class: _normalizeClass({'is-invalid': !!_ctx.errors.type}),
                  options: _ctx.calendarEventTypeOptions,
                  reduce: returnValue => returnValue.key,
                  "select-on-key-codes": [188, 13],
                  clearable: false,
                  modelValue: _ctx.type,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.type) = $event))
                }, {
                  "no-options": _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                  ]),
                  _: 1
                }, 8, ["class", "options", "reduce", "modelValue"])
              ]),
              _: 1
            }, 8, ["label", "yup-errors-variable"]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.isConsultation)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_v_form_group_select, {
                      label: _ctx.$t('Paciente'),
                      class: "mb-24",
                      "yup-errors-variable": _ctx.errors.patientId
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          class: _normalizeClass({'is-invalid': !!_ctx.errors.patientId}),
                          options: _ctx.availablePatients,
                          reduce: returnValue => returnValue.key,
                          "select-on-key-codes": [188, 13],
                          clearable: false,
                          modelValue: _ctx.patientId,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.patientId) = $event))
                        }, {
                          "no-options": _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                          ]),
                          _: 1
                        }, 8, ["class", "options", "reduce", "modelValue"]),
                        (!_ctx.patientId)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('Obligatorio: Se le asignará la cita a este paciente')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["label", "yup-errors-variable"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isConsultation && !_ctx.isVideoconsulta && _ctx.ownerId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_v_form_group_select, {
                      label: _ctx.$t('Clínica'),
                      class: "mb-24",
                      "yup-errors-variable": _ctx.errors.clinicId
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          class: _normalizeClass({'is-invalid': !!_ctx.errors.clinicId}),
                          options: _ctx.availableClinics,
                          reduce: returnValue => returnValue.key,
                          "select-on-key-codes": [188, 13],
                          clearable: false,
                          modelValue: _ctx.clinicId,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.clinicId) = $event))
                        }, {
                          "no-options": _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                          ]),
                          _: 1
                        }, 8, ["class", "options", "reduce", "modelValue"])
                      ]),
                      _: 1
                    }, 8, ["label", "yup-errors-variable"])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.isConsultation)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_v_form_group_select, {
                      label: _ctx.$t('Profesional'),
                      class: "mb-24",
                      "yup-errors-variable": _ctx.errors.ownerId
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_select, {
                          class: _normalizeClass({'is-invalid': !!_ctx.errors.ownerId}),
                          options: _ctx.availableProfessionals,
                          reduce: returnValue => returnValue.key,
                          "select-on-key-codes": [188, 13],
                          clearable: false,
                          modelValue: _ctx.ownerId,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ownerId) = $event))
                        }, {
                          "no-options": _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                          ]),
                          _: 1
                        }, 8, ["class", "options", "reduce", "modelValue"]),
                        (!_ctx.ownerId)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('Obligatorio: Se le asignará la cita a este profesional')), 1))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["label", "yup-errors-variable"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_v_form_group_input_date, {
                  value: _ctx.date,
                  label: _ctx.$t('Fecha'),
                  class: "mb-24",
                  "yup-errors-variable": _ctx.errors.date,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.date = $event))
                }, null, 8, ["value", "label", "yup-errors-variable"])
              ]),
              (!_ctx.isAllDayActivated)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_v_form_group_select, {
                        label: _ctx.$t('Inicio'),
                        class: "mb-24",
                        "yup-errors-variable": _ctx.errors.startTime
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            class: _normalizeClass({'is-invalid': !!_ctx.errors.startTime}),
                            options: _ctx.startTimeOptions,
                            reduce: returnValue => returnValue.key,
                            "select-on-key-codes": [188, 13],
                            clearable: false,
                            "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.startTime = $event)),
                            modelValue: _ctx.startTime,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.startTime) = $event))
                          }, {
                            "no-options": _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                            ]),
                            _: 1
                          }, 8, ["class", "options", "reduce", "modelValue"])
                        ]),
                        _: 1
                      }, 8, ["label", "yup-errors-variable"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_v_form_group_select, {
                        label: _ctx.$t('Fin'),
                        class: "mb-24",
                        "yup-errors-variable": _ctx.errors.endTime
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_v_select, {
                            class: _normalizeClass({'is-invalid': !!_ctx.errors.endTime}),
                            options: _ctx.endTimeOptions,
                            reduce: returnValue => returnValue.key,
                            "select-on-key-codes": [188, 13],
                            clearable: false,
                            "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (_ctx.endTime = $event)),
                            modelValue: _ctx.endTime,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.endTime) = $event))
                          }, {
                            "no-options": _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('No se han encontrado resultados')), 1)
                            ]),
                            _: 1
                          }, 8, ["class", "options", "reduce", "modelValue"])
                        ]),
                        _: 1
                      }, 8, ["label", "yup-errors-variable"])
                    ])
                  ], 64))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_v_form_group_input_checkbox, {
                  "for-key": "isAllDayActivated",
                  label: _ctx.$t('Durante todo el día'),
                  value: _ctx.isAllDayActivated,
                  class: "'form-check-inline'",
                  "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => (_ctx.handleCheckboxClick($event, 'isAllDayActivated')))
                }, null, 8, ["label", "value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('Descripción')), 1),
                _createElementVNode("textarea", {
                  class: "form-control",
                  rows: "5",
                  value: _ctx.description,
                  placeholder: _ctx.$t('Introduce una descripción aquí...'),
                  onInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.description = $event.target.value))
                }, null, 40, _hoisted_18)
              ])
            ]),
            (_ctx.isConsultation)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createVNode(_component_v_alert, {
                    variant: "primary",
                    role: "alert"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(// eslint-disable-next-line max-len
              _ctx.$t('Al crear esta {tipo_consulta} se mandará un SMS al paciente con la hora indicada.',{ tipo_consulta: _ctx.consultationType })), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "link-secondary",
            class: "me-24",
            "aria-label": _ctx.$t('Cerrar'),
            "data-bs-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cerrar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"]),
          _createVNode(_component_v_button, {
            variant: "secondary",
            "aria-label": _ctx.$t('Añadir'),
            disabled: !_ctx.meta.valid || _ctx.accepted,
            "is-loading": _ctx.accepted,
            onClick: _ctx.createCalendarEvent
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Añadir')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "disabled", "is-loading", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}