
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'TheProfessionalConsultationScroll',
  computed: {
    ...mapState('expedient', ['currentExpedient', 'consultations']),
  },
});
