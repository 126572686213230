import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e828ea2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-end mb-16" }
const _hoisted_2 = { class: "fw-bold me-4" }
const _hoisted_3 = { class: "purchase-authorization-container mb-16" }
const _hoisted_4 = { class: "h6 mb-8 fw-bold" }
const _hoisted_5 = { class: "content mb-0" }
const _hoisted_6 = { class: "fw-bold" }
const _hoisted_7 = { class: "content mb-0" }
const _hoisted_8 = { class: "fw-bold" }
const _hoisted_9 = { class: "content mb-0" }
const _hoisted_10 = { class: "fw-bold" }
const _hoisted_11 = { class: "content mb-0" }
const _hoisted_12 = { class: "mb-0 fw-bold" }
const _hoisted_13 = { class: "d-flex align-items-center" }
const _hoisted_14 = { class: "mb-0 me-12" }
const _hoisted_15 = { class: "content mb-0" }
const _hoisted_16 = { class: "fw-bold" }
const _hoisted_17 = { class: "content mb-0" }
const _hoisted_18 = { class: "fw-bold" }
const _hoisted_19 = { class: "content mb-0" }
const _hoisted_20 = { class: "fw-bold" }
const _hoisted_21 = { class: "content mb-0" }
const _hoisted_22 = { class: "fw-bold" }
const _hoisted_23 = { class: "product-list" }
const _hoisted_24 = { class: "h6 mb-8 fw-bold" }
const _hoisted_25 = { class: "text-xs fw-semi row gx-8 px-16 mb-4" }
const _hoisted_26 = { class: "col-8" }
const _hoisted_27 = { class: "col-4" }
const _hoisted_28 = { class: "row gx-4 text-center" }
const _hoisted_29 = { class: "col" }
const _hoisted_30 = { class: "col" }
const _hoisted_31 = { class: "col" }
const _hoisted_32 = { class: "col-8" }
const _hoisted_33 = { class: "me-4" }
const _hoisted_34 = { class: "col-4" }
const _hoisted_35 = { class: "row gx-4 text-center" }
const _hoisted_36 = { class: "col" }
const _hoisted_37 = { class: "col text-nowrap" }
const _hoisted_38 = { class: "col text-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    "class-name": "modal-fullscreen-lg-down",
    size: "lg"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Autorización de compra')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('Fecha creación:')), 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$str.formatDate(_ctx.purchaseAuthorization.createdAt)), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('Datos de la autorización de compra:')), 1),
            _createElementVNode("p", _hoisted_5, [
              _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('Profesional:')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.purchaseAuthorization.professional.user?.name) + " " + _toDisplayString(_ctx.purchaseAuthorization.professional.user?.surname), 1)
            ]),
            _createElementVNode("p", _hoisted_7, [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('Paciente:')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.purchaseAuthorization.patient.user?.name) + " " + _toDisplayString(_ctx.purchaseAuthorization.patient.user?.surname), 1)
            ]),
            _createElementVNode("p", _hoisted_9, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('Fecha de vencimiento:')), 1),
              _createTextVNode(" " + _toDisplayString(_ctx.$str.formatDate(_ctx.purchaseAuthorization.dueDate)), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('Estado:')), 1),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t(_ctx.status)), 1)
              ])
            ]),
            (_ctx.purchaseAuthorization.protocolPhaseTreatment)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("p", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t('Protocolo:')), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.purchaseAuthorization.protocolPhaseTreatment.protocolPhase.protocol.name), 1)
                  ]),
                  _createElementVNode("p", _hoisted_17, [
                    _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.$t('Fase:')), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.purchaseAuthorization.protocolPhaseTreatment.protocolPhase.name), 1)
                  ])
                ], 64))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_19, [
              _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('Subtotal')) + ":", 1),
              _createTextVNode(" " + _toDisplayString((_ctx.purchaseAuthorization.subtotal / 100)) + _toDisplayString(_ctx.$t('€')), 1)
            ]),
            _createElementVNode("p", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('Impuestos')) + ":", 1),
              _createTextVNode(" " + _toDisplayString((_ctx.purchaseAuthorization.subtotalTaxes / 100)) + _toDisplayString(_ctx.$t('€')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("p", _hoisted_24, _toDisplayString(_ctx.$t('Productos')), 1),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('Nombre')), 1),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('Cantidad')), 1),
                  _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('Subtotal')), 1),
                  _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('Impuestos')), 1)
                ])
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.purchaseAuthorization.products, (product) => {
              return (_openBlock(), _createElementBlock("div", {
                key: product.id,
                class: "product-container-item align-items-center py-8 px-16 row gx-8"
              }, [
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("span", _hoisted_33, _toDisplayString(product.name) + " (" + _toDisplayString(product?.type) + ") -", 1),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('{doses} dosis durante {days} días', {
                  doses: product.pivot.doses,
                  days: product.pivot.days,
                })), 1)
                ]),
                _createElementVNode("div", _hoisted_34, [
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, "x" + _toDisplayString(product.pivot.quantity), 1),
                    _createElementVNode("div", _hoisted_37, _toDisplayString(!!product.pivot.subtotal ? (product.pivot.subtotal / 100) : 0) + _toDisplayString(_ctx.$t('€')), 1),
                    _createElementVNode("div", _hoisted_38, _toDisplayString(!!product.pivot.subtotal_taxes ? (product.pivot.subtotal_taxes / 100) : 0) + _toDisplayString(_ctx.$t('€')), 1)
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            "aria-label": this.$t('Volver'),
            variant: "secondary",
            onClick: _ctx.closeModal
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Volver')), 1)
            ]),
            _: 1
          }, 8, ["aria-label", "onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}