import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-4" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-4" }
const _hoisted_6 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_form_group_read_only_text = _resolveComponent("v-form-group-read-only-text")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, { "class-name": "modal-fullscreen-lg-down" }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Evento')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_form_group_read_only_text, {
              label: _ctx.$t('Asunto'),
              text: _ctx.calendarEvent.title
            }, null, 8, ["label", "text"]),
            _createVNode(_component_v_form_group_read_only_text, {
              label: _ctx.$t('Tipo'),
              text: _ctx.consultationType.label
            }, null, 8, ["label", "text"]),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_v_form_group_read_only_text, {
                  label: _ctx.$t('Fecha'),
                  text: _ctx.$str.formatDate(_ctx.calendarEvent.startDatetime)
                }, null, 8, ["label", "text"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_v_form_group_read_only_text, {
                  label: _ctx.$t('Inicio'),
                  text: _ctx.$str.formatDate(_ctx.calendarEvent.startDatetime, 'HH:mm')
                }, null, 8, ["label", "text"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_v_form_group_read_only_text, {
                  label: _ctx.$t('Fin'),
                  text: _ctx.$str.formatDate(_ctx.calendarEvent.endDatetime, 'HH:mm')
                }, null, 8, ["label", "text"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_v_form_group_read_only_text, {
                label: _ctx.$t('Descripción'),
                text: _ctx.$t(`Tienes programada una consulta con ${_ctx.professionalName}`)
              }, null, 8, ["label", "text"])
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "link-secondary",
            class: "me-24",
            "aria-label": _ctx.$t('Cancelar'),
            "data-bs-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cancelar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}