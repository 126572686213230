
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import api from '@/api';
import VLoader from '@/components/vendor/VLoader.vue';
import Expedient from '@/api/objects/Expedient';

export default defineComponent({
  name: 'ThePatientListProfessionals',
  components: {
    VLoader,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      expedients: [] as Expedient[],
      status: 'loading',
    };
  },
  async mounted() {
    await this.loadProfessional();
  },
  methods: {
    async loadProfessional() {
      try {
        this.expedients = await api.patient.expedients.index();

        this.status = 'loaded';
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar los expedientes'));
      }
    },
  },
});
