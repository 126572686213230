
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import VFormGroupTextarea from '@/components/vendor/basic/form/VFormGroupTextarea.vue';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import api from '@/api';
import Video from '@/api/objects/Video';
import vSelect from 'vue-select';
import VFormGroupSelect from '@/components/vendor/basic/form/VFormGroupSelect.vue';
import useCapabilities from '@/resources/capabilities';

export default defineComponent({
  name: 'TheProfessionalVideoShow',
  components: {
    VFormGroupSelect,
    VFormGroupReadOnlyText,
    VFormGroupTextarea,
    vSelect,
  },
  props: {
    video: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  data() {
    return {
      isLoading: true,
      videoLoaded: {} as Video,
      modalToShow: null as null | string,
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
  },
  async created() {
    await this.loadVideo();
  },
  methods: {
    async loadVideo() {
      try {
        this.isLoading = true;

        this.videoLoaded = await api.professional.video.show(parseInt(this.video, 10));

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;

        this.$toast.error(this.$t('Ha ocurrido un error al cargar el vídeo.'));
      }
    },
    createUrl() {
      const aux = this.videoLoaded.url.substring(this.videoLoaded.url.indexOf('.') + 1);
      const urlId = aux.substring(aux.indexOf('/') + 1);

      return `https://player.vimeo.com/video/${urlId}`;
    },
  },
});
