import AbstractService from '@/api/services/AbstractService';
import CalendarEvent from '@/api/objects/CalendarEvent';

class PatientCalendarService extends AbstractService {
  public async all(): Promise<CalendarEvent[]> {
    return this.request('get', this.buildPath('patient-zone/calendar-events'));
  }
}

export default new PatientCalendarService();
