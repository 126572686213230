import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 1,
  class: "invalid-feedback mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_input_datetime_local = _resolveComponent("v-input-datetime-local")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass(["form-label", {'text-disabled': _ctx.disabled}])
        }, _toDisplayString(_ctx.$t(_ctx.label)), 3))
      : _createCommentVNode("", true),
    _createVNode(_component_v_input_datetime_local, {
      class: _normalizeClass([this.inputClass, {'is-invalid': !!_ctx.yupErrorsVariable}]),
      disabled: _ctx.disabled,
      value: _ctx.value,
      "onUpdate:value": _ctx.emitValue
    }, null, 8, ["class", "disabled", "value", "onUpdate:value"]),
    (!!_ctx.yupErrorsVariable)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.yupErrorsVariable), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}