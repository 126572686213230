
import VAlert from '@/components/vendor/basic/alert/VAlert.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AlertDivLayout',
  components: {
    VAlert,
  },
});
