import { POSITION, TYPE, useToast } from 'vue-toastification';
import { App, Plugin } from 'vue';
import 'vue-toastification/src/scss/index.scss';

interface ToastOptions {
  timeout?: number,
  position?: any,
}

class Toast {
  success(message = 'Operación realizada con éxito',
    options: ToastOptions = {
      position: POSITION.TOP_RIGHT,
      timeout: 5000,
    }) {
    const toast = useToast();

    toast(message, {
      type: TYPE.SUCCESS,
      position: options?.position,
      timeout: options?.timeout,
    });
  }

  info(message = 'Mensaje de información', options: ToastOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: 5000,
  }) {
    const toast = useToast();

    toast(message, {
      type: TYPE.INFO,
      position: options.position,
      timeout: options.timeout,
    });
  }

  warning(message = 'Mensaje de adventencia', options: ToastOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: 5000,
  }) {
    const toast = useToast();

    toast(message, {
      type: TYPE.WARNING,
      position: options.position,
      timeout: options.timeout,
    });
  }

  error(message = 'Ha ocurrido un error', options: ToastOptions = {
    position: POSITION.TOP_RIGHT,
    timeout: 5000,
  }) {
    const toast = useToast();

    toast(message, {
      type: TYPE.ERROR,
      icon: 'icon icon-alarm',
      position: options.position,
      timeout: options.timeout,
    });
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $toast: Toast;
  }
}

const createToast: Plugin = {
  install(app: App) {
    app.config.globalProperties.$toast = new Toast();
  },
};

export default createToast;
