
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import useCapabilities from '@/resources/capabilities';
import Consultation from '@/api/objects/Consultation';

export default defineComponent({
  name: 'TheAdminHome',
  computed: {
    ...mapState('user', ['authUser']),
  },
  data() {
    return {
      status: 'loading',
      user_id: 3,
      consultations: [] as Consultation[],
    };
  },
  async mounted() {
    this.status = 'loaded';
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
});
