import AbstractService from '@/api/services/AbstractService';
import CalendarEvent from '@/api/objects/CalendarEvent';

class CalendarEventService extends AbstractService {
  public async all(): Promise<CalendarEvent[]> {
    return this.request('get', this.buildPath('admin/calendar-events/author'));
  }

  /**
   * Creates a consultation calendar event as if it was created by the professional. It opens
   * an expedient between professional and patient.
   *
   * @param calendarEventData
   */
  public async postStoreConsultationEventCalendar(calendarEventData: any): Promise<CalendarEvent> {
    return this.request('post', this.buildPath('admin/calendar-event/consultation'), [], calendarEventData);
  }

  public async update(calendarEventId: number, calendarEventData: any): Promise<CalendarEvent> {
    return this.request('put', this.buildPath('admin/calendar-event/{calendarEventId}',
      {
        calendarEventId,
      }), [], calendarEventData);
  }

  public async delete(calendarEventId: number) {
    return this.request('delete', this.buildPath('admin/calendar-event/{calendarEventId}', {
      calendarEventId,
    }));
  }
}

export default new CalendarEventService();
