import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-4" }
const _hoisted_5 = { class: "col-5" }
const _hoisted_6 = { class: "col-3" }
const _hoisted_7 = { class: "col-4" }
const _hoisted_8 = { class: "col-4" }
const _hoisted_9 = { class: "col-4" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col" }
const _hoisted_14 = { class: "form-group" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-6" }
const _hoisted_17 = { class: "col-6" }
const _hoisted_18 = {
  key: 0,
  class: "form-group"
}
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = { class: "row" }
const _hoisted_22 = {
  key: 0,
  class: "row"
}
const _hoisted_23 = {
  key: 1,
  class: "d-flex table-responsive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_modal_title = _resolveComponent("modal-title")!
  const _component_modal_header = _resolveComponent("modal-header")!
  const _component_v_loader = _resolveComponent("v-loader")!
  const _component_v_form_group_read_only_text = _resolveComponent("v-form-group-read-only-text")!
  const _component_v_button = _resolveComponent("v-button")!
  const _component_v_table_header = _resolveComponent("v-table-header")!
  const _component_v_table = _resolveComponent("v-table")!
  const _component_modal_body = _resolveComponent("modal-body")!
  const _component_modal_footer = _resolveComponent("modal-footer")!
  const _component_v_modal = _resolveComponent("v-modal")!

  return (_openBlock(), _createBlock(_component_v_modal, {
    size: "xl",
    "class-name": "modal-fullscreen-lg-down"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_modal_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_modal_title, { as: "h4" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Paciente')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_modal_body, null, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_v_loader, { key: 0 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("fieldset", _hoisted_2, [
                    _createElementVNode("legend", null, _toDisplayString(_ctx.$t('Datos básicos')), 1),
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Nombre'),
                          text: _ctx.patient.user.name
                        }, null, 8, ["label", "text"])
                      ]),
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Apellidos'),
                          text: _ctx.patient.user.surname
                        }, null, 8, ["label", "text"])
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Género'),
                          text: _ctx.genre
                        }, null, 8, ["label", "text"])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Tipo doc. identificativo'),
                          text: _ctx.identityDocType
                        }, null, 8, ["label", "text"])
                      ]),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Nº doc. identificativo'),
                          text: _ctx.patient.nif
                        }, null, 8, ["label", "text"])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Fecha de nacimiento'),
                          text: _ctx.$str.formatDate(_ctx.patient.bornDate)
                        }, null, 8, ["label", "text"])
                      ])
                    ])
                  ]),
                  _createElementVNode("fieldset", _hoisted_10, [
                    _createElementVNode("legend", null, _toDisplayString(_ctx.$t('Personificación')), 1),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("p", null, _toDisplayString(// eslint-disable-next-line max-len
                  _ctx.$t('Puedes iniciar sesión como si fueses el paciente en cuestión para visualizar lo mismo que ve ese profesional y así poder comprobar errores que reporta, validar funcionalidades, etc.Entrarás a la plataforma como si hubieses iniciado sesión con sus credenciales. Ten cuidado porque los cambios que hagas también serán definitivos como si hubieran sido suyos.')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_v_button, {
                          variant: "primary",
                          onClick: _ctx.impersonate
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('Iniciar sesión como el paciente')) + " " + _toDisplayString(_ctx.patient.user.name) + " " + _toDisplayString(_ctx.patient.user.surname), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ])
                    ])
                  ]),
                  _createElementVNode("fieldset", _hoisted_14, [
                    _createElementVNode("legend", null, _toDisplayString(_ctx.$t('Contacto')), 1),
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", _hoisted_16, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Correo electrónico'),
                          text: _ctx.patient.user.email
                        }, null, 8, ["label", "text"])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_v_form_group_read_only_text, {
                          label: _ctx.$t('Teléfono'),
                          text: _ctx.patient.user.phoneNumber
                        }, null, 8, ["label", "text"])
                      ])
                    ])
                  ]),
                  (_ctx.patient.user?.phoneNumbers?.length > 0)
                    ? (_openBlock(), _createElementBlock("fieldset", _hoisted_18, [
                        _createElementVNode("legend", null, _toDisplayString(_ctx.$t('Otros teléfonos')), 1),
                        _createElementVNode("div", _hoisted_19, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patient.user.phoneNumbers, (phone, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "col-6",
                              key: index
                            }, [
                              _createVNode(_component_v_form_group_read_only_text, {
                                label: _ctx.$t('Teléfono'),
                                text: phone
                              }, null, 8, ["label", "text"])
                            ]))
                          }), 128))
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("fieldset", _hoisted_20, [
                  _createElementVNode("legend", null, _toDisplayString(_ctx.$t('Profesionales relacionados')), 1),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t('Este es el listado de profesionales que han atendido o están atendiendo a este paciente.')), 1)
                  ]),
                  (_ctx.patient.expedients.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t('Este paciente no tiene expedientes')), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                        _createVNode(_component_v_table, { class: "table" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_table_header, null, {
                              default: _withCtx(() => [
                                _createElementVNode("tr", null, [
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('Profesional')), 1),
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('Especialidad')), 1),
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('Inicio')), 1),
                                  _createElementVNode("th", null, _toDisplayString(_ctx.$t('Estado RGPD')), 1)
                                ]),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.patient.expedients, (expedient) => {
                                  return (_openBlock(), _createElementBlock("tr", {
                                    key: expedient.id
                                  }, [
                                    _createElementVNode("td", null, _toDisplayString(expedient.professional.name) + " " + _toDisplayString(expedient.professional.surname), 1),
                                    _createElementVNode("td", null, _toDisplayString(expedient.professional.speciality) + " (" + _toDisplayString(expedient.professional.subspeciality) + ")", 1),
                                    _createElementVNode("td", null, _toDisplayString(_ctx.$str.formatDate(expedient.createdAt)), 1),
                                    _createElementVNode("td", null, _toDisplayString(expedient.rgpdConfirmedAt !== null ? _ctx.$t('Confirmada') : _ctx.$t('Pendiente')), 1)
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]))
                ])
              ], 64))
        ]),
        _: 1
      }),
      _createVNode(_component_modal_footer, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_button, {
            variant: "link-secondary",
            class: "me-24",
            "aria-label": this.$t('Cancelar'),
            "data-bs-dismiss": "modal"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Cancelar')), 1)
            ]),
            _: 1
          }, 8, ["aria-label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}