import AbstractService from '@/api/services/AbstractService';
import Product from '@/api/objects/Product';

class ProductService extends AbstractService {
  public async all(): Promise<Product[]> {
    return this.request('get', this.buildPath('products'));
  }

  public async store(product: any) {
    return this.request('post', this.buildPath('product'), [], product);
  }
}

export default new ProductService();
