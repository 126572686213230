import AbstractService from '@/api/services/AbstractService';
import Protocol from '@/api/objects/Protocol';

class ProtocolService extends AbstractService {
  public async all(): Promise<Protocol[]> {
    return this.request('get', this.buildPath('protocols'));
  }
}

export default new ProtocolService();
