
import { defineComponent } from 'vue';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import api from '@/api';
import ResourceCategory from '@/api/objects/ResourceCategory';

export default defineComponent({
  name: 'TheAdminResourceCategoryDestroy',
  components: {
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
  },
  emits: ['closed', 'resourceCategoryDestroyed'],
  props: {
    category: {
      type: ResourceCategory,
      required: true,
    },
  },
  methods: {
    async destroyResourceCategory() {
      try {
        await api.admin.resourceCategory.delete(this.category.id);

        await this.$modal.info({
          title: this.$t('Categoría eliminada'),
          text: this.$t('Se ha eliminado la categoría con éxito.'),
        });

        this.$emit('resourceCategoryDestroyed');
        this.closeModal();
        await this.$router.push({ name: 'admin.video.index' });
        await this.$router.push({ name: 'admin.resource-category.index' });
      } catch (e) {
        this.$toast.error(e.response?.data?.message);
      }
    },
    closeModal() {
      this.$emit('closed');
    },
  },
});
