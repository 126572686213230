import BaseObject from '@/api/objects/BaseObject';
// eslint-disable-next-line import/no-cycle
import ProductPurchaseAuthorization from '@/api/objects/ProductPurchaseAuthorization';

export interface ProductContract {
  type: string;
  name: string;
  status: string;
  productId: number;
  sku: string;
  desc: string;
  pivot: any;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  productPurchaseAuthorization: ProductPurchaseAuthorization
}

export default class Product extends BaseObject implements ProductContract {
  get type(): string {
    return this.attributes.type;
  }

  get name(): string {
    return this.attributes.name;
  }

  get desc(): string {
    return this.attributes.desc;
  }

  get status(): string {
    return this.attributes.status;
  }

  get productId(): number {
    return this.attributes.product_id;
  }

  get sku(): string {
    return this.attributes.sku;
  }

  get pivot(): any {
    return this.attributes.pivot;
  }

  get createdAt(): string {
    return this.attributes.created_at;
  }

  get updatedAt(): any {
    return this.attributes.updated_at;
  }

  get deletedAt(): any {
    return this.attributes.deleted_at;
  }

  get productPurchaseAuthorization(): ProductPurchaseAuthorization {
    return this.getRelation('product_purchase_authorization');
  }
}
