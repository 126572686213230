import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import {
  PurchaseAuthorizationState,
} from '@/store/modules/patient/expedient/modules/purchaseAuthorization/purchaseAuthorization.types';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';
import api from '@/api';

type Context = ActionContext<PurchaseAuthorizationState, State>;

const actions: ActionTree<PurchaseAuthorizationState, State> = {
  async getCurrentPurchaseAuthorization({
    commit,
  }: Context, {
    purchaseAuthorization,
  }: { purchaseAuthorization: PurchaseAuthorization }) {
    if (!purchaseAuthorization) {
      commit('setCurrentPurchaseAuthorization', null);
    } else {
      commit('setCurrentPurchaseAuthorization', purchaseAuthorization);
    }
  },
  async refreshPurchaseAuthorizations({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number }) {
    // eslint-disable-next-line max-len
    const purchasesAuthorizations: PurchaseAuthorization[] = await api.patient.expedient.purchaseAuthorizations.all(expedientId);
    commit('setPurchaseAuthorizations', purchasesAuthorizations);
  },

};

export default actions;
