
import { defineComponent } from 'vue';
import useCapabilities from '@/resources/capabilities';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTableBody from '@/components/vendor/basic/table/VTableBody.vue';
import { mapState } from 'vuex';
import VButton from '@/components/vendor/basic/button/VButton.vue';
import ThePurchaseAuthorizationCreate
  from '@/views/professional/purchaseAuthorization/parts/ThePurchaseAuthorizationCreate.vue';
import { POSITION } from 'vue-toastification';
import ThePurchaseAuthorizationShow
  from '@/views/professional/purchaseAuthorization/parts/ThePurchaseAuthorizationShow.vue';
import api from '@/api';

export default defineComponent({
  name: 'ThePurchaseAuthorizationList',
  components: {
    ThePurchaseAuthorizationShow,
    ThePurchaseAuthorizationCreate,
    VButton,
    VTableBody,
    VTableHeader,
    VTable,
  },
  data() {
    return {
      modalToShow: null as any,
    };
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'purchaseAuthorizations']),
    ...mapState('treatment', ['currentTreatment']),
    ...mapState('app', ['formsSettings']),
  },
  methods: {
    async showPurchaseAuthorizationShowModal(purchaseAuthorization) {
      // Force to load the purchase authorization due to a problem with pivot models
      // eslint-disable-next-line max-len
      purchaseAuthorization = await api.professional.purchaseAuthorizations.get(this.currentExpedient.id, purchaseAuthorization.id);

      await this.setCurrentPurchase(purchaseAuthorization);
      this.modalToShow = 'the-purchase-authorization-show';
    },
    async showPurchaseAuthorizationCreateModal() {
      // If no current treatment we cannot create it
      if (!this.currentTreatment && !this.currentExpedient.professional.canFreelyAuthorize) {
        // TODO: Move this to a helper as it is repeated in different components
        await this.$modal.info({
          title: this.$t('¡Uups!'),
          // eslint-disable-next-line max-len
          text: this.$t('El paciente no tiene un tratamiento activo, por lo que no podemos crear una autorización. Inicia un tratamiento para poder crearla. Puedes iniciar un tratamiento respondiendo una anamnesis.'),
          rightButtonClasses: 'btn-secondary',
          acceptButtonText: this.$t('Cerrar'),
          leftButtonText: this.$t('Volver al panel'),
        });
      } else {
        this.modalToShow = 'the-purchase-authorization-create';
      }
    },
    purchase(purchaseId) {
      return this.purchaseAuthorizations.find((pa) => pa.id === purchaseId);
    },
    async loadPurchaseAuthorizations() {
      try {
        await this.$store.dispatch('expedient/refreshPurchaseAuthorizations', {
          expedientId: this.currentExpedient.id,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al cargar las autorizaciones de compra'), {
          position: POSITION.BOTTOM_RIGHT,
        });
      }
    },
    async setCurrentPurchase(purchaseAuthorization) {
      await this.$store.dispatch('purchaseAuthorization/getCurrentPurchaseAuthorization', {
        purchaseAuthorization,
      });
    },
    async resendLink(purchaseAuthorization) {
      await this.$modal.confirm({
        title: this.$t('Reenviar enlace de compra'),
        // eslint-disable-next-line max-len
        text: this.$t('Se va a enviar un nuevo enlace de compra al paciente y se anularán los anteriores. Desde este nuevo enlace podrá adquirir los productos. ¿Estás seguro?'),
        confirmButtonText: this.$t('Reenviar'),
        confirmButtonCallback: () => this.doResendPurchaseLink(purchaseAuthorization),
      });
    },
    async doResendPurchaseLink(purchaseAuthorization) {
      try {
        const response = await api.professional.purchaseAuthorizations.resendLink(
          purchaseAuthorization.expedientId,
          purchaseAuthorization.id,
        );

        this.$toast.success(this.$t('Se ha enviado un nuevo enlace de compra correctamente.'));

        await this.$store.dispatch('expedient/refreshPurchaseAuthorizations', {
          expedientId: response.expedientId,
        });
      } catch (e) {
        this.$toast.error(this.$t('Ha ocurrido un error al enviar un nuevo enlace de la autorización de compra'));
      }
    },
    status(purchaseAuthorization) {
      // eslint-disable-next-line max-len
      const type = this.formsSettings.purchase_authorization_statuses.find((o) => o.key === purchaseAuthorization?.status);

      return type.label;
    },
  },
});
