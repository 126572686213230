import { MutationTree } from 'vuex';
import { ExpedientState } from '@/store/modules/expedient/expedient.types';
import Expedient from '@/api/objects/Expedient';
import Form from '@/api/objects/Form';
import Consultation from '@/api/objects/Consultation';
import Treatment from '@/api/objects/Treatment';
import FormGroup from '@/api/objects/FormGroup';
import PurchaseAuthorization from '@/api/objects/PurchaseAuthorization';
import Media from '@/api/objects/Media';

interface ExpedientMutations {
  setStatus(state: ExpedientState, status: string): void;

  setExpedient(state: ExpedientState, payload: any): void;

  setForms(state: ExpedientState, forms: Form[]): void;

  setMeasures(state: ExpedientState, measures: Form[]): void;

  setConsultations(state: ExpedientState, consultations: Consultation[]): void;

  setAnamnesis(state: ExpedientState, anamnesis: Form): void;

  setNutritionalAnamnesis(state: ExpedientState, nutritionalAnamnesis: Form): void;

  setAnamnesisFormValue(state: ExpedientState, {
    formGroupKey,
    formValueKey,
    formValueValue,
  }): void;

  setFormValue(state: ExpedientState, {
    formId,
    formGroupId,
    formValueId,
    formValueValue,
  }): void;

  setMeasureFormValue(state: ExpedientState, {
    formId,
    formGroupId,
    formValueId,
    formValueValue,
  }): void;

  setExpedientSection(state: ExpedientState, expedientSection: string): void;

  setPurchaseAuthorizations(state: ExpedientState, purchaseAuthorizations: PurchaseAuthorization[]): void;
}

const mutations: MutationTree<ExpedientState> & ExpedientMutations = {
  setStatus(state: ExpedientState, status: string) {
    state.status = status;
  },
  setExpedient(state: ExpedientState, expedient: Expedient) {
    state.currentExpedient = expedient;
  },
  setForms(state: ExpedientState, forms: Form[]) {
    state.forms = forms;
  },
  setMeasures(state: ExpedientState, measures: Form[]) {
    state.measures = measures;
  },
  setTreatments(state: ExpedientState, treatments: Treatment[]) {
    state.treatments = treatments;
  },
  setConsultations(state: ExpedientState, consultations: Consultation[]) {
    state.consultations = consultations;
  },
  setAnamnesis(state: ExpedientState, anamnesis: Form) {
    state.currentAnamnesis = anamnesis;
  },
  setNutritionalAnamnesis(state: ExpedientState, nutritionalAnamnesis: Form) {
    state.currentNutritionalAnamnesis = nutritionalAnamnesis;
  },
  setAnamnesisFormValue(state: ExpedientState, {
    formGroupKey,
    formValueKey,
    formValueValue,
  }) {
    // Iterate over state.currentAnamnesis.formGroups until we find 'formGroupKey'
    const fg = state.currentAnamnesis?.formGroupByName(formGroupKey);
    // Then, we iterate over that formGroup.formValues until we find 'formValueKey'
    const fv = fg?.formValueByType(formValueKey);
    if (!!fv) {
      // Then, we set $formValueValue as that formValue value
      fv.value = formValueValue;
    }
  },
  setNutritionalAnamnesisFormValue(state: ExpedientState, {
    formGroupKey,
    formValueKey,
    formValueValue,
  }) {
    // Iterate over state.currentAnamnesis.formGroups until we find 'formGroupKey'
    const fg = state.currentNutritionalAnamnesis?.formGroupByName(formGroupKey);
    // Then, we iterate over that formGroup.formValues until we find 'formValueKey'
    const fv = fg?.formValueByType(formValueKey);
    if (!!fv) {
      // Then, we set $formValueValue as that formValue value
      fv.value = formValueValue;
    }
  },
  setFormValue(state: ExpedientState, {
    formId,
    formGroupId,
    formValueId,
    formValueValue,
  }) {
    // Iterate over state.forms until we find 'formId'
    const expedientForms = state.forms.find((f) => f.id === formId);

    // Iterate over expedientForms.formGroupById until we find 'formGroupId'
    const formGroup: FormGroup | undefined = expedientForms?.formGroupById(formGroupId);

    // Then, we iterate over that formGroup.formValues until we find 'formValueId'
    const formValue = formGroup?.formValueById(formValueId);

    if (!!formValue) {
      formValue.value = formValueValue;
    }
  },
  setMeasureFormValue(state: ExpedientState, {
    formId,
    formGroupId,
    formValueId,
    formValueValue,
  }) {
    // Iterate over state.forms until we find 'formId'
    const expedientMeasures = state.measures?.find((f) => f.id === formId);

    // Iterate over expedientForms.formGroupById until we find 'formGroupId'
    const formGroup: FormGroup | undefined = expedientMeasures?.formGroupById(formGroupId);

    // Then, we iterate over that formGroup.formValues until we find 'formValueId'
    const formValue = formGroup?.formValueById(formValueId);

    if (!!formValue) {
      formValue.value = formValueValue;
    }
  },
  setExpedientSection(state: ExpedientState, expedientSection: string) {
    state.expedientSection = expedientSection;
  },
  setPurchaseAuthorizations(state: ExpedientState, purchaseAuthorizations: PurchaseAuthorization[]) {
    state.purchaseAuthorizations = purchaseAuthorizations;
  },
  setMedia(state: ExpedientState, media: Media[]) {
    state.media = media;
  },
};

export default mutations;
