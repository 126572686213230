import AbstractService from '@/api/services/AbstractService';
import Category from '@/api/objects/Category';

class VideoCategoryService extends AbstractService {
  public async all(): Promise<Category[]> {
    return this.request('get', this.buildPath('video-categories'));
  }
}

export default new VideoCategoryService();
