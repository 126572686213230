<template>
  <nav :aria-label="this.$t('Navigation example')">
    <ul class="pagination">
      <li class="page-item" v-for="(item, key) in pagination" :key="key">
        <button type="button" class="btn page-link"
                :class="{'active': item.active}" @click="setPage(item.label, key)"
                :disabled="item.url === null">
          <span v-html="item.label"></span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'VPagination',
  props: {
    meta: {
      required: true,
    },
    pagination: {
      required: true,
    },
  },
  methods: {
    async setPage(page, key) {
      if (key === Object.keys(this.pagination).length - 1) {
        page = this.meta.current_page + 1;
      } else if (key === 0) {
        page = this.meta.current_page - 1;
      }

      this.$emit('page-changed', page);
    },
  },
};
</script>
