
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VInputDate',
  props: {
    size: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      date: '',
    };
  },
  watch: {
    date() {
      try {
        const formatDate = this.$str.formatDateTime(this.date, 'yyyy-MM-dd');

        this.$emit('update:value', !!formatDate ? formatDate : null);
      } catch (e) {
        this.$emit('update:value', '');
      }
    },
  },
  mounted() {
    // eslint-disable-next-line max-len
    // TODO: Mirar cómo juntar este componente con VInputDatetimeLocal.vue ya que son iguales y sólo cambia el formateo de la fecha
    this.date = !!this.value ? this.$str.formatDateTime(this.value, 'yyyy-MM-dd') : '';
  },
});
