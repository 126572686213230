import { ActionContext, ActionTree } from 'vuex';
import { State } from '@/store/types';
import { FormState } from '@/store/modules/patient/expedient/modules/form/form.types';
import api from '@/api';

type Context = ActionContext<FormState, State>;

const actions: ActionTree<FormState, State> = {
  async refreshExpedientMeasures({
    commit,
  }: Context, {
    expedientId,
  }: { expedientId: number, treatmentId?: number }) {
    const expedientMeasures = await api.patient.expedient.measures.all(expedientId);

    commit('setMeasures', expedientMeasures);
  },
};

export default actions;
