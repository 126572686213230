
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VFormGroupTextarea',
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    idKey: {
      type: String,
      required: false,
      default: '',
    },
    rows: {
      type: Number,
      required: false,
      default: 8,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    yupErrorsVariable: {
      type: String,
      required: false,
      default: null,
    },
    mask: {
      type: Object,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    textareaClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    emitValue(e) {
      this.$emit('update:value', e.target.value);
    },
  },
});
