
import { defineComponent, watch } from 'vue';
import { mapState, useStore } from 'vuex';
import api from '@/api';
import CalendarEvent from '@/api/objects/CalendarEvent';
import { DateTime } from 'luxon';
import FullCalendar, { CalendarOptions, EventClickArg } from '@fullcalendar/vue3';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import TheProfessionalCalendarEventUpdate
  from '@/views/professional/calendar/parts/TheProfessionalCalendarEventUpdate.vue';
import TheProfessionalCalendarEventCreate
  from '@/views/professional/calendar/parts/TheProfessionalCalendarEventCreate.vue';

export default defineComponent({
  name: 'TheProfessionalCalendar',
  components: {
    TheProfessionalCalendarEventCreate,
    TheProfessionalCalendarEventUpdate,
    FullCalendar,
  },
  computed: {
    ...mapState('user', ['authUser']),
    ...mapState('professional', ['currentProfessional']),
  },
  data() {
    return {
      isLoading: true,
      calendarEvents: [] as CalendarEvent[],
      modalToShow: '',
      startCalendarEventDay: '',
      startCalendarEventDate: DateTime.local() as DateTime,
      calendar: null as any,
      calendarOptions: {
        height: 'auto',
        locales: [esLocale],
        locale: 'es',
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          listPlugin,
          interactionPlugin,
          bootstrap5Plugin,
        ],
        themeSystem: 'bootstrap5',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        },
        initialView: 'timeGridWeek',
        slotMinTime: '07:00:00',
        editable: false,
        selectable: false,
        selectMirror: false,
        dayMaxEvents: true,
        nowIndicator: true,
        weekends: true,
        events: this.pushEventsToCalendar,
        dateClick: this.handleDateSelect,
        eventClick: this.handleCalendarEventClicked,
      } as CalendarOptions,
      selectedCalendarEvent: null as null | CalendarEvent | undefined,
    };
  },
  setup() {
    const {
      state,
      dispatch,
    } = useStore();

    watch(() => state.professional.currentProfessional, (value, oldValue) => {
      // this function loads the professional information if at any time it changes to null
      if (!!oldValue && !value) {
        dispatch('professional/getProfessional');
      }
    });
  },
  async beforeMount() {
    await this.refreshCalendarEventsAndAttributes();
    const dateAux = DateTime.local()
      .toUTC();
    this.startCalendarEventDay = this.$str.formatDateTime(dateAux, 'yyyy-MM-dd HH:mm');

    this.isLoading = false;
  },
  methods: {
    formatDate(date) {
      // FIXME: Add jsDate regExpression in $str parser
      return this.$str.formatDateTime(DateTime.fromJSDate(date), 'HH:mm');
    },
    handleDateSelect(selectInfo: any) {
      this.startCalendarEventDate = DateTime.fromISO(selectInfo.dateStr);

      this.modalToShow = 'the-professional-calendar-event-create';
    },
    handleCalendarEventClicked(clickInfo: EventClickArg) {
      this.selectedCalendarEvent = this.calendarEvents.find((event) => event.id === Number(clickInfo.event.id));

      this.modalToShow = 'the-professional-calendar-event-update';
    },
    /**
     * Loads in the local reactive variable all the professional calendar events.
     */
    async loadCalendarEvents() {
      this.calendarEvents = await api.professional.calendarEvent.all();
    },
    /**
     * Builds the v-calendar attributes with the necessary format to be shown in the calendar.
     */
    async pushEventsToCalendar(start, end, timezone, callback) {
      const events: any[] = [];

      this.calendarEvents.forEach((event) => {
        let className = '';
        let color = '';

        // TODO: Make this come from API or be read in another site
        switch (event.type) {
          case 'consulta': {
            className = 'bg-calendar-primary';
            color = '#69925F';
            break;
          }
          case 'interconsulta': {
            className = 'bg-calendar-secondary';
            color = '#B68D36';
            break;
          }
          case 'evento_general': {
            className = 'bg-calendar-tertiary';
            color = '#757D54';
            break;
          }
          default: {
            className = 'bg-calendar-primary';
            color = '#69925F';
            break;
          }
        }

        // We check if it is an allDay event (from 00:00:00 to 23:59:00);
        let allDay = false;
        const x = DateTime.fromISO(event.startDatetime);
        const startD = this.$str.formatDateTime(event.startDatetime, 'HH:mm:ss');
        const endD = this.$str.formatDateTime(event.endDatetime, 'HH:mm:ss');
        if (startD === '00:00:00' && endD === '23:59:00') {
          allDay = true;
        }

        let eventTitle = `${event.title}, ${this.$str.formatDate(event.startDatetime, 'HH:mm')}
        (${event.type}) ${!!event.description ? event.description : ''}`;

        if (!!event.patient?.user?.name) {
          eventTitle = `${event.patient?.user?.name} ${event.patient?.user?.surname},
            ${this.$str.formatDate(event.startDatetime, 'HH:mm')} | ${event.title} (${event.type})
            | ${!!event.description ? event.description : ''} (${event.patient?.user?.phoneNumber})`;
        }

        events.push(
          {
            id: event.id,
            title: eventTitle,
            start: event.startDatetime,
            end: event.endDatetime,
            textColor: '#FFFFFF',
            allDay,
            color,
            className,
          },
        );
      });

      return events;
    },
    setStartCalendarEventDate() {
      this.startCalendarEventDate = DateTime.local();
    },
    async refreshCalendarEventsAndAttributes() {
      await this.loadCalendarEvents();
    },
    async handleCalendarEventCreated() {
      await this.refreshCalendarEventsAndAttributes();

      this.forceFullCalendarRefreshEvents();
    },
    async handleCalendarEventUpdated() {
      await this.refreshCalendarEventsAndAttributes();

      this.forceFullCalendarRefreshEvents();
    },
    async handleCalendarEventDeleted() {
      await this.refreshCalendarEventsAndAttributes();

      this.forceFullCalendarRefreshEvents();
    },
    /**
     * Forces the FullCalendar to refetch its events and repaint them in the calendar view.
     */
    forceFullCalendarRefreshEvents() {
      this.calendar = this.$refs.calendar;

      this.calendar.getApi()
        .refetchEvents();
    },
  },
});
