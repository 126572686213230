import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b14aa12"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "form-label"
}
const _hoisted_2 = ["value"]
const _hoisted_3 = {
  key: 1,
  class: "invalid-feedback mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (!!_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString(_ctx.$t(_ctx.label)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: _normalizeClass(["form-control read-only", {'bg-transparent': !!_ctx.bgTransparent}]),
      type: "text",
      disabled: "",
      value: _ctx.text
    }, null, 10, _hoisted_2),
    (!!_ctx.disclaimer)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.disclaimer), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}