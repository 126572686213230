import AbstractService from '@/api/services/AbstractService';
import Form from '@/api/objects/Form';

class ExpedientFormMeasuresService extends AbstractService {
  public async all(expedient: number): Promise<Form[]> {
    return this.request('get', this.buildPath('expedient/{expedient}/measures/forms', { expedient }));
  }

  public async create(expedient: number, form: any): Promise<Form[]> {
    return this.request('post', this.buildPath('expedient/{expedient}/measures/form', { expedient }), [], form);
  }
}

export default new ExpedientFormMeasuresService();
