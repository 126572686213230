
import { defineComponent } from 'vue';
// eslint-disable-next-line import/no-cycle
import VButton from '@/components/vendor/basic/button/VButton.vue';
import Patient from '@/api/objects/Patient';
import VModal from '@/components/vendor/modal/VModal.vue';
import ModalHeader from '@/components/vendor/modal/ModalHeader.vue';
import ModalTitle from '@/components/vendor/modal/ModalTitle.vue';
import ModalBody from '@/components/vendor/modal/ModalBody.vue';
import ModalFooter from '@/components/vendor/modal/ModalFooter.vue';
import VFormGroupReadOnlyText from '@/components/vendor/basic/form/VFormGroupReadOnlyText.vue';
import { mapState } from 'vuex';
import VTableHeader from '@/components/vendor/basic/table/VTableHeader.vue';
import VTable from '@/components/vendor/basic/table/VTable.vue';
import api from '@/api';
import VLoader from '@/components/vendor/VLoader.vue';

export default defineComponent({
  name: 'TheAdminPatientShow',
  components: {
    VLoader,
    VTable,
    VTableHeader,
    VFormGroupReadOnlyText,
    ModalFooter,
    ModalBody,
    ModalTitle,
    ModalHeader,
    VModal,
    VButton,
  },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      patient: null as Patient | null,
      isLoading: true,
    };
  },
  computed: {
    ...mapState('app', ['formsSettings']),
    genre() {
      return this.formsSettings.accepted_genre_options.find((o) => o.key === this.patient?.genre).label;
    },
    identityDocType() {
      // eslint-disable-next-line max-len
      return this.formsSettings.accepted_identification_document_types.find((o) => o.key === this.patient?.identityDocType).label;
    },
  },
  async beforeMount() {
    this.isLoading = true;

    // Load patient
    this.patient = await api.admin.patient.show(this.patientId);

    this.isLoading = false;
  },
  methods: {
    async impersonate() {
      await this.$modal.confirm({
        title: this.$t('Iniciar sesión como'),
        // eslint-disable-next-line max-len,
        text: this.$t('Vas a iniciar sesión como el paciente {nombre_paciente} {apellido_paciente}. Podrás ver y hacer todo lo que él y volver a tu perfil de administrador cuando quieras. ¿Quieres continuar?', {
          nombre_paciente: this.patient?.user?.name,
          apellido_paciente: this.patient?.user?.surname,
        }),
        confirmButtonText: this.$t('Confirmar'),
        confirmButtonCallback: this.doImpersonate,
      });
    },
    async doImpersonate() {
      try {
        await this.$store.dispatch('user/impersonateStart', { userId: this.patient?.user?.id });
      } catch (e) {
        // this.$toast.error(this.$t('Ha ocurrido un error al intentar personificar al usuario'));
        await this.$modal.info({
          title: this.$t('Error personificando'),
          text: this.$t('Ha ocurrido un error al intentar personificar al usuario'),
        });
      }
    },
  },
});
