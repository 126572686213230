
import { defineComponent } from 'vue';
import TheDynamicInputAnamnesis
  from '@/views/professional/anamnesis/parts/TheDynamicInputAnamnesis.vue';
import withTimeout from '@/resources/timeOut';
import { mapState } from 'vuex';
import api from '@/api';
import axios, { CancelToken, CancelTokenSource } from 'axios';

export default defineComponent({
  name: 'TheFormGroupAnamnesis',
  components: { TheDynamicInputAnamnesis },
  props: {
    formGroupStructure: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('expedient', ['currentExpedient', 'currentAnamnesis']),
    currentFormGroup() {
      if (this.currentAnamnesis) {
        return this.currentAnamnesis.formGroupByName(this.formGroupStructure.key);
      }

      return null;
    },
  },
  data() {
    return {
      cancelToken: undefined as CancelTokenSource | undefined,
      timers: {
        updateFormGroup: '',
      },
      show: true,
    };
  },
  methods: {
    updateFormGroup() {
      /**
       * A unique cancelToken is generated and passed to a request of updating a form-group, which
       * has a timer and a timeout of about 250.
       */
      this.timers.updateFormGroup = withTimeout(async () => {
        if (this.cancelToken !== undefined) {
          this.cancelToken.cancel();
        }

        this.cancelToken = axios.CancelToken.source();

        await this.doUpdateFormGroup();
      }, this.timers.updateFormGroup, 250);
    },
    async doUpdateFormGroup() {
      const formGroupJSON = {
        _method: 'PUT',
        form_values: this.currentFormGroup.formValuesFormatted(),
      };

      const cancelToken: CancelToken | undefined | any = this.cancelToken?.token;

      try {
        await api.professional.expedients.forms.update(
          this.currentExpedient.id,
          this.currentFormGroup.id,
          formGroupJSON,
          cancelToken,
        );

        this.$emit('form-group-updated');
      } catch (e) {
        if (e.response) {
          this.$toast.error(this.$t('Ha ocurrido un error al guardar los cambios'));
        }

        if (!axios.isCancel(e)) {
          this.cancelToken = undefined;

          throw e;
        }
      }
    },
  },
});
