
import { defineComponent } from 'vue';
import TheProfessionalConsultationScrollItem
  from '@/views/professional/expedients/consultation/TheProfessionalConsultationScrollItem.vue';
import TheProfessionalConsultationShow
  from '@/views/professional/expedients/consultation/TheProfessionalConsultationShow.vue';
import TheProfessionalConsultationScroll
  from '@/views/professional/expedients/consultation/TheProfessionalConsultationScroll.vue';
import useCapabilities from '@/resources/capabilities';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'TheProfessionalConsultation',
  components: {
    TheProfessionalConsultationScroll,
    TheProfessionalConsultationScrollItem,
    TheProfessionalConsultationShow,
  },
  setup() {
    return {
      ...useCapabilities(),
    };
  },
  computed: {
    ...mapState('expedient', ['currentExpedient']),
  },
});
