
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'VInputRadio',
  props: {
    value: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
      default: 'inputId',
    },
    name: {
      type: String,
      required: false,
      default: 'inputName',
    },
    formClass: {
      type: String,
      required: false,
      default: '',
    },
    inputClass: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    selected: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      inputValue: '',
    };
  },
  mounted() {
    this.inputValue = this.selected;
  },
  methods: {
    emitValue(e) {
      this.$emit('update:value', e.target.value);
    },
  },
});
